import { useCallback } from "react";
import { useStore } from "react-redux";
import { showWidgetPropertyPane } from "legacy/actions/widgetActions";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { PAGE_WIDGET_ID } from "legacy/constants/WidgetConstants";
import { getSelectedWidgetsIds } from "legacy/selectors/sagaSelectors";
import { useAppDispatch, useAppSelector } from "store/helpers";
import type { AppState } from "store/types";

export const useTogglePropertyPane = ({
  beforeCallback,
}: {
  beforeCallback?: () => void;
}) => {
  const dispatch = useAppDispatch();
  const store = useStore<AppState>();
  const selectedWidgetIds = useAppSelector(getSelectedWidgetsIds);

  return useCallback(() => {
    beforeCallback?.();

    if (store.getState().legacy.ui.propertyPane.isVisible) {
      dispatch({
        type: ReduxActionTypes.HIDE_PROPERTY_PANE,
      });
    } else {
      const widgetIdToShow =
        selectedWidgetIds.length === 1 ? selectedWidgetIds[0] : PAGE_WIDGET_ID;

      dispatch(showWidgetPropertyPane(widgetIdToShow));
    }
  }, [dispatch, beforeCallback, selectedWidgetIds, store]);
};
