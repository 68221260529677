import { call, take } from "redux-saga/effects";
import { lock } from "store/slices/apisShared/sharedPersistApiLock";
import { Action, type PayloadActionWithMeta } from "store/utils/action";
import { type SagaActionMeta, createSaga } from "../../../utils/saga";
import slice, { type ApiDtoWithPb } from "../slice";
import { getV2ApiId } from "../utils/getApiIdAndName";
import { PersistApiPayload, persistV2ApiSaga } from "./persistV2Api";

function* waitForPersistV2api(params: {
  apiId: string;
  waitForOne?: boolean; // if true, even if the saga isn't running, it will wait
}) {
  if (params.waitForOne) {
    yield take((action: Action) => {
      if (action.type === persistV2ApiSaga.success.type) {
        const api = (
          action as PayloadActionWithMeta<
            ApiDtoWithPb,
            SagaActionMeta<PersistApiPayload>
          >
        ).payload;
        if (getV2ApiId(api) === params.apiId) {
          return true;
        }
      }
      return false;
    });
  } else {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let unlock = () => {};
    try {
      unlock = yield call(lock, params.apiId);
    } catch (e) {
      // do nothing
    } finally {
      unlock();
    }
  }
}

export const waitForPersistV2ApiSaga = createSaga(
  waitForPersistV2api,
  "waitForPersistV2ApiSaga",
  {
    sliceName: slice.name,
  },
);

slice.saga(waitForPersistV2ApiSaga, {
  start: () => undefined,
  success: () => undefined,
  error: () => undefined,
});
