import {
  ApplicationScope,
  RouteDef,
  WidgetTypes,
} from "@superblocksteam/shared";
import { useCallback, useMemo, useRef } from "react";
import { useDispatch, useStore } from "react-redux";
import { Dispatch } from "redux";
import { ApiInfo } from "legacy/constants/ApiConstants";
import { EmbedProperty } from "legacy/constants/EmbeddingConstants";
import { PAGE_WIDGET_ID } from "legacy/constants/WidgetConstants";
import { getCurrentItemScope } from "legacy/selectors/propertyPaneSelectors";
import { useAppDispatch, useAppSelector } from "store/helpers";
import { EventDefinition } from "store/slices/application/events/eventConstants";
import { AppStateVar } from "store/slices/application/stateVars/StateConstants";
import { AppTimer } from "store/slices/application/timers/TimerConstants";
import { AppState } from "store/types";
import { ItemKinds, ItemWithPropertiesType } from "./ItemKindConstants";
import { AiEditsAccessor } from "./accessors/AiEditsAccessor";
import { ApiV1Accessor, ApiV2Accessor } from "./accessors/ApiAccessor";
import { EmbedPropAccessor } from "./accessors/EmbedPropAccessor";
import { EventAccessor } from "./accessors/EventAccessor";
import { NestedItemAccessor } from "./accessors/NestedItemAccessor";
import { RouteAccessor } from "./accessors/RouteAccessor";
import { StateVarAccessor } from "./accessors/StateVarAccessor";
import { TimerAccessor } from "./accessors/TimerAccessor";
import { WidgetAccessor } from "./accessors/WidgetAccessor";
import type { WidgetProps } from "legacy/widgets/BaseWidget";
import type { NestedItemProps } from "legacy/widgets/NestedItem";

/**
 * A type that maps item kinds to the (typescript) type of the properties object of items of that kind.
 */
export interface ItemPropertiesType {
  [ItemKinds.WIDGET]: WidgetProps;
  [ItemKinds.NESTED_ITEM]: NestedItemProps;
  [ItemKinds.TIMER]: AppTimer;
  [ItemKinds.STATE_VAR]: AppStateVar;
  [ItemKinds.API_V1]: ApiInfo;
  [ItemKinds.API_V2]: ApiInfo;
  [ItemKinds.EMBED_PROP]: EmbedProperty;
  [ItemKinds.ROUTE]: RouteDef;
  [ItemKinds.CUSTOM_EVENT]: EventDefinition;
  [ItemKinds.AI_EDITS]: Record<string, unknown>;
}

export interface ItemKindAccessor<ItemKind extends ItemKinds> {
  useItemName: (itemProperties: ItemPropertiesType[ItemKind]) => {
    name: string;
    displayName?: string;
    editable: boolean;
    requiresValidation: boolean;
  };
  itemType: (
    itemProperties: ItemPropertiesType[ItemKind],
  ) => ItemWithPropertiesType;
  useItemProperties: (
    itemId: string,
  ) => ItemPropertiesType[ItemKind] | undefined;
  updateItemProperties: (
    dispatch: Dispatch,
    properties: ItemPropertiesType[ItemKind],
    updates: Record<string, unknown>,
    itemScope: ApplicationScope,
  ) => void;
  deleteItemProperties: (
    dispatch: Dispatch,
    properties: ItemPropertiesType[ItemKind],
    propertyPaths: string[],
    itemScope: ApplicationScope,
  ) => void;
  deleteItem: (
    dispatch: Dispatch,
    itemId: string,
    itemScope: ApplicationScope,
    deleteAllSelected?: boolean,
  ) => void;
  useIsItemPropertyLoading?: (itemId: string, propertyName: string) => boolean;
}

type ItemKindAccessorMap = {
  [ItemKind in ItemKinds]: ItemKindAccessor<ItemKind>;
};

export const ItemKindAccessors: ItemKindAccessorMap = {
  [ItemKinds.WIDGET]: WidgetAccessor,
  [ItemKinds.NESTED_ITEM]: NestedItemAccessor,
  [ItemKinds.TIMER]: TimerAccessor,
  [ItemKinds.STATE_VAR]: StateVarAccessor,
  [ItemKinds.API_V1]: ApiV1Accessor,
  [ItemKinds.API_V2]: ApiV2Accessor,
  [ItemKinds.EMBED_PROP]: EmbedPropAccessor,
  [ItemKinds.ROUTE]: RouteAccessor,
  [ItemKinds.CUSTOM_EVENT]: EventAccessor,
  [ItemKinds.AI_EDITS]: AiEditsAccessor,
};

/**
 * Usage note: if these hooks are used by a component, then itemKind must be constant to avoid violating the rules of hooks.
 * This means that the component probably should have a key that depends on itemKind so that an instance of the component
 * is not reused when itemKind changes.
 */
export function useGetItemProperties<ItemKind extends ItemKinds>(
  itemKind: ItemKind,
  itemId: string,
): ItemPropertiesType[ItemKind] {
  const accessor = ItemKindAccessors[itemKind];
  const itemProperties = accessor.useItemProperties(itemId);
  if (!itemProperties) {
    throw new Error(
      `Cannot find item properties for itemKind: ${itemKind} and itemId: ${itemId}`,
    );
  }

  return itemProperties;
}

export function useItemName<ItemKind extends ItemKinds>(
  itemKind: ItemKind,
  itemProperties: ItemPropertiesType[ItemKind],
) {
  const accessor = ItemKindAccessors[itemKind];
  return accessor.useItemName(itemProperties);
}

const useDefaultIsItemPropertyLoading = () => false;
export function useIsItemPropertyLoading<ItemKind extends ItemKinds>(
  itemKind: ItemKind,
  itemId: string,
  propertyName: string,
) {
  const accessor = ItemKindAccessors[itemKind];
  const useHook =
    accessor.useIsItemPropertyLoading ?? useDefaultIsItemPropertyLoading;
  return useHook(itemId, propertyName);
}

export function useUpdateItemProperties<ItemKind extends ItemKinds>(
  itemKind: ItemKind,
  itemId: string,
) {
  const dispatch = useAppDispatch();

  const accessor = ItemKindAccessors[itemKind];
  const properties = useGetItemProperties<ItemKind>(itemKind, itemId);

  const getItemScope = useCallback(
    (state: AppState) => {
      return getCurrentItemScope(state, itemKind);
    },
    [itemKind],
  );

  const itemScope = useAppSelector(getItemScope);

  const latestPropertiesRef = useRef<ItemPropertiesType[ItemKind]>(properties);

  // Update the latest properties ref so that the memoized functions below always have the latest properties
  // This is necessary because the properties object in the closure can be stale if a property control is unmounted
  // i.e. when the code editor is unmounted when switching actions and formatting is set to run
  latestPropertiesRef.current = properties;

  return useMemo(() => {
    return {
      updateProps: (updates: Record<string, unknown>) => {
        accessor.updateItemProperties(
          dispatch,
          latestPropertiesRef.current,
          updates,
          itemScope,
        );
      },
      deleteProps: (propertyPaths: string[]) => {
        accessor.deleteItemProperties(
          dispatch,
          latestPropertiesRef.current,
          propertyPaths,
          itemScope,
        );
      },
    };
  }, [accessor, dispatch, itemScope]);
}

export function useUpdatePopoverItemProperties<ItemKind extends ItemKinds>() {
  const dispatch = useDispatch();

  // normally you'd use a selector, but we need the entire store, and we only need it on demand in the callback,
  // so cutting down on renders is more important than the overhead of the useSelector
  const store = useStore<AppState>();

  return useCallback(
    (itemKind: ItemKind, itemId: string) => {
      const appState = store.getState();
      const accessor = ItemKindAccessors[itemKind];
      const itemScope = getCurrentItemScope(appState, itemKind);

      return {
        updateProps: (
          properties: ItemPropertiesType[ItemKind],
          updates: Record<string, unknown>,
        ) => {
          accessor.updateItemProperties(
            dispatch,
            properties,
            updates,
            itemScope,
          );
        },
        deleteProps: (
          properties: ItemPropertiesType[ItemKind],
          propertyPaths: string[],
        ) => {
          accessor.deleteItemProperties(
            dispatch,
            properties,
            propertyPaths,
            itemScope,
          );
        },
        deleteItem: (deleteAllSelected?: boolean) => {
          accessor.deleteItem(dispatch, itemId, itemScope, deleteAllSelected);
        },
      };
    },
    [dispatch, store],
  );
}

export function isStatefulItem(
  itemKind: ItemKinds,
  itemId: string,
  itemProperties: ItemPropertiesType[ItemKinds],
): boolean {
  let hasState =
    itemKind === ItemKinds.WIDGET ||
    itemKind === ItemKinds.API_V1 ||
    itemKind === ItemKinds.API_V2 ||
    itemKind === ItemKinds.STATE_VAR ||
    itemKind === ItemKinds.TIMER;
  if (itemKind === ItemKinds.WIDGET && "type" in itemProperties) {
    hasState =
      itemId !== PAGE_WIDGET_ID &&
      itemProperties.type !== WidgetTypes.SECTION_WIDGET &&
      itemProperties.type !== WidgetTypes.CANVAS_WIDGET;
  }
  return hasState;
}
