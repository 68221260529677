import { FontFamily } from "@superblocksteam/shared";
import React from "react";
import {
  DEFAULT_FONT_WEIGHTS,
  LEGACY_AVAILABLE_FONTS,
} from "../typefaceConstants";
import { GeneratedTheme } from "../types";

type ErrorResponse = {
  message: string;
  isValid: false;
};
type SuccessResponse = {
  fontFamilies: Record<string, FontFamily>;
  isValid: true;
};

const fontWeightNameToValue: Record<string, number> = {
  thin: 100,
  lighter: 100,
  extralight: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
  bolder: 900,
};

const getWeightValue = (weight: string): number => {
  const weightValue = parseInt(weight, 10);
  if (isNaN(weightValue)) {
    return fontWeightNameToValue[weight.toLowerCase()] || 400;
  }
  return weightValue;
};

export function extractFontFamilyNames(cssContent?: string): Array<{
  name: string;
  weights: Array<number>;
}> {
  if (!cssContent) return [];
  const fontFamilyData: Record<string, number[]> = {};
  const fontFaceRegex =
    /@font-face\s*{[^}]*font-family:\s*['"]?([^'";]+)['"]?[^}]*}/g;
  const fontWeightRegex =
    /font-weight:\s*(normal|bolder|bold|lighter|light|[1-9]00)(?:\s+([1-9]00))?/gi;
  let match;

  while ((match = fontFaceRegex.exec(cssContent)) !== null) {
    const fontFamily = match[1];
    const fontBlock = match[0];
    const weights = [];
    let weightMatch;
    if (!fontFamily || !fontFamily.trim()) continue;

    while ((weightMatch = fontWeightRegex.exec(fontBlock)) !== null) {
      const startWeight = getWeightValue(weightMatch[1]);
      const endWeight = weightMatch[2]
        ? getWeightValue(weightMatch[2])
        : startWeight;
      for (let weight = startWeight; weight <= endWeight; weight += 100) {
        weights.push(weight);
      }
    }

    // Ensure uniqueness of font weights
    const uniqueWeights = [...new Set(weights)];

    fontFamilyData[fontFamily] = fontFamilyData[fontFamily]
      ? [...new Set([...fontFamilyData[fontFamily], ...uniqueWeights])]
      : uniqueWeights;
  }

  return Object.entries(fontFamilyData).map(([name, weights]) => {
    weights.sort();
    return {
      name,
      weights: weights.length === 0 ? [400] : weights,
    };
  });
}

export async function validateAndParseCustomFontUrl(
  _url: string,
): Promise<SuccessResponse | ErrorResponse> {
  if (!_url) {
    return {
      message: "URL is required",
      isValid: false,
    };
  }
  let url = _url;
  // if its not a valid url
  if (!_url.startsWith("http://") && !_url.startsWith("https://")) {
    url = `https://${_url}`;
  }
  try {
    new URL(url);
  } catch (e) {
    return {
      message: "Invalid URL",
      isValid: false,
    };
  }
  // get the url and ensure that the response type is text/css
  try {
    const response = await fetch(url);
    // if response is not 200
    if (!response.ok) {
      return {
        message: "Failed to fetch valid CSS file",
        isValid: false,
      };
    }
    if (!response.headers.get("content-type")?.includes("text/css")) {
      return {
        message: "URL should point to a CSS file",
        isValid: false,
      };
    }
    const cssContent = await response.text();

    // Validate and extract font family names
    const fontFamilyNames = extractFontFamilyNames(cssContent);

    if (fontFamilyNames.length === 0) {
      return {
        message: "No font families found in the CSS file",
        isValid: false,
      };
    }
    const fontFamilies = fontFamilyNames.reduce((acc, { name, weights }) => {
      acc[name] = {
        name,
        type: "custom",
        key: name, // this needs to be unique
        url,
        weights,
      };
      return acc;
    }, {} as Record<string, FontFamily>);

    return {
      fontFamilies,
      isValid: true,
    };
  } catch (e) {
    return {
      message: "Failed to fetch valid CSS file",
      isValid: false,
    };
  }
}

const linkQuerySelector = (fontName: string, isTemporary?: boolean) => {
  return `link[data-font${isTemporary ? "-temp" : ""}="${fontName}"]`;
};

export function loadFont(
  themeFont: FontFamily,
  options?: { isTemporary: boolean },
) {
  if (themeFont.type === "google") {
    const fontName = themeFont.key;
    const weights = (themeFont.weights ?? DEFAULT_FONT_WEIGHTS).join(",");
    // Check if the font is already loaded
    const existingLink = document.querySelector(
      linkQuerySelector(fontName, options?.isTemporary),
    );
    if (existingLink) {
      return; // Font is already loaded
    }

    // Create a new link element
    const link = document.createElement("link");
    link.href = `https://fonts.googleapis.com/css?family=${fontName}:${weights}${
      options?.isTemporary ? "&display=swap" : ""
    }`;
    link.rel = "stylesheet";
    link.type = "text/css";
    link.dataset.font = fontName; // Custom attribute to easily identify the link later

    // Append the link element to the document head
    document.head.appendChild(link);
  } else if (themeFont.type === "custom" && themeFont.url) {
    const fontName = themeFont.key;
    const existingLink = document.querySelector(
      linkQuerySelector(fontName, options?.isTemporary),
    );
    if (existingLink) {
      return; // Font is already loaded
    }
    // Create a new <link> element for the font URL
    const link = document.createElement("link");
    link.href = themeFont.url;
    link.rel = "stylesheet";
    link.type = "text/css";
    link.dataset.font = fontName; // Custom attribute to easily identify the link later
    document.head.appendChild(link);
  }
}

export function removeFont(
  fontKey: string,
  options?: { isTemporary: boolean },
) {
  const link = document.querySelector(
    linkQuerySelector(fontKey, options?.isTemporary),
  );
  if (link) {
    link.remove();
  }
}

export function loadThemeFonts(generatedTheme: GeneratedTheme) {
  const avaiableFonts = generatedTheme.availableFonts;
  Object.values(avaiableFonts).forEach((font) => loadFont(font));
  // remove fonts that are not used in the theme
  const existingLinks = document.querySelectorAll("link[data-font]");
  existingLinks.forEach((link) => {
    const fontKey = link.getAttribute("data-font");
    if (fontKey && !avaiableFonts[fontKey]) {
      link.remove();
    }
  });
}

export const getAvailableFontFamilies = (
  themeFontFamilies?: Record<string, FontFamily>,
): Record<string, FontFamily> => {
  return themeFontFamilies ?? LEGACY_AVAILABLE_FONTS;
};

export const getFontFamilyOptions = (
  themeFontFamilies?: Record<string, FontFamily>,
): Array<{ value: string; label: string | JSX.Element }> => {
  const options = Object.values(
    getAvailableFontFamilies(themeFontFamilies),
  ).map((font) => ({
    label: (
      <span style={{ fontFamily: `${font.key}, var(--font-family)` }}>
        {font.name}
      </span>
    ),
    value: font.key,
  }));
  options.sort((a, b) => a.value.localeCompare(b.value));
  return options;
};
