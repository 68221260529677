import { Organization } from "@superblocksteam/shared";
import { call } from "redux-saga/effects";
import { EntitiesStateWithMeta, ROOT } from "store/utils/types";
import { createSaga } from "../../../utils/saga";
import { getAllOrganizationsByUser } from "../client";
import slice, { OrganizationMeta } from "../slice";

interface GetOrganizationsPayload {
  userId: string;
}

function* getOrganizationsInternal({ userId }: GetOrganizationsPayload) {
  const result: Organization[] = yield call(getAllOrganizationsByUser, userId);
  return result;
}

export const getOrganizationsSaga = createSaga(
  getOrganizationsInternal,
  "getOrganizationsSaga",
  { sliceName: "organizations" },
);

slice.saga(getOrganizationsSaga, {
  start(state, { payload }) {
    state.loading[ROOT] = true;
    state.meta[ROOT] = { userId: payload.userId };
    delete state.errors[ROOT];
  },
  success(state, { payload }) {
    modifyState(state, payload);
    delete state.loading[ROOT];
  },
  error(state, { payload }) {
    state.errors[ROOT] = { error: payload };
    delete state.loading[ROOT];
  },
  store(state, { payload }) {
    modifyState(state, payload);
  },
});

const modifyState = (
  state: EntitiesStateWithMeta<Organization, OrganizationMeta>,
  payload: Organization[],
): void => {
  state.entities = payload.reduce((result, organization) => {
    result[organization.id] = organization;
    return result;
  }, {} as Record<string, Organization>);
};
