import { ApplicationScope } from "@superblocksteam/shared";
import { set } from "dot-object";
import { useMemo } from "react";
import { Dispatch } from "redux";
import { mergeUpdatesWithBindingsOrTriggers } from "legacy/utils/DynamicBindingUtils";
import { useAppSelector } from "store/helpers";
import { AppStateVar } from "store/slices/application/stateVars/StateConstants";
import { getStateVarById } from "store/slices/application/stateVars/selectors";
import {
  updateStateVars,
  deleteStateVar,
} from "store/slices/application/stateVars/stateVarsActions";
import { fastClone } from "utils/clone";
import { NOOP } from "utils/function";
import { STATE_VAR_PROPERTIES } from "../../Explorer/StateVarProperties";
import { ItemKinds, ItemTypeNonWidget } from "../ItemKindConstants";
import type { ItemKindAccessor } from "../ItemKinds";

export const StateVarAccessor: ItemKindAccessor<ItemKinds.STATE_VAR> = {
  useItemName: (itemProperties) =>
    useMemo(() => {
      return {
        name: itemProperties.name,
        editable: true,
        requiresValidation: true,
      };
    }, [itemProperties.name]),
  itemType: (itemProperties) => ItemTypeNonWidget.STATE_VAR,
  useItemProperties: (itemId: string) => {
    return useAppSelector((state) => getStateVarById(state, itemId));
  },
  updateItemProperties: (
    dispatch: Dispatch,
    properties: AppStateVar,
    updates: Record<string, unknown>,
    itemScope: ApplicationScope,
  ) => {
    const changes: AppStateVar = fastClone(properties);

    const updatesWithBindings = mergeUpdatesWithBindingsOrTriggers(
      properties,
      STATE_VAR_PROPERTIES,
      updates,
    );
    for (const [key, value] of Object.entries(updatesWithBindings)) {
      set(key, value, changes);
    }
    dispatch(
      updateStateVars({
        scope: itemScope,
        stateVars: { [properties.id]: changes },
      }),
    );
  },
  deleteItemProperties: NOOP,
  deleteItem: (dispatch, stateVarId, itemScope) => {
    dispatch(deleteStateVar(itemScope, stateVarId));
  },
};
