import {
  getNextEntityName,
  sanitizeV2RequestBody,
} from "@superblocksteam/shared";
import { call, put, select } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import { updatePartialApiInfo } from "legacy/actions/apiActions";
import { TriggerStepType } from "legacy/constants/ActionConstants";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { getCurrentBranch } from "legacy/selectors/editorSelectors";
import { getAllEntityNames } from "legacy/selectors/sagaSelectors";

import { getV2ApiId } from "store/slices/apisV2/utils/getApiIdAndName";
import { HttpError, ROOT } from "store/utils/types";
import { signAndUpdateApi } from "utils/resource-signing";
import { API_STATUS_CODES } from "../../../../legacy/constants/ApiConstants";
import selectLastSuccessfulWrite from "../../../../legacy/selectors/successfulWriteSelector";
import logger from "../../../../utils/logger";
import {
  findAndMarshalProtoBlocks,
  findAndUnMarshalProtoBlocks,
} from "../../../../utils/marshalProto";
import { createSaga } from "../../../utils/saga";

import { ApiTriggerType } from "../../apis/types";
import { lock } from "../../apisShared/sharedPersistApiLock";
import * as BackendTypes from "../backend-types";
import { createV2Api } from "../client";
import slice, { type ApiDtoWithPb } from "../slice";
import { getTriggerTypeFromApi, getUpdatedTime } from "../utils/api-utils";

/*
 *  Some API differences between v1 and v2
 *  - no separate creation endpoint for workflows and jobs in v2
 *
 */

export function* createV2ApiInternal({
  payload,
  pageId,
  prepopulatedName,
  skipTriggers = false,
  skipRename = false,
}: {
  payload: BackendTypes.Api;
  pageId?: string;
  prepopulatedName?: string;
  skipTriggers?: boolean;
  skipRename?: boolean;
}): Generator<unknown, ApiDtoWithPb, any> {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  let unlock = () => {};
  try {
    unlock = yield call(lock, payload.metadata.id);

    const triggerType = getTriggerTypeFromApi(payload);
    const lastSuccessfulWrite: Date = yield select(selectLastSuccessfulWrite);
    if (triggerType === ApiTriggerType.UI) {
      if (!pageId) throw new Error("PageId is required for UI trigger type");
      if (skipRename && prepopulatedName) {
        payload.metadata.name = prepopulatedName;
      } else {
        const allNames: string[] = yield select(getAllEntityNames);
        const name = getNextEntityName(prepopulatedName ?? "API", allNames);
        payload.metadata.name = name;
      }
    }
    findAndMarshalProtoBlocks(payload);
    sanitizeV2RequestBody(payload);

    const branch: ReturnType<typeof getCurrentBranch> = yield select(
      getCurrentBranch,
    );

    try {
      yield call(signAndUpdateApi, payload);
    } catch (e: any) {
      throw new Error("Failed to sign API");
    }

    const result: Awaited<ReturnType<typeof createV2Api>> = yield call(
      createV2Api,
      branch?.name,
      payload,
      pageId,
      lastSuccessfulWrite,
    );
    const { apiPb, updated } = result;
    if (apiPb == null) {
      throw new Error("apiPb was not found");
    }
    yield put({
      type: ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE,
      payload: getUpdatedTime(updated),
    });

    const apiId = apiPb.metadata.id;

    if (!skipTriggers) {
      yield put(
        updatePartialApiInfo({
          id: apiId,
          data: {
            id: apiId,
            onError: [
              {
                id: uuidv4(),
                type: TriggerStepType.SHOW_ALERT,
                message: `{{${apiPb.metadata.name}.error}}`,
                style: "error",
              },
            ],
            dynamicTriggerPathList: [{ key: "onError" }],
          },
        }),
      );
    }

    return result as ApiDtoWithPb;
  } catch (err) {
    logger.debug(`Errored when persisting the API: ${err}`);
    throw err;
  } finally {
    unlock();
  }
}

export const createV2ApiSaga = createSaga(
  createV2ApiInternal,
  "createV2ApiSaga",
  {
    sliceName: slice.name,
  },
);

slice.saga(createV2ApiSaga, {
  start(state, { payload }) {
    state.loading[ROOT] = true;
    delete state.errors[ROOT];
    // newly created APIs have no deps
  },
  success(state, { payload, meta }) {
    findAndUnMarshalProtoBlocks(payload);
    state.entities[getV2ApiId(payload)] = payload;
    delete state.loading[ROOT];
    state.meta[getV2ApiId(payload)] = {};
  },
  error(state, { payload, meta }) {
    state.errors[ROOT] = { error: payload };
    delete state.loading[ROOT];

    if (
      payload instanceof HttpError &&
      payload.code === API_STATUS_CODES.RESOURCE_CONFLICT
    ) {
      state.errors.stale = { error: payload };
    }
  },
});
