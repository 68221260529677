import { AgentsHealthStatus } from "@superblocksteam/shared";
import { Layout } from "antd";
import React from "react";
import styled from "styled-components";
import { VIEW_AGENTS } from "constants/rbac";
import { useSaga } from "hooks/store";
import { useFeatureFlag } from "hooks/ui";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { getAppProfilesInCurrentMode } from "legacy/selectors/applicationSelectors";
import { useAppSelector } from "store/helpers";
import { getAgentsSaga, selectAgentsHealthStatus } from "store/slices/agents";
import { getEnvironment } from "store/slices/application/selectors";
import { Flag } from "store/slices/featureFlags";
import { NoActiveAgentDetailedMessage } from "utils/error/agentError";
import { SUPERBLOCKS_UI_POLL_AGENT_INTERVAL_MS } from "../../env";
import { useInterval } from "../../hooks/ui/useInterval";
import { getUserCurrentOrgId } from "../../legacy/selectors/organizationSelectors";
import {
  selectOrganizationById,
  selectOnlyOrganizationIsCloud,
} from "../../store/slices/organizations";

const StyledHeader = styled(Layout.Header)`
  background-color: #cc6666;
  padding: 0;
  width: 100%;
  height: ${({ theme }) => theme.legacy.agentDisasterHeaderHeight};
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const StyledMessage = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
  height: ${({ theme }) => theme.legacy.agentDisasterHeaderHeight};
  line-height: ${({ theme }) => theme.legacy.agentDisasterHeaderHeight};

  a {
    color: white;
    text-decoration: underline;
  }
`;

export const AgentDisasterHeader = () => {
  const orgIsCloud = useAppSelector(selectOnlyOrganizationIsCloud);
  if (orgIsCloud) {
    return null;
  }
  return <AgentDisasterHeaderInner />;
};

const AgentDisasterHeaderInner = () => {
  const organizationId = useAppSelector(getUserCurrentOrgId) ?? "";
  const organization = useAppSelector((state) =>
    selectOrganizationById(state, organizationId),
  );
  const enableProfiles = useFeatureFlag(Flag.ENABLE_PROFILES);
  const profiles = useAppSelector(getAppProfilesInCurrentMode);
  const profile = profiles?.selected;
  const environment = useAppSelector(getEnvironment);
  const agentsHealthStatus = useAppSelector(
    selectAgentsHealthStatus(
      organization?.agentType,
      enableProfiles ? profile?.key ?? "" : environment,
    ),
  );
  const showDisasterHeader =
    agentsHealthStatus === AgentsHealthStatus.NO_AGENTS ||
    agentsHealthStatus === AgentsHealthStatus.NO_AGENTS_FOR_PROFILE ||
    agentsHealthStatus === AgentsHealthStatus.NO_REACHABLE_AGENTS;
  const [canManageAgents] = useAuthorizationCheck([VIEW_AGENTS]);

  const [getAgents] = useSaga(getAgentsSaga);
  useInterval(
    async () => await getAgents({ organization }),
    SUPERBLOCKS_UI_POLL_AGENT_INTERVAL_MS,
  );

  return !showDisasterHeader ? null : (
    <StyledHeader data-test="agent-disaster-test">
      <StyledMessage>
        <NoActiveAgentDetailedMessage
          organizationAgentType={organization?.agentType}
          userIsAdmin={canManageAgents}
          agentsHealthStatus={agentsHealthStatus}
          profile={profile}
        />
      </StyledMessage>
    </StyledHeader>
  );
};
