import { Tooltip } from "antd";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { ItemKinds } from "./ItemKindConstants";
import { useV2PropertyPaneEnabled } from "./useV2PropertyPaneEnabled";

type Props = {
  itemKind: ItemKinds;
  tooltip?: string | JSX.Element;
  label: string;
  subheader?: string | JSX.Element;
  maxLines?: number;
};

const V1Label = styled.label`
  color: ${colors.GREY_800};
  font-weight: 500;
  margin-right: 2px;
`;

const V2Label = styled.label`
  font-size: 12px;
  font-weight: 400;
  color: ${colors.GREY_500};
  margin-right: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 1.2;
  cursor: inherit; // uses default or pointer (for props with blue-dot menu) depending on parent styling
`;

const AutoTooltipPropertyHelpLabel: React.FC<Props> = (props) => {
  const ref = useRef<HTMLLabelElement | null>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [tooltipContents, setTooltipContents] = useState<React.ReactNode>(
    props.tooltip,
  );

  useEffect(() => {
    const checkOverflow = () => {
      const element = ref.current;
      if (element) {
        setIsOverflowing(element.scrollWidth > element.clientWidth);
      }
    };
    const debouncedCheckOverflow = debounce(checkOverflow, 100);

    const resizeObserver = new ResizeObserver(debouncedCheckOverflow);
    ref.current && resizeObserver.observe(ref.current);

    // Initial check
    checkOverflow();

    return () => {
      resizeObserver.disconnect();
    };
  }, [props.label]);

  useEffect(() => {
    if (isOverflowing) {
      setTooltipContents(
        props.tooltip && props.tooltip !== props.label ? (
          <div>
            <div style={{ fontWeight: 500 }}>{props.label}</div>
            <div>{props.tooltip}</div>
          </div>
        ) : (
          <div style={{ fontWeight: 500 }}>{props.label}</div>
        ),
      );
    } else {
      setTooltipContents(props.tooltip);
    }
  }, [isOverflowing, props.label, props.tooltip]);

  const labelContent = <V2Label ref={ref}>{props.label}</V2Label>;

  const labelWithTooltip = tooltipContents ? (
    <Tooltip title={tooltipContents} placement="left">
      {labelContent}
    </Tooltip>
  ) : (
    labelContent
  );

  if (props.subheader) {
    return (
      <div style={{ width: "100%" }}>
        {labelWithTooltip}
        <div>{props.subheader}</div>
      </div>
    );
  }
  return labelWithTooltip;
};

const PropertyHelpLabel = (props: Props) => {
  const isV2Panel = useV2PropertyPaneEnabled(props.itemKind);
  if (isV2Panel) {
    return <AutoTooltipPropertyHelpLabel {...props} />;
  }
  if (props.tooltip) {
    return (
      <div>
        <Tooltip title={props.tooltip} placement={"left"}>
          <V1Label style={{ cursor: "help" }}>{props.label}</V1Label>
        </Tooltip>
        {props.subheader && <div>{props.subheader}</div>}
      </div>
    );
  }

  return (
    <div>
      <V1Label>{props.label}</V1Label>
      {props.subheader && <div>{props.subheader}</div>}
    </div>
  );
};

export default PropertyHelpLabel;
