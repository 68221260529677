import { ExecutionParam } from "@superblocksteam/shared";
import { find, isEmpty } from "lodash";

export const findKeyInExecutionParams = (
  params: ExecutionParam[] | undefined,
  key: string,
) => {
  return find(params, (item) => item.key === key);
};

export const convertParamToJSON = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (err) {
    return value;
  }
};

export const removeEmptyItemInJSON = (params: unknown) => {
  if (isEmpty(params)) {
    return params;
  }
  return Object.fromEntries(
    Object.entries(params as Record<string, unknown>).filter(
      ([key, value]) => !isEmpty(key) || !isEmpty(value),
    ),
  );
};
