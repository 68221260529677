import { PlayCircleOutlined } from "@ant-design/icons";
import { Card, Form, Row, Select, Spin, Typography } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Button } from "components/ui/Button";
import JsonView from "components/ui/JsonView";
import { FullWidthSpace } from "components/ui/Space";
import { useIsControlFlowEnabled } from "hooks/api/useIsControlFlowEnabled";
import { useSaga } from "hooks/store";
import {
  EventType,
  ExecuteActionCallStack,
} from "legacy/constants/ActionConstants";
import { findV2Error } from "legacy/utils/ApiNotificationUtility";
import { getOrderedActions } from "pages/Editors/ApiEditor/ApiCanvas/utils";
import { extractCurrentBranchFromSearchStringOrStorage } from "pages/Repositories/utils";
import { useAppSelector } from "store/helpers";
import { executeApiSaga, fetchApiSaga } from "store/slices/apis";
import { getAllWorkflows } from "store/slices/apis/client";
import { ApiExecutionResponseDto } from "store/slices/apis/types";
import {
  ExecutionResponse,
  executeV2ApiSaga,
  fetchV2ApiSaga,
} from "store/slices/apisV2";
import { ApiDtoWithPb } from "store/slices/apisV2/slice";
import { selectOrganizations } from "store/slices/organizations";

interface Props {
  environment: string;
  workflowId: string;
  onChange: (value: string) => unknown;
}

const getWorflowName = (workflow: ApiDtoWithPb) => {
  return (
    workflow?.apiPb?.metadata.name ?? workflow?.name ?? workflow?.actions?.name
  );
};

const WorkflowForm = ({ environment, workflowId, onChange }: Props) => {
  const organizations = useSelector(selectOrganizations);
  const organizationId = Object.keys(organizations)[0];

  const [workflows, setWorkflows] = useState<ApiDtoWithPb[]>([]);
  const [workflow, setSelectedWorkflow] = useState<ApiDtoWithPb | undefined>(
    undefined,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isRunningApi, setIsRunningApi] = useState(false);

  const isV2 = useIsControlFlowEnabled();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const workflows = await getAllWorkflows();

      const deployedWorkflows = workflows
        .filter((wf) => wf.isDeployed)
        .sort((first, second) =>
          first.actions && second.actions
            ? first?.actions?.name.localeCompare(second?.actions?.name)
            : 0,
        );

      setWorkflows(deployedWorkflows);

      if (workflowId) {
        setSelectedWorkflow(
          workflows.find((workflow) => workflow.id === workflowId),
        );
      }
      setIsLoading(false);
    })();
  }, [environment, organizationId, workflowId]);

  const [executeApi] = useSaga(executeApiSaga);
  const [fetchApi] = useSaga(fetchApiSaga);

  const [executeV2Api] = useSaga(executeV2ApiSaga);
  const [fetchV2Api] = useSaga(fetchV2ApiSaga);

  const executionResult:
    | Readonly<ExecutionResponse>
    | Readonly<ApiExecutionResponseDto>
    | null
    | undefined = useAppSelector((state) => {
    if (!workflow || !workflow.id) {
      return null;
    }
    if (isV2) {
      return state.apisV2.meta[workflow.id]
        ?.executionResult as Readonly<ExecutionResponse>;
    } else {
      return state.apis.meta[workflow.id]?.executionResult;
    }
  });

  const apiOutput:
    | null
    | unknown
    | {
        error: string;
      } = useMemo(() => {
    if (!executionResult || !workflow) {
      return null;
    }
    if (isV2) {
      const execResult = executionResult as ExecutionResponse;
      const error = findV2Error(execResult);
      if (error) {
        return {
          error,
        };
      }
      const output =
        "systemError" in execResult ? undefined : execResult?.output?.result;
      return output;
    } else {
      const execResult = executionResult as ApiExecutionResponseDto;
      if (execResult?.context?.error) {
        return {
          error: execResult?.context?.error,
        };
      }

      const actions = getOrderedActions(workflow.actions);

      if (!actions || actions.length <= 0) {
        return;
      }

      const lastStep = actions[actions.length - 1];
      const executionOutputs = execResult?.context?.outputs;

      for (const action of actions) {
        const error = executionOutputs?.[action.name]?.error;
        if (error) {
          return { error };
        }
      }

      return executionOutputs ? executionOutputs[lastStep.name]?.output : {};
    }
  }, [executionResult, workflow, isV2]);

  const handleRunApi = useCallback(
    async (e: unknown, id?: string, workflowName?: string) => {
      if (!workflow && !id) {
        return;
      }
      if (!id) {
        id = workflow?.id ?? "";
      }
      if (!workflowName) {
        workflowName =
          workflow?.apiPb?.metadata.name ??
          workflow?.name ??
          workflow?.actions?.name;
      }
      setIsRunningApi(true);

      const callStack: ExecuteActionCallStack = [
        {
          type: EventType.ON_RUN_CLICK,
          propertyPath: `${
            workflowName ?? "<API>"
          }.<intergration form manual run>`,
        },
      ];

      if (isV2) {
        const branch = extractCurrentBranchFromSearchStringOrStorage(
          location.search,
          id,
          navigate,
        );
        await fetchV2Api({ apiId: id, environment, branch });
        await executeV2Api({
          apiId: id,
          environment,
          viewMode: true,
          eventType: EventType.ON_RUN_CLICK,
          params: [],
          notifyOnSystemError: true,
          manualRun: true,
          callStack,
          includeOutputs: false,
        });
      } else {
        await fetchApi({ apiId: id, environment });
        await executeApi({
          apiId: id,
          environment,
          viewMode: true,
          eventType: EventType.ON_RUN_CLICK,
          params: [],
          notifyOnSystemError: true,
          manualRun: true,
          callStack,
        });
      }

      setIsRunningApi(false);
    },
    [
      environment,
      executeApi,
      fetchApi,
      workflow,
      isV2,
      fetchV2Api,
      executeV2Api,
      location.search,
      navigate,
    ],
  );

  const handleWorkflowChange = useCallback(
    (workflowId: string) => {
      const workflow = workflows.find((workflow) => workflow.id === workflowId);
      const workflowName =
        workflow?.apiPb?.metadata.name ??
        workflow?.name ??
        workflow?.actions?.name;
      onChange(workflowId);
      setSelectedWorkflow(workflow);
      handleRunApi(undefined, workflowId, workflowName);
    },
    [onChange, handleRunApi, workflows],
  );

  const filterWorkflows = useCallback(
    (input: any, option: any) =>
      option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    [],
  );

  const workflowData = useMemo(() => {
    const data =
      typeof apiOutput === "object" ? apiOutput ?? {} : { response: apiOutput };

    const dataKey = workflow
      ? `${workflow?.actions?.name}${
          typeof apiOutput === "object" ? ".response" : ""
        }`
      : null;

    if (dataKey) {
      return {
        [dataKey]: data,
      };
    }

    return data;
  }, [apiOutput, workflow]);

  return (
    <Spin spinning={isLoading}>
      <Form
        layout="vertical"
        key={workflowId}
        initialValues={{ workflow: workflowId }}
      >
        <Form.Item name="workflow" required>
          <Select
            data-test="fetch-dynamically-workflow"
            showSearch
            placeholder="Select Workflow"
            onChange={handleWorkflowChange}
            filterOption={filterWorkflows}
          >
            <Select.Option value="" key="">
              None
            </Select.Option>
            {workflows.map((workflow) => {
              const wfName = getWorflowName(workflow);
              return (
                <Select.Option
                  data-test={`workflow-option-${wfName}`}
                  value={workflow.id}
                  key={workflow.id}
                >
                  {wfName}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
      {workflow && (
        <FullWidthSpace direction="vertical">
          <Row justify="space-between">
            <Typography.Text strong>
              2. Run Workflow and Verify Results
            </Typography.Text>
            <Button
              icon={<PlayCircleOutlined />}
              onClick={handleRunApi}
              loading={isRunningApi}
            >
              Run Workflow
            </Button>
          </Row>
          <Spin spinning={isRunningApi}>
            <Card
              data-test="fetch-dynamically-response"
              bodyStyle={{
                padding: "8px",
              }}
            >
              <JsonView
                data={workflowData}
                width="100%"
                maxHeight={300}
                isOpenByDefault
                displayObjectSize={false}
                scrollbarBehavior="visible"
              />
            </Card>
            <Typography.Text type="secondary">
              To reference the Workflow output, use{" "}
            </Typography.Text>
            <Typography.Text type="secondary" strong>
              {`{{${getWorflowName(workflow)}.response}}`}
            </Typography.Text>
          </Spin>
          <Typography.Text strong>
            3. Add Credentials based on Workflow results
          </Typography.Text>
        </FullWidthSpace>
      )}
    </Spin>
  );
};

export default WorkflowForm;
