let propertyControl: any = null;
let propertySection: any = null;

export function getPropertyControl() {
  if (!propertyControl)
    throw new Error("Circular dependency in property controls");
  return propertyControl;
}

export function getPropertySection() {
  if (!propertySection)
    throw new Error("Circular dependency in property controls");
  return propertySection;
}

export function setPropertyControl(p: any) {
  propertyControl = p;
}

export function setPropertySection(p: any) {
  propertySection = p;
}
