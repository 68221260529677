import {
  getNextEntityName,
  sanitizeV2RequestBody,
} from "@superblocksteam/shared";
import { call, put, select } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import { updatePartialApiInfo } from "legacy/actions/apiActions";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { getDataTree } from "legacy/selectors/dataTreeSelectors";
import {
  getCurrentBranch,
  getCurrentPageId,
} from "legacy/selectors/editorSelectors";
import {
  getApiInfoById,
  getAllEntityNames,
} from "legacy/selectors/sagaSelectors";
import { createFakeDataTree } from "store/helpers/refactoring";
import { ApiUnionTriggerType } from "store/slices/apisShared";
import { createDuplicatePartialApiInfo } from "store/slices/apisShared/createDuplicatePartialApiInfo";
import {
  getV2ApiId,
  getV2ApiName,
} from "store/slices/apisV2/utils/getApiIdAndName";
import {
  findAndMarshalProtoBlocks,
  findAndUnMarshalProtoBlocks,
} from "../../../../utils/marshalProto";
import { createSaga } from "../../../utils/saga";
import { createV2Api } from "../client";
import { selectAllV2Apis } from "../selectors";
import slice, { type ApiDtoWithPb } from "../slice";
import { getTriggerTypeFromApi, getUpdatedTime } from "../utils/api-utils";
import { createDuplicateV2ApiPayload } from "../utils/createDuplicateV2ApiPayload";

function* duplicateV2ApiInternal(params: {
  apiId: string;
}): Generator<unknown, ApiDtoWithPb, any> {
  const origApiId = params.apiId;
  const allNames: string[] = yield select(getAllEntityNames);
  const apis: ReturnType<typeof selectAllV2Apis> = yield select(
    selectAllV2Apis,
  );

  const originalApi: undefined | ApiDtoWithPb = apis[origApiId];
  if (originalApi == null) {
    throw new Error(`API with ID "${origApiId}" does not exist.`);
  }

  const newName = getNextEntityName(`${originalApi.name}_copy`, allNames);
  const payload = createDuplicateV2ApiPayload(originalApi.apiPb, {
    newName,
    newId: uuidv4(),
  });

  findAndMarshalProtoBlocks(payload);
  sanitizeV2RequestBody(payload);

  const generator = (triggerType: ApiUnionTriggerType) => {
    switch (triggerType) {
      case ApiUnionTriggerType.UI:
        return createV2Api;
      case ApiUnionTriggerType.WORKFLOW:
      case ApiUnionTriggerType.SCHEDULE:
        // This code path is unreachable for now
        throw new Error("Non UI API duplication not yet supported.");
      default:
        throw new Error("Invalid Trigger Type");
    }
  };

  const branch: ReturnType<typeof getCurrentBranch> = yield select(
    getCurrentBranch,
  );
  const pageId = yield select(getCurrentPageId);
  const result: Awaited<ReturnType<typeof createV2Api>> = yield call(
    generator(getTriggerTypeFromApi(payload)),
    branch?.name,
    payload,
    pageId,
  );

  const { apiPb, updated } = result;
  if (apiPb == null) {
    throw new Error("apiPb was not found");
  }

  yield put({
    type: ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE,
    payload: getUpdatedTime(updated),
  });

  const dataTree = yield select(getDataTree);
  const fakeDataTree = createFakeDataTree(dataTree);

  const origApiInfo = yield select(getApiInfoById, origApiId);
  const newPartialInfo = yield call(() =>
    createDuplicatePartialApiInfo(origApiInfo, {
      newId: payload.metadata.id,
      originalApiName: getV2ApiName(originalApi),
      newApiName: apiPb.metadata.name,
      fakeDataTree,
    }),
  );

  yield put(
    updatePartialApiInfo({
      id: apiPb.metadata.id,
      data: newPartialInfo,
    }),
  );

  return result as ApiDtoWithPb;
}

export const duplicateV2ApiSaga = createSaga(
  duplicateV2ApiInternal,
  "duplicateV2ApiSaga",
  {
    sliceName: slice.name,
  },
);

slice.saga(duplicateV2ApiSaga, {
  start(state, { payload }) {
    state.loading[payload.apiId] = true;
  },
  success(state, { payload, meta }) {
    findAndUnMarshalProtoBlocks(payload.apiPb);
    state.entities[getV2ApiId(payload)] = payload;
    delete state.loading[meta.args.apiId];
  },
  error(state, { payload, meta }) {
    state.errors[meta.args.apiId] = { error: payload };
    delete state.loading[meta.args.apiId];
  },
});
