import { camelCaseToDisplay } from "@superblocksteam/shared";
import { Input, Dropdown } from "antd";
import { TextAreaRef } from "antd/lib/input/TextArea";
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
  useImperativeHandle,
  forwardRef,
  useLayoutEffect,
} from "react";
import styled from "styled-components";
import { ReactComponent as ArrowIcon } from "assets/icons/common/arrow-left.svg";
import { usePrevious } from "hooks/ui";
import { colors } from "styles/colors";

const { TextArea } = Input;

type MenuItem = {
  key: string;
  label: string | JSX.Element;
  type: "value" | "category";
};

const StyledTextArea = styled(TextArea)`
  margin-bottom: 16px;
  border: none;
  outline: none;
  box-shadow: none;
  scrollbar-color: light;
  color-scheme: light;
  color: transparent;
  caret-color: ${colors.GREY_500};
`;

const TagOverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  // hide the scrollbar, because the text area has one
  // but keep the space for it so the text area doesn't shift
  ::-webkit-scrollbar {
    visibility: hidden;
  }
  z-index: 10;
  pointer-events: none;
  padding: 0px 12px;
  white-space: pre-wrap;
  white-space: pre-wrap;
  word-wrap: break-word;
  box-sizing: border-box;
  font-family: var(--font-family);
  font-size: 14px;
  font-feature-settings: normal;
`;

const Content = styled.span`
  color: ${colors.GREY_700};
`;

const Tag = styled.span`
  background: #d9f3ff;
  color: #27bbff;
  border-radius: 4px;
  padding: 2px 1px;
  margin-left: -2px;
`;

type Props = {
  content: string;
  setContent: (content: string) => void;
  context: Record<string, any>;
  onSubmit: () => void;
  onClose: () => void;
  hasResults: boolean;
  setLayout: (layout: "compact" | "expanded") => void;
};

const TagsOverlay = ({
  content,
  scrollPosition,
  contextItems,
}: {
  content: string;
  scrollPosition: number;
  contextItems: Set<string>;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({
        top: scrollPosition,
        behavior: "instant" as any,
      });
    }
  }, [scrollPosition]);

  const contents = useMemo(() => {
    const contents: React.ReactNode[] = [];
    const lines = content.split(/\r?\n/);
    lines.forEach((line, i) => {
      const pieces = line.split("@");
      pieces.forEach((piece, j) => {
        if (j === 0) {
          contents.push(<Content key={`${i}-${j}`}>{piece}</Content>);
          return;
        }
        const nextSpace = piece.indexOf(" ");
        const tag = nextSpace > -1 ? piece.slice(0, nextSpace) : piece;
        const rest = nextSpace > -1 ? piece.slice(nextSpace) : "";
        if (tag && contextItems.has(tag)) {
          contents.push(
            <React.Fragment key={`${i}-${j}`}>
              <Tag>@{tag}</Tag>
              <Content>{rest}</Content>
            </React.Fragment>,
          );
        } else {
          contents.push(<Content key={`${i}-${j}`}>@{piece}</Content>);
        }
      });
      if (i < lines.length - 1) {
        contents.push(<br key={`br-${i}`} />);
      }
    });
    return contents;
  }, [content, contextItems]);

  return <TagOverlayWrapper ref={ref}>{contents}</TagOverlayWrapper>;
};

const PromptEditor = forwardRef((props: Props, ref) => {
  const {
    content,
    setContent,
    context,
    onSubmit,
    onClose,
    hasResults,
    setLayout,
  } = props;
  const [showMentionMenu, setShowMentionMenu] = useState(false);
  const [mentionSearch, setMentionSearch] = useState("");
  const [cursorPosition, setCursorPosition] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<null | string>(null);
  const textAreaRef = useRef<TextAreaRef>(null);
  const [keyboardIndex, setKeyboardIndex] = useState(0);

  const categories: MenuItem[] = useMemo(() => {
    return Object.keys(context).map((key) => ({
      key,
      label: (
        <div
          style={{
            textTransform: "capitalize",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {camelCaseToDisplay(key)}
          <ArrowIcon
            style={{
              transform: "rotate(180deg)",
              height: "16px",
              width: "16px",
            }}
          />
        </div>
      ),
      type: "category",
    }));
  }, [context]);

  const itemsByCategory = useMemo(() => {
    const itemsByCategory: Record<string, MenuItem[]> = {};
    Object.entries(context).forEach(([key, values]) => {
      itemsByCategory[key] = values.map((value: any) => ({
        key: value.name,
        label: value.name,
        type: "value",
      }));
    });
    return itemsByCategory;
  }, [context]);

  const filteredItems: MenuItem[] = useMemo(() => {
    return Object.values(itemsByCategory)
      .flat()
      .filter((item) =>
        item.key.toLowerCase().includes(mentionSearch.toLowerCase()),
      );
  }, [itemsByCategory, mentionSearch]);

  const contextItems = useMemo(() => {
    return new Set(
      Object.values(itemsByCategory)
        .flat()
        .map((item) => item.key),
    );
  }, [itemsByCategory]);

  const handleSelectItem = useCallback(
    (item: MenuItem) => {
      if (!textAreaRef.current) return;
      if (item.type === "category") {
        setSelectedCategory(item.key);
      } else {
        const mentionStart = content.lastIndexOf("@", cursorPosition - 1);
        const newContent =
          content.slice(0, mentionStart) +
          `@${item.key} ` +
          content.slice(cursorPosition);
        setContent(newContent);
        setShowMentionMenu(false);
        setSelectedCategory(null);

        const newCursorPosition = mentionStart + item.key.length + 2;
        setCursorPosition(newCursorPosition);
        textAreaRef.current.focus();
      }
    },
    [content, cursorPosition, setContent],
  );

  const currentMenuItems = useMemo(() => {
    let items: MenuItem[] = [];
    if (mentionSearch) {
      items = filteredItems;
    } else if (selectedCategory) {
      items = itemsByCategory[selectedCategory];
    } else {
      items = categories;
    }
    return items.map((item, index) => ({
      ...item,
      onClick: () => handleSelectItem(item),
      onMouseEnter: () => setKeyboardIndex(index),
    }));
  }, [
    categories,
    itemsByCategory,
    selectedCategory,
    mentionSearch,
    filteredItems,
    handleSelectItem,
  ]);

  useEffect(() => {
    if (showMentionMenu) {
      const mentionStart = content.lastIndexOf("@", cursorPosition - 1) + 1;
      setMentionSearch(content.slice(mentionStart, cursorPosition));
      if (keyboardIndex >= currentMenuItems.length) {
        setKeyboardIndex(0);
      }
    }
  }, [
    showMentionMenu,
    content,
    cursorPosition,
    currentMenuItems.length,
    keyboardIndex,
  ]);

  const textareaHandleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !showMentionMenu) {
      e.preventDefault();
      if (!hasResults || !e.metaKey) {
        onSubmit();
      }
    }
    if (e.key === "Escape" && !showMentionMenu) {
      onClose();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!showMentionMenu) return;

    const itemCount = currentMenuItems.length;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setKeyboardIndex((prevIndex) => (prevIndex + 1) % itemCount);
        break;
      case "ArrowUp":
        e.preventDefault();
        setKeyboardIndex(
          (prevIndex) => (prevIndex - 1 + itemCount) % itemCount,
        );
        break;
      case "Enter":
        e.preventDefault();
        if (keyboardIndex !== -1) {
          handleSelectItem(currentMenuItems[keyboardIndex]);
        }
        break;
      case "Escape":
        e.preventDefault();
        e.stopPropagation();
        if (selectedCategory) {
          setSelectedCategory(null);
        } else {
          setShowMentionMenu(false);
        }
        setKeyboardIndex(0);
        break;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = e.target.value;
    setContent(newContent);
    setCursorPosition(e.target.selectionStart);

    if (
      newContent.charAt(e.target.selectionStart - 1) === "@" &&
      !showMentionMenu
    ) {
      setShowMentionMenu(true);
      setSelectedCategory(null);
    } else if (
      showMentionMenu &&
      !newContent.slice(0, e.target.selectionStart).includes("@")
    ) {
      setShowMentionMenu(false);
      setSelectedCategory(null);
    }
  };

  const handleVisibleChange = (visible: boolean) => {
    if (!visible) {
      setShowMentionMenu(false);
      setSelectedCategory(null);
    }
  };

  useImperativeHandle(ref, () => ({
    addMention: () => {
      setContent(
        content.slice(0, cursorPosition) + "@" + content.slice(cursorPosition),
      );
      setCursorPosition(cursorPosition + 1);
      textAreaRef.current?.focus();
      setShowMentionMenu(true);
    },
  }));

  const prevContent = usePrevious(content);
  useLayoutEffect(
    () => {
      setTimeout(() => {
        const textArea = textAreaRef.current?.resizableTextArea?.textArea;
        // when text area grows to > 1 line, call a function
        if (!content || !prevContent) {
          setLayout("compact");
        } else if (
          textArea?.clientHeight &&
          textArea?.clientHeight > 24 &&
          content.length > prevContent.length
        ) {
          setLayout("expanded");
        } else if (
          textArea?.clientHeight &&
          textArea?.clientHeight <= 24 &&
          content.length < prevContent.length
        ) {
          setLayout("compact");
        }
      }, 50);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [content, setLayout],
  );
  const [scrollPosition, setScrollPosition] = useState(0);

  return (
    <div style={{ flex: 1, width: "100%" }} onKeyDown={handleKeyDown}>
      <Dropdown
        menu={{
          items: currentMenuItems,
          selectedKeys: [currentMenuItems[keyboardIndex]?.key ?? ""],
          defaultSelectedKeys: [currentMenuItems[keyboardIndex]?.key ?? ""],
        }}
        open={showMentionMenu}
        onOpenChange={handleVisibleChange}
        trigger={["click"]}
        placement="bottom"
        overlayClassName="ai-component-editor"
        className="ai-component-editor"
        autoAdjustOverflow={false}
      >
        <div style={{ position: "relative" }}>
          <TagsOverlay
            content={content}
            contextItems={contextItems}
            scrollPosition={scrollPosition}
          />
          <StyledTextArea
            ref={textAreaRef}
            value={content}
            onChange={handleChange}
            onKeyDown={textareaHandleKeyDown}
            placeholder="i.e. Run @Api1 on click"
            autoSize={{ minRows: 1, maxRows: 10 }}
            autoFocus={true}
            style={{
              border: "none",
              outline: "none",
              boxShadow: "none",
              marginBottom: "0px",
              padding: "0px 12px",
            }}
            onScroll={(e) => {
              setScrollPosition((e.target as HTMLTextAreaElement).scrollTop);
            }}
          />
        </div>
      </Dropdown>
    </div>
  );
});

PromptEditor.displayName = "PromptEditor";

export default PromptEditor;
