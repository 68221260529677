import { ApplicationScope } from "@superblocksteam/shared";
import { Dispatch, useCallback } from "react";
import { useStore } from "react-redux";
import { getEditorBasePath } from "hooks/store/useGetEditorPath";
import {
  openEditorTab,
  updateApplicationSidebarKey,
} from "legacy/actions/editorPreferencesActions";
import { editEmbedPropPropertyPane } from "legacy/actions/embeddingActions";
import { showItemPropertyPane } from "legacy/actions/propertyPaneActions";
import {
  showModal,
  closeAllModals,
  focusWidget,
  selectWidgets,
} from "legacy/actions/widgetActions";
import { EditorOpenTabType } from "legacy/constants/EditorPreferencesConstants";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { WidgetType, WidgetTypes } from "legacy/constants/WidgetConstants";
import { EditorRoute } from "legacy/constants/routes";
import { type DataTreeObjectEntity } from "legacy/entities/DataTree/dataTreeFactory";
import { getParentsToOpenIfAny } from "legacy/hooks/useClickOpenPropPane";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { buildNestedItemId } from "legacy/pages/Editor/PropertyPane/NestedItemsUtils";
import { SideBarKeys } from "legacy/pages/Editor/constants";
import { flashElementById } from "legacy/pages/Editor/visibilityUtil";
import { getCurrentApplicationId } from "legacy/selectors/editorSelectors";
import {
  getCurrentRoutePathWithParams,
  getRoutesList,
} from "legacy/selectors/routeSelectors";
import {
  getSelectedWidgetsParentId,
  getWidgets,
  getWidgetsAreSelected,
} from "legacy/selectors/sagaSelectors";
import { getParentWidgets } from "legacy/utils/WidgetPropsUtils";
import { useAppDispatch, useAppSelector } from "store/helpers";
import { editEventPropertyPane } from "store/slices/application/events/eventActions";
import { editStateVarPropertyPane } from "store/slices/application/stateVars/stateVarsActions";
import { editTimerPropertyPane } from "store/slices/application/timers/timerActions";
import { AppState } from "store/types";
import { ENTITY_TYPE } from "utils/dataTree/constants";
import { sendMessage } from "utils/iframe";
import { switchTab } from "./../../legacy/actions/widgetActions";
import type { WidgetMap } from "legacy/widgets";
import type { SkeletonWidgetProps } from "legacy/widgets/SkeletonWidget";

export type Widget = {
  ENTITY_TYPE: ENTITY_TYPE.WIDGET;
  type: WidgetType;
  widgetId: string;
};

export type NestedItem = {
  ENTITY_TYPE: ENTITY_TYPE.NESTED_ITEM;
  type: WidgetType;
  widgetId: string;
  path: string;
};

type Action = {
  ENTITY_TYPE: ENTITY_TYPE.ACTION;
  actionId: string; // the api id
  actions?: {
    openInput?: boolean;
  };
};

export type StateVar = {
  ENTITY_TYPE: ENTITY_TYPE.STATE_VAR;
  id: string;
  scope: ApplicationScope;
};

type Timer = {
  ENTITY_TYPE: ENTITY_TYPE.TIMER;
  id: string;
  scope: ApplicationScope;
};

type EmbeddingPanel = {
  ENTITY_TYPE: ENTITY_TYPE.EMBEDDING;
};

type EmbedProp = {
  ENTITY_TYPE: ENTITY_TYPE.EMBED_PROP;
  id: string;
};

type EventProp = {
  ENTITY_TYPE: ENTITY_TYPE.CUSTOM_EVENT;
  id: string;
};

type Unsupported = {
  ENTITY_TYPE?: ENTITY_TYPE.GLOBAL | ENTITY_TYPE.API;
};

export type NavItem =
  | Widget
  | NestedItem
  | Action
  | StateVar
  | Timer
  | EmbeddingPanel
  | EmbedProp
  | EventProp
  | Unsupported;

function canNavigateTo(item: NavItem | undefined) {
  return [
    ENTITY_TYPE.ACTION,
    ENTITY_TYPE.WIDGET,
    ENTITY_TYPE.NESTED_ITEM,
    ENTITY_TYPE.STATE_VAR,
    ENTITY_TYPE.TIMER,
    ENTITY_TYPE.EMBEDDING,
    ENTITY_TYPE.EMBED_PROP,
    ENTITY_TYPE.CUSTOM_EVENT,
  ].includes(item?.ENTITY_TYPE as ENTITY_TYPE);
}

const getNavItemFromDataTreeEntity = (
  entity: DataTreeObjectEntity,
): NavItem | undefined => {
  const type = entity.ENTITY_TYPE;
  switch (type) {
    case ENTITY_TYPE.ACTION:
      return {
        ENTITY_TYPE: ENTITY_TYPE.ACTION,
        actionId: entity.actionId,
      };
    case ENTITY_TYPE.WIDGET:
      return {
        ENTITY_TYPE: ENTITY_TYPE.WIDGET,
        type: entity.type,
        widgetId: entity.widgetId,
      };
    case ENTITY_TYPE.STATE_VAR:
      return {
        ENTITY_TYPE: ENTITY_TYPE.STATE_VAR,
        id: entity.id,
        scope: entity.scope,
      };
    case ENTITY_TYPE.TIMER:
      return {
        ENTITY_TYPE: ENTITY_TYPE.TIMER,
        id: entity.id,
        scope: entity.scope,
      };
    case ENTITY_TYPE.EMBEDDING:
      return {
        ENTITY_TYPE: ENTITY_TYPE.EMBEDDING,
      };
    case ENTITY_TYPE.CUSTOM_EVENT:
      return {
        ENTITY_TYPE: ENTITY_TYPE.CUSTOM_EVENT,
        id: entity.id,
      };
    case ENTITY_TYPE.THEME:
      return;
    case ENTITY_TYPE.ICONS:
      return;
    case ENTITY_TYPE.GLOBAL:
      return;
    default: {
      // eslint-disable-next-line
      const exhaustiveCheck: never = type;
      return;
    }
  }
};

function viewWidget(
  item: Widget,
  canvasWidgets: WidgetMap,
  dispatch: Dispatch<any>,
  isSelectingMultiple?: boolean,
  shouldSelectWidgetsInRange?: boolean,
  widgetsInRange?: string[],
  options?: {
    dontOpenModals?: boolean;
  },
) {
  if (!item.widgetId) {
    return;
  }

  const isOverlay =
    item.type === WidgetTypes.MODAL_WIDGET ||
    item.type === WidgetTypes.SLIDEOUT_WIDGET;
  if (isOverlay && !options?.dontOpenModals) {
    if (isSelectingMultiple) {
      // if in multiselect mode, and you select other widgets after selecting modal, the modal will close and trigger deselects of all widgets
      // so we just select the modal without opening it
      dispatch(
        shouldSelectWidgetsInRange && widgetsInRange
          ? selectWidgets(widgetsInRange, false)
          : selectWidgets([item.widgetId], isSelectingMultiple),
      );
    } else {
      dispatch(
        showModal(item.widgetId, {
          showPropertyPane: true,
          isSelectingMultiple,
          doNotSelect: false,
        }),
      );
    }
    return;
  }

  const parents = [item, ...getParentWidgets(item.widgetId, canvasWidgets)];
  const parentsToOpen = getParentsToOpenIfAny(
    item.widgetId,
    canvasWidgets,
  ).filter((w) => w?.widgetId !== item.widgetId);

  let inModal = false;
  for (const parent of parentsToOpen) {
    if (parent) {
      const parentId = parent.widgetId;
      if (
        parent.type === WidgetTypes.TABS_WIDGET ||
        (parent as unknown as SkeletonWidgetProps).loadedType ===
          WidgetTypes.TABS_WIDGET
      ) {
        const tabIndex = parents.findIndex((p) => parentId === p.widgetId) - 1;
        const tabId = parents[tabIndex].widgetId;
        dispatch(switchTab(parentId, tabId));
      } else if (
        parent.type === WidgetTypes.MODAL_WIDGET ||
        parent.type === WidgetTypes.SLIDEOUT_WIDGET
      ) {
        inModal = true;
        dispatch(
          showModal(parentId, {
            showPropertyPane: false,
            isSelectingMultiple: false,
            doNotSelect: true,
          }),
        );
      }
    }
  }

  if (!inModal && !isSelectingMultiple) {
    dispatch(closeAllModals());
  }
  flashElementById(item.widgetId);
  sendMessage({ type: "flash-element", payload: { elementId: item.widgetId } });
  dispatch(focusWidget(item.widgetId));
  dispatch(
    shouldSelectWidgetsInRange && widgetsInRange
      ? selectWidgets(widgetsInRange, false)
      : selectWidgets([item.widgetId], isSelectingMultiple),
  );
}

function viewNestedItem(item: NestedItem, dispatch: Dispatch<any>) {
  const id = buildNestedItemId(item);

  dispatch(
    showItemPropertyPane({
      kind: ItemKinds.NESTED_ITEM,
      id,
      scope: ApplicationScope.PAGE,
    }),
  );

  dispatch(selectWidgets([]));
}

function viewStateVar(item: StateVar, dispatch: Dispatch<any>) {
  if (!item.id) {
    return;
  }

  dispatch(editStateVarPropertyPane(item.id, item.scope));
  dispatch(selectWidgets([]));
}

function viewTimer(item: Timer, dispatch: Dispatch<any>) {
  if (!item.id) {
    return;
  }
  dispatch(editTimerPropertyPane(item.id, item.scope));
  dispatch(selectWidgets([]));
}

function viewEmbeddingPanel(dispatch: Dispatch<any>) {
  dispatch(
    updateApplicationSidebarKey({
      selectedKey: SideBarKeys.EMBEDDING,
    }),
  );
}

function viewEmbedProp(item: EmbedProp, dispatch: Dispatch<any>) {
  if (!item.id) {
    return;
  }

  dispatch(editEmbedPropPropertyPane(item.id));
  dispatch(selectWidgets([]));
}

function viewEvent(item: EventProp, dispatch: Dispatch<any>) {
  if (!item.id) {
    return;
  }

  // TODO(APP_SCOPE): Update when adding support for multiple scopes
  dispatch(editEventPropertyPane(item.id, ApplicationScope.PAGE));
  dispatch(selectWidgets([]));
}

function viewApi(
  item: Action,
  currentAppId: string | undefined,
  currentRoute: string | undefined,
  dispatch: Dispatch<any>,
): void {
  const baseRoute = item.actions?.openInput
    ? EditorRoute.EditApiInputs
    : EditorRoute.EditApiActionBase;
  const apiURL = getEditorBasePath(baseRoute, {
    applicationId: currentAppId,
    apiId: item?.actionId,
    currentRoute,
  });
  const currentUrl = window.location.pathname + window.location.search;
  if (currentUrl !== encodeURI(apiURL)) {
    dispatch(
      openEditorTab({
        tabType: EditorOpenTabType.API,
        entityId: item?.actionId,
        actionId: item.actions?.openInput ? "input" : undefined,
      }),
    );
  }
}

const useNavigateTo = (
  item?: NavItem,
  options?: {
    dontOpenModals?: boolean;
  },
) => {
  const dispatch = useAppDispatch();
  const store = useStore<AppState>();

  const openWidget = useCallback(
    (e: React.MouseEvent | undefined, item: Widget, itemIds?: string[]) => {
      // Avoiding useSelector here because we don't care about the selection state until click
      const selectedWidgetsParentId = getSelectedWidgetsParentId(
        store.getState(),
      );
      const hasSelection = getWidgetsAreSelected(store.getState());
      const shouldSelectWidgetsInRange = e?.shiftKey && hasSelection;
      const stateWidgets: WidgetMap = getWidgets(store.getState());

      if (
        (e?.shiftKey || e?.metaKey || e?.altKey) &&
        selectedWidgetsParentId !== undefined &&
        selectedWidgetsParentId !== stateWidgets[item.widgetId].parentId
      ) {
        // if multiselect an item that is not sharing the same parent with selected widget, do not select
        return;
      }
      const routeList = getRoutesList(store.getState());
      const matchingRoute = routeList?.find(
        (r) => "widgetId" in r && r.widgetId === item.widgetId,
      );
      if (matchingRoute) {
        dispatch({
          type: ReduxActionTypes.EDITOR_VIEW_ROUTE,
          payload: {
            path: matchingRoute.path,
          },
        });
        return;
      }

      viewWidget(
        item,
        stateWidgets,
        dispatch,
        e?.shiftKey || e?.metaKey || e?.altKey,
        shouldSelectWidgetsInRange,
        itemIds,
        options,
      );
    },
    [store, dispatch, options],
  );

  const openNestedItem = useCallback(
    (item: NestedItem) => {
      return viewNestedItem(item, dispatch);
    },
    [dispatch],
  );

  const currentAppId = useAppSelector(getCurrentApplicationId);
  const currentRoute = useAppSelector(getCurrentRoutePathWithParams);
  const openAPI = useCallback(
    (item: Action) => {
      return viewApi(item, currentAppId, currentRoute, dispatch);
    },
    [currentAppId, currentRoute, dispatch],
  );
  const openStateVar = useCallback(
    (item: StateVar) => {
      return viewStateVar(item, dispatch);
    },
    [dispatch],
  );
  const openTimer = useCallback(
    (item: Timer) => {
      return viewTimer(item, dispatch);
    },
    [dispatch],
  );
  const openEmbeddingPanel = useCallback(() => {
    return viewEmbeddingPanel(dispatch);
  }, [dispatch]);
  const openEmbedProp = useCallback(
    (item: EmbedProp) => {
      return viewEmbedProp(item, dispatch);
    },
    [dispatch],
  );
  const openEventPanel = useCallback(
    (item: EventProp) => {
      return viewEvent(item, dispatch);
    },
    [dispatch],
  );
  const supported = canNavigateTo(item);

  const navigateToItem = useCallback(
    (e: any, itemIds?: string[]) => {
      switch (item?.ENTITY_TYPE) {
        case ENTITY_TYPE.ACTION:
          return openAPI(item);
        case ENTITY_TYPE.WIDGET:
          return openWidget(e, item, itemIds);
        case ENTITY_TYPE.NESTED_ITEM:
          return openNestedItem(item);
        case ENTITY_TYPE.STATE_VAR:
          return openStateVar(item);
        case ENTITY_TYPE.TIMER:
          return openTimer(item);
        case ENTITY_TYPE.EMBEDDING:
          return openEmbeddingPanel();
        case ENTITY_TYPE.EMBED_PROP:
          return openEmbedProp(item);
        case ENTITY_TYPE.CUSTOM_EVENT:
          return openEventPanel(item);
      }
    },
    [
      item,
      openAPI,
      openStateVar,
      openTimer,
      openWidget,
      openNestedItem,
      openEmbeddingPanel,
      openEmbedProp,
      openEventPanel,
    ],
  );

  return { navigateToItem, supported };
};

// Many exported functions to support class components
export {
  useNavigateTo,
  canNavigateTo,
  viewWidget,
  viewApi,
  viewStateVar,
  viewTimer,
  viewEmbedProp,
  viewEvent,
  getNavItemFromDataTreeEntity,
};
