import styled, { css } from "styled-components";
import { colors } from "styles/colors";

const rainbowLinearGradient = `
linear-gradient(
  to right,
  #47a9ff 0%,
  #4ff865 25%,
  #f8be4f 50%,
  #f8be4f 75%,
  #7c4ff8 100%
)
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid transparent;
  background: ${(props) => props.theme.colors.WHITE};
  border-image: ${rainbowLinearGradient};
  border-image-slice: 1;
`;

export const RainbowBoxShadow = styled.div`
  box-shadow: inset 0 0 12px 12px white, inset 0 0 3px 2px white;
  background: ${rainbowLinearGradient};
  padding: 0px 0px 8px;
  position: sticky;
  top: 0px;
  height: 51px;
  z-index: 10;
`;

export const TitleSection = styled.div`
  display: flex;
  align-items: center;
`;

export const AiAssitantBg = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
  z-index: 2000;
`;

export const Title = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.colors.GREY_700};
  margin: 0px 4px;
  font-weight: 500;
  line-height: 16px;
`;

export const BetaTag = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.colors.GREY_500};
  border: 1px solid ${(props) => props.theme.colors.GREY_100};
  border-radius: 3px;
  padding: 0px 4px;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  svg {
    height: 16px;
    width: 16px;
    path {
      fill: ${(props) => props.theme.colors.GREY_300};
    }
  }
`;

export const SubmitButton = styled.button<{ $isDisabled: boolean }>`
  background: none;
  border: none;
  cursor: ${(props) => (props.$isDisabled ? "not-allowed" : "pointer")};
  padding: 0;
  margin: 0;
  height: 18px;
  display: flex;
  align-items: center;
  svg {
    height: 18px;
    width: 18px;
    path {
      fill: ${(props) =>
        props.$isDisabled
          ? props.theme.colors.GREY_300
          : props.theme.colors.ACCENT_BLUE_500};
    }
  }
`;

export const SubmitSectionWrapper = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  height: 18px;
`;

export const ContextButton = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  color: ${(props) => props.theme.colors.GREY_500};
  font-size: 16px;
  display: flex;
  align-items: center;
  &:hover {
    color: ${(props) => props.theme.colors.ACCENT_BLUE_500};
  }
  cursor: pointer;
`;

export const ButtonsWrapper = styled.div`
  height: 22px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  color: ${colors.GREY_500};

  &[data-layout="expanded"] {
    width: 100%;
    justify-content: end;
  }
`;

export const InputSection = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  gap: 4px;
  &[data-layout="expanded"] {
    flex-direction: column;
    padding-right: 8px;
  }
  &[data-layout="compact"] {
    flex-direction: row;
    padding-right: 8px;
  }
  .EditorWrapper {
    border: none;
  }
  .CodeMirror {
    padding: 0px 8px;
  }
`;

export const ResponseSection = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.GREY_100};
  padding: 12px;
  position: relative;
  flex: 3;
  display: flex;
  flex-direction: column;

  &[data-diff-view="true"] {
    padding: 0px;
  }

  &[data-code-view="true"] {
    .EditorWrapper {
      border: none;
      opacity: 1;
      .CodeMirror {
        background: ${(props) => props.theme.colors.GREY_25};
        .CodeMirror-lines {
          background: none;
          cursor: text;
        }
      }
    }
  }
`;

export const ResponseEditorWrapper = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  background: ${(props) => props.theme.colors.GREY_25};
  border-radius: 4px;
  cursor: default;
`;

export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  background: rgba(255, 72, 72, 0.08);
  border: 1px solid rgba(255, 72, 72, 0.48);
  border-radius: 4px;
  color: ${(props) => props.theme.colors.GREY_700};
  margin-bottom: 12px;
  svg {
    margin-right: 8px;
  }
`;

export const PasteButton = styled.button`
  background: ${(props) => props.theme.colors.WHITE};
  border-radius: 4px;
  padding: 4px 12px;
  border: none;
  cursor: pointer;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.GREY_100};
  color: ${(props) => props.theme.colors.GREY_700};
  text-align: left;
  display: flex;
  align-items: center;
  &:hover {
    background: ${(props) => props.theme.colors.GREY_25};
  }
  svg {
    margin-right: 6px;
  }
  :focus,
  :active {
    border-color: ${(props) => props.theme.colors.ACCENT_BLUE_500};
  }
  :active {
    background: ${(props) => props.theme.colors.GREY_50};
  }
`;

export const ResponseActionsWrapper = styled.div<{ isAbsolute?: boolean }>`
  padding: 4px;
  width: 24px;
  ${(props) =>
    props.isAbsolute
      ? css`
          position: absolute;
          right: 8px;
          top: 0px;
        `
      : css`
          background: ${props.theme.colors.GREY_25};
        `}
  button {
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;
  }
  svg {
    height: 16px;
    width: 16px;
    path {
      fill: ${(props) => props.theme.colors.GREY_300};
    }
  }
`;

export const Row = styled.div<{
  $gap: number;
  $height: string | number;
  $alignment?: string;
}>`
  flex: 1;
  display: flex;
  position: relative;
  height: ${(props) =>
    typeof props.$height === "string" ? props.$height : `${props.$height}px`};
  align-items: ${(props) => (props.$alignment ? props.$alignment : "center")};
  *:not(:last-child) {
    margin-right: ${(props) => props.$gap}px;
  }
`;

export const CancelButton = styled.button`
  cursor: pointer;
  background: none;
  height: 18px;
  width: 18px;
  border: none;
  padding: 0;
  position: absolute;
  background: ${(props) => props.theme.colors.RED_500};
  border-radius: 50%;
  display: none; // hidden by default
  align-items: center;
  justify-content: center;
  svg {
    height: 12px;
    width: 12px;
  }
`;

export const LoadingIndicatorWrapper = styled.div`
  &:not[data-position="default"] {
    height: 24px;
    width: 24px;
    margin-right: -4px;
  }
  &:hover {
    // the loader is hidden and the cancel button is shown
    .loader {
      display: none;
    }
    .cancel-button {
      display: flex;
    }
  }
`;

export const Footer = styled(Row)`
  padding: 12px;
  height: 56px;
  position: sticky;
  bottom: 0px;
  background: white;
  flex: 0;
`;

export const PillButton = styled.button`
  border-radius: 32px;
  padding: 0px 8px;
  color: ${(props) => props.theme.colors.GREY_500};
  background: ${(props) => props.theme.colors.ACCENT_BLUE_500_25};
  margin-right: 6px;
  height: 20px;
  cursor: pointer;
  white-space: nowrap;
  border: none;
  font-size: 12px;
  line-height: 20px;
  &:hover {
    background: ${(props) => props.theme.colors.ACCENT_BLUE_500_18};
  }
`;

export const Pills = styled.div`
  width: 100%;
  padding: 2px 8px 8px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
`;
