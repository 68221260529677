import { ApplicationScope, RouteDef } from "@superblocksteam/shared";
import { uniq, uniqBy } from "lodash";
import { createNameValidator } from "hooks/store/useEntityNameValidator";
import { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { ItemWithPropertiesType } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { getItemPropertyPaneConfig } from "legacy/pages/Editor/PropertyPane/ItemPropertyPaneConfig";
import { GeneratedTheme } from "legacy/themes";
import {
  getWidgetDynamicPropertyPathList,
  mergeUpdatesWithBindingsOrTriggers,
} from "legacy/utils/DynamicBindingUtils";
import { WidgetProps } from "legacy/widgets/BaseWidget/types";
import { AllFlags } from "../featureFlags";
import { getEventHandlers } from "./eventHandlers";
import { addColumns, removeColumns, updateColumns } from "./tableUtils";
import {
  AddActionEvent,
  AddColumnAction,
  ComponentEditAction,
  RemoveColumnAction,
  RemoveEventAction,
  SetPrimayColumnsAction,
} from "./types";
import { sanitizeEdits } from "./utils";

type DiscardedEdit = {
  propertyName: string;
  reason: string;
  propertyValue: unknown;
};

export const processActionsIntoChanges = async ({
  actions,
  existingWidget,
  dataTree,
  routes,
  theme,
  nameValidator,
  featureFlags,
}: {
  actions: ComponentEditAction[];
  existingWidget: Partial<WidgetProps>;
  dataTree: DataTree;
  routes: RouteDef[];
  theme: GeneratedTheme;
  nameValidator: ReturnType<typeof createNameValidator>;
  featureFlags: Partial<AllFlags>;
}): Promise<{
  changedKeys: Array<string>;
  rename?: string;
  dataTreeChanges: Record<string, unknown>;
}> => {
  const changes: Record<string, unknown> = {};
  let rename: string | undefined;
  const discardedEdits: DiscardedEdit[] = [];
  let numColumns = (existingWidget as any).primaryColumns
    ? Object.keys((existingWidget as any).primaryColumns).length
    : 0;

  actions.forEach((action) => {
    const {
      action: actionType,
      property: propertyName,
      value: returnedValue,
    } = action;
    const prevValue =
      changes[propertyName] ??
      existingWidget[propertyName as keyof WidgetProps];

    switch (actionType) {
      case "set": {
        if (propertyName === "widgetName") {
          if (typeof returnedValue === "string") {
            const val = returnedValue.replaceAll(" ", "_");
            const nameError = nameValidator({
              currentName: existingWidget.widgetName as string,
              name: val,
              scope: ApplicationScope.PAGE,
            });
            if (!nameError) {
              rename = val;
            }
          }
        }
        if (propertyName.startsWith("primaryColumns")) {
          updateColumns({
            action: action as SetPrimayColumnsAction,
            widget: existingWidget,
            changes,
            dataTree,
            routes,
            theme,
          });
        } else {
          changes[propertyName] = returnedValue;
        }
        break;
      }
      case "add": {
        if (propertyName === "primaryColumns") {
          addColumns(
            action as AddColumnAction,
            existingWidget,
            changes,
            numColumns,
          );
          numColumns += 1;
        } else {
          changes[propertyName] = getEventHandlers({
            prevValue,
            value: returnedValue as AddActionEvent | AddActionEvent[],
            dataTree,
            routes,
          });
        }
        break;
      }
      case "remove": {
        if (propertyName === "primaryColumns") {
          removeColumns(action as RemoveColumnAction, existingWidget, changes);
          numColumns -= 1;
        } else {
          const idToRemove = (action as RemoveEventAction).value?.id;
          if (Array.isArray(prevValue)) {
            changes[propertyName] = prevValue.filter(
              (item) => item.id !== idToRemove,
            );
          }
        }
        break;
      }
      default:
        console.error(`Unknown action type ${actionType} returned`);
    }
  });

  if (Object.keys(changes).length > 0) {
    const propSections = getItemPropertyPaneConfig(
      existingWidget.type as ItemWithPropertiesType,
    );
    let dataTreeChanges = mergeUpdatesWithBindingsOrTriggers(
      existingWidget,
      propSections,
      changes,
    );

    const allProperties = propSections
      .flatMap((section) => section.children)
      .filter(Boolean);

    const dynamicProperties = getWidgetDynamicPropertyPathList(existingWidget);
    await sanitizeEdits({
      edits: dataTreeChanges,
      properties: allProperties as PropertyPaneConfig[],
      discardedEdits,
      dynamicProperties,
      previousWidget: existingWidget,
      theme,
      featureFlags,
    });

    // make sure dynamic property list is unique
    dataTreeChanges.dynamicPropertyPathList = uniqBy(
      dynamicProperties,
      (prop) => prop.key,
    );
    dataTreeChanges = mergeUpdatesWithBindingsOrTriggers(
      existingWidget,
      propSections,
      dataTreeChanges,
    );

    const changedKeys = uniq(
      Object.keys(dataTreeChanges).filter((key) => {
        if (discardedEdits.some((edit) => edit.propertyName === key)) {
          return false;
        }
        return true;
      }),
    );

    if (
      changedKeys.filter(
        (key) =>
          ![
            "dynamicPropertyPathList",
            "dynamicBindingPathList",
            "dynamicTriggerPathList",
          ].includes(key),
      ).length === 0
    ) {
      return { changedKeys: [], dataTreeChanges: {}, rename };
    }

    dataTreeChanges.dynamicBindingPathList = uniqBy(
      (dataTreeChanges as any).dynamicBindingPathList ?? [],
      (prop: { key: string }) => prop.key,
    );
    dataTreeChanges.dynamicTriggerPathList = uniqBy(
      (dataTreeChanges as any).dynamicTriggerPathList ?? [],
      (prop: { key: string }) => prop.key,
    );
    dataTreeChanges.dynamicPropertyPathList = uniqBy(
      (dataTreeChanges as any).dynamicPropertyPathList ?? [],
      (prop: { key: string }) => prop.key,
    );
    return {
      dataTreeChanges,
      changedKeys,
      rename,
    };
  }

  return { changedKeys: [], dataTreeChanges: {}, rename };
};
