import React from "react";
import { flushSync } from "react-dom";
import { polyfill } from "react-lifecycles-compat";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import Pane from "./Pane";
import Resizer, { RESIZER_DEFAULT_CLASSNAME } from "./Resizer";

function unFocus(document: Document, window: Window) {
  if (document.getSelection()) {
    document.getSelection()?.empty();
  } else {
    try {
      window.getSelection()?.removeAllRanges();
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
}

function getDefaultSize(
  defaultSize?: string | number,
  minSize?: string | number,
  maxSize?: string | number,
  draggedSize?: string | number,
) {
  if (typeof draggedSize === "number" && draggedSize !== 0) {
    const min = typeof minSize === "number" ? minSize : 0;
    const max =
      typeof maxSize === "number" && maxSize >= 0 ? maxSize : Infinity;
    return Math.max(min, Math.min(max, draggedSize));
  }
  if (defaultSize !== undefined) {
    return defaultSize;
  }
  return minSize;
}

function removeNullChildren(children: (React.ReactChild | null)[]) {
  return React.Children.toArray(children).filter((c) => c);
}

type SplitPaneProps = {
  onChange?: (size: string | number) => void;
  allowResize?: boolean;
  children: (React.ReactChild | null)[];
  className?: string;
  defaultSize?: string | number;
  direction?: "horizontal" | "vertical";
  maxSize?: number;
  minSize?: string | number;
  onDragFinished?: (newSize?: string | number) => void;
  onDragStarted?: () => void;
  onResized?: () => void;
  onResizerClick?: (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => void;
  onResizerDoubleClick?: (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => void;
  paneClassName?: string;
  paneStyle?: React.CSSProperties;
  pane1ClassName?: string;
  pane1Style?: React.CSSProperties;
  pane2ClassName?: string;
  pane2Style?: React.CSSProperties;
  primary?: "first" | "second";
  resizerClassName?: string;
  resizerStyle?: React.CSSProperties;
  size?: string | number;
  split?: "vertical" | "horizontal";
  style?: React.CSSProperties;
  step?: number;
  onPaneToggle?: (isCollapsed: boolean) => void;
  collapseSize?: number;
  isCollapsible?: boolean;
  isCollapsed?: boolean;
  dragToCollapseThreshold?: number;
  uncollapseSize?: number;
  maintainDraggedSize?: boolean;
  // redux
  setSplitPaneDragging?: (isDragging: boolean) => void;
};

type SplitPaneState = {
  active: boolean;
  resized: boolean;
  pane1Size?: string | number;
  pane2Size?: string | number;
  instanceProps: Record<string, unknown>;
  draggedSize?: string | number;
  position: number;
  isCollapsed: boolean;
  prevIsCollapsed: boolean;
};

const DEFAULT_DRAG_TO_COLLAPSE_THRESHOLD = 40;
class SplitPane extends React.Component<SplitPaneProps, SplitPaneState> {
  pane1?: HTMLDivElement;
  pane2?: HTMLDivElement;
  splitPane?: HTMLDivElement;

  static defaultProps = {
    allowResize: true,
    minSize: 50,
    primary: "first",
    split: "vertical",
    paneClassName: "",
    pane1ClassName: "",
    pane2ClassName: "",
    collapseSize: 0,
    isCollapsable: false,
  };

  constructor(props: SplitPaneProps) {
    super(props);

    this.pane1 = undefined;
    this.pane2 = undefined;

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onPaneToggle = this.onPaneToggle.bind(this);

    // order of setting panel sizes.
    // 1. size
    // 2. getDefaultSize(defaultSize, minsize, maxSize)

    const { size, defaultSize, minSize, maxSize, primary } = props;

    const initialSize =
      size !== undefined
        ? size
        : getDefaultSize(defaultSize, minSize, maxSize, undefined);

    this.state = {
      active: false,
      resized: false,
      pane1Size: primary === "first" ? initialSize : undefined,
      pane2Size: primary === "second" ? initialSize : undefined,
      draggedSize: undefined,
      position: 0,

      // these are props that are needed in static functions. ie: gDSFP
      instanceProps: {
        size,
      },
      prevIsCollapsed: false,
      isCollapsed: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mouseup", this.onMouseUp);
    document.addEventListener("mousemove", this.onMouseMove);
    document.addEventListener("touchmove", this.onTouchMove);
    if (
      !this.props.isCollapsed &&
      typeof this.props.size === "number" &&
      typeof this.props.dragToCollapseThreshold === "number" &&
      this.props.size <
        (this.props.collapseSize || 0) + this.props.dragToCollapseThreshold
    ) {
      this.props?.onPaneToggle?.(true);
    }
    this.setState(SplitPane.getSizeUpdate(this.props, this.state));
  }

  componentDidUpdate() {
    if (
      this.props.isCollapsed != null &&
      this.state.isCollapsed !== this.props.isCollapsed
    ) {
      flushSync(() => {
        this.setState({ isCollapsed: Boolean(this.props.isCollapsed) });
      });
    }
  }

  static getDerivedStateFromProps(
    nextProps: SplitPaneProps,
    prevState: SplitPaneState,
  ) {
    return SplitPane.getSizeUpdate(nextProps, prevState);
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.onMouseUp);
    document.removeEventListener("mousemove", this.onMouseMove);
    document.removeEventListener("touchmove", this.onTouchMove);
  }

  onMouseDown(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    const eventWithTouches = Object.assign({}, event, {
      touches: [{ clientX: event.clientX, clientY: event.clientY }],
    });
    this.onTouchStart(eventWithTouches as unknown as React.TouchEvent);
  }

  onTouchStart(event: React.TouchEvent) {
    const { allowResize, onDragStarted, split, setSplitPaneDragging } =
      this.props;
    if (allowResize) {
      unFocus(document, window);
      const position =
        split === "vertical"
          ? event.touches[0].clientX
          : event.touches[0].clientY;

      if (typeof onDragStarted === "function") {
        onDragStarted();
      }
      setSplitPaneDragging?.(true);
      this.setState({
        active: true,
        position,
      });
    }
  }

  onMouseMove(event: MouseEvent) {
    const eventWithTouches = Object.assign({}, event, {
      touches: [{ clientX: event.clientX, clientY: event.clientY }],
    });
    this.onTouchMove(eventWithTouches);
  }

  onTouchMove(ev: Event) {
    const event = ev as unknown as React.TouchEvent<Event>;
    const {
      allowResize,
      maxSize,
      minSize,
      onChange,
      split,
      step,
      collapseSize,
      isCollapsible,
    } = this.props;
    const { active, position, isCollapsed } = this.state;
    if (allowResize && active) {
      unFocus(document, window);
      const isPrimaryFirst = this.props.primary === "first";
      const ref = isPrimaryFirst ? this.pane1 : this.pane2;
      const ref2 = isPrimaryFirst ? this.pane2 : this.pane1;

      if (ref && ref2) {
        const node = ref;
        const node2 = ref2;
        if (node.getBoundingClientRect) {
          const width = node.getBoundingClientRect().width;
          const height = node.getBoundingClientRect().height;
          const current =
            split === "vertical"
              ? event.touches[0].clientX
              : event.touches[0].clientY;
          const size = split === "vertical" ? width : height;
          let positionDelta = position - current;
          if (step) {
            if (Math.abs(positionDelta) < step) {
              return;
            }
            // Integer division
            // eslint-disable-next-line no-bitwise
            positionDelta = ~~(positionDelta / step) * step;
          }
          let sizeDelta = isPrimaryFirst ? positionDelta : -positionDelta;

          const pane1Order = parseInt(window.getComputedStyle(node).order);
          const pane2Order = parseInt(window.getComputedStyle(node2).order);
          if (pane1Order > pane2Order) {
            sizeDelta = -sizeDelta;
          }

          let newMaxSize: string | number | undefined = maxSize;
          if (maxSize !== undefined && maxSize <= 0 && this.splitPane) {
            const splitPane = this.splitPane;
            if (split === "vertical") {
              newMaxSize = splitPane?.getBoundingClientRect().width + maxSize;
            } else {
              newMaxSize = splitPane?.getBoundingClientRect().height + maxSize;
            }
          }

          let newSize: string | number | undefined = size - sizeDelta;

          const newPosition = position - positionDelta;
          //sizeDelta < 0 moving outside, sizeDelta > 0 moving inside
          const dragToCollapseThreshold =
            this.props.dragToCollapseThreshold ??
            DEFAULT_DRAG_TO_COLLAPSE_THRESHOLD;

          if (
            isCollapsible &&
            sizeDelta > 0 &&
            !isCollapsed &&
            newSize < Number(collapseSize) + dragToCollapseThreshold
          ) {
            //snap to collapse
            this.props.onPaneToggle?.(true);
            this.setState({
              isCollapsed: true,
            });
          } else if (
            isCollapsible &&
            sizeDelta < 0 &&
            isCollapsed &&
            newSize > Number(collapseSize)
          ) {
            //snap to expand
            this.props.onPaneToggle?.(false);
            this.setState({
              isCollapsed: false,
            });
          } else if (minSize && Number(newSize) < Number(minSize)) {
            newSize = minSize;
          } else if (
            maxSize !== undefined &&
            newMaxSize &&
            newSize > newMaxSize
          ) {
            newSize = newMaxSize;
          } else {
            flushSync(() => {
              this.setState({
                position: newPosition,
                resized: true,
              });
            });
          }

          if (onChange && newSize) onChange(newSize);

          flushSync(() => {
            this.setState({
              // FIXME (Eric): to please typescript for now
              ...this.state,
              draggedSize: newSize,
              [isPrimaryFirst ? "pane1Size" : "pane2Size"]: newSize,
            });
          });
        }
      }
    }
  }

  onMouseUp() {
    const { allowResize, onDragFinished, setSplitPaneDragging } = this.props;
    const { active, draggedSize } = this.state;
    if (allowResize && active) {
      if (typeof onDragFinished === "function") {
        onDragFinished(draggedSize);
      }
      setSplitPaneDragging?.(false);
      this.setState({ active: false });
    }
  }

  onPaneToggle() {
    this.setState((state) => {
      this.props.onPaneToggle?.(!state.isCollapsed);
      return {
        isCollapsed: !state.isCollapsed,
      };
    });
  }

  // we have to check values since gDSFP is called on every render and more in StrictMode
  static getSizeUpdate(props: SplitPaneProps, state: SplitPaneState) {
    const newState: SplitPaneState = state;
    const {
      instanceProps,
      isCollapsed: isCollapsedIntertal,
      prevIsCollapsed,
    } = state;

    const isCollapsed = props.isCollapsed ?? isCollapsedIntertal;

    if (
      instanceProps.size === props.size &&
      props.size !== undefined &&
      prevIsCollapsed === isCollapsed
    ) {
      return {};
    }
    let newSize;
    if (isCollapsed) {
      newSize = props.collapseSize;
    } else if (
      prevIsCollapsed &&
      !isCollapsed &&
      typeof props.uncollapseSize === "number"
    ) {
      newSize = props.uncollapseSize;
    } else if (props.size !== undefined) {
      newSize = props.size;
    } else {
      newSize = getDefaultSize(
        props.defaultSize,
        props.minSize,
        props.maxSize,
        state.draggedSize,
      );
    }

    if (props.size !== undefined) {
      newState.draggedSize = newSize;
    }

    const isPanel1Primary = props.primary === "first";

    newState[isPanel1Primary ? "pane1Size" : "pane2Size"] = newSize;
    newState[isPanel1Primary ? "pane2Size" : "pane1Size"] = undefined;

    newState.instanceProps = { size: props.size };
    newState.prevIsCollapsed = isCollapsed;
    return newState;
  }

  render() {
    const {
      allowResize = true,
      children,
      className,
      onResizerClick,
      onResizerDoubleClick,
      paneClassName,
      pane1ClassName,
      pane2ClassName,
      paneStyle,
      pane1Style: pane1StyleProps,
      pane2Style: pane2StyleProps,
      resizerClassName,
      resizerStyle,
      split,
      style: styleProps,
      primary,
      isCollapsible,
      collapseSize,
    } = this.props;

    const {
      pane1Size,
      pane2Size,
      isCollapsed: isCollapsedInternal,
      active,
    } = this.state;

    const isCollapsed = this.props.isCollapsed ?? isCollapsedInternal;

    const disabledClass = `${allowResize ? "" : "disabled"}${
      isCollapsed ? " collapsed" : ""
    }`;

    const resizerClassNamesIncludingDefault = resizerClassName
      ? `${resizerClassName} ${RESIZER_DEFAULT_CLASSNAME}`
      : resizerClassName;

    const notNullChildren = removeNullChildren(children);

    const style: React.CSSProperties = {
      display: "flex",
      flex: 1,
      height: "100%",
      position: "absolute",
      outline: "none",
      overflow: "hidden",
      MozUserSelect: "text",
      WebkitUserSelect: "text",
      msUserSelect: "text",
      userSelect: "text",
      ...(split === "vertical"
        ? {
            flexDirection: "row",
            left: 0,
            right: 0,
          }
        : {
            bottom: 0,
            flexDirection: "column",
            minHeight: "100%",
            top: 0,
            width: "100%",
          }),
      ...styleProps,
    };

    const classes = ["SplitPane", className, split, disabledClass];

    const pane1Style = { ...paneStyle, ...pane1StyleProps };
    const pane2Style = { ...paneStyle, ...pane2StyleProps };

    const pane1Classes = ["Pane1", paneClassName, pane1ClassName].join(" ");
    const pane2Classes = ["Pane2", paneClassName, pane2ClassName].join(" ");

    let direction: "left" | "right" | "up" | "down" = "left";
    if (split === "vertical" && primary === "second") {
      direction = "right";
    } else if (split === "horizontal" && primary === "first") {
      direction = "up";
    } else if (split === "horizontal" && primary === "second") {
      direction = "down";
    }

    return (
      <div
        className={classes.join(" ")}
        ref={(node: HTMLDivElement) => {
          this.splitPane = node;
        }}
        style={style}
      >
        <Pane
          className={pane1Classes}
          key="pane1"
          eleRef={(node) => {
            this.pane1 = node;
          }}
          size={pane1Size}
          split={split}
          style={pane1Style}
        >
          {notNullChildren[0]}
        </Pane>
        {notNullChildren.length > 1 ? (
          <>
            <Resizer
              isCollapsed={isCollapsed}
              isActive={active}
              direction={direction}
              className={disabledClass}
              onClick={onResizerClick}
              onDoubleClick={onResizerDoubleClick}
              onMouseDown={this.onMouseDown}
              onTouchStart={this.onTouchStart}
              onTouchEnd={this.onMouseUp}
              key="resizer"
              resizerClassName={resizerClassNamesIncludingDefault}
              split={split}
              style={resizerStyle || {}}
              onPaneToggle={this.onPaneToggle}
              isCollapsable={isCollapsible}
              hideWhenCollapsed={(collapseSize ?? 0) > 3}
            />
            <Pane
              className={pane2Classes}
              key="pane2"
              eleRef={(node: HTMLDivElement) => {
                this.pane2 = node;
              }}
              size={pane2Size}
              split={split}
              style={pane2Style}
            >
              {notNullChildren[1]}
            </Pane>
          </>
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSplitPaneDragging: (isDragging: boolean) =>
    dispatch({ type: "SET_SPLIT_PANE_DRAGGING", payload: isDragging }),
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
polyfill(SplitPane);
export default connect(
  null,
  mapDispatchToProps,
)(SplitPane as React.ComponentClass<SplitPaneProps, SplitPaneState>);
