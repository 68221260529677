import { createSelector } from "@reduxjs/toolkit";
import { ApplicationScope } from "@superblocksteam/shared";
import type { AppState } from "store/types";

const getEvents = (state: AppState) => state.application.events;

export const getAllEvents = createSelector([getEvents], (events) => ({
  ...events[ApplicationScope.PAGE],
  ...events[ApplicationScope.APP],
}));

const EMPTY_OBJ = {};
export const selectEventsByScopeAndId = createSelector(
  [getEvents],
  (events) => ({
    [ApplicationScope.PAGE]: events[ApplicationScope.PAGE],
    [ApplicationScope.APP]: events[ApplicationScope.APP],
    [ApplicationScope.GLOBAL]: EMPTY_OBJ,
  }),
);

export const getAllEventNames = createSelector(
  [getAllEvents],
  (events): string[] => {
    return Object.values(events).map((event) => event.name);
  },
);

export const getScopedEventNames = createSelector(
  [getEvents, (_, scope: ApplicationScope) => scope],
  (events, scope) => {
    return Object.values(events[scope]).map((event) => event.name);
  },
);

export const getScopedEvents = createSelector(
  [getEvents, (_, scope: ApplicationScope) => scope],
  (events, scope) => {
    return events[scope];
  },
);

export const getEventById = createSelector(
  [getAllEvents, (_, id: string) => id],
  (events, id) => events[id],
);

export const getAllEventsAsArray = createSelector([getAllEvents], (events) => {
  return Object.values(events);
});

export const getEventByName = createSelector(
  [
    getEvents,
    (_, eventName: string) => eventName,
    (_, _name, scope: ApplicationScope) => scope,
  ],
  (events, eventName, scope) => {
    return Object.values(events[scope]).find(
      (event) => event.name === eventName,
    );
  },
);
