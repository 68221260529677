import {
  ExportResult,
  ExportResultCode,
  hrTimeToMicroseconds,
} from "@opentelemetry/core";
import { ReadableSpan, SpanExporter } from "@opentelemetry/sdk-trace-base";

export const TRACES_KEY = "sb_app_debugger_traces";
const TRACE_ID_KEY = "sb_app_debugger_trace_id";
export const TRACE_LAST_UPDATED_KEY = "sb_app_debugger_last_updated";
const STORAGE_LIMIT = 1000;
/**
 * This is implementation of {@link SpanExporter} that stores spans in localStorage.
 * This class is meant to be used temporarily for displaying only the most recent
 * trace.
 */

export class LocalStorageExporter implements SpanExporter {
  /**
   * Export spans.
   * @param spans
   * @param resultCallback
   */
  export(
    spans: ReadableSpan[],
    resultCallback: (result: ExportResult) => void,
  ): void {
    return this._storeSpans(spans, resultCallback);
  }

  /**
   * Shutdown the exporter.
   */
  shutdown(): Promise<void> {
    this._storeSpans([]);
    return Promise.resolve();
  }

  /**
   * converts span info into more readable format
   * @param span
   */
  private _exportInfo(span: ReadableSpan) {
    return {
      traceId: span.spanContext().traceId,
      parentId: span.parentSpanId,
      traceState: span.spanContext().traceState?.serialize(),
      name: span.name,
      id: span.spanContext().spanId,
      kind: span.kind,
      timestamp: hrTimeToMicroseconds(span.startTime),
      duration: hrTimeToMicroseconds(span.duration),
      attributes: span.attributes,
      status: span.status,
      events: span.events,
      links: span.links,
    };
  }

  /**
   * Store spans in localStorage
   * @param spans
   * @param done
   */
  private _storeSpans(
    spans: ReadableSpan[],
    done?: (result: ExportResult) => void,
  ): void {
    if (spans.length === 0) {
      if (done) {
        return done({ code: ExportResultCode.SUCCESS });
      }
    }
    const parsedSpans = spans.map((span) => this._exportInfo(span));
    const traceId = parsedSpans[parsedSpans.length - 1].traceId;
    const storedTraceId = localStorage.getItem(TRACE_ID_KEY);
    if (storedTraceId && traceId === storedTraceId) {
      // add to the existing trace
      try {
        const rawExistingTraces = localStorage.getItem(TRACES_KEY);
        const existingTraces = JSON.parse(rawExistingTraces ?? "[]");
        if (existingTraces.length + parsedSpans.length > STORAGE_LIMIT) {
          const overage =
            STORAGE_LIMIT - (existingTraces.length + parsedSpans.length);
          localStorage.setItem(
            TRACES_KEY,
            JSON.stringify([...existingTraces.slice(overage), ...parsedSpans]),
          );
        } else {
          localStorage.setItem(
            TRACES_KEY,
            JSON.stringify([...existingTraces, ...parsedSpans]),
          );
        }
      } catch (e) {
        localStorage.setItem(TRACES_KEY, JSON.stringify(parsedSpans));
      }
    } else {
      // replace the existing trace
      localStorage.setItem(TRACES_KEY, JSON.stringify(parsedSpans));
      localStorage.setItem(TRACE_ID_KEY, traceId);
    }
    localStorage.setItem(TRACE_LAST_UPDATED_KEY, Date.now().toString());
    if (done) {
      return done({ code: ExportResultCode.SUCCESS });
    }
  }
}
