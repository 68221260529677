import { ScheduleState } from "@superblocksteam/shared";
import { call } from "redux-saga/effects";
import { lock } from "store/slices/apisShared/sharedPersistApiLock";
import { EntitiesErrorType } from "store/utils/types";
import logger from "utils/logger";
import { createSaga, SagaType } from "../../../utils/saga";
import { updateScheduleState } from "../client";
import slice from "../slice";

type UpdateScheduleStatePayload = {
  scheduledJobId: string;
  scheduleState: ScheduleState;
};

function* updateScheduleStateInternal({
  scheduledJobId,
  scheduleState,
}: UpdateScheduleStatePayload) {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  let unlock = () => {};
  try {
    unlock = yield call(lock, scheduledJobId);

    const result: Awaited<ReturnType<typeof updateScheduleState>> = yield call(
      updateScheduleState,
      {
        scheduledJobId,
        scheduleState,
      },
    );
    return result;
  } catch (err) {
    logger.debug(`Errored when updating schedule state: ${err}`);
    throw err;
  } finally {
    unlock();
  }
}

export const updateScheduleStateSaga = createSaga(
  updateScheduleStateInternal,
  "updateScheduleStateSaga",
  {
    sliceName: slice.name,
    type: SagaType.Throttled,
    keySelector: (payload) => payload.scheduledJobId,
  },
);

slice.saga(updateScheduleStateSaga, {
  start(state, { payload }) {
    delete state.errors[payload.scheduledJobId];
  },
  success(state, { meta }) {
    delete state.errors[meta.args.scheduledJobId];
  },
  error(state, { payload, meta }) {
    state.meta[meta.args.scheduledJobId].dirty = true;
    state.errors[meta.args.scheduledJobId] = {
      error: payload,
      type: EntitiesErrorType.SAVE_ERROR,
    };
  },
});
