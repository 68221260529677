import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Converter } from "showdown";
import styled from "styled-components";
import { ReactComponent as LightbulbIcon } from "assets/icons/common/lightbulb.svg";
import { ReactComponent as PasteIcon } from "assets/icons/common/paste-arrow.svg";
import { SyntaxType } from "code-formatting/constants";
import { AutolinkedText } from "components/ui/AutolinkedText";
import { JwtContext } from "hooks/ui/JwtProvider";
import { ReactComponent as CloseIcon } from "legacy/assets/icons/control/close.svg";

import { colors } from "styles/colors";
import {
  BetaTag,
  CloseButton,
  ErrorMessage,
  Header,
  PasteButton,
  RainbowBoxShadow,
  Title,
  TitleSection,
} from "../Shared";
import { AiAssistantOptionType, FlowType } from "./constants";
import { queryAiAssistant } from "./query";
import { generateRequestBodyForTask, getTitleForOption } from "./util";

const Explanation = styled.div`
  font-size: 12px;
  padding: 8px 12px 12px;
  max-height: 350px;
  overflow-y: auto;
  code {
    color: ${(props) => props.theme.colors.ACCENT_ORANGE};
    font-weight: 600;
  }
  p:last-child {
    margin: 0;
  }
`;

const ErrorWrapper = styled.div`
  padding: 12px;
`;

const LoadingIndicator = styled.span`
  font-style: italic;
  color: ${(props) => props.theme.colors.GREY_400};
`;

const converter = new Converter();
converter.setOption("simpleLineBreaks", true);

const UNCOMMENTABLE_SYNTAXES = [
  SyntaxType.BINDING,
  SyntaxType.MONGODB,
  SyntaxType.DYNAMODB,
  SyntaxType.UNKNOWN,
  SyntaxType.JSON,
  SyntaxType.ROCKSET,
];

const AiExplainFlow = ({
  codeToExplain,
  onClose,
  allCode,
  option,
  onConfirm,
}: {
  codeToExplain: string;
  onClose: () => void;
  allCode: string;
  option: AiAssistantOptionType;
  onConfirm: (
    aiAssistantOptionType: AiAssistantOptionType,
    code?: string | null,
  ) => void;
}) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [rawExplanation, setRawExplanation] = useState<string | null>("");
  const [formattedExplanation, setFormattedExplanation] = useState<
    string | null
  >("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { jwt } = useContext(JwtContext);
  const header = useMemo(() => getTitleForOption(option), [option]);

  const handleMessage = useCallback(
    (message: string) => setRawExplanation((exp) => exp + message),
    [],
  );

  const canAddAsComment = useMemo(
    () => !UNCOMMENTABLE_SYNTAXES.includes(option.syntax),
    [option.syntax],
  );

  useEffect(() => {
    setFormattedExplanation(converter.makeHtml(rawExplanation ?? ""));
  }, [rawExplanation]);

  useEffect(() => {
    if (!jwt) return;
    // clear previous explanation
    setRawExplanation("");

    queryAiAssistant({
      token: jwt,
      option,
      body: generateRequestBodyForTask({
        selectedCode: codeToExplain,
        allCode,
        flowType: FlowType.EXPLAIN,
        syntax: option.syntax,
      }),
      onMessage: handleMessage,
      onComplete: () => {
        setIsLoading(false);
        buttonRef.current?.focus();
      },
      onError: (error) => {
        setIsLoading(false);
        setError(error);
      },
    });
  }, [codeToExplain, allCode, handleMessage, option, jwt]);

  return (
    <div>
      <RainbowBoxShadow>
        <Header>
          <TitleSection>
            <LightbulbIcon color={colors.GREY_500} />
            <Title>{header}</Title>
            <BetaTag>Beta</BetaTag>
          </TitleSection>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </Header>
      </RainbowBoxShadow>
      {isLoading && !formattedExplanation && (
        <Explanation>
          <LoadingIndicator>Loading...</LoadingIndicator>
        </Explanation>
      )}
      {error ? (
        <ErrorWrapper>
          <ErrorMessage>{error}</ErrorMessage>
        </ErrorWrapper>
      ) : (
        <Explanation>
          <AutolinkedText text={formattedExplanation ?? ""} target="_parent" />
        </Explanation>
      )}
      {canAddAsComment && !error && !isLoading && (
        <div style={{ padding: "12px " }}>
          <PasteButton
            ref={buttonRef}
            autoFocus
            onClick={() => onConfirm(option, rawExplanation)}
          >
            <PasteIcon />
            Insert as comment
          </PasteButton>
        </div>
      )}
    </div>
  );
};

export default AiExplainFlow;
