import {
  DropdownOption,
  OrganizationUserDto,
  PermissionedEntities,
  ShareEntryDto,
} from "@superblocksteam/shared";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { MultiFilter } from "components/ui/Filter";
import RecommendedTable, { RecColumn } from "components/ui/RecommendedTable";
import {
  SearchContainer,
  SearchInput,
  filterBySearch,
} from "components/ui/SearchSection";
import { useDebounce } from "hooks/ui";
import ShareModal, { ShareRole } from "pages/components/ShareModal";
import { selectOnlyOrganization } from "store/slices/organizations";
import { styleAsClass } from "styles/styleAsClass";
import { getIntegrationsPermissions, getUsers } from "./client";
import { EntityToRender, convertToEntityToRender } from "./constants";

const NameStyle = styleAsClass`
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type ColType = RecColumn<EntityToRender>;

const IntegrationList = () => {
  const orgId = useSelector(selectOnlyOrganization).id;
  const [searchTerm, setSearchTerm] = useState("");
  const onSearchChangeDebounced = useDebounce(
    (e) => setSearchTerm(e.target.value),
    200,
  );
  const [entities, setEntities] = useState<EntityToRender[]>([]);
  const [entitiesLoading, setEntitiesLoading] = useState(false);
  const [entityToModify, setEntityToModify] = useState<EntityToRender | null>();

  const [allOrgUsers, setAllOrgUsers] = useState<OrganizationUserDto[]>([]);
  const allUsersOptions = useMemo(
    () =>
      allOrgUsers.map((user) => ({
        value: user.id,
        displayName: user.name,
        key: user.id,
      })),
    [allOrgUsers],
  );

  const [selectedUsers, setSelectedUsers] = useState<DropdownOption[]>([]);
  const selectedUserIds = useMemo(
    () => selectedUsers.map((user) => user.value),
    [selectedUsers],
  );

  useEffect(() => {
    async function loadEntities() {
      setEntitiesLoading(true);
      const entities = await getIntegrationsPermissions();
      if (entities) {
        setEntities(entities.map(convertToEntityToRender));
      }
      setEntitiesLoading(false);
    }
    loadEntities();
  }, []);

  useEffect(() => {
    async function loadAllOrgUsers() {
      const users = await getUsers(orgId);
      if (users) {
        setAllOrgUsers(users);
        setSelectedUsers(
          users.map((user) => ({
            value: user.id,
            displayName: user.name,
            key: user.id,
          })),
        );
      }
    }
    loadAllOrgUsers();
  }, [orgId]);

  const [isShareModalVisible, setShareModalVisible] = useState(false);

  const [shareModalProps, setShareModalProps] = useState<{
    organizationId: string;
    resourceId: string;
    resourceType: PermissionedEntities;
    resourceDisplayName: string;
    roles: ShareRole[];
  }>();

  const onRowClick = (entity: EntityToRender) => {
    setShareModalVisible(true);
    setEntityToModify(entity);
    setShareModalProps({
      resourceId: entity.id,
      resourceType: PermissionedEntities.INTEGRATION,
      resourceDisplayName: entity.name,
      organizationId: orgId,
      roles: [ShareRole.BUILD_WITH, ShareRole.CONFIGURATOR],
    });
  };

  const onModalClose = useCallback(
    (shareEntries: ShareEntryDto[]) => {
      if (!entityToModify) return;
      setEntities((entities) => {
        const modifiedEntity = entities.find(
          (entity) => entity.id === entityToModify.id,
        );
        if (!modifiedEntity) return entities;

        modifiedEntity.configurators = shareEntries
          .filter((entry) => entry.role === "configurator")
          .map((entry) => entry.name);
        modifiedEntity.builders = shareEntries
          .filter((entry) => entry.role === "builder")
          .map((entry) => entry.name);
        return [...entities];
      });
    },
    [entityToModify],
  );

  const fitleredEntities = useMemo(() => {
    let filtered =
      searchTerm.length > 1
        ? entities.filter((entity) =>
            filterBySearch(entity, searchTerm, [
              "name",
              "owner",
              "builders",
              "viewers",
            ]),
          )
        : entities;

    filtered =
      selectedUsers.length === allUsersOptions.length
        ? filtered
        : filtered.filter((entity) => selectedUserIds.includes(entity.ownerId));
    return filtered;
  }, [
    allUsersOptions.length,
    entities,
    searchTerm,
    selectedUserIds,
    selectedUsers.length,
  ]);

  const columns: ColType[] = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        hidden: true,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Owner",
        accessor: "owner",
      },
      {
        Header: "Configurators",
        accessor: "configurators",
        Cell: ({ value }) => (
          <div className={NameStyle}>{value?.join(", ")}</div>
        ),
      },
      {
        Header: "Builders",
        accessor: "builders",
        Cell: ({ value }) => (
          <div className={NameStyle}>{value.join(", ")}</div>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <div className={SearchContainer}>
        <SearchInput
          placeholder="Search"
          onChange={onSearchChangeDebounced}
          data-test="entity-search-input"
        />
        <MultiFilter
          selectedItems={selectedUsers}
          options={allUsersOptions}
          onChange={setSelectedUsers}
          label="Owner"
          width={168}
          placeholder="Filter by status"
          enableSelectAll={true}
          defaultSelectAll={true}
          showSearchInPopover={true}
        />
      </div>
      <RecommendedTable<EntityToRender>
        data={fitleredEntities}
        dataLabel="integrations"
        uniqueKey="id"
        columns={columns}
        // using totalCount to avoid frequent update when search which could cause crash
        paginationOptions={entities.length > 10 ? { pageSize: 10 } : undefined}
        loading={entitiesLoading}
        onRowClick={onRowClick}
      />
      {shareModalProps && (
        <ShareModal
          isVisible={isShareModalVisible}
          setShareModalVisible={setShareModalVisible}
          {...shareModalProps}
          onModalClose={onModalClose}
        />
      )}
    </>
  );
};

export default IntegrationList;
