import {
  ScheduleConfig,
  Interval,
  ExecutionParam,
} from "@superblocksteam/shared";
import { defaults } from "lodash";
import { ApiDto, ApiTriggerType } from "store/slices/apis/types";
import {
  JobInterval,
  JobTriggerConfig,
} from "store/slices/apisV2/backend-types";
import { type ApiDtoWithPb } from "store/slices/apisV2/slice";
import {
  getV2ApiId,
  getV2ApiName,
} from "store/slices/apisV2/utils/getApiIdAndName";
import type { BackendTypes } from "store/slices/apisV2";

const getV2TriggerType = (api: BackendTypes.Api) => {
  if (api.trigger.application) {
    return ApiTriggerType.UI;
  }
  if (api.trigger.workflow) {
    return ApiTriggerType.WORKFLOW;
  }
  if (api.trigger.job) {
    return ApiTriggerType.SCHEDULE;
  }
};

const createV1ExecutionParamList = (
  rootKey: "params" | "body",
  v2Params: Record<string, any>,
): ExecutionParam[] => {
  const params = Object.entries(v2Params).reduce((accum, [key, value]) => {
    if (rootKey === "body") {
      accum[key] = value;
      return accum;
    }

    if (
      value != null &&
      typeof value === "object" &&
      Array.isArray(value.values)
    ) {
      if (value.values.length === 1 && typeof value.values[0] === "string") {
        accum[key] = value.values[0];
      } else {
        accum[key] = value.values;
      }
    } else {
      accum[key] = value;
    }
    return accum;
  }, {} as Record<string, unknown>);

  return [
    {
      key: rootKey,
      value: params,
    },
  ];
};

const v2ToV1IntervalMap = {
  INTERVAL_UNSPECIFIED: Interval.Month, // 0 / UNSPECIFIED in v2, so just use the default for v1 which is month
  INTERVAL_MINUTE: Interval.Minute,
  INTERVAL_HOUR: Interval.Hour,
  INTERVAL_DAY: Interval.Day,
  INTERVAL_WEEK: Interval.Week,
  INTERVAL_MONTH: Interval.Month,
};

const v1ToV2IntervalMap = {
  [Interval.Minute]: JobInterval.INTERVAL_MINUTE,
  [Interval.Hour]: JobInterval.INTERVAL_HOUR,
  [Interval.Day]: JobInterval.INTERVAL_DAY,
  [Interval.Week]: JobInterval.INTERVAL_WEEK,
  [Interval.Month]: JobInterval.INTERVAL_MONTH,
};

const namesOfDaysOfWeekOrdered = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
] as const;

export const createV1Schedule = (
  job: undefined | BackendTypes.Trigger["job"],
) => {
  if (!job) {
    return undefined;
  }

  const days = job.days;

  const daysOfWeek = days && namesOfDaysOfWeekOrdered.map((day) => days[day]);

  // Ensure Sunday is true by default if none are selected
  if (daysOfWeek && daysOfWeek.every((day) => !day)) {
    daysOfWeek[0] = true;
  }

  return new ScheduleConfig(
    defaults(
      {
        frequency: job?.frequency,
        interval:
          (job.interval && v2ToV1IntervalMap[job.interval]) ?? Interval.Month,
        time: job.time && new Date(job.time),
        dayOfMonth: job.dayOfMonth != null ? job.dayOfMonth : 1,
        daysOfWeek,
        timezoneLocale: job.timezoneLocale,
      },
      ScheduleConfig.default(),
    ),
  );
};

export const createV2Schedule = (
  v1Schedule: ScheduleConfig,
): Partial<JobTriggerConfig> => {
  const days = {
    sunday: v1Schedule.daysOfWeek[0],
    monday: v1Schedule.daysOfWeek[1],
    tuesday: v1Schedule.daysOfWeek[2],
    wednesday: v1Schedule.daysOfWeek[3],
    thursday: v1Schedule.daysOfWeek[4],
    friday: v1Schedule.daysOfWeek[5],
    saturday: v1Schedule.daysOfWeek[6],
  };
  if (Object.values(days).every((day) => !day)) {
    days.monday = true;
  }

  return {
    frequency: v1Schedule.frequency,
    interval:
      v1ToV2IntervalMap[v1Schedule.interval] ?? JobInterval.INTERVAL_MONTH,
    time: v1Schedule.time && v1Schedule.time.toISOString(),
    dayOfMonth: v1Schedule.dayOfMonth ?? 1,
    timezoneLocale: v1Schedule.timezoneLocale,
    days,
  };
};

export const getApiPropsForWorkflow = (
  version: "v1" | "v2",
  api: undefined | ApiDto,
) => {
  if (version === "v1") {
    const v1Api = api as ApiDto | undefined;
    return {
      id: v1Api?.id ?? "",
      name: v1Api?.name ?? v1Api?.actions?.name ?? "",
      creator: v1Api?.creator,
      triggerType: v1Api?.actions?.triggerType,
      queryParams: v1Api?.actions?.workflowQueries ?? [],
      bodyParams: v1Api?.actions?.workflowParams ?? [],
      schedule: v1Api?.actions?.schedule,
      sendEmailOnFailure: v1Api?.sendEmailOnFailure ?? false,
      deployedCommitId: v1Api?.deployedCommitId,
      hasGitRepoConnection: Boolean(v1Api?.repoConnection?.created),
    };
  } else {
    const v2Api = api as ApiDtoWithPb | undefined;
    return {
      id: v2Api ? getV2ApiId(v2Api) : "",
      name: v2Api ? getV2ApiName(v2Api) : "",
      creator: v2Api?.creator,
      triggerType: v2Api && getV2TriggerType(v2Api?.apiPb),
      queryParams: createV1ExecutionParamList(
        "params",
        v2Api?.apiPb?.trigger?.workflow?.parameters?.query ?? [],
      ),
      bodyParams: createV1ExecutionParamList(
        "body",
        v2Api?.apiPb?.trigger?.workflow?.parameters?.body ?? [],
      ),
      schedule: createV1Schedule(v2Api?.apiPb?.trigger?.job),
      sendEmailOnFailure:
        v2Api?.apiPb?.trigger?.job?.options?.sendEmailOnFailure ?? false,
      deployedCommitId: v2Api?.deployedCommitId,
      hasGitRepoConnection: !!v2Api?.repoConnection?.created,
    };
  }
};
