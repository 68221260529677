import { ApplicationScope, RouteDef } from "@superblocksteam/shared";
import {
  DataTree,
  DataTreeEvent,
} from "legacy/entities/DataTree/dataTreeFactory";
import { generateReactKey } from "legacy/utils/generators";
import {
  getResettableProperties,
  getSettableProperties,
} from "legacy/widgets/eventHandlerPanel";
import { AddActionEvent } from "./types";

const sanitizeEventHandlers = ({
  dataTree,
  routes,
  value,
}: {
  dataTree: DataTree;
  routes: RouteDef[];
  value: AddActionEvent;
}) => {
  const getWidget = (widgetName: string) => {
    for (const scope in dataTree) {
      const widgets = (dataTree as Record<string, any>)[scope];
      const allWidgets = Object.values(widgets) as {
        widgetId?: string;
        widgetName?: string;
        type?: string;
      }[];
      const widget = allWidgets.find(
        (widget) => widget.widgetName === widgetName,
      );
      if (widget) {
        return {
          name: widgetName,
          id: widget.widgetId,
          type: widget.type,
        };
      }
    }
    return undefined;
  };

  const getItemId = (itemName: string, scope: string) => {
    const items = (dataTree as Record<string, any>)[scope];
    const allItems = Object.values(items) as {
      id?: string;
      name?: string;
    }[];
    const foundItem = allItems.find((item) => item.name === itemName);
    if (foundItem) {
      return foundItem.id;
    }
    return undefined;
  };

  const itemExists = (itemName: string) => {
    const items = (dataTree as Record<string, any>)[ApplicationScope.PAGE];
    return !!items[itemName];
  };

  const getItem = (itemName: string, scope: string) => {
    const items = (dataTree as Record<string, any>)[scope];
    const allItems = Object.values(items) as {
      id?: string;
      name?: string;
    }[];
    const foundItem = allItems.find((item) => item.name === itemName);
    if (foundItem) {
      return foundItem;
    }
    return undefined;
  };

  let newValue = {};

  switch (value.type) {
    case "setComponentProperty":
    case "resetComponent": {
      let property: string | undefined = value.propertyName;
      const widget = getWidget(value.component);
      const availableProperties = !widget?.type
        ? []
        : value.type === "setComponentProperty"
        ? getSettableProperties(widget.type)
        : getResettableProperties(widget.type);
      if (
        !availableProperties ||
        !availableProperties.some((prop) => prop.value === property)
      ) {
        console.warn(
          `Property ${property} is not available for component ${value.component}`,
        );
        property = availableProperties?.[0]?.value;
      }
      if (!availableProperties || availableProperties?.length === 0) {
        newValue = {
          ...value,
          component: undefined,
          propertyName: undefined,
          widget: undefined,
        };
      } else {
        newValue = {
          ...value,
          component: undefined,
          widget,
          propertyName: property,
        };
      }
      break;
    }
    case "navigateToRoute":
      newValue = {
        ...value,
        routePathDescriptor: undefined,
        routeId: routes.find(
          (route) => route.path === value.routePathDescriptor,
        )?.id,
      };
      break;
    case "setStateVar":
    case "resetStateVar":
    case "controlTimer":
      {
        const itemId = getItemId(value.state.name, value.state.scope);
        newValue = {
          ...value,
          state: itemId
            ? {
                ...value.state,
                id: itemId,
              }
            : undefined,
        };
        if (value.type === "controlTimer" && itemId) {
          // need to hoist the timer name to the top level
          (newValue as any).name = value.state.name;
        }
      }
      break;
    case "triggerEvent": {
      const event = getItem(
        value.event.name,
        value.event.scope,
      ) as DataTreeEvent;
      let eventPayload = {};
      if (event) {
        eventPayload = (value.eventPayload ?? []).reduce((acc, val) => {
          const argumentName = val.name;
          const argumentValue = val.value;
          const argumentId = (event.arguments ?? [])?.find(
            (arg: { name: string; id: string }) => arg.name === argumentName,
          )?.id;
          if (argumentId) {
            acc[argumentId] = argumentValue;
          }
          return acc;
        }, {} as Record<string, any>);
      }
      newValue = {
        ...value,
        event: event
          ? {
              ...value.event,
              id: event?.id,
            }
          : undefined,
        eventPayload,
      };
      break;
    }
    case "executeApi":
    case "cancelApi":
      newValue = {
        ...value,
        apiNames: value.apiNames.filter(itemExists),
      };
      break;
    default:
      newValue = value;
  }

  return {
    ...newValue,
    id: generateReactKey(),
  };
};

export const getEventHandlers = ({
  prevValue,
  value,
  dataTree,
  routes,
}: {
  prevValue: any;
  value: AddActionEvent | AddActionEvent[];
  dataTree: DataTree;
  routes: RouteDef[];
}) => {
  const newEvents = [...((prevValue as Array<any>) ?? [])];
  if (Array.isArray(value)) {
    for (const event of value) {
      newEvents.push(
        sanitizeEventHandlers({
          dataTree,
          routes,
          value: event as AddActionEvent,
        }),
      );
    }
  } else {
    newEvents.push(
      sanitizeEventHandlers({
        dataTree,
        routes,
        value,
      }),
    );
  }
  return newEvents;
};
