import { useCallback, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "styled-components";
import { useElementRect, useFeatureFlag } from "hooks/ui";
import { PropertyPaneLayoutConfig } from "legacy/constants/WidgetConstants";
import { APP_MODE } from "legacy/reducers/types";
import {
  getAppMode,
  getResponsiveCanvasUnscaledWidth,
  getScrollbarWidth,
} from "legacy/selectors/applicationSelectors";
import {
  getCanvasGutter,
  getIsLeftPanePinned,
  getPageWidth,
} from "legacy/selectors/editorSelectors";
import {
  getIsPropertyPaneVisible,
  getPropertyPaneWidth,
} from "legacy/selectors/propertyPaneSelectors";
import sessionStorage, { SessionStorageKey } from "legacy/utils/sessionStorage";
import { useAppDispatch, useAppSelector } from "store/helpers";
import { Flag } from "store/slices/featureFlags";
import { useThrottle } from "../../hooks/ui";
import { updateResponsiveCanvasMetadata } from "../actions/pageActions";

export const useDynamicAppLayout = () => {
  const screenWidth =
    useElementRect(document.body)?.width || document.body.clientWidth;
  const screenWidthRef = useRef(0);
  screenWidthRef.current = screenWidth;
  // TODO: add this when we support multi page
  // const currentPageId = useSelector(getCurrentPageId);
  const appMode = useSelector(getAppMode);
  const propertyPaneWidth =
    useSelector(getPropertyPaneWidth) ??
    sessionStorage.getInt(SessionStorageKey.PROP_PANE_WIDTH) ??
    PropertyPaneLayoutConfig.defaultWidth;
  const isPropertyPaneVisible = useSelector(getIsPropertyPaneVisible);

  const isLeftPanePinned = useSelector(getIsLeftPanePinned);
  const canvasGutter = useSelector(getCanvasGutter);

  const responsiveRawCanvasWidth = useAppSelector((state) =>
    getResponsiveCanvasUnscaledWidth(state),
  );
  const dispatch = useAppDispatch();
  const scrollbarWidth = useSelector(getScrollbarWidth) ?? 0;
  const isIframeEnabled = useFeatureFlag(Flag.ENABLE_IFRAME);

  /**
   * app layout range i.e minWidth and maxWidth for the current layout
   * if there is no config for the current layout, use default layout i.e desktop
   */
  const { minWidth, maxWidth } = useSelector(getPageWidth);
  const layoutWidthRange = useMemo(() => {
    return {
      minWidth: minWidth?.value ?? 0,
      maxWidth: maxWidth?.value ?? Number.MAX_SAFE_INTEGER,
    };
  }, [maxWidth?.value, minWidth?.value]);

  const theme = useTheme();
  const explorerClosedWidth = theme.legacy.explorerClosedWidth;

  /**
   * calculate the width for the canvas
   *
   * cases:
   *  - if max width is negative, use calculated width
   *  - if calculated width is in range of min/max widths of layout, use calculated width
   *  - if calculated width is less then min width, use min Width
   *  - if calculated width is larger than max width, use max width
   *  - by default use min width
   *
   * @param screenWidth
   * @returns
   */
  const calculateCanvasWidth = useCallback(
    (screenWidth: number) => {
      const { maxWidth, minWidth } = layoutWidthRange;

      let calculatedWidth: number;

      if (!isIframeEnabled) {
        calculatedWidth =
          appMode === APP_MODE.EDIT
            ? screenWidth - explorerClosedWidth
            : screenWidth - scrollbarWidth;
      } else if (appMode === APP_MODE.EDIT) {
        // Scrollbar is internal to the iframe width, the extra 1px is to prevent a second level of scrollbars
        calculatedWidth =
          screenWidth -
          explorerClosedWidth -
          2 -
          scrollbarWidth -
          canvasGutter * 2;
      } else {
        calculatedWidth = screenWidth - scrollbarWidth;
      }
      if (isPropertyPaneVisible && appMode === APP_MODE.EDIT) {
        calculatedWidth -= propertyPaneWidth;
      }

      if (isLeftPanePinned && appMode === APP_MODE.EDIT) {
        calculatedWidth -= theme.legacy.sidebarWidth;
      }

      switch (true) {
        case calculatedWidth < maxWidth && calculatedWidth > minWidth:
          return calculatedWidth;
        case calculatedWidth < minWidth:
          return minWidth;
        case calculatedWidth > maxWidth:
          return maxWidth;
        default:
          return minWidth;
      }
    },
    [
      layoutWidthRange,
      appMode,
      explorerClosedWidth,
      scrollbarWidth,
      isPropertyPaneVisible,
      isLeftPanePinned,
      canvasGutter,
      propertyPaneWidth,
      theme.legacy.sidebarWidth,
      isIframeEnabled,
    ],
  );

  const calculatedUnscaledWidth = useMemo(
    () => calculateCanvasWidth(screenWidth),
    [calculateCanvasWidth, screenWidth],
  );
  /**
   * resizes the layout based on the screenWidth
   *
   * @param screenWidth
   */
  const resizeToLayout = useCallback(
    (screenWidth: any) => {
      if (responsiveRawCanvasWidth !== calculatedUnscaledWidth) {
        dispatch(
          updateResponsiveCanvasMetadata({
            unscaledWidth: calculatedUnscaledWidth,
          }),
        );
      }
    },
    [responsiveRawCanvasWidth, calculatedUnscaledWidth, dispatch],
  );

  const throttledResize = useThrottle(resizeToLayout, 250);

  useEffect(() => {
    if (throttledResize) {
      throttledResize(screenWidth);
    }
  }, [throttledResize, screenWidth]);

  /**
   * resize the layout if any of the following thing changes:
   *  - properties panel width
   *  - resizeToLayout
   */
  useEffect(() => {
    resizeToLayout(screenWidthRef.current);
  }, [propertyPaneWidth, resizeToLayout]);

  return {
    rawCanvasWidth: calculatedUnscaledWidth,
    screenWidth: screenWidth,
    propertyPaneWidth: propertyPaneWidth,
  };
};
