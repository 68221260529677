import { Classes } from "@blueprintjs/core";
import { ApplicationScope } from "@superblocksteam/shared";
import { Row } from "antd";
import React, {
  ReactNode,
  useState,
  useEffect,
  useRef,
  forwardRef,
  Ref,
  useCallback,
  SetStateAction,
  Dispatch,
  useMemo,
  useImperativeHandle,
} from "react";
import { useStore } from "react-redux";
import scrollIntoView from "scroll-into-view-if-needed";
import styled, { css } from "styled-components";
import EntityAnalysisRenderer from "analysis/EntityAnalysisBadge";
import { ReactComponent as ChevronDownCircleIcon } from "assets/icons/common/chevron-down-circle.svg";
import { ReactComponent as StateIcon } from "assets/icons/sidebar/state.svg";
import IconButton from "components/ui/IconButtons/IconButton";
import { useEntityNameValidator } from "hooks/store";
import { useFeatureFlag } from "hooks/ui";
import { updateApplicationSidebarKey } from "legacy/actions/editorPreferencesActions";
import { Layers } from "legacy/constants/Layers";
import { getMainContainerWidgetId } from "legacy/selectors/editorSelectors";

import { getIsTab } from "legacy/selectors/entitiesSelector";
import { getWidgetsAreSelected } from "legacy/selectors/sagaSelectors";
import { useAppDispatch, useAppSelector } from "store/helpers";
import { Flag } from "store/slices/featureFlags";
import { colors } from "styles/colors";
import { ItemKinds } from "../../PropertyPane/ItemKindConstants";
import { SideBarKeys } from "../../constants";
import { SelectedItemPos } from "../helpers";
import Collapse from "./Collapse";
import CollapseToggle from "./CollapseToggle";
import { EntityContextMenu } from "./EntityContextMenu";
import EntityName from "./Name";
import type { AppState } from "store/types";

enum EntityClassNames {
  CONTEXT_MENU = "entity-context-menu",
  ADD_BUTTON = "t--entity-add-btn",
  WRAPPER = "t--entity",
  PROPERTY = "t--entity-property",
  COLLAPSE_ICON = "t--collapse-icon",
}

const EntityItemWrapper = styled.div<{ active: boolean; step: number }>`
  position: relative;
  line-height: ${(props) => props.theme.legacy.lineHeights[2]}px;

  ${(props) =>
    props.step === 1 &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_100};
    `}

  &:last-child {
    border-bottom: none;
  }

  &.nav-group {
    padding: 6px 0;
  }
`;

const HierarchyDivider = styled.div<{ step: number }>`
  position: absolute;
  bottom: 6px;
  left: ${(props) => (props.step < 2 ? 0 : 8 + (props.step - 1) * 17)}px;
  height: 100%;
  width: 1px;
  visibility: ${({ step }) => (step < 2 ? "hidden" : "visible")};
  background: ${({ theme }) => theme.colors.GREY_100};
  z-index: ${Layers.navDividers};
`;

const ItemButton = styled(IconButton)`
  opacity: 0;
`;

interface EntityItemProps {
  active: boolean;
  step: number;
  spaced: boolean;
  hasIcon: boolean;
  hasBottomMargin?: boolean;
  disableEdit?: boolean;
  disableClick?: boolean;
  noIndentForCollapse?: boolean;
}

export const ItemIndentation = ({
  step,
  children,
  ...props
}: { step: number } & React.HTMLAttributes<HTMLDivElement>) => {
  const style = useMemo(() => {
    return {
      position: "relative",
      paddingLeft: step < 2 ? 17 : (step - 1) * 17,
    } as React.CSSProperties;
  }, [step]);
  return (
    <div style={style} {...props}>
      {children}
    </div>
  );
};

const EntityItem = styled.div<EntityItemProps>`
  position: relative;
  font-size: ${(props) => props.theme.legacy.appStyles.dropdown.fontSize}px;
  font-weight: ${(props) => (props.step === 1 ? 500 : 400)};
  padding-left: ${(props) => (props.step < 2 ? 17 : (props.step - 1) * 17)}px;
  background: ${(props) =>
    props.active ? props.theme.colors.SUBTLE_BLUE : props.theme.colors.WHITE};
  &:hover {
    background: ${({ theme, active }) =>
      active ? theme.colors.SUBTLE_BLUE : theme.colors.GREY_50};
  }
  color: ${({ theme, step }) =>
    step === 1 ? theme.colors.GREY_800 : theme.colors.GREY_700};
  height: 28px;
  width: 100%;
  display: inline-grid;
  grid-template-columns: ${({ hasIcon, noIndentForCollapse }) =>
    hasIcon
      ? `${noIndentForCollapse ? 0 : 18}px 18px minmax(100px, 1fr) 16px`
      : "18px minmax(100px, 1fr) 16px"};
  cursor: ${(props) => (props.disableClick ? "default" : "pointer")};
  align-items: center;

  margin-bottom: ${(props) =>
    props.hasBottomMargin && props.step === 1 ? "6px" : "0px"};

  ${(props) =>
    props.step > 0 &&
    css`
      &:hover {
        .context-menu-button,
        .entity-button {
          opacity: 1;
        }
      }
    `}

  .context-menu-open.context-menu-button {
    opacity: 1;
    border: 1px solid ${colors.GREY_100};
    background-color: ${colors.GREY_25};
  }

  & .${Classes.POPOVER_TARGET} {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &&&& .${EntityClassNames.CONTEXT_MENU} {
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
  }

  &&&&:hover .${EntityClassNames.CONTEXT_MENU} {
    visibility: visible;
  }

  .copy-button {
    display: none;
  }

  &&&&:hover .copy-button {
    display: block;
  }
`;

const IconWrapper = styled.span<{ isActive?: boolean }>`
  line-height: ${(props) => props.theme.legacy.lineHeights[0]}px;

  ${({ isActive, theme }) =>
    isActive &&
    css`
      svg path,
      svg rect {
        fill: ${theme.colors.ACCENT_BLUE_500};
      }
      svg.stroke-outline * {
        stroke: ${(props) => props.theme.colors.ACCENT_BLUE_500};
        fill: ${(props) => props.theme.colors.ACCENT_BLUE_500};
      }
    `}
`;

export type EntityElement = {
  startEditing: () => void;
  scrollIntoView: () => void;
};

export type EntityProps = {
  entityId: string;
  entityKind?: ItemKinds;
  className?: string;
  name: string;
  children?: ReactNode;
  icon?: ReactNode;
  disabled?: boolean;
  action?: (e?: any, itemIds?: string[]) => void;
  doubleClick?: (e?: any, itemIds?: string[]) => void;
  active?: boolean;
  isDefaultExpanded?: boolean;
  additionalContextMenu?: ReactNode;
  searchKeyword?: string;
  step: number;
  apiVersion?: "v1" | "v2"; // Temporary
  updateEntityName?: (id: string, name: string) => any;
  runActionOnExpand?: boolean;
  onNameEdit?: (input: string, limit?: number) => string;
  onToggle?: (isOpen: boolean) => void;
  // Section headers are "User Interfaces", "APIs", etc. They shouldn't be selectable or highlighted.
  isSectionHeader?: boolean;
  childrenIdsOfParent?: string[];
  itemPos?: SelectedItemPos;
  lastSelectedItemPos?: SelectedItemPos | undefined;
  setLastSelectedItemPos?: Dispatch<
    SetStateAction<SelectedItemPos | undefined>
  >;
  hideContextMenu?: boolean;
  disableEdit?: boolean;
  toggleOnClick?: boolean;
  codeTypeface?: boolean;
  allowExpandingIfEmpty?: boolean;
  disableClick?: boolean;
  showTooltip?: boolean;
  innerTooltip?: React.ReactNode;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  // not collapsible and in root level, used in pure list
  noIndentForCollapse?: boolean;
  showDuplicate?: boolean;
  dataTest?: string;
  // when entity opened not by manually toggle (e.g. children is selected)
  passiveExpand?: boolean;
  setPassiveExpand?: Dispatch<SetStateAction<boolean>>;
  scope?: ApplicationScope;
  showViewState?: boolean;
};

type EntityItemContainerProps = {
  children?: ReactNode;
  active?: boolean;
  step: number;
  className?: string;
};

export const EntityItemContainer = forwardRef(
  (props: EntityItemContainerProps, ref: Ref<HTMLDivElement>) => {
    return (
      <EntityItemWrapper
        active={!!props.active}
        step={props.step}
        className={`${EntityClassNames.WRAPPER} ${props.className}`}
        ref={ref}
      >
        {new Array(props.step).fill(0).map((_, i) => (
          <HierarchyDivider key={i} step={i} className="hierarchy-divider" />
        ))}
        {props.children}
      </EntityItemWrapper>
    );
  },
);
EntityItemContainer.displayName = "EntityItemContainer";

const Entity = forwardRef((props: EntityProps, ref: Ref<EntityElement>) => {
  const {
    updateEntityName,
    entityId,
    hideContextMenu,
    disableEdit,
    codeTypeface,
    allowExpandingIfEmpty,
    disableClick,
    showTooltip,
    innerTooltip,
    prefix,
    suffix,
    noIndentForCollapse,
    passiveExpand,
    entityKind,
    name,
    scope,
    setPassiveExpand,
    showViewState = false,
  } = props;

  const store = useStore<AppState>();
  const dispatch = useAppDispatch();

  const isInvalidName = useEntityNameValidator(props.name, props.scope);

  const isUpdating = useAppSelector(
    (state: AppState) =>
      state.legacy.ui.explorer.updatingEntity === props.entityId,
  );
  const isTab = useAppSelector((state) => getIsTab(state, props.entityId));

  const [isOpen, open] = useState(!!props.isDefaultExpanded);
  const [isEditing, setEditing] = useState(false);

  const enterEditMode = useCallback(() => !isTab && setEditing(true), [isTab]);

  const exitEditMode = useCallback(() => setEditing(false), []);

  const toggleChildren = useCallback(() => {
    // Make sure this entity is enabled before toggling the collpse of children.
    !props.disabled && open(!isOpen);
    if (props.runActionOnExpand && !isOpen) {
      props.action && props.action();
    }

    if (props.onToggle) {
      props.onToggle(!isOpen);
    }
  }, [isOpen, props]);

  const openChildren = useCallback(() => {
    !props.disabled && open(true);
    if (!isOpen) {
      if (props.runActionOnExpand) {
        props.action?.();
      }
      props.onToggle?.(true);
    }
  }, [isOpen, props]);

  const updateNameCallback = useCallback(
    (name: string) => {
      return updateEntityName && updateEntityName(entityId, name);
    },
    [updateEntityName, entityId],
  );

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (props.disableClick) return;
      window.getSelection()?.empty(); // when holding shift to multiselect, window selection will be set unexpectedly
      let itemIds;
      if (
        e.shiftKey &&
        props.itemPos &&
        props.lastSelectedItemPos &&
        props.itemPos.level === props.lastSelectedItemPos.level &&
        props.itemPos.index !== props.lastSelectedItemPos.index
      ) {
        let start, end;
        if (props.itemPos.index < props.lastSelectedItemPos.index) {
          start = props.itemPos.index;
          end = props.lastSelectedItemPos.index;
        } else {
          start = props.lastSelectedItemPos.index;
          end = props.itemPos.index;
        }
        itemIds = props.childrenIdsOfParent?.slice(start, end + 1);
      }

      const areWidgetsSelected = getWidgetsAreSelected(store.getState());
      if ((!e.shiftKey && !props.active) || !areWidgetsSelected) {
        //initiate shift and select, only set anchor if selecting (not deselecting)
        props.setLastSelectedItemPos?.(props.itemPos);
      }

      if (props.disableEdit && props.toggleOnClick) {
        toggleChildren();
      }

      props.action?.(e, itemIds);
    },
    [props, store, toggleChildren],
  );

  const handleDoubleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (props.disableClick) return;
      if (props.doubleClick && props.disableEdit && props.toggleOnClick) {
        // this cancels the double toggle
        openChildren();
      }
      props.doubleClick?.();
    },
    [openChildren, props],
  );

  const handleNameChange = useCallback(
    (newName: string) => {
      if (
        props.name &&
        newName !== props.name &&
        !isInvalidName(newName) &&
        updateNameCallback
      ) {
        setEditing(false);
        updateNameCallback(newName);
      }
    },
    [isInvalidName, props.name, updateNameCallback],
  );

  const focusItem = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();

      if (!entityKind || !scope) {
        return;
      }
      dispatch(
        updateApplicationSidebarKey({
          selectedKey: SideBarKeys.NAVIGATION,
          focusedItems: [
            {
              type: entityKind,
              name,
              scope,
              id: entityId,
            },
          ],
        }),
      );
    },
    [dispatch, entityKind, entityId, name, scope],
  );

  useEffect(() => {
    if (passiveExpand && !isOpen) {
      open(true);
      // set it back to false so you can set it true to toggle auto expand again
      setPassiveExpand?.(false);
    }
    // the isOpen itself should not trigger any change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passiveExpand, setPassiveExpand]);

  useEffect(() => {
    if (props.isDefaultExpanded) {
      open(true);
    }
  }, [props.isDefaultExpanded]);
  useEffect(() => {
    if (!props.searchKeyword && !props.isDefaultExpanded) {
      open(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchKeyword]);

  const divRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(
    ref,
    () => ({
      startEditing: enterEditMode,
      scrollIntoView: () => {
        if (!divRef.current) return;
        scrollIntoView(divRef.current, {
          behavior: "smooth",
          block: "center",
          scrollMode: "if-needed",
        });
      },
    }),
    [enterEditMode],
  );

  const mainContainerWidgetId = useAppSelector(getMainContainerWidgetId);

  const itemRef = useRef<HTMLDivElement | null>(null);

  const dataTest = props.dataTest
    ? props.dataTest
    : props.entityKind
    ? `${props.entityKind}-entity-item`
    : `${props.entityId}-entity-item`;

  const referenceAnalysisEnabled = useFeatureFlag(
    Flag.ENABLE_REFERENCE_ANALYSIS,
  );

  return (
    <EntityItemContainer
      active={!!props.active && !isEditing}
      step={props.step}
      className={props.className}
      ref={divRef}
    >
      <EntityItem
        active={!!props.active && !isEditing}
        step={props.step}
        spaced={!!props.children}
        hasIcon={!!props.icon}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        hasBottomMargin={props.isSectionHeader && !!props.children && isOpen}
        data-test={dataTest}
        className="entity-item"
        data-entity-id={props.entityId}
        disableEdit={disableEdit}
        disableClick={disableClick}
        noIndentForCollapse={noIndentForCollapse}
      >
        <CollapseToggle
          className={EntityClassNames.COLLAPSE_ICON}
          isOpen={isOpen}
          isVisible={allowExpandingIfEmpty || !!props.children}
          onClick={toggleChildren}
          disabled={!!props.disabled}
          icon={props.step === 1 ? <ChevronDownCircleIcon /> : null}
        />
        {props.icon && (
          <IconWrapper isActive={props.active || isEditing}>
            {props.icon &&
              React.cloneElement(props.icon as any, {
                color: props.active ? colors.ACCENT_BLUE_500 : undefined,
              })}
          </IconWrapper>
        )}
        <Row justify="space-between" align="middle">
          <EntityName
            entityId={props.entityId}
            ref={itemRef}
            isEditing={isEditing}
            name={props.name}
            isInvalidName={isInvalidName}
            nameTransformFn={props.onNameEdit}
            handleNameChange={handleNameChange}
            searchKeyword={props.searchKeyword}
            disableEdit={!!props.isSectionHeader || disableEdit}
            enterEditMode={enterEditMode}
            exitEditMode={exitEditMode}
            codeTypeface={codeTypeface}
            showTooltip={showTooltip}
            innerTooltip={innerTooltip}
            prefix={prefix}
            suffix={
              <>
                {suffix}
                {referenceAnalysisEnabled && (
                  <EntityAnalysisRenderer
                    name={props.name}
                    scope={props.scope}
                    entityKind={props.entityKind}
                    entityId={props.entityId}
                  />
                )}
              </>
            }
          />
          {props.additionalContextMenu}

          {props.entityId !== mainContainerWidgetId && !isUpdating && (
            <div
              style={{
                display: "flex",
                gap: "2px",
                alignItems: "center",
              }}
            >
              {showViewState ? (
                <ItemButton
                  icon={<StateIcon width={16} height={16} />}
                  data-test={`${props.name}-expand-state`}
                  className="entity-button"
                  onClick={focusItem}
                  title="View state"
                  tooltipPlacement="top"
                />
              ) : null}
              <EntityContextMenu
                name={props.name}
                active={props.active}
                entityId={props.entityId}
                entityKind={props.entityKind}
                hideContextMenu={hideContextMenu}
                isSectionHeader={props.isSectionHeader}
                isEditing={isEditing}
                onEnterEditMode={enterEditMode}
                showDuplicate={props.showDuplicate}
                scope={props.scope}
              />
            </div>
          )}
        </Row>
      </EntityItem>

      <Collapse step={props.step} isOpen={isOpen} active={props.active}>
        {props.children}
      </Collapse>
    </EntityItemContainer>
  );
});
Entity.displayName = "Entity";

export default Entity;
