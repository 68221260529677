import { ViewMode } from "@superblocksteam/shared";
import { call, select } from "redux-saga/effects";
import ApplicationApi from "legacy/api/ApplicationApi";
import { getCurrentBranch } from "legacy/selectors/editorSelectors";
import { selectControlFlowEnabledDynamic } from "store/slices/apisShared/selectors";
import { ROOT } from "store/utils/types";
import { createSaga } from "../../../utils/saga";

import slice, { ApiState } from "../slice";
import type { ApiDto } from "../types";

interface FetchAllApisPayload {
  applicationId: string;
  environment: string;
  isPublished: boolean;
}

// TODO: once backend implement the endpoint that fetch apis by app, use the commented code above
function* fetchAllApisFromApplicationInEditModeSaga({
  applicationId,
}: FetchAllApisPayload) {
  const controlFlowEnabled: boolean = yield select(
    selectControlFlowEnabledDynamic,
  );
  if (controlFlowEnabled) {
    return [];
  }

  const branch: ReturnType<typeof getCurrentBranch> = yield select(
    getCurrentBranch,
  );

  const result: Awaited<
    ReturnType<typeof ApplicationApi.fetchSingleApplication>
  > = yield call(ApplicationApi.fetchSingleApplication, {
    applicationId,
    viewMode: ViewMode.EDITOR,
    branch: branch?.name,
  });

  return result.data.apis?.filter((api) => {
    return api.actions != null;
  });
}

export const getAllApisSaga = createSaga(
  fetchAllApisFromApplicationInEditModeSaga,
  "fetchAllApisSaga",
  {
    sliceName: "apis",
  },
);

slice.saga(getAllApisSaga, {
  start(state, { payload }) {
    state.loading[ROOT] = true;
    state.meta[ROOT] = { applicationId: payload.applicationId };
    delete state.errors[payload.applicationId];
  },
  success(state, { payload }) {
    state.entities =
      payload?.reduce((result, api) => {
        result[api.id] = api as unknown as ApiDto;
        return result;
      }, {} as Record<string, ApiDto>) ?? {};
    delete state.loading[ROOT];
    Object.values(payload ?? {}).forEach((api) => {
      state.meta[api.id] = state.meta[api.id] ?? {};
      state.meta[api.id].needsBindingExtraction = true;
    });
  },
  error(state, { payload }) {
    state.errors[ROOT] = { error: payload };
    delete state.loading[ROOT];
  },
  store(state, { payload }) {
    modifyState(state, payload as unknown as ApiDto[]);
  },
});

const modifyState = (state: ApiState, payload: ApiDto[]): void => {
  state.entities = payload.reduce((result, api) => {
    result[api.id] = api;
    return result;
  }, {} as Record<string, ApiDto>);
  delete state.loading[ROOT];
  Object.values(payload).forEach((api) => {
    state.meta[api.id] = state.meta[api.id] ?? {};
    state.meta[api.id].needsBindingExtraction = true;
  });
};
