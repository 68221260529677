import { ApplicationScope, getNextEntityName } from "@superblocksteam/shared";
import { isEmpty, set } from "lodash";
import { all, takeEvery } from "redux-saga/effects";
import { put, select } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import {
  deleteEntityFromWidgets,
  selectWidgets,
} from "legacy/actions/widgetActions";
import { ReduxActionErrorTypes } from "legacy/constants/ReduxActionConstants";
import { getEntity } from "legacy/entities/DataTree/DataTreeHelpers";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import {
  getCurrentApplicationId,
  getCurrentPageId,
} from "legacy/selectors/editorSelectors";
import {
  getAllEntityNames,
  getEvaluationTree,
} from "legacy/selectors/sagaSelectors";
import localStorage from "legacy/utils/localStorage";
import { isStateVar } from "legacy/workers/evaluationUtils";
import { requestApplicationSave } from "store/slices/application/applicationActions";
import {
  AppStateVarPersistance,
  getAppLocalStorageKey,
  getPageLocalStorageKey,
} from "store/slices/application/stateVars/StateConstants";
import {
  getStateVarById,
  getScopedStateVars,
} from "store/slices/application/stateVars/selectors";
import { overwriteScopedStateVars } from "store/slices/application/stateVars/slice";
import {
  createStateVar,
  deleteStateVar,
  duplicateStateVar,
  editStateVarPropertyPane,
  initAppScopedStorageVars,
  initPageScopedStorageVars,
  updateStateVars,
} from "store/slices/application/stateVars/stateVarsActions";
import { getStateVarMetaById } from "store/slices/application/stateVarsMeta/selectors";
import {
  updateStateVarMetaProperties,
  resetStateVarMetaProperties,
} from "store/slices/application/stateVarsMeta/slice";
import {
  resetStateVar,
  setStateVarValue,
  setPropertyStateVar,
} from "store/slices/application/stateVarsMeta/stateVarsMetaActions";
import { deleteEntityFromTimers } from "store/slices/application/timers/timerActions";
import { getScopedEntityPrefix } from "store/utils/scope";
import { fastClone } from "utils/clone";
import logger from "utils/logger";

function getStateKey({
  scope,
  appId,
  pageId,
  stateVarId,
}: {
  scope: ApplicationScope;
  appId: string;
  pageId: string | undefined;
  stateVarId: string;
}) {
  let key = getAppLocalStorageKey(appId, stateVarId);
  if (scope === ApplicationScope.PAGE) {
    if (!pageId) throw new Error("Page ID is required");
    key = getPageLocalStorageKey(appId, pageId, stateVarId);
  }

  return key;
}

function* updateStateVarSaga(
  action: ReturnType<typeof updateStateVars>,
): Generator<any, any, any> {
  try {
    const scopedStateVars = yield select((state) =>
      getScopedStateVars(state, action.payload.scope),
    );

    const newVarMap = fastClone(scopedStateVars);
    const updates = action.payload.stateVars;

    Object.entries(updates).forEach(([updatedStateVarId, update]) => {
      newVarMap[updatedStateVarId] = {
        ...newVarMap[updatedStateVarId],
        ...update,
      };
    });

    yield put(
      overwriteScopedStateVars({
        scope: action.payload.scope,
        stateVars: newVarMap,
      }),
    );
    if (action.payload.scope === ApplicationScope.APP) {
      yield put(requestApplicationSave({ hasUpdatedConfiguration: true }));
    }
  } catch (error) {
    logger.error(
      `STATE_VAR_OPERATION_ERROR action type: ${action.type} error: ${
        (error as any)?.message
      }, payload: ${JSON.stringify(action.payload)}`,
    );
    yield put({
      type: ReduxActionErrorTypes.STATE_VAR_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

// createStateVar can be passed with an ID and Name if these are controlled by
// the caller E.g. when created from a form-based trigger.
function* createStateVarSaga(
  action: ReturnType<typeof createStateVar>,
): Generator<any, any, any> {
  try {
    const id = action.payload.id ?? uuidv4();

    let name = action.payload.name;
    if (!name) {
      const entityNames = yield select(getAllEntityNames);
      name = getNextEntityName(
        getScopedEntityPrefix(action.payload.scope, "Variable"),
        [...entityNames],
      );
    }
    if (!name) {
      // Ensure that names are unique.
      throw new Error("Failed to generate name");
    }

    const scopedStateVars: ReturnType<typeof getScopedStateVars> = yield select(
      (state) => getScopedStateVars(state, action.payload.scope),
    );

    const newStateVar = {
      id: id,
      name: name,
      defaultValue: "",
      createdAt: new Date().getTime(),
      persistance: action.payload.persistance,
      scope: action.payload.scope,
    };

    // either page or app scoped
    yield put(
      overwriteScopedStateVars({
        scope: action.payload.scope,
        stateVars: {
          ...scopedStateVars,
          [newStateVar.id]: newStateVar,
        },
      }),
    );
    if (action.payload.scope === ApplicationScope.APP) {
      yield put(requestApplicationSave({ hasUpdatedConfiguration: true }));
    }
    yield put(resetStateVar(action.payload.scope, newStateVar.id));

    if (action.payload.open) {
      yield put(editStateVarPropertyPane(newStateVar.id, action.payload.scope));
      yield put(selectWidgets([]));
    }
  } catch (error) {
    logger.error(
      `STATE_VAR_OPERATION_ERROR action type: ${action.type} error: ${
        (error as any)?.message
      }, payload: ${JSON.stringify(action.payload)}`,
    );
    yield put({
      type: ReduxActionErrorTypes.STATE_VAR_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

function* duplicateStateVarSaga(
  action: ReturnType<typeof duplicateStateVar>,
): Generator<any, any, any> {
  try {
    const stateVar: ReturnType<typeof getStateVarById> = yield select(
      getStateVarById,
      action.payload.stateVarId,
    );

    if (!stateVar) {
      throw new Error("Variable not found");
    }

    const id = uuidv4();

    const entityNames = yield select(getAllEntityNames);
    const name = getNextEntityName(`${stateVar.name}_copy`, [...entityNames]);

    const newStateVar = {
      ...stateVar,
      id: id,
      name: name,
      createdAt: new Date().getTime(),
      scope: action.payload.toScope,
    };

    const scopedStateVars: ReturnType<typeof getScopedStateVars> = yield select(
      (state) => getScopedStateVars(state, action.payload.toScope),
    );

    yield put(
      overwriteScopedStateVars({
        scope: action.payload.toScope,
        stateVars: {
          ...scopedStateVars,
          [newStateVar.id]: newStateVar,
        },
      }),
    );
    if (action.payload.toScope === ApplicationScope.APP) {
      yield put(requestApplicationSave({ hasUpdatedConfiguration: true }));
    }
    yield put(resetStateVar(action.payload.toScope, newStateVar.id));
    yield put(editStateVarPropertyPane(newStateVar.id, action.payload.toScope));
    yield put(selectWidgets([]));
  } catch (error) {
    logger.error(
      `STATE_VAR_OPERATION_ERROR action type: ${action.type} error: ${
        (error as any)?.message
      }, payload: ${JSON.stringify(action.payload)}`,
    );
    yield put({
      type: ReduxActionErrorTypes.STATE_VAR_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

function* setValueStateVarSaga(
  action: ReturnType<typeof setStateVarValue>,
): Generator<any, any, any> {
  try {
    const storeVar: ReturnType<typeof getStateVarById> = yield select(
      getStateVarById,
      action.payload.id,
    );
    if (isEmpty(storeVar)) {
      logger.warn(`State var with id ${action.payload.id} not found`);
      return;
    }
    if (
      storeVar.persistance === AppStateVarPersistance.LOCAL_STORAGE &&
      localStorage.isSupported()
    ) {
      const appId: string | undefined = yield select(getCurrentApplicationId);
      if (!appId) {
        throw new Error("Cannot store value locally, App ID is not present");
      }
      const pageId: ReturnType<typeof getCurrentPageId> = yield select(
        getCurrentPageId,
      );
      const key = getStateKey({
        scope: storeVar.scope,
        appId,
        pageId,
        stateVarId: storeVar.id,
      });
      localStorage.setItem(key, JSON.stringify(action.payload.value));
    }
    yield put(
      updateStateVarMetaProperties(storeVar.scope, action.payload.id, {
        value: action.payload.value,
      }),
    );
  } catch (error) {
    logger.error(
      `STATE_VAR_OPERATION_ERROR action type: ${action.type} error: ${
        (error as any)?.message
      }, payload: ${JSON.stringify(action.payload)}`,
    );
    yield put({
      type: ReduxActionErrorTypes.STATE_VAR_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

function* setPropertyStateVarSaga(
  action: ReturnType<typeof setPropertyStateVar>,
): Generator<any, any, any> {
  try {
    const storeVar: ReturnType<typeof getStateVarById> = yield select(
      getStateVarById,
      action.payload.id,
    );
    const currentMeta: ReturnType<typeof getStateVarMetaById> = yield select(
      getStateVarMetaById,
      storeVar.scope,
      action.payload.id,
    );

    const evalTree: DataTree = yield select(getEvaluationTree);

    const currentEval = getEntity(storeVar, evalTree);
    if (!isStateVar(currentEval)) {
      throw new Error(
        `Expected a variable for entity ${action.payload.id}, ${storeVar.name}`,
      );
    }

    // If there's a current meta defined then use the value from there.
    const currentValue = currentMeta ? currentMeta.value : currentEval.value;
    const newValue = set(
      fastClone(currentValue),
      action.payload.path,
      action.payload.value,
    );

    if (
      storeVar.persistance === AppStateVarPersistance.LOCAL_STORAGE &&
      localStorage.isSupported()
    ) {
      const appId: string | undefined = yield select(getCurrentApplicationId);
      if (!appId) {
        throw new Error("Cannot store value locally, App ID is not present");
      }

      const pageId: string | undefined = yield select(getCurrentPageId);
      const key = getStateKey({
        scope: storeVar.scope,
        appId,
        pageId,
        stateVarId: storeVar.id,
      });
      localStorage.setItem(key, JSON.stringify(newValue));
    }

    yield put(
      updateStateVarMetaProperties(storeVar.scope, action.payload.id, {
        value: newValue,
      }),
    );
  } catch (error) {
    logger.error(
      `STATE_VAR_OPERATION_ERROR action type: ${action.type} error: ${
        (error as any)?.message
      }, payload: ${JSON.stringify(action.payload)}`,
    );
    yield put({
      type: ReduxActionErrorTypes.STATE_VAR_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

// initLocalStoreVarSaga reads the persisted local variables from local storage
// an initializes their value.
function* initPageScopedStoreSaga(
  action: ReturnType<typeof initPageScopedStorageVars>,
): Generator<any, any, any> {
  try {
    const stateVars: ReturnType<typeof getScopedStateVars> = yield select(
      getScopedStateVars,
      ApplicationScope.PAGE,
    );
    const stateVariables = Object.values(stateVars);
    const storeVars = stateVariables.filter(
      (stateVar) =>
        stateVar.persistance === AppStateVarPersistance.LOCAL_STORAGE,
    );
    for (const stateVar of storeVars) {
      // Migrate the value to a page specific key if it exists under the application
      if (action.payload.pageId) {
        const appStorageKey = getAppLocalStorageKey(
          action.payload.applicationId,
          stateVar.id,
        );
        const storedValue = localStorage.getItem(appStorageKey);

        const pageStorageKey = getPageLocalStorageKey(
          action.payload.applicationId,
          action.payload.pageId,
          stateVar.id,
        );
        const pageStoredValue = localStorage.getItem(pageStorageKey);
        if (pageStoredValue === null && storedValue !== null) {
          localStorage.setItem(pageStorageKey, storedValue);
          // Removing the value isnt a backwards compat move, so disabled for now
          // localStorage.removeItem(appStorageKey);
        }
      }

      const value = localStorage.getItem(
        getStateKey({
          scope: ApplicationScope.PAGE,
          appId: action.payload.applicationId,
          pageId: action.payload.pageId,
          stateVarId: stateVar.id,
        }),
      );

      if (value == null) {
        continue;
      }
      yield put(
        updateStateVarMetaProperties(ApplicationScope.PAGE, stateVar.id, {
          value: JSON.parse(value),
        }),
      );
    }
  } catch (error) {
    logger.error(
      `STATE_VAR_OPERATION_ERROR action type: ${action.type} error: ${
        (error as any)?.message
      }, payload: ${JSON.stringify(action.payload)}`,
    );
    yield put({
      type: ReduxActionErrorTypes.STATE_VAR_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

function* initAppScopedStoreSaga(
  action: ReturnType<typeof initAppScopedStorageVars>,
): Generator<any, any, any> {
  try {
    const stateVars: ReturnType<typeof getScopedStateVars> = yield select(
      getScopedStateVars,
      ApplicationScope.APP,
    );
    const stateVariables = Object.values(stateVars);
    const storeVars = stateVariables.filter(
      (stateVar) =>
        stateVar.persistance === AppStateVarPersistance.LOCAL_STORAGE,
    );
    for (const stateVar of storeVars) {
      const value = localStorage.getItem(
        getStateKey({
          scope: ApplicationScope.APP,
          appId: action.payload.applicationId,
          pageId: undefined,
          stateVarId: stateVar.id,
        }),
      );

      if (value == null) {
        continue;
      }
      yield put(
        updateStateVarMetaProperties(ApplicationScope.APP, stateVar.id, {
          value: JSON.parse(value),
        }),
      );
    }
  } catch (error) {
    logger.error(
      `STATE_VAR_OPERATION_ERROR action type: ${action.type} error: ${
        (error as any)?.message
      }, payload: ${JSON.stringify(action.payload)}`,
    );
    yield put({
      type: ReduxActionErrorTypes.STATE_VAR_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

function* deleteStateVarSaga(
  action: ReturnType<typeof deleteStateVar>,
): Generator<any, any, any> {
  try {
    const { id } = action.payload;

    const scopedStateVars: ReturnType<typeof getScopedStateVars> = yield select(
      (state) => getScopedStateVars(state, action.payload.scope),
    );

    // Clone the state vars and make the edit
    const newStateVarMap = fastClone(scopedStateVars);

    const stateVar = newStateVarMap[id];
    const stateVarName = stateVar.name;

    delete newStateVarMap[id];

    yield put(
      overwriteScopedStateVars({
        scope: action.payload.scope,
        stateVars: newStateVarMap,
      }),
    );
    if (action.payload.scope === ApplicationScope.APP) {
      yield put(requestApplicationSave({ hasUpdatedConfiguration: true }));
    }

    // TODO(pbardea): These 2 should always be called together - they should be
    // combined.
    yield put(deleteEntityFromWidgets(stateVarName));
    yield put(deleteEntityFromTimers(stateVarName));
  } catch (error) {
    logger.error(
      `STATE_VAR_OPERATION_ERROR action type: ${action.type} error: ${
        (error as any)?.message
      }, payload: ${JSON.stringify(action.payload)}`,
    );
    yield put({
      type: ReduxActionErrorTypes.STATE_VAR_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

function* resetStateVarSaga(
  action: ReturnType<typeof resetStateVar>,
): Generator<any, any, any> {
  try {
    const storeVar = yield select(getStateVarById, action.payload.id);
    if (isEmpty(storeVar)) {
      logger.warn(`State var with id ${action.payload.id} not found`);
      return;
    }
    if (
      storeVar.persistance === AppStateVarPersistance.LOCAL_STORAGE &&
      localStorage.isSupported()
    ) {
      const appId: string | undefined = yield select(getCurrentApplicationId);
      if (!appId) {
        throw new Error("Cannot store value locally, App ID is not present");
      }

      const pageId: string | undefined = yield select(getCurrentPageId);
      const key = getStateKey({
        scope: storeVar.scope,
        appId,
        pageId,
        stateVarId: storeVar.id,
      });
      localStorage.removeItem(key);
    }
    yield put(resetStateVarMetaProperties(storeVar.scope, action.payload.id));
  } catch (error) {
    logger.error(
      `STATE_VAR_OPERATION_ERROR action type: ${action.type} error: ${
        (error as any)?.message
      }, payload: ${JSON.stringify(action.payload)}`,
    );
    yield put({
      type: ReduxActionErrorTypes.STATE_VAR_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

export default function* stateVarSagas() {
  yield all([
    takeEvery(createStateVar.type, createStateVarSaga),
    takeEvery(duplicateStateVar.type, duplicateStateVarSaga),
    takeEvery(initPageScopedStorageVars.type, initPageScopedStoreSaga),
    takeEvery(initAppScopedStorageVars.type, initAppScopedStoreSaga),
    takeEvery(setStateVarValue.type, setValueStateVarSaga),
    takeEvery(setPropertyStateVar.type, setPropertyStateVarSaga),
    takeEvery(updateStateVars.type, updateStateVarSaga),
    takeEvery(deleteStateVar.type, deleteStateVarSaga),
    takeEvery(resetStateVar.type, resetStateVarSaga),
  ]);
}
