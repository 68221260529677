import { Typography } from "antd";
import Modal from "antd/lib/modal";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useFeatureFlag } from "hooks/ui";
import { Flag } from "store/slices/featureFlags";

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    display: flex;
    align-items: center;
  }

  .ant-typography {
    display: block;
    margin-bottom: 12px;
  }
`;

const CONTACT_SALES =
  "Please contact sales@superblockshq.com or your account rep.";

const BLOCK_REASONS_CONFIG: Record<string, { title: string; message: string }> =
  {
    trial: {
      title: "Your trial has expired",
      message:
        "To resume access, contact sales to request a trial extension or move to a paid Superblocks plan.",
    },
    plan_exceeded: {
      title: "Your usage exceeds plan limits",
      message:
        "To resume access, contact sales to discuss right-sizing your paid Superblocks plan.",
    },
    custom: { title: "", message: "" },
  };

type BlockReason = keyof typeof BLOCK_REASONS_CONFIG;

const TrialExpiredModal = () => {
  const salesSettings = useFeatureFlag(Flag.SALES_SETTINGS_JSON);
  const [closed, setClosed] = useState(false);

  function isValidBlockReason(blockReason: any): blockReason is BlockReason {
    return Object.keys(BLOCK_REASONS_CONFIG).includes(blockReason);
  }

  const blockReason: BlockReason | null = useMemo(() => {
    if (!salesSettings || !salesSettings.app_block_reason) return null;
    const blockReason = salesSettings.app_block_reason;
    if (isValidBlockReason(blockReason)) {
      return blockReason;
    }
    return null;
  }, [salesSettings]);

  const shouldBlock: boolean = useMemo(() => {
    return blockReason !== null;
  }, [blockReason]);

  const title = useMemo(() => {
    if (!blockReason) return "";
    if (blockReason === "custom") {
      return salesSettings?.block_config?.title ?? "";
    }
    return BLOCK_REASONS_CONFIG[blockReason]?.title ?? "";
  }, [blockReason, salesSettings?.block_config?.title]);

  const message = useMemo(() => {
    if (!blockReason) return "";
    if (blockReason === "custom") {
      return salesSettings?.block_config?.message ?? "";
    }
    return (
      BLOCK_REASONS_CONFIG[blockReason]?.message + "\n\n" + CONTACT_SALES ?? ""
    );
  }, [blockReason, salesSettings?.block_config?.message]);

  const closable = useMemo(() => {
    if (!blockReason) return false;
    if (blockReason === "custom") {
      return salesSettings?.block_config?.closable ?? false;
    }

    return false;
  }, [blockReason, salesSettings?.block_config]);

  return (
    <StyledModal
      open={shouldBlock && !closed}
      title={title}
      closable={closable}
      onCancel={() => closable && setClosed(true)}
      footer={[]}
    >
      <Typography.Paragraph style={{ whiteSpace: "pre-line" }}>
        {message}
      </Typography.Paragraph>
    </StyledModal>
  );
};

export default TrialExpiredModal;
