import { eventChannel } from "redux-saga";
import { call, take } from "redux-saga/effects";
import {
  GetDropTargetMessageTypes,
  IframeEventHandler,
  iframeMessageHandler,
  MessageTypeToPayload,
  sendMessage,
} from "utils/iframe";

/**
 * getDropTarget retrieves drop target properties in a saga.
 *
 * # Purpose
 * The drop target helps us calculate the scroll offset of the canvas (Element.getBoundingClientRect()).
 * Combined with the current mouse position (X,Y), it let us determine where to paste a widget.
 *
 * # How it works
 * To obtain drop target properties, we send a message (postMessage) to the iFrame and wait for the result (via another postMessage).
 * - All communication with the iFrame is managed by `iframeMessageHandler`.
 * - We use `EventChannel` as the preferred method in Redux Saga for connecting with external event.
 *
 * # Before this implementation
 * In mid-2023, we introduced an iFrame that wraps the canvas in the editor for security reasons.
 * Previously, retrieving the drop target in a saga was straightforward (document.getElementById).
 *
 * Other considered approaches:
 *  1. Directly accessing the iframe's drop target with `iframe.contentDocument || iframe.contentWindow.document`.
 *     This would be simple and work locally, but not in production due to cross-origin security between the iFrame and editor subdomains.
 *     A legacy workaround of setting document.domain to a desired value is deprecated; postMessage is now recommended.
 *     https://developer.mozilla.org/en-US/docs/Web/API/Document/domain
 *
 *  2. Including drop target properties when dispatching the pasteWidget action in the usePasteWidget hook within the iFrame.
 *     Initially appealing to avoid complexity, but calculating which widget ancestor to use for the drop target requires multiple Redux selectors already used in the pasteWidget saga.
 *     Moving these calculations to the hook would lead to duplicated calculations, and more selectors in the hook causing more re-renders.
 *
 * # Status quo:
 * Previously, if the drop target wasn't found, it defaulted to {x: 0, y: 0}.
 * This led to a bug that which prevented pasting a widget on a desired position below the fold.
 * With the current implementation, failure to access the iframe blocks the saga, resulting in a noticeable paste failure.
 *
 * # Testing
 * - E2E with Cypress: no changes needed.
 * - Unit testing the combination of Saga/Store/Reducer: Mock getDropTarget (see WidgetOperationsSagas.test.ts)
 *
 * Testing with Cypress follows regular flow, while Jest testing requires mocking getDropTarget (see WidgetOperationsSagas.test.ts).
 *
 */

type HandleResults = IframeEventHandler<GetDropTargetMessageTypes.GET_RESULT>;

function getDropTargetChannel() {
  return eventChannel<
    MessageTypeToPayload[GetDropTargetMessageTypes.GET_RESULT]
  >((emitter) => {
    const handleResults: HandleResults = ({ data }) => {
      emitter(data.payload);
    };

    iframeMessageHandler.addEventListener(
      GetDropTargetMessageTypes.GET_RESULT,
      handleResults,
    );

    return () => {
      iframeMessageHandler.removeEventListener(
        GetDropTargetMessageTypes.GET_RESULT,
        handleResults,
      );
    };
  });
}

export type getDropTargetResultType =
  MessageTypeToPayload[GetDropTargetMessageTypes.GET_RESULT];

export default function* getDropTarget(parentId: string) {
  const channel: ReturnType<typeof getDropTargetChannel> = yield call(
    getDropTargetChannel,
  );
  sendMessage({ type: GetDropTargetMessageTypes.GET, payload: { parentId } });
  const result: getDropTargetResultType = yield take(channel);
  channel.close();

  return result;
}
