import { ApplicationScope, WidgetTypes } from "@superblocksteam/shared";
import { Tooltip } from "antd";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import styled from "styled-components";
import {
  RESIZE_HANDLE_STYLES,
  RESIZE_HANDLES,
} from "ai/AiAssistant/ResizeHandle";
import { ReactComponent as CheckIcon } from "assets/icons/common/checkmark.svg";
import { ReactComponent as SendIcon } from "assets/icons/common/send.svg";
import { Button } from "components/ui/Button";
import LoadingIndicator from "components/ui/LoadingIndicator";
import Popper from "components/ui/Popper";
import {
  AI_COMPONENT_EDITOR_DEFAULT_WIDTH,
  AI_COMPONENT_EDITOR_MAX_HEIGHT,
  AI_COMPONENT_EDITOR_MIN_HEIGHT,
  AI_COMPONENT_EDITOR_MIN_WIDTH,
} from "constants/ai";
import { useSaga } from "hooks/store";
import { updateWidgetProperties } from "legacy/actions/controlActions";
import { ReactComponent as ErrorIcon } from "legacy/assets/icons/ads/error.svg";
import { ReactComponent as CloseIcon } from "legacy/assets/icons/control/close.svg";
import { Layers } from "legacy/constants/Layers";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { getDataTreeItem } from "legacy/selectors/dataTreeSelectors";
import {
  selectIsDragging,
  selectIsResizing,
} from "legacy/selectors/dndSelectors";
import { getWidget, getWidgetTypeByName } from "legacy/selectors/sagaSelectors";
import { WidgetProps } from "legacy/widgets";
import { useAppDispatch, useAppSelector } from "store/helpers";
import renameApplicationEntity from "store/sagas/renameApplicationEntity";
import { transformComponentForAi } from "store/slices/ai/component-transforms";
import {
  selectAiEditedWidgetId,
  selectInitialPosition,
  selectAiState,
} from "store/slices/ai/selectors";
import {
  clearAiChanges,
  getWidgetEditActionsStream,
  sendWidgetEditActionsFeedback,
  setIsLoading,
  updateFeedbackCommentText,
} from "store/slices/ai/slice";
import { colors } from "styles/colors";
import { isUserOnMac } from "utils/navigator";
import {
  AiAssitantBg,
  ButtonsWrapper,
  CancelButton,
  ContextButton,
  ErrorMessage,
  InputSection,
  LoadingIndicatorWrapper,
  ResponseSection,
  SubmitButton,
} from "../Shared";

import PromptEditorWithContext from "./PromptEditorWithContext";
import { PropertyResponseSection } from "./ResponseSection";
import { AI_EDITABLE_WIDGET_TYPES } from "./constants";
import { useAiContext, useAiTransformContext } from "./useAiContext";
import { useGetUpdatedActions } from "./useGetUpdatedActions";

const ActionsBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 8px;
  padding-top: 12px;

  .actions-bar-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 8px;
  }
`;

const FeedbackTextArea = styled.textarea`
  margin-top: 8px;
  border: 1px solid ${colors.GREY_300};
  border-radius: 4px;
  padding: 8px;
  font-size: 12px;
  line-height: 16px;
  resize: vertical;
  min-height: 80px;
`;

const StyledButton = styled(Button)`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const getReferencedVariables = (prompt: string) => {
  // extract all of the refernced values, which will follow an @ symbol
  const referencedValues = prompt.match(/@(.*?)(?=\s|$)/g);
  const referencedValuesSet = new Set(
    referencedValues?.map((v) => v.replace("@", "")),
  );
  if (prompt.includes("user") && !referencedValuesSet.has("user")) {
    referencedValuesSet.add("user");
  }
  return referencedValuesSet;
};

const transformWidgetTypeIntoComponentType = (widgetType: string) => {
  return widgetType.replace("_WIDGET", "").toLowerCase();
};

const createPrompt = (
  prompt: string,
  referencedValues: Set<string>,
  context: Record<string, any>,
  widgetTypesByName: Record<string, string>,
) => {
  if (!referencedValues) {
    return prompt;
  }
  const contextByVariableName = Object.entries(context).reduce(
    (acc, [key, value]) => {
      value.forEach((v: any) => {
        acc[v.name] = key;
      });
      return acc;
    },
    {} as Record<string, string>,
  );

  for (const value of referencedValues) {
    // get indicies of value in the prompt
    const startIndex = prompt.indexOf("@" + value);
    if (startIndex === -1) {
      continue;
    }
    const endIndex = startIndex + value.length + 1;
    if (contextByVariableName[value]) {
      const categoryName = contextByVariableName[value].slice(0, -1); // remove the s
      if (categoryName === "User") continue;
      // replace startIndex --> endIndex with <category name="value">
      let componentTypeString = "";
      if (categoryName === "component") {
        componentTypeString = ` type="${transformWidgetTypeIntoComponentType(
          widgetTypesByName[value],
        )}"`;
      }
      prompt =
        prompt.slice(0, startIndex) +
        `<${categoryName} name="${value}"${componentTypeString}>` +
        prompt.slice(endIndex);
    }
  }
  return prompt;
};

const AiComponentEditor = ({
  widgetProps,
  isDragging,
  isHidden,
}: {
  widgetProps: Partial<WidgetProps> & {
    widgetName: string;
    widgetId: string;
  };
  isDragging: boolean;
  isHidden: boolean;
}) => {
  const [prompt, setPrompt] = useState("");
  const dataTreeWidget = useSelector((state) =>
    getDataTreeItem(state, widgetProps.widgetName),
  );
  const transformContext = useAiTransformContext();

  const {
    isLoading,
    dataTreeChanges: edits,
    actionsRequestId,
    error,
    widgetRename,
    propertiesToChange,
    feedbackCommentText,
  } = useSelector(selectAiState);
  const dispatch = useAppDispatch();

  const [layout, setLayout] = useState<"compact" | "expanded">("compact");
  const widgetTypesByName = useAppSelector(getWidgetTypeByName);

  const feedbackTextInputChanged = useCallback(
    (text: string) => {
      dispatch(updateFeedbackCommentText(text));
    },
    [dispatch],
  );

  const pendingRequest = useRef<AbortController | null>(null);
  const cancelRequest = useCallback(() => {
    if (
      pendingRequest.current &&
      typeof pendingRequest.current.abort === "function"
    ) {
      pendingRequest.current.abort();
      dispatch(setIsLoading(false));
    }
  }, [dispatch]);

  const { autocompleteContext, createPromptContext } = useAiContext();

  const onSubmit = useCallback(() => {
    cancelRequest();

    if (edits) {
      if (actionsRequestId) {
        dispatch(
          sendWidgetEditActionsFeedback({
            actionsRequestId,
            feedback: "redo",
          }),
        );
      }
      dispatch(clearAiChanges({ shouldClose: false }));
    }
    const referencedValues = getReferencedVariables(prompt);
    const mergedWidget = Object.assign({}, dataTreeWidget, widgetProps);
    const existingWidget = transformComponentForAi(
      mergedWidget,
      transformContext,
    );
    const payload = {
      widgetId: widgetProps.widgetId,
      existingWidget,
      rawPrompt: prompt.replaceAll("@", ""),
      prompt: createPrompt(
        prompt,
        referencedValues,
        autocompleteContext,
        widgetTypesByName,
      ),
      context: createPromptContext(referencedValues),
    };

    pendingRequest.current = dispatch(getWidgetEditActionsStream(payload));
  }, [
    actionsRequestId,
    prompt,
    autocompleteContext,
    createPromptContext,
    edits,
    cancelRequest,
    dispatch,
    dataTreeWidget,
    widgetProps,
    transformContext,
    widgetTypesByName,
  ]);

  const getUpdatedActions = useGetUpdatedActions(widgetProps.widgetId);

  const discardEdits = useCallback(
    (feedback: "exit" | "deny" | "redo" | "accept") => {
      const expectedActions = getUpdatedActions();

      if (actionsRequestId && feedback) {
        dispatch(
          sendWidgetEditActionsFeedback({
            actionsRequestId,
            feedback,
            commentText: feedbackCommentText,
            expectedActions,
          }),
        );
      }

      setPrompt("");
      dispatch(
        clearAiChanges({
          shouldClose: feedback === "exit" || feedback === "accept",
        }),
      );
      // refocus on the iframe
      const iframe = document.querySelector("[data-test='sb-iframe']");
      if (iframe) {
        (iframe as HTMLIFrameElement).focus();
      }
    },
    [dispatch, actionsRequestId, feedbackCommentText, getUpdatedActions],
  );

  const denyEdits = useCallback(() => {
    discardEdits("deny");
  }, [discardEdits]);

  const handleClose = useCallback(() => {
    cancelRequest();
    discardEdits("exit");
  }, [cancelRequest, discardEdits]);

  const [renameEntity] = useSaga(renameApplicationEntity);
  const acceptEdits = useCallback(() => {
    if (edits) {
      dispatch(
        updateWidgetProperties(widgetProps.widgetId, edits, false, false, true),
      );
    }

    if (widgetRename) {
      renameEntity({
        entityId: widgetProps.widgetId,
        oldName: widgetProps.widgetName,
        newName: widgetRename,
        scope: ApplicationScope.PAGE,
      });
    }
    discardEdits("accept");
  }, [
    dispatch,
    edits,
    widgetProps.widgetId,
    discardEdits,
    widgetRename,
    renameEntity,
    widgetProps.widgetName,
  ]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter" && e.metaKey) {
        e.preventDefault();
        acceptEdits();
      } else if (e.key === "Backspace" && e.metaKey) {
        e.preventDefault();
        discardEdits("deny");
      } else if (e.key === "Escape") {
        e.preventDefault();
        discardEdits("exit");
      }
    },
    [acceptEdits, discardEdits],
  );

  const metaKey = isUserOnMac() ? "⌘" : "Ctrl";

  const hasActions = edits && Object.keys(edits).length > 0;
  const promptEditorRef = useRef<{
    addMention: () => void;
  }>(null);

  const showPropertiesToChange =
    propertiesToChange && propertiesToChange.length > 0;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        boxShadow:
          "0px 1px 3px rgba(34,39,47,0.06), 0px 12px 32px -8px rgba(34,39,47,0.16), 0px 0px 1px rgba(34,39,47,0.16)",
        pointerEvents: "all",
        overflow: "auto",
        height: "100%",
        width: "100%",
        borderRadius: 4,
        background: colors.WHITE,
        maxHeight: 400,
        visibility: isHidden ? "hidden" : "visible",
      }}
      onKeyDown={handleKeyDown}
      id="sb-ai-component-editor"
    >
      <div
        style={{ height: "12px", width: "100%", cursor: "move", flexShrink: 0 }}
        className="drag-enabled"
      ></div>
      <InputSection data-layout={layout}>
        <PromptEditorWithContext
          content={prompt}
          setContent={setPrompt}
          context={autocompleteContext}
          onSubmit={onSubmit}
          onClose={handleClose}
          hasResults={edits != null && Object.keys(edits).length > 0}
          ref={promptEditorRef}
          setLayout={setLayout}
        />

        <ButtonsWrapper data-layout={layout}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              top: "1px",
            }}
          >
            {widgetProps.widgetName}
          </div>
          <ContextButton onClick={() => promptEditorRef.current?.addMention()}>
            @
          </ContextButton>
          {isLoading ? (
            <LoadingIndicatorWrapper data-position="default">
              <LoadingIndicator />
              <Tooltip title="Cancel">
                <CancelButton
                  style={{ position: "relative" }}
                  onClick={cancelRequest}
                  className="cancel-button"
                >
                  <CloseIcon color={colors.WHITE} />
                </CancelButton>
              </Tooltip>
            </LoadingIndicatorWrapper>
          ) : (
            <Tooltip title="Press Enter to send">
              <SubmitButton
                $isDisabled={!prompt}
                onClick={onSubmit}
                style={{ cursor: "pointer" }}
              >
                <SendIcon />
              </SubmitButton>
            </Tooltip>
          )}
        </ButtonsWrapper>
      </InputSection>
      {error && !isLoading && (
        <ResponseSection style={{ marginTop: "12px", paddingBottom: "0px" }}>
          <ErrorMessage>
            <ErrorIcon />
            {error}
          </ErrorMessage>
        </ResponseSection>
      )}
      {(edits || showPropertiesToChange) && (
        <ResponseSection
          style={{
            justifyContent: "space-between",
            marginTop: "12px",
            paddingBottom: "0px",
          }}
        >
          <PropertyResponseSection
            widgetType={widgetProps.type as WidgetTypes}
            widgetId={widgetProps.widgetId}
            isDragging={isDragging}
          />
          <ActionsBar>
            <div className="actions-bar-buttons">
              {hasActions && (
                <>
                  <Tooltip title={`${metaKey} + Delete to discard`}>
                    <StyledButton type="ghost" onClick={denyEdits}>
                      <CloseIcon /> Discard
                    </StyledButton>
                  </Tooltip>
                  <Tooltip title={`${metaKey} + Enter to accept`}>
                    <StyledButton type="ghost" onClick={acceptEdits}>
                      <CheckIcon /> Accept
                    </StyledButton>
                  </Tooltip>
                </>
              )}
              {!hasActions && (
                <div className="actions-bar-buttons">
                  <StyledButton
                    type="ghost"
                    onClick={() => discardEdits("exit")}
                  >
                    <CheckIcon /> Send feedback
                  </StyledButton>
                  <StyledButton
                    type="ghost"
                    onClick={() => discardEdits("accept")}
                  >
                    <CheckIcon /> Accept
                  </StyledButton>
                </div>
              )}
            </div>
            <FeedbackTextArea
              name="feedbackText"
              placeholder="Feeback text"
              onChange={(e) => feedbackTextInputChanged(e.target.value)}
              value={feedbackCommentText}
            />
          </ActionsBar>
        </ResponseSection>
      )}
      <div
        style={{ height: "12px", width: "100%", cursor: "move", flexShrink: 0 }}
        className="drag-enabled"
      ></div>
    </div>
  );
};

const AiComponentEditorWrapper = () => {
  const currentlyEditedWidgetId = useAppSelector(selectAiEditedWidgetId);
  const initialPosition = useAppSelector(selectInitialPosition);
  const widgetProps = useAppSelector((state) =>
    currentlyEditedWidgetId
      ? getWidget(state, currentlyEditedWidgetId)
      : undefined,
  );

  const adjustedPosition = useMemo(() => {
    let x = initialPosition?.x ?? 0;
    let y = initialPosition?.y ?? 0;
    // x and y are relative to the iframe, so we need to add the top + left offsets of the iframe
    const iframe = document.querySelector("[data-test='sb-iframe']");
    if (iframe) {
      const rect = iframe.getBoundingClientRect();
      x += rect.left;
      y += rect.top;
    }
    return { x, y };
  }, [initialPosition]);

  const widgetType = widgetProps?.type as WidgetTypes;
  const isResizing = useAppSelector(selectIsResizing);
  const isDraggingWidget = useAppSelector(selectIsDragging);
  const dispatch = useAppDispatch();

  const [isDragging, setIsDragging] = useState(false);
  const setDraggingOrResizing = useCallback(
    (isDragging: boolean) => {
      setIsDragging(isDragging);
      dispatch({
        type: ReduxActionTypes.SET_AI_ASSISTANT_DRAGGING,
        payload: isDragging,
      });
    },
    [dispatch],
  );

  const handleResizeDragStart = useCallback(() => {
    setDraggingOrResizing(true);
  }, [setDraggingOrResizing]);

  // todo: potentially change max height + max width when this happens
  const handleResizeDragStop = useCallback(
    (_e: any, _data: any) => {
      setDraggingOrResizing(false);
    },
    [setDraggingOrResizing],
  );

  if (
    !currentlyEditedWidgetId ||
    !widgetType ||
    !widgetProps ||
    !AI_EDITABLE_WIDGET_TYPES.includes(widgetType)
  ) {
    return null;
  }

  return (
    <Popper
      zIndex={Layers.propertiesPopupPanels}
      isOpen={true}
      placement="auto-start"
      dataTest="ai-component-editor-popper"
    >
      <AiAssitantBg>
        <Rnd
          key={widgetProps.widgetId}
          scale={1}
          default={{
            x: adjustedPosition.x,
            y: adjustedPosition.y,
            width: AI_COMPONENT_EDITOR_DEFAULT_WIDTH,
            height: "auto",
          }}
          minHeight={AI_COMPONENT_EDITOR_MIN_HEIGHT}
          minWidth={AI_COMPONENT_EDITOR_MIN_WIDTH}
          maxHeight={AI_COMPONENT_EDITOR_MAX_HEIGHT}
          dragAxis={"both"}
          enableResizing={{
            top: false,
            bottom: false,
            left: false,
            right: false,
            topLeft: true,
            topRight: true,
            bottomLeft: true,
            bottomRight: true,
          }}
          bounds="window"
          style={{
            pointerEvents: "all",
          }}
          dragHandleClassName="drag-enabled"
          onDragStop={handleResizeDragStop}
          onDragStart={handleResizeDragStart}
          onResizeStart={handleResizeDragStart}
          onResizeStop={handleResizeDragStop}
          resizeHandleComponent={RESIZE_HANDLES}
          resizeHandleStyles={RESIZE_HANDLE_STYLES}
        >
          <AiComponentEditor
            widgetProps={widgetProps}
            isDragging={isDragging}
            isHidden={isResizing || isDraggingWidget}
          />
        </Rnd>
      </AiAssitantBg>
    </Popper>
  );
};

export default AiComponentEditorWrapper;
