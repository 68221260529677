import React, { useEffect, useState } from "react";
import { FullWidthSpace } from "components/ui/Space";
import { ProgressBarContainer, SurveyProgressBar } from "./SurveyProgressBar";
import {
  ProgressLabelContainer,
  QuestionLabel,
  SurveyContentsWrapper,
} from "./SurveyUtilities";

interface SurveyWelcomeProps {
  runTransitions: boolean;
  onDone: () => void;
}

const stepsDefinition: { durationMs: number; message: string }[] = [
  {
    durationMs: 1_500,
    message: "Personalizing your Superblocks account...",
  },
  {
    durationMs: 1_500,
    message: "Get ready to build internal tools fast 🚀",
  },
  {
    durationMs: 1_500,
    message: "Your tools, your rules 😎",
  },
];

// add all durations
const totalDurationMs = stepsDefinition.reduce((s, d) => s + d.durationMs, 0);

export const SurveyEnd = ({ runTransitions, onDone }: SurveyWelcomeProps) => {
  const [step, setStep] = useState(0);
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    if (!runTransitions) return;

    // force a repaint before we change the progress bar value so that the CSS transition takes effect
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    document.body.offsetHeight;

    setProgressValue(100);
  }, [runTransitions]);

  useEffect(() => {
    if (!runTransitions) return;

    if (step === stepsDefinition.length) {
      onDone();
      return;
    }

    const timeout = setTimeout(() => {
      setStep(step + 1);
    }, stepsDefinition[step].durationMs);

    return () => {
      clearTimeout(timeout);
    };
  }, [onDone, runTransitions, step]);

  return (
    <SurveyContentsWrapper>
      <ProgressLabelContainer />
      <ProgressBarContainer>
        <SurveyProgressBar
          value={progressValue}
          max={100}
          transitionDurationMs={totalDurationMs}
          easingFunction={"cubic-bezier(.3,.3,.2,1)"}
        />
      </ProgressBarContainer>
      <FullWidthSpace direction="vertical" size={30}>
        <QuestionLabel>
          {stepsDefinition[Math.min(step, stepsDefinition.length - 1)].message}
        </QuestionLabel>
      </FullWidthSpace>
    </SurveyContentsWrapper>
  );
};
