import { RouteDef } from "@superblocksteam/shared";
import { set } from "dot-object";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";

import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { mergeUpdatesWithBindingsOrTriggers } from "legacy/utils/DynamicBindingUtils";
import { type AppState } from "store/types";
import { fastClone } from "utils/clone";
import { NOOP } from "utils/function";
import { ItemKinds, ItemTypeNonWidget } from "../ItemKindConstants";
import type { ItemKindAccessor } from "../ItemKinds";

export const RouteAccessor: ItemKindAccessor<ItemKinds.ROUTE> = {
  useItemName: (itemProperties) =>
    useMemo(() => {
      return {
        name: itemProperties.path,
        editable: false,
        requiresValidation: true,
      };
    }, [itemProperties.path]),
  itemType: (itemProperties) => ItemTypeNonWidget.ROUTE,
  useItemProperties: (itemId: string) => {
    return useSelector(
      (state: AppState) => state.legacy.entities.app.routes[itemId] ?? {},
    );
  },
  updateItemProperties: (
    dispatch: Dispatch,
    properties: RouteDef,
    updates: Record<string, unknown>,
  ) => {
    const changes = fastClone(properties);

    const updatesWithBindings = mergeUpdatesWithBindingsOrTriggers(
      properties,
      [],
      updates,
    );
    for (const [key, value] of Object.entries(updatesWithBindings)) {
      set(key, value, changes);
    }

    dispatch({
      type: ReduxActionTypes.UPDATE_ROUTE_PROPERTIES,
      payload: { [properties.id]: changes },
    });
  },
  deleteItemProperties: NOOP,
  deleteItem: (dispatch, itemId: string) => {
    dispatch({
      type: ReduxActionTypes.SET_DELETE_ROUTE_CONFIRMATION_MODAL,
      payload: {
        show: true,
        routeId: itemId,
      },
    });
  },
};
