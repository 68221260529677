import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";

import { getWidgetPropertiesById } from "legacy/selectors/propertyPaneSelectors";
import { useAppSelector } from "store/helpers";
import {
  selectAiComponentDataTreeEdits,
  selectAiState,
} from "store/slices/ai/selectors";
import { updateAiChanges } from "store/slices/ai/slice";
import { AppState } from "store/types";
import { ItemKinds, ItemTypeNonWidget } from "../ItemKindConstants";
import type { ItemKindAccessor } from "../ItemKinds";

export const AiEditsAccessor: ItemKindAccessor<ItemKinds.AI_EDITS> = {
  useItemName: () => {
    return {
      name: "",
      displayName: "",
      editable: false,
      requiresValidation: false,
    };
  },
  itemType: () => ItemTypeNonWidget.AI_EDITS,
  useItemProperties: (itemId: string) => {
    const baseWidget = useAppSelector(
      useCallback(
        (state: AppState) => getWidgetPropertiesById(state, itemId),
        [itemId],
      ),
    );
    const aiEdits = useSelector(selectAiComponentDataTreeEdits);
    return Object.assign({}, baseWidget, aiEdits);
  },
  useIsItemPropertyLoading: (itemId: string, propertyName: string) => {
    const { changedKeys, propertiesToChange } = useAppSelector(selectAiState);
    const isInChangeSet =
      (changedKeys ?? [])?.some((key) => key.split(".")[0] === propertyName) ??
      false;
    const isInExpectedSet = propertiesToChange?.includes(propertyName) ?? false;

    return !isInChangeSet && isInExpectedSet;
  },
  updateItemProperties: (
    dispatch: Dispatch<any>,
    properties: any,
    updates: Record<string, unknown>,
  ) => {
    dispatch(
      updateAiChanges({
        updates,
      }),
    );
  },
  deleteItemProperties: (
    dispatch: Dispatch<any>,
    properties: any,
    propertyPaths: string[],
  ) => {},
  deleteItem: (dispatch, widgetId, _itemScope, deleteAllSelected) => {
    // not possible
  },
};
