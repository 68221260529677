import {
  Dimension,
  DimensionModes,
  WidgetTypes,
} from "@superblocksteam/shared";

export { WidgetTypes };

export const WidgetTypesArr = Object.values(WidgetTypes);

export const WIDGET_CAN_HAVE_CHILDREN = [
  WidgetTypes.CONTAINER_WIDGET,
  WidgetTypes.TABS_WIDGET,
  WidgetTypes.MODAL_WIDGET,
  WidgetTypes.SLIDEOUT_WIDGET,
  WidgetTypes.GRID_WIDGET,
  WidgetTypes.FORM_WIDGET,
];

export const DETACHED_WIDGETS = [
  WidgetTypes.SLIDEOUT_WIDGET,
  WidgetTypes.MODAL_WIDGET,
] as WidgetType[];

export const ATTACHED_WIDGET_AND_CAN_HAVE_CHILDREN =
  WIDGET_CAN_HAVE_CHILDREN.filter(
    (widgetType) => !DETACHED_WIDGETS.includes(widgetType),
  );

export type WidgetType = keyof typeof WidgetTypes;

export type Hierarchy = {
  widgetId: string;
  widgetName: string;
  type: WidgetType;
  children?: Hierarchy[];
  icon?: React.ReactNode;

  top?: Dimension;
  left?: Dimension;
};

export const SLIDEOUT_DEFAULT_COLUMNS = Dimension.gridUnit(80);
export const SLIDEOUT_DEFAULT_ROWS = Dimension.gridUnit(69);

export const GridDefaults = {
  DEFAULT_GRID_COLUMNS: 96,
  SLIDEOUT_DEFAULT_GRID_COLUMNS: SLIDEOUT_DEFAULT_COLUMNS.value,
  DEFAULT_GRID_ROW_HEIGHT: 12,
  DEFAULT_GRID_COLUMN_WIDTH: 12, // Note: we only use this for fixed pixel sizing - everything else will always have a fluid width value, stored in parentColumnSpace
  // Measured in rows
  CANVAS_EXTENSION_OFFSET: 0,

  // Measured in rows
  // This is the number of rows that the canvas will extend by when a widget is dragged to the bottom of the canvas
  // At this point, the canvas will wait EXTEND_CANVAS_TIMEOUT_MS before extending any more
  CANVAS_EXTENSION_INITIAL_LIMIT: 5,

  // Measured in rows
  FILL_PARENT_DEFAULT_MIN_HEIGHT: 1,
  // Measured in px
  FILL_PARENT_DEFAULT_MIN_WIDTH_PX: 12,

  EMPTY_CONTAINER_EDIT_MODE_HEIGHT: 6,
  EMPTY_CONTAINER_EDIT_MODE_WIDTH_PX: 120,
};

export const MIN_MAX_BUFFER_PX = 24;

export const CanvasDefaults = {
  SPACING: Dimension.px(12),
  MIN_GRID_UNIT_WIDTH: 4,
  MIN_FIT_CONTENT_WIDTH: 2,
};

export enum CanvasLayout {
  FIXED = "FIXED_GRID",
  VSTACK = "VSTACK",
  HSTACK = "HSTACK",
}

export type StackCanvasLayout = CanvasLayout.VSTACK | CanvasLayout.HSTACK;

export enum CanvasDistribution {
  TOP = "TOP",
  CENTER = "CENTER",
  BOTTOM = "BOTTOM",
  STRETCH = "STRETCH",
  SPACE_BETWEEN = "SPACE_BETWEEN",
  SPACE_AROUND = "SPACE_AROUND",
}

export enum CanvasAlignment {
  LEFT = "LEFT",
  CENTER = "CENTER",
  RIGHT = "RIGHT",
  STRETCH = "STRETCH",
  SPACE_BETWEEN = "SPACE_BETWEEN",
  SPACE_AROUND = "SPACE_AROUND",
}

const SectionDefaultGridRowsByParentType: Record<string, any> = {
  [WidgetTypes.MODAL_WIDGET]: 8,
};

export const SectionDefaults = {
  SECTION_COLUMNS: 12,
  EMPTY_FIRST_SECTION_GRID_ROWS: 12,
  DEFAULT_SECTION_GRID_ROWS: 12,
  DEFAULT_SECTION_GRID_ROWS_BY_PARENT_TYPE: SectionDefaultGridRowsByParentType,
  MIN_COLUMN_GRID_COLUMNS: 8,
  MIN_COLUMN_GRID_ROWS: 1,
  SECTION_COLUMN_PADDING: Dimension.px(GridDefaults.DEFAULT_GRID_ROW_HEIGHT),
};

export type WidgetHeightConstraintType = "height" | "minHeight" | "maxHeight";
export type WidgetWidthConstraintType = "width" | "minWidth" | "maxWidth";

export const SectionColumnsByType: Record<string, number> = {
  [WidgetTypes.SLIDEOUT_WIDGET]: 10, // must be divisible by the gridColumns of slideouts (should be 80)
};

export enum VerticalAlign {
  TOP = "TOP",
  CENTER = "CENTER",
  BOTTOM = "BOTTOM",
  AUTO = "AUTO",
}

export const WIDGET_PADDING = 1;
export const RADIO_GROUP_LABEL_PADDING = 12;

export const PAGE_WIDGET_ID = "0";

export const DEFAULT_CENTER = { lat: -34.397, lng: 150.644 };

export enum ImageAlign {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export const ROOT_CANVAS = "root-canvas";
export const getRootCanvas = () => {
  return document.querySelector(
    `[data-superblocks='${ROOT_CANVAS}']`,
  ) as HTMLElement;
};

// SLIDEOUT_CONTAINER_ID is the ID of the element that is used as a container
// when a slideover panel widget is opened in the app.
export const SLIDEOUT_CONTAINER_ID = "slideout-container";

// MODAL_TARGET_ID is the ID of the element that is targetted when modals
// are opened in the app.
export const MODAL_TARGET_ID = "modal-target-container";

export const WIDGET_DRAG_PREVIEW_CLASS = "sb-widget-drag-preview";

export enum CodeWidgetLanguages {
  HJSON = "application/hjson",
  JSON = "application/json",
  RUBY_ERB = "application/x-erb",
  HTML = "text/html",
  MARKDOWN = "text/x-markdown",
  JAVASCRIPT = "javascript",
  PYTHON = "python",
  TEXT = "text/plain",
}

export const LINTING_ENABLED_CODE_WIDGET_MODES = [
  CodeWidgetLanguages.HJSON,
  CodeWidgetLanguages.JSON,
];

export const PROPERTY_PANE_POPOVER_WIDTH = 330;

export const getPropertyPanePopoverWidth = () => PROPERTY_PANE_POPOVER_WIDTH;

export const PROPERTY_PANE_PROPERTY_SECTION_LEFT_RIGHT_PADDING = 16;

export const PropertyPaneLayoutConfig = {
  minWidth: 280,
  defaultWidth: 300,
  resetWidth: 500,
  maxWidthGap: PROPERTY_PANE_POPOVER_WIDTH + 20,
};

export const PropertyPopoverMaxHeight = "calc(100vh - 100px)";

export const MIN_WIDGET_SIZE = {
  height: 3, // grid units
  width: 2, // grit units
};

export const SLIDEOUT_GAP = 20;

export enum SlideoutSize {
  EXTRA_SMALL = "EXTRA_SMALL",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  FULLSCREEN = "FULLSCREEN",
}

export const SLIDEOUT_WIDTH_PRESETS: {
  EXTRA_SMALL: number;
  SMALL: number;
  MEDIUM: number;
  LARGE: number;
  FULLSCREEN: "100%";
} = {
  [SlideoutSize.EXTRA_SMALL]: 540,
  [SlideoutSize.SMALL]: 720,
  [SlideoutSize.MEDIUM]: 960,
  [SlideoutSize.LARGE]: 1200,
  [SlideoutSize.FULLSCREEN]: "100%",
};

export enum ModalSize {
  EXTRA_SMALL = "EXTRA_SMALL",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  FULLSCREEN = "FULLSCREEN",
}

export const MODAL_COLUMNS = GridDefaults.DEFAULT_GRID_COLUMNS;
export const MODAL_ROW_HEIGHT = GridDefaults.DEFAULT_GRID_ROW_HEIGHT;

export const MODAL_PERIMETER_GAP = 16;
export const MODAL_LEGACY_COLUMNS = 40;
export const MODAL_BORDER_RADIUS = 4;

export const MODAL_WIDTH_PRESETS = {
  // in percentages
  [ModalSize.EXTRA_SMALL]: 30,
  [ModalSize.SMALL]: 40,
  [ModalSize.MEDIUM]: 50,
  [ModalSize.LARGE]: 75,
  [ModalSize.FULLSCREEN]: 100,
};

export const MODAL_ROWS_PRESETS = {
  [ModalSize.EXTRA_SMALL]: 13,
  [ModalSize.SMALL]: 22,
  [ModalSize.MEDIUM]: 31,
  [ModalSize.LARGE]: 47,
  [ModalSize.FULLSCREEN]: 72,
};

export interface WidgetCardProps {
  type: WidgetType;
  key: string;
  widgetCardName: string;
}

export const Breakpoints = Object.freeze({
  MOBILE: Dimension.px(480),
  TABLET: Dimension.px(768),
  NEVER: undefined,
});

export const LAYOUT_DEFAULTS: Record<
  CanvasLayout,
  {
    alignment?: CanvasAlignment;
    distribution?: CanvasDistribution;
  }
> = {
  [CanvasLayout.VSTACK]: {
    alignment: CanvasAlignment.STRETCH,
    distribution: CanvasDistribution.TOP,
  },
  [CanvasLayout.HSTACK]: {
    alignment: CanvasAlignment.LEFT,
    distribution: CanvasDistribution.CENTER,
  },
  [CanvasLayout.FIXED]: {},
};

export type WidgetWidthModes = "px" | "gridUnit" | "fitContent" | "fillParent";
export type WidgetHeightModes = "px" | "gridUnit" | "fitContent" | "fillParent";

// When first dropping into a stack, if a widget is fitContent, we won't know it's fit-content pixel width
// without getting it from the DOM. So to avoid some re-render jank, we're using these hard coded values that
// we know are accurate.
// TODO: Find a better solution that doesn't need these values
export const FIT_CONTENT_DEFAULT_PX_WIDTHS = Object.freeze({
  [WidgetTypes.TEXT_WIDGET]: 33.6875,
  [WidgetTypes.BUTTON_WIDGET]: 66.515625,
  [WidgetTypes.SWITCH_WIDGET]: 73.6875,
  [WidgetTypes.CHECKBOX_WIDGET]: 57.6875,
  [WidgetTypes.ICON_WIDGET]: 50,
  [WidgetTypes.IMAGE_WIDGET]: 229.4,
  [WidgetTypes.MENU_WIDGET]: 66.515625,
  [WidgetTypes.LINK_WIDGET]: 57.3281,
}) as Record<WidgetType, number>;

export const FIT_CONTENT_WIDTH_WIDGETS = Object.keys(
  FIT_CONTENT_DEFAULT_PX_WIDTHS,
) as WidgetType[];

export const CANVAS_LAYOUT_WIDTH_MODES: Record<
  CanvasLayout,
  WidgetWidthModes[]
> = {
  [CanvasLayout.FIXED]: ["gridUnit"],
  [CanvasLayout.VSTACK]: ["gridUnit", "px", "fitContent", "fillParent"],
  [CanvasLayout.HSTACK]: ["gridUnit", "px", "fitContent", "fillParent"],
};

export const CANVAS_LAYOUT_HEIGHT_MODES: Record<
  CanvasLayout,
  WidgetHeightModes[]
> = {
  [CanvasLayout.FIXED]: ["gridUnit", "fitContent"],
  [CanvasLayout.VSTACK]: ["gridUnit", "px", "fitContent", "fillParent"],
  [CanvasLayout.HSTACK]: ["gridUnit", "px", "fitContent", "fillParent"],
};

// padding added to bottom of page with fill-viewport sections in edit mode to ensure that
// scrollbar doesn't show and the add section button can still fit
export const FP_BOTTOM_PADDING = 12;

export enum Position {
  STICKY = "STICKY",
  // STATIC = "static",
  // In the future:
  // FIXED = "fixed",
  // RELATIVE = "relative",
  // ABSOLUTE = "absolute",
}

export enum PositionStickyBehavior {
  STACK = "STACK",
}

export const DIMENSION_MODES_WITH_NUMERIC_INPUT: DimensionModes[] = ["px"];

export const WIDGETS_WITH_PADDED_FOCUS: WidgetType[] = [
  WidgetTypes.BUTTON_WIDGET,
  WidgetTypes.FORM_BUTTON_WIDGET,
  WidgetTypes.VIDEO_WIDGET,
  WidgetTypes.IFRAME_WIDGET,
  WidgetTypes.PDF_VIEWER_WIDGET,
  WidgetTypes.LINK_WIDGET,
  WidgetTypes.MENU_WIDGET,
];
export const WIDGET_PADDED_FOCUS_PX = 7;

export const enum WidgetLabelPosition {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum TextAlign {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  CENTER = "CENTER",
}
