import {
  AgentType,
  DropdownOption,
  EditorLanguage,
  FormComponentType,
  InputDataType,
  Plugin,
  PluginResponseType,
  PluginType
} from '../../types';
import { camelCaseToDisplay } from '../../utils';
import { getAWSAuthSection } from './shared';

export const DynamoDBActions = [
  'getItem',
  'updateItem',
  'putItem',
  'deleteItem',
  'query',
  'scan',
  'executeStatement',
  'executeTransaction',
  'listTagsOfResource',
  'tagResource',
  'listTables',
  'describeTable',
  'createTable',
  'updateTable',
  'deleteTable',
  'batchWriteItem'
  // "batchExecuteStatement",
  // "batchGetItem",
  // "createBackup",
  // "createGlobalTable",
  // "deleteBackup",
  // "describeBackup",
  // "describeContinuousBackups",
  // "describeContributorInsights",
  // "describeEndpoints",
  // "describeExport",
  // "describeGlobalTable",
  // "describeGlobalTableSettings",
  // "describeKinesisStreamingDestination",
  // "describeLimits",
  // "describeTableReplicaAutoScaling",
  // "describeTimeToLive",
  // "disableKinesisStreamingDestination",
  // "enableKinesisStreamingDestination",
  // "exportTableToPointInTime",
  // "listBackups",
  // "listContributorInsights",
  // "listExports",
  // "listGlobalTables",
  // "restoreTableFromBackup",
  // "restoreTableToPointInTime",
  // "transactGetItems",
  // "transactWriteItems",
  // "untagResource",
  // "updateContinuousBackups",
  // "updateContributorInsights",
  // "updateGlobalTable",
  // "updateGlobalTableSettings",
  // "updateTableReplicaAutoScaling",
  // "updateTimeToLive",
  // "waitFor",
];

function getDynamoDBActionDropdownOptions(): DropdownOption[] {
  return DynamoDBActions.map(
    (method: string): DropdownOption => {
      const displayName = camelCaseToDisplay(method);

      return {
        key: method,
        value: method,
        displayName: displayName
      };
    }
  );
}

export const DynamoDBPluginVersions = {
  V1: '0.0.1',
  V4: '0.0.4',
  V5: '0.0.5'
};

export const DynamoDBPlugin: Plugin = {
  id: 'dynamodb',
  name: 'Amazon DynamoDB',
  moduleName: 'DynamoDBPlugin',
  modulePath: 'plugins/dynamodb/DynamoDBPlugin',
  iconLocation: 'https://superblocks.s3-us-west-2.amazonaws.com/img/integrations/dynamodb.png',
  docsUrl: 'https://docs.superblocks.com/integrations/integrations-library/aws-dynamodb',
  type: PluginType.DB,
  responseType: PluginResponseType.JSON,
  hasRawRequest: true,
  hasMetadata: true,
  rawRequestName: 'Executed query',
  datasourceTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Display name',
            name: 'name',
            startVersion: DynamoDBPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            rules: [{ required: true, message: 'Display name is required' }]
          },
          {
            label: 'Region',
            name: 'authentication.custom.region.value',
            startVersion: DynamoDBPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            agentType: AgentType.MULTITENANT
          },
          {
            label: 'Access key ID',
            name: 'authentication.custom.accessKeyID.value',
            startVersion: DynamoDBPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            agentType: AgentType.MULTITENANT
          },
          {
            label: 'Secret key',
            name: 'authentication.custom.secretKey.value',
            startVersion: DynamoDBPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            dataType: InputDataType.PASSWORD,
            agentType: AgentType.MULTITENANT
          },
          {
            label: 'IAM role ARN (optional)',
            name: 'authentication.custom.iamRoleArn.value',
            startVersion: DynamoDBPluginVersions.V5,
            componentType: FormComponentType.INPUT_TEXT,
            agentType: AgentType.MULTITENANT,
            rules: [{ required: false }]
          }
        ]
      },
      getAWSAuthSection({ startVersion: DynamoDBPluginVersions.V4, startVersionForRoleAssumption: DynamoDBPluginVersions.V5 })
    ]
  },
  actionTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Action',
            name: 'action',
            startVersion: DynamoDBPluginVersions.V1,
            componentType: FormComponentType.DROPDOWN,
            initialValue: DynamoDBActions.length > 0 && DynamoDBActions[0],
            options: getDynamoDBActionDropdownOptions()
          },
          {
            label: 'Params',
            name: 'body',
            startVersion: DynamoDBPluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.JSON,
            placeholder: `{
  "TableName": "ExampleTable",
  "Key": {
      "PK": "user#abc",
      "SK": "account#0123456789"
  },
  "ConditionExpression": "#balance > :amount",
  "ExpressionAttributeNames": {
      "#balance": "balance"
  },
  "ExpressionAttributeValues": {
      ":amount": { "N": "123" },
      ":zero": { "N": "0" },
  }
}`
          }
        ]
      }
    ]
  }
};
