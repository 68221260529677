import { Icon } from "@blueprintjs/core";
import React from "react";
import { AiFillApi, AiFillDatabase } from "react-icons/ai";
import { FaDesktop, FaDatabase } from "react-icons/fa";
import { ReactComponent as ApiIcon } from "assets/icons/widgets/api.svg";
import { ReactComponent as TimersIcon } from "assets/icons/widgets/timer.svg";
import { ReactComponent as DataSourcesIcon } from "legacy/assets/icons/menu/data-sources.svg";
import { ReactComponent as DatasourceColumnIcon } from "legacy/assets/icons/menu/datasource-column.svg";
import { ReactComponent as DatasourceTableIcon } from "legacy/assets/icons/menu/datasource-table.svg";
import { ReactComponent as ExplorerIcon } from "legacy/assets/icons/menu/explorer.svg";
import { ReactComponent as ForeignKeyIcon } from "legacy/assets/icons/menu/foreign-key.svg";
import { ReactComponent as HomepageIcon } from "legacy/assets/icons/menu/homepage.svg";
import { ReactComponent as OrgIcon } from "legacy/assets/icons/menu/org.svg";
import { ReactComponent as PageIcon } from "legacy/assets/icons/menu/page.svg";
import { ReactComponent as PagesIcon } from "legacy/assets/icons/menu/pages.svg";
import { ReactComponent as PrimaryKeyIcon } from "legacy/assets/icons/menu/primary-key.svg";
import { IconProps, IconWrapper } from "legacy/constants/IconConstants";

/* eslint-disable react/display-name */

export const MenuIcons: {
  //TODO(abhinav): Fix this type to JSXElementConstructor<IconProps>
  // eslint-disable-next-line @typescript-eslint/ban-types
  [id: string]: Function;
} = {
  WIDGETS_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <FaDesktop />
    </IconWrapper>
  ),
  APIS_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <AiFillApi />
    </IconWrapper>
  ),
  TIMERS_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <TimersIcon />
    </IconWrapper>
  ),
  ORG_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <OrgIcon />
    </IconWrapper>
  ),
  PAGES_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <PagesIcon />
    </IconWrapper>
  ),
  PAGE_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <PageIcon />
    </IconWrapper>
  ),
  DATASOURCES_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <DataSourcesIcon />
    </IconWrapper>
  ),
  QUERIES_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <FaDatabase />
    </IconWrapper>
  ),
  HOMEPAGE_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <HomepageIcon />
    </IconWrapper>
  ),
  EXPLORER_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <ExplorerIcon />
    </IconWrapper>
  ),
  DOCS_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <Icon icon="help"></Icon>
    </IconWrapper>
  ),
  WIDGETS_COLORED_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <FaDesktop />
    </IconWrapper>
  ),
  APIS_COLORED_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <ApiIcon />
    </IconWrapper>
  ),
  DATASOURCES_COLORED_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <AiFillDatabase />
    </IconWrapper>
  ),
  DATASOURCES_TABLE_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <DatasourceTableIcon />
    </IconWrapper>
  ),
  PRIMARY_KEY_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <PrimaryKeyIcon />
    </IconWrapper>
  ),
  FOREIGN_KEY_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <ForeignKeyIcon />
    </IconWrapper>
  ),
  DATASOURCE_COLUMN_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <DatasourceColumnIcon />
    </IconWrapper>
  ),
};
