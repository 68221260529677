import {
  ApplicationScope,
  EvaluationPair,
  extractJsEvaluationPairsWithTokenizer,
  extractPythonEvaluationPairsWithParser,
  PythonParser,
} from "@superblocksteam/shared";
import { isObject, isUndefined } from "lodash";
import { SettableWidgets } from "legacy/constants/EventTriggerPropertiesConstants";
import { isCustomComponentType } from "legacy/widgets/CustomComponentTypeUtil";
import { ENTITY_TYPE } from "utils/dataTree/constants";
import { cloneAndRemoveFunctions } from "utils/removeFunctions";
import { WidgetTypes } from "../constants/WidgetConstants";
import {
  DataTree,
  DataTreeAction,
  DataTreeApp,
  DataTreeEmbed,
  DataTreeEntity,
  DataTreeEvent,
  DataTreeIcons,
  DataTreeObjectEntity,
  DataTreeStateVar,
  DataTreeTheme,
  DataTreeTimer,
  DataTreeWidget,
} from "../entities/DataTree/dataTreeFactory";
import { EvalError, EvalErrorTypes } from "../utils/DynamicBindingUtils";
import { ValidationResponse } from "./../constants/WidgetValidation";
import { EntitySchema } from "./EntitySchemaMap";
import { STATE_VALUE_VALIDATION } from "./validators/stateValidator";
import { VALIDATORS } from "./validators/validations";

export const extractPythonEvaluationPairs = async (
  code: string,
  entities: Set<string>,
  dataTree: Record<string, unknown>,
  namespacedEntitiesToExtract?: Record<string, Set<string>>,
): Promise<EvaluationPair[]> => {
  const { parser } = await import(
    /* webpackChunkName: "python" */ "@lezer/python"
  );

  return extractPythonEvaluationPairsWithParser(
    code,
    entities,
    dataTree,
    parser as unknown as PythonParser,
    namespacedEntitiesToExtract,
  );
};

export const extractJsEvaluationPairs = async (
  jsSnippet: string,
  entitiesToExtract: Set<string>,
  dataTree: Record<Exclude<string, "API" | "WIDGETS">, unknown>,
  namespacedEntitiesToExtract?: Record<string, Set<string>>,
): Promise<EvaluationPair[]> => {
  const { tokenize } = await import(
    /* webpackChunkName: "esprima" */ "esprima"
  );
  return extractJsEvaluationPairsWithTokenizer(
    jsSnippet,
    entitiesToExtract,
    dataTree,
    tokenize,
    namespacedEntitiesToExtract,
  );
};

export class CrashingError extends Error {}

export class ValidationError extends Error {}

export function isValidEntity(
  entity?: DataTreeEntity | string[] | Set<string> | Record<string, any>,
): entity is DataTreeObjectEntity {
  if (!isObject(entity)) {
    return false;
  }
  return "ENTITY_TYPE" in entity;
}
export function isAppInfo(
  entity: DataTreeEntity | string[] | Set<string>,
): entity is DataTreeApp {
  return isValidEntity(entity) && entity.ENTITY_TYPE === ENTITY_TYPE.GLOBAL;
}

export function isThemeInfo(
  entity: DataTreeEntity | string[] | Set<string>,
): entity is DataTreeTheme {
  return isValidEntity(entity) && entity.ENTITY_TYPE === ENTITY_TYPE.THEME;
}

export function isIconInfo(
  entity: DataTreeEntity | string[] | Set<string>,
): entity is DataTreeIcons {
  return isValidEntity(entity) && entity.ENTITY_TYPE === ENTITY_TYPE.ICONS;
}

export function isWidget(
  entity: DataTreeEntity | string[] | Set<string>,
): entity is DataTreeWidget {
  return isValidEntity(entity) && entity.ENTITY_TYPE === ENTITY_TYPE.WIDGET;
}

export function isAction(
  entity: DataTreeEntity | string[] | Set<string> | Record<string, any>,
): entity is DataTreeAction {
  return isValidEntity(entity) && entity.ENTITY_TYPE === ENTITY_TYPE.ACTION;
}

export function isTimer(
  entity: DataTreeEntity | string[] | Set<string> | Record<string, any>,
): entity is DataTreeTimer {
  return isValidEntity(entity) && entity.ENTITY_TYPE === ENTITY_TYPE.TIMER;
}

export function isCustomEvent(
  entity: DataTreeEntity | string[] | Set<string>,
): entity is DataTreeEvent {
  return (
    isValidEntity(entity) && entity.ENTITY_TYPE === ENTITY_TYPE.CUSTOM_EVENT
  );
}

export function isStateVar(
  entity: DataTreeEntity | string[] | Set<string>,
): entity is DataTreeStateVar {
  return isValidEntity(entity) && entity.ENTITY_TYPE === ENTITY_TYPE.STATE_VAR;
}

export function isTable(
  entity: DataTreeEntity | string[] | Set<string>,
): entity is DataTreeWidget {
  return (
    isValidEntity(entity) &&
    entity.ENTITY_TYPE === ENTITY_TYPE.WIDGET &&
    entity.type === WidgetTypes.TABLE_WIDGET
  );
}

export function isEmbedding(
  entity: DataTreeEntity | string[] | Set<string>,
): entity is DataTreeEmbed {
  return isValidEntity(entity) && entity.ENTITY_TYPE === ENTITY_TYPE.EMBEDDING;
}

export function isOpenable(entity: DataTreeEntity): entity is DataTreeWidget {
  return (
    isValidEntity(entity) &&
    entity.ENTITY_TYPE === ENTITY_TYPE.WIDGET &&
    (entity.type === WidgetTypes.SLIDEOUT_WIDGET ||
      entity.type === WidgetTypes.MODAL_WIDGET)
  );
}

export function hasSettableProperties(
  entity: DataTreeEntity,
): entity is DataTreeWidget {
  return (
    isValidEntity(entity) &&
    entity.ENTITY_TYPE === ENTITY_TYPE.WIDGET &&
    // TODO(george): `SettableWidgets` is updated when a new custom component is registed, but this is not visible in
    // the evaluator worker. So we need to check for custom components separately for this to work in the evaluator
    (SettableWidgets.includes(
      entity.type as (typeof SettableWidgets)[number],
    ) ||
      isCustomComponentType(entity.type))
  );
}

const removeFunctions = <T>(value: T): T | string => {
  return cloneAndRemoveFunctions(value);
};

export function validateEntityProperty(
  entitySchema: EntitySchema,
  entity: DataTreeEntity,
  property: string,
  value: any,
  dataTree?: DataTree,
): ValidationResponse {
  const propertyValidationTypes = entitySchema.validations;
  const validationTypeOrValidator = isEmbedding(entity)
    ? STATE_VALUE_VALIDATION
    : propertyValidationTypes[property];
  let validator;

  if (typeof validationTypeOrValidator === "function") {
    validator = validationTypeOrValidator;
  } else {
    validator = VALIDATORS[validationTypeOrValidator];
  }
  try {
    if (validator) {
      return validator(value, entity, dataTree);
    } else {
      return { isValid: true, parsed: value };
    }
  } catch (e: any) {
    return { isValid: false, parsed: undefined, message: e.message };
  }
}

export const extractJsEvaluationBindings = async (
  jsSnippet: string,
  entitiesToExtract: Set<string>,
  dataTree: Record<Exclude<string, "API">, any>,
  namespacedEntitiesToExtract?: Record<string, Set<string>>,
): Promise<string[]> => {
  const evaluationPairs = await extractJsEvaluationPairs(
    jsSnippet,
    entitiesToExtract,
    dataTree,
    namespacedEntitiesToExtract,
  );
  return evaluationPairs.map((evalPair) => evalPair.binding);
};

export const extractPythonEvaluationBindings = async (
  code: string,
  entities: Set<string>,
  dataTree: Record<string, any>,
  namespacedEntitiesToExtract?: Record<string, Set<string>>,
): Promise<string[]> => {
  const evaluationPairs = await extractPythonEvaluationPairs(
    code,
    entities,
    dataTree,
    namespacedEntitiesToExtract,
  );

  return evaluationPairs.map((evalPair) => evalPair.binding);
};

export function safeValidation(
  validation: ValidationResponse,
  value: any,
  propertyPath: string,
): ValidationResponse & {
  evaluatedValue: any;
  error?: EvalError;
} {
  const { isValid, parsed, transformed, message } = validation;

  const evaluatedValue = isValid
    ? undefined // dont replicate valid evaluated values here
    : !isUndefined(transformed)
    ? transformed
    : value;

  try {
    return {
      isValid: isValid,
      message: message,
      parsed: removeFunctions(parsed),
      transformed: removeFunctions(transformed),
      evaluatedValue: removeFunctions(evaluatedValue),
    };
  } catch (e: any) {
    return {
      parsed: undefined,
      evaluatedValue: undefined,
      transformed: undefined,
      isValid: false,
      message: message ?? e,
      error: {
        type: EvalErrorTypes.VALIDATION_ERROR,
        message: `${propertyPath} could not be evaluated: ${message ?? e}`,
        context: {
          propertyPath,
        },
      },
    };
  }
}

export type LocalBindingPathsTree = { [key: string]: BindingPathsTreeValue };
export type BindingPathsTreeValue = true | LocalBindingPathsTree;
export type BindingPathsTree = {
  [scope in ApplicationScope]: LocalBindingPathsTree;
};
