import { takeLatest } from "redux-saga/effects";
import { processAiActions, updateAiDynamicProperties } from "../slice";
import { processAiActionsSaga } from "./processAiActions";
import { updateAiDynamicPropertiesSaga } from "./updateDynamicProperties";

export function* aiSaga() {
  yield takeLatest(processAiActions.type, processAiActionsSaga);
  yield takeLatest(
    updateAiDynamicProperties.type,
    updateAiDynamicPropertiesSaga,
  );
}
