import React, { Context, createContext, useMemo } from "react";
import {
  deleteWidgetProperty as deletePropertyAction,
  updateWidgetAutoHeight,
  updateWidgetAutoWidth,
  updateWidgetProperties,
} from "legacy/actions/controlActions";
import {
  resetChildrenMetaProperty,
  setMetaProps,
  setMetaProp,
} from "legacy/actions/metaActions";
import {
  updateWidget,
  WidgetOperationPayloads,
} from "legacy/actions/pageActions";

import {
  disableDragAction,
  runEventHandlers,
  disableNudgeAction,
} from "legacy/actions/widgetActions";

import { RunEventHandlersPayload } from "legacy/constants/ActionConstants";
import { OccupiedSpace } from "legacy/constants/editorConstants";
import { WidgetOperation } from "legacy/widgets/WidgetOperations";
import { useAppDispatch } from "store/helpers";

export type EditorContextType = {
  runEventHandlers?: (actionPayloads: RunEventHandlersPayload) => void;
  updateWidget?: <Op extends WidgetOperation & keyof WidgetOperationPayloads>(
    operation: Op,
    widgetId: string,
    payload: Omit<WidgetOperationPayloads[Op], "widgetId">,
  ) => void;
  updateWidgetMetaProperty?: (
    widgetId: string,
    propertyName: string,
    propertyValue: any,
    skipMessageSending?: boolean,
  ) => void;
  updateWidgetMetaProperties?: (
    widgetId: string,
    updates: Record<string, any>,
  ) => void;
  resetChildrenMetaProperty?: (widgetId: string) => void;
  disableDrag?: (disable: boolean) => void;
  disableNudge?: (disable: boolean) => void;
  occupiedSpaces?: { [containerWidgetId: string]: OccupiedSpace[] };
  deleteWidgetProperty?: (widgetId: string, propertyPaths: string[]) => void;
  updateWidgetProperties?: (
    widgetId: string,
    updates: Record<string, unknown>,
  ) => void;
  updateWidgetAutoHeight?: (widgetId: string, height: number) => void;
  updateWidgetAutoWidth?: (widgetId: string, width: number) => void;
  evaluateBindings?: (bindings: string[], callbackId: string) => void;
};

export const EditorContext: Context<EditorContextType> = createContext({});

const EditorContextProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();

  const value = useMemo(
    () =>
      ({
        runEventHandlers(actionPayload: RunEventHandlersPayload) {
          dispatch(runEventHandlers(actionPayload));
        },
        updateWidget<
          Op extends WidgetOperation & keyof WidgetOperationPayloads,
        >(
          operation: Op,
          widgetId: string,
          payload: Omit<WidgetOperationPayloads[Op], "widgetId">,
        ) {
          dispatch(updateWidget(operation, widgetId, payload));
        },
        updateWidgetMetaProperty(
          widgetId: string,
          propertyName: string,
          propertyValue: any,
          skipMessageSending?: boolean,
        ) {
          dispatch(
            setMetaProp(
              widgetId,
              propertyName,
              propertyValue,
              skipMessageSending,
            ),
          );
        },
        updateWidgetMetaProperties(
          widgetId: string,
          updates: Record<string, any>,
        ) {
          dispatch(setMetaProps(widgetId, updates));
        },
        disableDrag(disable: boolean) {
          dispatch(disableDragAction(disable));
        },
        disableNudge(disable: boolean) {
          dispatch(disableNudgeAction(disable));
        },
        resetChildrenMetaProperty(widgetId: string) {
          dispatch(resetChildrenMetaProperty(widgetId));
        },
        deleteWidgetProperty(widgetId: string, propertyPaths: string[]) {
          dispatch(deletePropertyAction(widgetId, propertyPaths));
        },
        updateWidgetProperties(
          widgetId: string,
          updates: Record<string, unknown>,
        ) {
          dispatch(updateWidgetProperties(widgetId, updates));
        },
        updateWidgetAutoHeight(widgetId: string, height: number) {
          dispatch(updateWidgetAutoHeight(widgetId, height));
        },
        updateWidgetAutoWidth(widgetId: string, width: number) {
          dispatch(updateWidgetAutoWidth(widgetId, width));
        },
      } satisfies EditorContextType),
    [dispatch],
  );
  return (
    <EditorContext.Provider value={value}>{children}</EditorContext.Provider>
  );
};

export default EditorContextProvider;
