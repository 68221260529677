import {
  Classes,
  EditableText as BlueprintEditableText,
} from "@blueprintjs/core";
import { Tooltip } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as EditIcon } from "assets/icons/home/edit.svg";
import Spinner from "legacy/components/editorComponents/Spinner";
import { IconWrapper } from "legacy/constants/IconConstants";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

export enum EditInteractionKind {
  SINGLE,
  DOUBLE,
}

type EditableTextProps = {
  type: "text" | "password" | "email" | "phone" | "date";
  defaultValue: string;
  onTextChanged: (value: string) => void;
  placeholder: string;
  className?: string;
  valueTransform?: (value: string) => string;
  isEditingDefault?: boolean;
  forceDefault?: boolean;
  isUpdating?: boolean;
  isInvalid?: (value: string) => string | boolean;
  editInteractionKind: EditInteractionKind;
  hideEditIcon?: boolean;
  onBlur?: () => void;
  disabled?: boolean;
  "data-test"?: string;
  textClassName?: string;
  onStartedEditing?: () => void;
  onStoppedEditing?: () => void;
  inputClassName?: string;
  pencilIconClassName?: string;
  fullWidth?: boolean;
};

const EditableTextWrapper = styleAsClass`
  overflow: hidden;
  position: relative;

  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    & .${Classes.POPOVER_TARGET} {
      justify-content: flex-start;
    }

    & .${Classes.EDITABLE_TEXT} {
      cursor: pointer; // Cursor on the text itself

      text-transform: none;

      // Ellipsis
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      display: block;

      // Removes blueprint default styling
      &:before,
      &:after {
        display: none;
      }

      & > span {
        min-width: unset !important;
      }
    }

    &[data-disabled=true] {
      & .${Classes.EDITABLE_TEXT} {
        cursor: auto;
      }
    }

    & div.${Classes.EDITABLE_TEXT_INPUT} {
      text-transform: none;
      width: 100%;
    }
  }
`;

const IconOnHover = styleAsClass`
  display: none;
  cursor: pointer;
  .${EditableTextWrapper}:hover & {
    display: block;
  }
`;

const TextContainerClass = styleAsClass`
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1;

  cursor: pointer; // Cursor beyond the text
  &[data-disabled=true] {
    cursor: auto;
  }
`;

const UpdatingContainerClass = styleAsClass`
  width: 100%;
  border-radius: 4px;
  padding: 4px 8px;
  color: ${colors.GREY_300};
  display: flex;
  align-items: center;
  font-weight: 400;
  justify-content: space-between;
`;

// Avoids jumpiness when transitioning from text to input
const baseSpanClassName = styleAsClass`
  border: 1px solid transparent;
  box-shadow: 0 0 0 2px transparent;
  border-radius: 4px;
  padding: 4px 8px;
  padding-right: 2px;
`;

const baseInputClassName = styleAsClass`
  width: 100%;
  border-radius: 4px;
  padding: 4px 8px;
  border: 1px solid ${colors.ACCENT_BLUE_500};
  box-shadow: 0 0 0 2px #27bbff33;
  outline: 0;
  font-weight: 400;
  font-size: 12px;

  ::selection {
    color: ${colors.GREY_700};
    background: ${colors.SUBTLE_BLUE_SOLID};
  }

  .${TextContainerClass}[data-error=true] & {
    border: 1px solid ${colors.RED_500};
    box-shadow: 0 0 0 2px transparent;
  }
`;

const fullWidthInputClassName = styleAsClass`
  input {
    width: 100% !important;
  }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditableText = ({
  defaultValue,
  editInteractionKind,
  forceDefault,
  hideEditIcon,
  isEditingDefault = false,
  isInvalid,
  isUpdating,
  onBlur,
  onTextChanged,
  placeholder,
  valueTransform,
  disabled,
  onStartedEditing,
  onStoppedEditing,
  fullWidth = true,
  ...props
}: EditableTextProps) => {
  const [isEditing, setIsEditing] = useState(!!isEditingDefault);
  const [error, setError] = useState<boolean | string>(false);
  const [currentValue, setCurrentValue] = useState(defaultValue);

  useEffect(() => {
    if (forceDefault) {
      setCurrentValue(defaultValue);
    }
  }, [forceDefault, defaultValue]);

  const handleStart = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      setIsEditing(true);
      e.preventDefault();
      e.stopPropagation();
      if (onStartedEditing) onStartedEditing();
    },
    [onStartedEditing],
  );

  const handleCancel = useCallback(() => {
    onBlur && onBlur();
    setIsEditing(false);
    setError(false);
    setCurrentValue(defaultValue);
    if (onStoppedEditing) onStoppedEditing();
  }, [defaultValue, onBlur, onStoppedEditing]);

  const handleConfirm = useCallback(
    (value: string) => {
      const error = isInvalid ? isInvalid(value) : false;

      if (error) {
        // we want to keep the user in the editing mode when there is an error, so they can fix it
        return;
      } else {
        onTextChanged(value);
      }

      onBlur && onBlur();
      setIsEditing(false);
      setError(false);
      if (onStoppedEditing) onStoppedEditing();
    },
    [isInvalid, onBlur, onTextChanged, onStoppedEditing],
  );

  const handleInputChange = useCallback(
    (value: string) => {
      const transformedValue = valueTransform ? valueTransform(value) : value;
      const error = isInvalid ? isInvalid(transformedValue) : false;

      setError(error);
      setCurrentValue(transformedValue);
    },
    [isInvalid, valueTransform],
  );
  const showEditIcon = !hideEditIcon && !isUpdating && !isEditing && !disabled;

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const ref = useRef<HTMLDivElement>(null);

  const componentClassName = isUpdating
    ? ""
    : isEditing
    ? `${baseInputClassName} ${fullWidth ? fullWidthInputClassName : ""} ${
        props.inputClassName ?? ""
      }`
    : `${baseSpanClassName} ${fullWidth ? fullWidthInputClassName : ""} ${
        props.textClassName ?? ""
      }`;

  return (
    <div
      className={EditableTextWrapper}
      data-test={props["data-test"]}
      onDoubleClick={
        editInteractionKind === EditInteractionKind.DOUBLE && !disabled
          ? handleStart
          : undefined
      }
      onClick={
        editInteractionKind === EditInteractionKind.SINGLE && !disabled
          ? handleStart
          : disabled
          ? undefined
          : stopPropagation
      }
      data-disabled={disabled}
      // in order to keep the input open when there is an error to allow fixing it
      onKeyDownCapture={(e) => {
        if (e.key === "Enter" && error) {
          e.stopPropagation();
          e.preventDefault();
        }
      }}
      data-editing={isEditing}
    >
      <Tooltip
        placement="top"
        title={error && <div data-test="editable-text-error"> {error} </div>}
        open={Boolean(error)}
      >
        <div
          ref={ref}
          data-disabled={disabled}
          data-error={Boolean(error)}
          className={
            isUpdating
              ? `${TextContainerClass} ${UpdatingContainerClass}`
              : TextContainerClass
          }
        >
          <BlueprintEditableText
            disabled={disabled || !isEditing} // hack to avoid jumpiness
            isEditing={isEditing}
            onChange={handleInputChange}
            onConfirm={handleConfirm}
            selectAllOnFocus={true}
            value={currentValue}
            placeholder={placeholder}
            className={componentClassName}
            onCancel={handleCancel}
          />
          {isUpdating && (
            <SpinnerWrapper data-test="updating-name-spinner">
              <Spinner size={14} />
            </SpinnerWrapper>
          )}
          {showEditIcon && (
            <div
              className={`${IconOnHover} ${props.pencilIconClassName ?? ""}`}
            >
              <IconWrapper color={colors.GREY_500} height={14} width={14}>
                <EditIcon />
              </IconWrapper>
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default EditableText;
