import { WidgetTypes } from "@superblocksteam/shared";
import { get, set, mergeWith } from "lodash";
import {
  PropertyPaneConfig,
  PropertyPaneControlConfig,
} from "legacy/constants/PropertyControlConstants";
import { WidgetFactory } from "legacy/widgets";
import BaseWidget from "legacy/widgets/BaseWidget";
import { WidgetProps } from "legacy/widgets/BaseWidget/types";
import ButtonWidget from "legacy/widgets/ButtonWidget/ButtonWidget";
import { AllFlags } from "store/slices/featureFlags/models/Flags";
import type { GeneratedTheme } from "legacy/themes/types";

/**
 * Sets a nested property value in an object using a dotted path notation.
 * Creates intermediate objects if they don't exist.
 */
export const setExpandedNestedProperty = (
  obj: Record<string, any>,
  dottedPropertyPath: string,
  value: any,
): void => {
  const parts = dottedPropertyPath.split(".");
  const lastPart = parts.pop();

  if (!lastPart) {
    throw new Error(
      "Invalid property path: cannot end with a dot or be empty.",
    );
  }

  const parentPath = parts.join(".");

  if (parentPath) {
    // Ensure the parent object exists
    const parentObject = get(obj, parentPath, {});
    set(parentObject, lastPart, value);
    set(obj, parentPath, parentObject); // directly set the modified parent back to obj
  } else {
    // If there's no parent path, set directly on the object
    set(obj, dottedPropertyPath, value);
  }
};

const getWidgetPropertyDefaultValue = ({
  props,
  propertyName,
  itemProperties,
  flags,
  theme,
}: {
  props: any;
  propertyName: string;
  itemProperties: any;
  flags: any;
  theme: any;
}) => {
  let defaultValue = props.defaultValue;

  if (props.defaultValueFn) {
    defaultValue = props.defaultValueFn({
      propertyName,
      props: itemProperties,
      flags,
      theme,
    });
  }

  return defaultValue;
};

export const getWidgetPropertyThemeValue = ({
  props,
  propertyName,
  itemProperties,
  flags,
  theme,
}: {
  props: any; // the property config
  propertyName: string; // the widget property name
  itemProperties: any; // the widget DSL (properties with values)
  flags: any;
  theme: any;
}) => {
  const themeValueResp =
    typeof props.themeValue === "function"
      ? props.themeValue({
          theme,
          props: itemProperties,
          flags,
          propertyName,
        })
      : { value: props.themeValue, treatAsNull: false };
  const themeValue = themeValueResp?.value;

  return themeValue;
};

const startsWithAnyOf = (str: string, prefixes: string[]) => {
  return prefixes.some((prefix) => str.startsWith(prefix));
};

const valueNeedsThemePrefix = (value: string) => {
  return (
    typeof value === "string" &&
    startsWithAnyOf(value, ["colors", "typographies"])
  );
};

const prefixValueWithThemeIfNeeded = (value: string) => {
  return valueNeedsThemePrefix(value) ? `{{ theme.${value} }}` : value;
};

const destructureThemeValue = (themeValue: any): any => {
  if (
    themeValue != null &&
    typeof themeValue === "object" &&
    themeValue.value != null
  ) {
    return themeValue.value;
  }
  return themeValue;
};

const deleteTreatAsNulls = (obj: Record<string, any>) => {
  Object.entries(obj).forEach(([key, value]) => {
    if (value?.treatAsNull) {
      delete obj[key];
    }
  });
};

const WIDGETS_DEFAULT_VALUE_SHOULD_BE_INCLUDED: Record<string, string[]> = {
  BUTTON_WIDGET: ["textProps.textStyle"],
};

const getPropertyPaneConfig = (
  widgetClass: typeof BaseWidget,
): PropertyPaneConfig[] => {
  let newConfig: PropertyPaneConfig[] | undefined;
  if (widgetClass.getNewPropertyPaneConfig) {
    newConfig = widgetClass.getNewPropertyPaneConfig();
  }

  if (newConfig && newConfig.length > 0) {
    return newConfig;
  } else if (widgetClass.getPropertyPaneConfig) {
    return widgetClass.getPropertyPaneConfig();
  }

  return [];
};

export const getWidgetThemeValues = ({
  widgetClass,
  widget,
  flags,
  theme,
}: {
  widgetClass: typeof BaseWidget;
  widget: Record<string, any>;
  flags: Partial<AllFlags>;
  theme: GeneratedTheme;
}) => {
  if (
    !widgetClass ||
    (!widgetClass.getPropertyPaneConfig &&
      !widgetClass.getNewPropertyPaneConfig)
  ) {
    return {};
  }
  const propertyPaneConfig = getPropertyPaneConfig(widgetClass);

  const widgetThemeValues: Record<string, any> = {};

  for (const section of propertyPaneConfig) {
    if (section.children) {
      for (const propertyConfig of section.children as any) {
        if (propertyConfig.themeValue != null) {
          const themeValue = getWidgetPropertyThemeValue({
            props: propertyConfig,
            propertyName: propertyConfig.propertyName,
            itemProperties: widget,
            flags,
            theme,
          });

          const defaultValue = getWidgetPropertyDefaultValue({
            props: propertyConfig,
            propertyName: propertyConfig.propertyName,
            itemProperties: widget,
            flags,
            theme,
          });

          const isATextStyleProperty =
            propertyConfig.propertyName.endsWith("textStyle");

          let finalThemeValue: any;

          // Some of our text style properties have default values that have the variant the component is using for the property, and the theme value has the color (or vice versa) so we need to merge them and use that value here
          // TODO: We should figure out why, and if that's required, and if not fix it by only using themeValue
          if (isATextStyleProperty) {
            finalThemeValue = mergeWith(
              {},
              defaultValue,
              themeValue,
              (objValue, srcValue) => {
                if (srcValue !== undefined) {
                  return srcValue;
                }
                return objValue;
              },
            );

            // Destructure the themeValue value into the right DSL value
            // which means removing treatAsNull, using .value directly,
            // and prefixing with theme if needed
            if (finalThemeValue["textColor.default"]) {
              finalThemeValue["textColor.default"] =
                prefixValueWithThemeIfNeeded(
                  destructureThemeValue(finalThemeValue["textColor.default"]),
                );
            }
          }
          // Is there a default value that should be used "as a theme value"?
          else if (
            WIDGETS_DEFAULT_VALUE_SHOULD_BE_INCLUDED[widget.type]?.includes(
              propertyConfig.propertyName,
            ) &&
            defaultValue != null
          ) {
            finalThemeValue = prefixValueWithThemeIfNeeded(
              destructureThemeValue(defaultValue),
            );
          }
          // Otherwise, use the theme value
          else if (themeValue != null) {
            finalThemeValue = prefixValueWithThemeIfNeeded(themeValue);
          }

          if (finalThemeValue !== undefined) {
            // if the finalThemeValue is an object, ensure we
            // delete any treatAsNull properties anywhere in the object
            if (typeof finalThemeValue === "object") {
              deleteTreatAsNulls(finalThemeValue);

              // Loop through keys and set each one
              Object.entries(finalThemeValue).forEach(([key, value]) => {
                setExpandedNestedProperty(
                  widgetThemeValues,
                  `${propertyConfig.propertyName}.${key}`,
                  value,
                );
              });
            } else {
              setExpandedNestedProperty(
                widgetThemeValues,
                propertyConfig.propertyName,
                finalThemeValue,
              );
            }
          }
        }
      }
    }
  }

  const filteredWidgetThemeValues = Object.fromEntries(
    Object.entries(widgetThemeValues).filter(
      ([_, value]) => value !== undefined,
    ),
  );

  return filteredWidgetThemeValues;
};

export const getWidgetClassForType = (type: WidgetTypes) => {
  if (!type) return ButtonWidget;
  return WidgetFactory.getWidgetClasses()[type] ?? ButtonWidget;
};

const processProperties = ({
  propertyPaneConfig,
  widget,
  parentPath,
  callbackFn,
}: {
  propertyPaneConfig: PropertyPaneConfig[];
  widget: Partial<WidgetProps>;
  parentPath?: string;
  callbackFn: (
    propertyConfig: PropertyPaneControlConfig,
    parentPath?: string,
  ) => void;
}) => {
  for (const section of propertyPaneConfig) {
    if (section.children) {
      for (const propertyConfig of section.children as any) {
        if (propertyConfig.panelConfig && !propertyConfig.isTriggerProperty) {
          const panelChildren = propertyConfig.panelConfig.children;
          if (propertyConfig.propertyName === "primaryColumns") {
            const primaryColumnIds = Object.keys(
              (widget as any).primaryColumns ?? {},
            );
            primaryColumnIds.forEach((id) => {
              processProperties({
                propertyPaneConfig: panelChildren,
                widget,
                parentPath: `${propertyConfig.propertyName}.${id}`,
                callbackFn,
              });
            });
          } else {
            const { panelIdPropertyName } = (
              propertyConfig as PropertyPaneControlConfig
            ).panelConfig!;

            const subPath = get(
              widget,
              `${propertyConfig.propertyName}.${panelIdPropertyName}`,
            );
            const nestedParentPath = subPath
              ? `${propertyConfig.propertyName}.${subPath}`
              : propertyConfig.propertyName;
            processProperties({
              propertyPaneConfig: panelChildren,
              widget,
              parentPath: nestedParentPath,
              callbackFn,
            });
            callbackFn(propertyConfig, parentPath);
          }
        }

        if (propertyConfig.propertyName) {
          callbackFn(propertyConfig, parentPath);
        }
        if (propertyConfig.children) {
          for (const child of propertyConfig.children) {
            if (child.propertyName) {
              callbackFn(child, parentPath);
            }
          }
        }
      }
    }
  }
};

export const forEachWidgetProperty = ({
  widgetClass,
  widget,
  callbackFn,
}: {
  widgetClass: typeof BaseWidget;
  widget: Partial<WidgetProps>;
  callbackFn: (
    propertyConfig: PropertyPaneControlConfig,
    parentPath?: string,
  ) => void;
}) => {
  if (
    !widgetClass ||
    (!widgetClass.getPropertyPaneConfig &&
      !widgetClass.getNewPropertyPaneConfig)
  ) {
    return;
  }
  const propertyPaneConfig = getPropertyPaneConfig(widgetClass);

  processProperties({
    propertyPaneConfig,
    widget,
    callbackFn,
  });
};
