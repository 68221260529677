import {
  ApplicationScope,
  containsBindingsAnywhere,
} from "@superblocksteam/shared";
import React, { useMemo } from "react";
import styled from "styled-components";
import CodeEditor from "components/app/CodeEditor";
import {
  AutocompleteConfiguration,
  EditorModes,
  EditorSize,
  EditorTheme,
  TabBehaviour,
} from "components/app/CodeEditor/EditorConfig";
import ExpandCodeEditorBtn from "components/app/CodeEditor/ExpandCodeEditorBtn";
import { PROPERTY_PANE_POPOVER_WIDTH } from "legacy/constants/WidgetConstants";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import {
  isArrayPropertyPath,
  isCodeProperty,
} from "legacy/utils/BottomPanelTabUtils";
import { ColumnProperties } from "legacy/widgets/TableWidget/TableComponent/Constants";
import { ApiError } from "utils/error/error";
import { JSToString, stringToJS } from "utils/string";
import BaseControl, { ControlLayout, ControlProps } from "./BaseControl";
import { CHARACTERS_PER_PX } from "./InputTextControl";
import { StyledDynamicInput } from "./StyledControls";

const CurlyBraces = styled.span`
  color: white;
  background-color: #f3672a;
  border-radius: 2px;
  padding: 2px;
  margin: 0px 2px;
`;

export function InputText(props: {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement> | string) => void;
  isValid: boolean;
  validationError?: string;
  apiErrors?: ApiError[];
  evaluatedValue?: any;
  expected?: string;
  placeholder?: string;
  dataTreePath?: string;
  additionalDynamicData: Record<string, Record<string, unknown>>;
  disabled: boolean;
  shouldOpenIconSelectorForIconBindings?: boolean;
  disableEvaluatedValuePopover?: boolean;
  onEditorMount?: (editor: CodeMirror.Editor) => void;
  openIconSelector?: () => void;
  isIconSelectorOpen?: boolean;
  onBlur?: () => void;
  propertyName: string;
  widgetProperties: any;
}) {
  const {
    validationError,
    apiErrors,
    expected,
    value,
    onChange,
    placeholder,
    dataTreePath,
    evaluatedValue,
    additionalDynamicData,
    disabled,
    onBlur,
    propertyName,
    widgetProperties,
  } = props;

  const input = useMemo(
    () => ({
      value: value,
      onChange: onChange,
    }),
    [onChange, value],
  );

  const autocompleteConfiguration = useMemo<AutocompleteConfiguration>(
    () => ({
      global: true,
      openIconSelector: props.openIconSelector,
      shouldOpenIconSelectorForIconBindings:
        props.shouldOpenIconSelectorForIconBindings,
    }),
    [props.openIconSelector, props.shouldOpenIconSelectorForIconBindings],
  );

  const promptMessage = useMemo(
    () => (
      <React.Fragment>
        Access the current cell using <CurlyBraces>{"{{"}</CurlyBraces>
        currentRow.columnName
        <CurlyBraces>{"}}"}</CurlyBraces>
      </React.Fragment>
    ),
    [],
  );

  const expandBtn =
    !isArrayPropertyPath(propertyName || "") ||
    isCodeProperty(propertyName || "") ? (
      <ExpandCodeEditorBtn
        properties={widgetProperties}
        propertyPath={propertyName}
        itemKind={ItemKinds.WIDGET}
      />
    ) : null;

  return (
    <StyledDynamicInput>
      <CodeEditor
        input={input}
        evaluatedValue={evaluatedValue}
        expected={expected}
        // TODO(APP_SCOPE): Change if we ever allow app-scope components
        dataTreeScope={ApplicationScope.PAGE}
        dataTreePath={dataTreePath}
        validationError={validationError}
        apiErrors={apiErrors}
        theme={EditorTheme.LIGHT}
        mode={EditorModes.TEXT_WITH_BINDING}
        tabBehaviour={TabBehaviour.INDENT}
        size={EditorSize.EXTENDED}
        enableSearch={false}
        placeholder={placeholder}
        autocompleteConfiguration={autocompleteConfiguration}
        additionalDynamicData={additionalDynamicData}
        promptMessage={promptMessage}
        monospace={true}
        disabled={disabled}
        onEditorMount={props.onEditorMount}
        isIconSelectorOpen={props.isIconSelectorOpen}
        disableEvaluatedValuePopover={props.disableEvaluatedValuePopover}
        shouldOpenIconSelectorForIconBindings={
          props.shouldOpenIconSelectorForIconBindings
        }
        onEditorBlur={onBlur}
        expandIntoTabBtn={expandBtn}
      />
    </StyledDynamicInput>
  );
}

export const getInputComputedValue = (
  tableName: string,
  propertyValue: string,
  defaultValue?: string,
) => {
  const isDynamicRowValue =
    containsBindingsAnywhere(propertyValue) &&
    propertyValue.includes("tableData");
  const value =
    propertyValue && isDynamicRowValue
      ? parseComputedValue(propertyValue, tableName)
      : propertyValue
      ? propertyValue
      : defaultValue;
  return value ?? "";
};

export const getOutputComputedValue = (tableId: string, value: string) => {
  const stringToEvaluate = stringToJS(value, false);
  return `{{${tableId}.tableDataWithInserts.map((currentRow) => { return ${stringToEvaluate}})}}`;
};

const parseComputedValue = (propertyValue: string, tableId: string) => {
  if (propertyValue.includes(".tableDataWithInserts")) {
    const value = `${propertyValue.substring(
      `{{${tableId}.tableDataWithInserts.map((currentRow) => { return `.length,
      propertyValue.length - 4,
    )}`;
    const stringValue = JSToString(value);
    return stringValue;
  } else {
    const value = `${propertyValue.substring(
      `{{${tableId}.tableData.map((currentRow) => { return `.length,
      propertyValue.length - 4,
    )}`;
    const stringValue = JSToString(value);
    return stringValue;
  }
};

class ComputeTablePropertyControl extends BaseControl<ComputeTablePropertyControlProps> {
  render() {
    const {
      expected,
      propertyValue,
      isValid,
      label,
      dataTreePath,
      validationMessage,
      defaultValue,
      itemKind,
    } = this.props;
    const tableId = this.props.widgetProperties.widgetName;
    const value = getInputComputedValue(tableId, propertyValue, defaultValue);
    const evaluatedProperties = this.props.widgetProperties;

    const columns: Record<string, ColumnProperties> =
      evaluatedProperties.primaryColumns || {};
    const currentRow: { [key: string]: any } = {};
    Object.keys(columns).forEach((id: string) => {
      currentRow[id] = undefined;
    });

    return (
      <InputText
        label={label}
        value={value}
        onChange={this.onTextChange}
        isValid={isValid}
        validationError={validationMessage}
        expected={expected}
        dataTreePath={
          itemKind !== ItemKinds.AI_EDITS ? dataTreePath : undefined
        }
        additionalDynamicData={{
          currentRow,
        }}
        disabled={this.props.isDisabled ?? false}
        onBlur={this.onBlur}
        propertyName={this.props.propertyName}
        widgetProperties={this.props.widgetProperties}
      />
    );
  }

  getComputedValue = (value: string, tableId: string) => {
    return getOutputComputedValue(tableId, value);
  };

  onTextChange = (event: React.ChangeEvent<HTMLTextAreaElement> | string) => {
    let value = "";
    if (typeof event !== "string") {
      value = event.target.value;
    } else {
      value = event;
    }
    if (value) {
      const output = this.getComputedValue(
        value,
        this.props.widgetProperties.widgetName,
      );
      this.changeControlLayoutIfNeeded(output);
      this.updateProperty(this.props.propertyName, output);
    } else {
      this.changeControlLayoutIfNeeded(value);
      this.updateProperty(this.props.propertyName, value);
    }
  };

  onBlur = () => {
    if (this.lastLayout) {
      this.props.setControlLayout?.(this.lastLayout);
      this.hasBeenVertical = undefined;
    }
  };

  hasBeenVertical?: boolean = undefined;
  lastLayout: ControlLayout | undefined;
  changeControlLayoutIfNeeded = (value: any) => {
    const tableId = this.props.widgetProperties.widgetName;
    const layout = ComputeTablePropertyControl.computeControlLayout(
      value,
      tableId,
      PROPERTY_PANE_POPOVER_WIDTH,
    );

    if (layout) {
      this.lastLayout = layout;
      if (layout === ControlLayout.VERTICAL_FLEX) {
        this.hasBeenVertical = true;
        this.props.setControlLayout?.(layout);
      } else if (!this.hasBeenVertical) {
        this.props.setControlLayout?.(layout);
      }
    }
  };

  static computeControlLayout(
    propertyValue: any,
    itemName: string,
    panelWidth: number,
  ) {
    if (typeof propertyValue === "string") {
      const value = getInputComputedValue(itemName, propertyValue);
      const layout =
        value.length > CHARACTERS_PER_PX * panelWidth || value.includes("\n")
          ? ControlLayout.VERTICAL_FLEX
          : ControlLayout.GRID;

      return layout;
    }
  }

  static getControlLayout({
    propertyValue,
    itemName,
  }: {
    propertyValue: any;
    itemName: string;
  }) {
    return ComputeTablePropertyControl.computeControlLayout(
      propertyValue,
      itemName,
      PROPERTY_PANE_POPOVER_WIDTH,
    );
  }

  static getControlType() {
    return "COMPUTE_TABLE_VALUE";
  }
}

interface ComputeTablePropertyControlProps extends ControlProps {
  defaultValue?: string;
}

export default ComputeTablePropertyControl;
