import { all, put, select, takeLatest } from "redux-saga/effects";
import {
  focusWidget,
  selectWidgets,
  showWidgetPropertyPane,
} from "legacy/actions/widgetActions";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";

import {
  getIsWidgetFocused,
  getWidgetsAreSelected,
} from "legacy/selectors/sagaSelectors";

function* clearPropertyPaneSaga(): Generator<any, any, any> {
  const appHasSelectedWidgets = yield select(getWidgetsAreSelected);
  const appHasFocusedWidgets = yield select(getIsWidgetFocused);

  if (appHasSelectedWidgets) yield put(selectWidgets([], false));
  if (appHasFocusedWidgets) yield put(focusWidget(undefined));
  yield put(showWidgetPropertyPane(undefined));
}

export default function* selectionCanvasSagas() {
  yield all([
    takeLatest(ReduxActionTypes.CLEAR_PROPERTY_PANE, clearPropertyPaneSaga),
  ]);
}
