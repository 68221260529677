/* eslint-disable import/no-unused-modules */
import { Resource } from "@opentelemetry/resources";
import { BatchSpanProcessor } from "@opentelemetry/sdk-trace-base";
import {
  WebTracerProvider,
  StackContextManager,
} from "@opentelemetry/sdk-trace-web";
import { SEMRESATTRS_SERVICE_NAME } from "@opentelemetry/semantic-conventions";
import { LocalStorageExporter } from "./LocalStorageExporter";

const resource = Resource.default().merge(
  new Resource({
    [SEMRESATTRS_SERVICE_NAME]: "superblocks",
  }),
);

const provider = new WebTracerProvider({
  resource: resource,
});

provider.addSpanProcessor(new BatchSpanProcessor(new LocalStorageExporter()));

/*
TODO: When we have our own trace intake
provider.addSpanProcessor(new BatchSpanProcessor(new OTLPTraceExporter({
  url: "superblocks-intake-endpoint.com",
  headers: {}
})))
*/

provider.register({
  contextManager: new StackContextManager().enable(),
});
