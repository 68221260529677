import { AccessMode } from "@superblocksteam/shared";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { getAccessMode } from "legacy/selectors/usersSelectors";
import {
  fetchApiPermissions,
  fetchApiPermissionsSuccess,
  fetchApiPermissionsError,
} from "../actions";
import { getApiPermissions } from "../client";

function* fetchApiPermissionsSaga(
  action: ReturnType<typeof fetchApiPermissions>,
): Generator<any, any, any> {
  const accessMode: ReturnType<typeof getAccessMode> = yield select(
    getAccessMode,
  );
  if (
    accessMode === AccessMode.EXTERNAL_USER ||
    accessMode === AccessMode.VISITOR
  ) {
    return;
  }

  const { apiId, type } = action.payload;
  try {
    const response = yield call(getApiPermissions, { apiId, type });
    if (response) {
      yield put(
        fetchApiPermissionsSuccess({
          apiId,
          permissions: response.permissions,
        }),
      );
    } else {
      yield put(fetchApiPermissionsError("Failed to fetch api permissions"));
    }
  } catch (e: any) {
    yield put(fetchApiPermissionsError("Failed to fetch api permissions"));
  }
}

export default function* rootSaga() {
  yield all([takeLatest(fetchApiPermissions.type, fetchApiPermissionsSaga)]);
}
