import { buffers } from "@redux-saga/core";
import { Channel } from "@redux-saga/types";
import { PutApplicationUpdateRequestBody } from "@superblocksteam/schemas";
import {
  ApplicationScope,
  ApplicationSignatureTreeSigned,
  ApplicationSignatureTreeUpdate,
  NON_SB_ORG_UPDATE_ERROR,
  PageDSL8,
  PageDSL,
} from "@superblocksteam/shared";
import {
  actionChannel,
  call,
  delay,
  flush,
  fork,
  put,
  select,
  take,
} from "redux-saga/effects";
import { requestPageSave, savePageSuccess } from "legacy/actions/pageActions";
import ApplicationApi from "legacy/api/ApplicationApi";
import LayoutApi from "legacy/api/LayoutApi";
import { SavePageRequest, SavePageResponse } from "legacy/api/PageApi";
import { Toaster } from "legacy/components/ads/Toast";
import { Variant } from "legacy/components/ads/common";
import {
  ReduxAction,
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import CanvasWidgetsNormalizer from "legacy/normalizers/CanvasWidgetsNormalizer";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { getCurrentBranch } from "legacy/selectors/editorSelectors";
import selectLastSuccessfulWrite from "legacy/selectors/successfulWriteSelector";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "legacy/utils/PerformanceTracker";
import { lock as lockApis } from "store/slices/apisShared/sharedPersistApiLock";
import { lock } from "store/slices/apisShared/sharedPersistApiLock";
import {
  requestApplicationSave,
  updateApplicationSettings,
} from "store/slices/application/applicationActions";
import { getScopedEvents } from "store/slices/application/events/selectors";
import {
  getCurrentApplication,
  getMergedAppConfiguration,
} from "store/slices/application/selectors";
import { AppStateVar } from "store/slices/application/stateVars/StateConstants";
import { getScopedStateVars } from "store/slices/application/stateVars/selectors";
import { AppTimer } from "store/slices/application/timers/TimerConstants";
import { getScopedTimers } from "store/slices/application/timers/selectors";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { toUnscopedEntity } from "store/utils/scope";
import { NOOP } from "utils/function";
import logger from "utils/logger";
import { sendErrorUINotification } from "utils/notification";
import omit from "utils/omit";
import { updateApplicationSignature } from "utils/resource-signing";
import { APP_MODE } from "../reducers/types";
import {
  getCurrentlyEditedPageId,
  getEditorConfigs,
  getWidgets,
} from "../selectors/sagaSelectors";
import { validateResponse } from "./ErrorSagas";

function* persistCurrentPageSaga(): Generator<any, any, any> {
  let unlock = NOOP;
  try {
    const editorConfigs: ReturnType<typeof getEditorConfigs> = yield select(
      getEditorConfigs,
    );

    if (!editorConfigs) {
      throw new Error("Page ID or Layout ID is missing on save");
    }

    unlock = yield call(lock, editorConfigs.pageId);

    const currentlyEditedPageId: string = yield select(
      getCurrentlyEditedPageId,
    );
    if (currentlyEditedPageId !== editorConfigs.pageId) {
      throw new Error(
        "page ID changed while waiting for lock, this should not happen",
      );
    }

    const lastSuccessfulWrite: Date = yield select(selectLastSuccessfulWrite);

    PerformanceTracker.startAsyncTracking(
      PerformanceTransactionName.SAVE_PAGE_API,
      {
        pageId: editorConfigs.pageId,
      },
    );

    const payload: SavePageRequest = yield call(getLayoutSavePayload, {
      lastSuccessfulWrite,
    });
    // if the page does not need to be signed, updateApplicationSignature will return nothing
    const signature: ApplicationSignatureTreeSigned | undefined = yield call(
      updateApplicationSignature,
      [
        {
          type: "page",
          pageDsl: payload.dsl,
          pageId: editorConfigs.pageId,
        },
      ],
    );
    payload.signature = signature;

    const superblocksSupportUpdateEnabled: boolean = yield select(
      selectFlagById,
      Flag.ENABLE_SUPERBLOCKS_SUPPORT_UPDATES,
    );
    const branch: ReturnType<typeof getCurrentBranch> = yield select(
      getCurrentBranch,
    );
    let savePageResponse: SavePageResponse;
    try {
      savePageResponse = yield call(
        LayoutApi.savePage,
        payload,
        superblocksSupportUpdateEnabled,
        branch?.name,
      );
    } catch (error: any) {
      logger.warn(`Error in LayoutApi.savePage: ${error?.message}`);
      throw error;
    }
    let isValidResponse;
    try {
      isValidResponse = yield validateResponse(savePageResponse, false);
    } catch (error: any) {
      logger.warn(`Error in validateResponse: ${error.message}`);
      throw error;
    }
    if (isValidResponse) {
      const { messages } = savePageResponse.data;
      // Show toast messages from the server
      if (messages && messages.length) {
        savePageResponse.data.messages.forEach((message) => {
          Toaster.show({
            text: message,
            type: Variant.info,
          });
        });
      }
      yield put(savePageSuccess());
      PerformanceTracker.stopAsyncTracking(
        PerformanceTransactionName.SAVE_PAGE_API,
      );

      yield put({
        type: ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE,
        payload: savePageResponse.data.updated,
      });
    }
  } catch (error: any) {
    PerformanceTracker.stopAsyncTracking(
      PerformanceTransactionName.SAVE_PAGE_API,
      {
        failed: true,
      },
    );

    if (error?.message?.includes(NON_SB_ORG_UPDATE_ERROR)) {
      logger.info(error.message);
    } else {
      logger.warn(`Save page error: ${error.message}; Stack: ${error.stack}`);
    }

    yield put({
      type: ReduxActionErrorTypes.SAVE_PAGE_ERROR,
      payload: {
        // Used by one of the reducers that looks for 429 conflict errors
        error: { code: error.code },
        show: false,
      },
    });

    if (error.code !== 409) {
      // if error code is 409, a 409 modal will show so we do not need to show the bottom right notification
      sendErrorUINotification({
        message: `Failed to save app: ${error.message} (${error.code}) `,
      });
    }
  } finally {
    unlock();
  }
}

const DSL_OMITTED_KEY = "widgetLastChange";
function removeUnnecessaryKeysFromDSL(dsl: PageDSL8): PageDSL8 {
  const denormalizedDSL = omit(dsl, DSL_OMITTED_KEY) as any;
  if (denormalizedDSL.children) {
    denormalizedDSL.children = denormalizedDSL.children?.map((child: any) =>
      removeUnnecessaryKeysFromDSL(child),
    );
  }
  return denormalizedDSL;
}

export function* getLayoutSavePayload({
  lastSuccessfulWrite,
  signature,
}: {
  lastSuccessfulWrite: Date;
  signature?: ApplicationSignatureTreeSigned;
}) {
  const widgets: ReturnType<typeof getWidgets> = yield select(getWidgets);

  const stateVarsScoped: ReturnType<typeof getScopedStateVars> = yield select(
    getScopedStateVars,
    ApplicationScope.PAGE,
  );
  const stateVars = toUnscopedEntity<AppStateVar>(stateVarsScoped);

  const timersScoped: ReturnType<typeof getScopedTimers> = yield select(
    getScopedTimers,
    ApplicationScope.PAGE,
  );
  const timers = toUnscopedEntity<AppTimer>(timersScoped);

  const scopedEvents: ReturnType<typeof getScopedEvents> = yield select(
    getScopedEvents,
    ApplicationScope.PAGE,
  );
  const events = toUnscopedEntity(scopedEvents);

  const editorConfigs: ReturnType<typeof getEditorConfigs> = yield select(
    getEditorConfigs,
  );

  if (!editorConfigs) {
    throw new Error("Page ID or Layout ID is missing on save");
  }

  const denormalizedDSL = CanvasWidgetsNormalizer.denormalize(
    Object.keys(widgets)[0],
    { canvasWidgets: widgets },
  );

  return {
    ...editorConfigs,
    lastSuccessfulWrite,
    dsl: {
      ...removeUnnecessaryKeysFromDSL(denormalizedDSL),
      stateVars: {
        stateVarMap: stateVars,
      },
      timers: {
        timerMap: timers,
      },
      events: {
        eventMap: events,
      },
    } satisfies PageDSL,
    signature,
  };
}

function* persistCurrentAppSaga({
  hasUpdatedConfiguration,
  hasUpdatedSettings,
}: {
  hasUpdatedConfiguration?: boolean;
  hasUpdatedSettings?: boolean;
}): Generator<any, any, any> {
  let unlock = NOOP;
  try {
    const currentApplication: ReturnType<typeof getCurrentApplication> =
      yield select(getCurrentApplication);
    if (!currentApplication) {
      throw new Error("Current application not found");
    }
    // update settings redux state optimistically
    if (hasUpdatedSettings && currentApplication.settings) {
      // this block will only run in editor mode
      yield put(updateApplicationSettings(currentApplication.settings));
    }
    unlock = yield call(lockApis, currentApplication.id);
    const superblocksSupportUpdateEnabled: boolean = yield select(
      selectFlagById,
      Flag.ENABLE_SUPERBLOCKS_SUPPORT_UPDATES,
    );

    const newConfiguration: ReturnType<typeof getMergedAppConfiguration> =
      yield select(getMergedAppConfiguration);

    // if signing is not enabled, signature will be empty
    let signature: ApplicationSignatureTreeSigned | undefined;
    const signatureUpdates: ApplicationSignatureTreeUpdate[] = [];
    if (hasUpdatedConfiguration) {
      signatureUpdates.push({
        type: "configuration",
        configuration: newConfiguration,
      });
    }
    if (hasUpdatedSettings) {
      signatureUpdates.push({
        type: "settings",
        settings: currentApplication.settings!,
      });
    }
    if (signatureUpdates.length > 0) {
      signature = yield call(updateApplicationSignature, signatureUpdates);
    }

    const currentBranch: ReturnType<typeof getCurrentBranch> = yield select(
      getCurrentBranch,
    );
    const payload = {
      id: currentApplication.id,
      branch: currentBranch?.name,
      configuration: newConfiguration,
      settings: currentApplication.settings,
    } satisfies PutApplicationUpdateRequestBody & {
      id: string;
      branch: string | undefined;
    };

    const response: Awaited<
      ReturnType<typeof ApplicationApi.updateApplication>
    > = yield call(
      ApplicationApi.updateApplication,
      payload,
      signature,
      superblocksSupportUpdateEnabled,
    );
    const isValidResponse = yield validateResponse(response);
    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.UPDATE_APPLICATION_SUCCESS,
      });
    }
    yield put({
      type: ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE,
      payload: response.data.updated,
    });
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.UPDATE_APPLICATION_ERROR,
      payload: {
        error,
      },
    });
  } finally {
    unlock();
  }
}

export default function* appAndPagePersistence() {
  yield fork(function* () {
    type SaveAction = ReduxAction<
      typeof requestPageSave.type | typeof requestApplicationSave.type
    >;
    const savePageActionChannel: Channel<SaveAction> = yield actionChannel(
      [requestPageSave.type, requestApplicationSave.type],
      buffers.expanding(),
    );

    while (true) {
      const latestAction: SaveAction = yield take(savePageActionChannel);

      const appMode: APP_MODE = yield select(getAppMode);
      if (appMode !== APP_MODE.EDIT) {
        // keep the loop running in case the user opens edit mode later in the same session
        break;
      }
      yield delay(500);

      // if there are multiple save requests, we want to flush all of them and only save the last one
      const allActions: SaveAction[] = yield flush(savePageActionChannel);
      const hasPageSave =
        requestPageSave.match(latestAction) ||
        allActions.some(requestPageSave.match);

      if (hasPageSave) {
        yield call(persistCurrentPageSaga);
      }

      const hasUpdatedConfiguration =
        (requestApplicationSave.match(latestAction) &&
          latestAction.payload.hasUpdatedConfiguration) ||
        allActions.some(
          (action) =>
            requestApplicationSave.match(action) &&
            action.payload.hasUpdatedConfiguration,
        );
      const hasUpdatedSettings =
        (requestApplicationSave.match(latestAction) &&
          latestAction.payload.hasUpdatedSettings) ||
        allActions.some(
          (action) =>
            requestApplicationSave.match(action) &&
            action.payload.hasUpdatedSettings,
        );

      if (hasUpdatedConfiguration || hasUpdatedSettings) {
        yield call(persistCurrentAppSaga, {
          hasUpdatedConfiguration,
          hasUpdatedSettings,
        });
      }
    }
  });
}
