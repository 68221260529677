import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WidgetTypes } from "@superblocksteam/shared";
import { set, uniq } from "lodash";
import { WidgetProps } from "legacy/widgets/BaseWidget/types";
import { fastClone } from "utils/clone";
import {
  getAiWidgetEditActionsStream,
  sendAiWidgetEditActionsFeedback,
} from "./client";
import { ComponentEditAction, SortedProperties } from "./types";

const initialState: {
  isLoading: boolean;
  selectedWidgetId?: string;
  initialPosition?: { x: number; y: number };
  changedKeys?: Array<string>;
  dataTreeChanges?: Record<string, unknown>;
  actions?: Array<any>;
  widgetRename?: string;
  error?: string;
  actionsRequestId?: string;
  feedbackCommentText?: string;
  reasoning?: Reasoning[];
  initialDataTreeChanges?: Record<string, unknown>;
  propertiesToChange?: string[];
} = {
  isLoading: false,
};

type Reasoning = {
  task: string;
  steps: string[];
};

type GetWidgetEditsPayload = {
  existingWidget: Partial<WidgetProps> | SortedProperties;
  prompt: string;
  rawPrompt: string;
  context: Record<string, any>;
  widgetId: string;
};

export const processAiActions = createAction<{
  actions: ComponentEditAction[];
  existingWidgetId: string;
  actionsRequestId?: string;
}>("ai/processAiActions");

export const updateAiDynamicProperties = createAction<{
  itemId: string;
  propertyName: string;
  isDynamicProperty: boolean;
}>("ai/updateAiDynamicProperties");

export const getWidgetEditActionsStream = createAsyncThunk<
  { actions: ComponentEditAction[] },
  GetWidgetEditsPayload
>(
  "ai/getWidgetEditActionsStream",
  async (
    {
      existingWidget,
      prompt,
      rawPrompt,
      context,
      widgetId,
    }: GetWidgetEditsPayload,
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setIsLoading(true));

    let actionsRequestId: string | undefined;
    const actions: ComponentEditAction[] = [];
    await getAiWidgetEditActionsStream({
      isTest: prompt.trim() === "test",
      existingWidget,
      prompt,
      rawPrompt,
      context,
      signal: thunkAPI.signal,
      onMessage: (message) => {
        const event = message?.value;
        if (Array.isArray(event)) {
          const properties = uniq(
            event.map((property) => {
              if (
                existingWidget.type === WidgetTypes.BUTTON_WIDGET &&
                property.endsWith(".textColor")
              ) {
                // hack for button color, pull to the top
                return "textColor";
              }
              return property.split(".")[0];
            }),
          );
          thunkAPI.dispatch(setPropertiesToChange(properties));
        } else if (event) {
          actions.push(event);
          thunkAPI.dispatch(
            processAiActions({
              actions: fastClone(actions),
              existingWidgetId: widgetId,
              actionsRequestId,
            }),
          );
        }
      },
    });

    thunkAPI.dispatch(setIsLoading(false));

    return {
      actions,
    };
  },
);

export const sendWidgetEditActionsFeedback = createAsyncThunk<
  void,
  {
    actionsRequestId: string;
    feedback: "accept" | "deny" | "exit" | "redo";
    commentText?: string;
    expectedActions?: ComponentEditAction[];
  }
>(
  "ai/sendAiWidgetEditActionsFeedback",
  async (
    { actionsRequestId, feedback, commentText, expectedActions },
    thunkAPI,
  ) => {
    sendAiWidgetEditActionsFeedback({
      actionsRequestId,
      feedback,
      comment: commentText,
      expectedActions,
    });
  },
);

export const updateFeedbackCommentText = createAction<string>(
  "ai/updateFeedbackCommentText",
);

export const aiSlice = createSlice({
  name: "ai",
  initialState,
  reducers: {
    clearAiChanges: (state, action: { payload: { shouldClose: boolean } }) => {
      state.actionsRequestId = undefined;
      state.changedKeys = undefined;
      state.dataTreeChanges = undefined;
      state.initialDataTreeChanges = undefined;
      state.isLoading = false;
      state.actions = undefined;
      state.widgetRename = undefined;
      state.feedbackCommentText = undefined;
      state.propertiesToChange = undefined;

      if (action.payload.shouldClose) {
        state.selectedWidgetId = undefined;
        state.initialPosition = undefined;
      }
    },
    updateFeedbackCommentText: (state, action: { payload: string }) => {
      state.feedbackCommentText = action.payload;
    },
    updateAiChanges: (
      state,
      action: {
        payload: { updates: Record<string, unknown>; rename?: string };
      },
    ) => {
      Object.entries(action.payload.updates).forEach(([key, value]) => {
        set(state.dataTreeChanges ?? {}, key, value);
        if (!state.changedKeys?.includes(key)) {
          state.changedKeys?.push(key);
        }
      });
      if (action.payload.rename) {
        state.widgetRename = action.payload.rename;
      }
    },
    setAiChanges: (
      state,
      action: {
        payload: {
          changedKeys: Array<string>;
          dataTreeChanges: Record<string, unknown>;
          rename: string;
          actionsRequestId?: string;
        };
      },
    ) => {
      const { changedKeys, dataTreeChanges, rename, actionsRequestId } =
        action.payload;
      state.changedKeys = changedKeys;
      state.dataTreeChanges = dataTreeChanges;
      state.initialDataTreeChanges = dataTreeChanges;
      state.widgetRename = rename;
      state.actionsRequestId = actionsRequestId;
    },
    openAiModal: (
      state,
      action: {
        payload: { widgetId: string; position: { x: number; y: number } };
      },
    ) => {
      state.selectedWidgetId = action.payload.widgetId;
      state.initialPosition = action.payload.position;
    },
    setIsLoading: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
    },
    setPropertiesToChange: (state, action: { payload: string[] }) => {
      state.propertiesToChange = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  clearAiChanges,
  openAiModal,
  setAiChanges,
  updateAiChanges,
  setIsLoading,
} = aiSlice.actions;

const { setPropertiesToChange } = aiSlice.actions;
