import React, { useMemo } from "react";
import PropertiesPanelSplitPane from "components/ui/PropertiesPanelSplitPane";
import { useFeatureFlag } from "hooks/ui";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import {
  isPropertyPaneCategoryConfig,
  isPropertyPaneControlConfig,
  isPropertyPaneSectionConfig,
} from "legacy/utils/PropertyControlUtils";
import { Flag } from "store/slices/featureFlags";
import IdleRender from "./IdleRender";
import { ItemKinds, ItemWithPropertiesType } from "./ItemKindConstants";
import {
  getItemPropertyPaneConfig,
  getItemV2PropertyPaneConfig,
} from "./ItemPropertyPaneConfig";
import { type PropertySectionProps } from "./PropertySection";
import { getPropertyControl, getPropertySection } from "./dynamic";
import { PanelChildProps } from "./types";
import { useV2PropertyPaneEnabled } from "./useV2PropertyPaneEnabled";

interface PropertyControlsGeneratorProps<ItemKind extends ItemKinds> {
  itemKind: ItemKind;
  itemId: string;
  type: ItemWithPropertiesType;
  panel: PanelChildProps;
  // by default, we show dividers between property pane sections. This flag disables them.
  disableSectionDivider?: boolean;
  panelProps?: Record<string, unknown>;
  getPanelContainer?: () => HTMLDivElement | undefined;
  shouldSplitEventHandlers?: boolean;
}

export function generatePropertyControl<ItemKind extends ItemKinds>(
  propertyPaneConfig: readonly PropertyPaneConfig[],
  props: PropertyControlsGeneratorProps<ItemKind>,
  isNested: boolean = false,
  topLevelSectionProps?: Partial<PropertySectionProps<ItemKind>>, // This to allow the renderer to control some behavior
) {
  if (!propertyPaneConfig) return null;
  return propertyPaneConfig.map((config: PropertyPaneConfig) => {
    if (
      isPropertyPaneSectionConfig(config) ||
      isPropertyPaneCategoryConfig(config)
    ) {
      const PropertySection = getPropertySection();
      const name = isPropertyPaneSectionConfig(config)
        ? config.sectionName
        : config.categoryName;
      return (
        <PropertySection
          key={`${props.itemKind}-${props.itemId}-${name}-${config.key ?? ""}`}
          itemKind={props.itemKind}
          itemId={props.itemId}
          id={name}
          name={name}
          helpText={config.helpText}
          showHeader={config.showHeader ?? false}
          items={config.children}
          hidden={config.hidden}
          getAdditionalHiddenData={config.getAdditionalHiddenData}
          propertyPath={config.propertySectionPath}
          isDefaultOpen={config.isDefaultOpen ?? true}
          headerType={config.headerType}
          sectionStyle={config.sectionStyle}
          childrenStyle={config.childrenStyle}
          showDivider={!props.disableSectionDivider}
          subHeader={config.subHeader}
          getPanelContainer={props.getPanelContainer}
          addButtonTooltip={config.addButtonTooltip}
          panelProps={props.panelProps}
          isNestedSection={isNested}
          sectionCategory={config.sectionCategory}
          {...topLevelSectionProps}
        >
          {config.children &&
            generatePropertyControl(config.children, props, true)}
        </PropertySection>
      );
    } else if (isPropertyPaneControlConfig(config)) {
      const PropertyControl = getPropertyControl();
      return (
        <PropertyControl
          key={`${props.itemKind}-${props.itemId}-${
            config.key ?? config.propertyName
          }`}
          itemKind={props.itemKind}
          itemId={props.itemId}
          {...config}
          panel={props.panel}
          panelProps={props.panelProps}
          getPanelContainer={props.getPanelContainer}
        />
      );
    }
    throw Error(
      `Unknown configuration provided: ${props.itemKind}-${props.type}`,
    );
  });
}

function SplitPropertyControlsGenerator<ItemKind extends ItemKinds>(props: {
  config: readonly PropertyPaneConfig[];
  generatorProps: PropertyControlsGeneratorProps<ItemKind>;
}) {
  const { mainConfig, eventHandlersConfig } = useMemo(() => {
    const mainConfig: PropertyPaneConfig[] = [];
    const eventHandlersConfig: PropertyPaneConfig[] = [];
    props.config.forEach((config) => {
      const isEventHandlerSection =
        isPropertyPaneCategoryConfig(config) &&
        config.key === PropsPanelCategory.EventHandlers;
      if (isEventHandlerSection) {
        eventHandlersConfig.push({
          ...config,
          showHeader: false,
          headerType: "Drawer",
          sectionStyle: { paddingBottom: "0px" },
        });
      } else {
        mainConfig.push(config);
      }
    });
    return { mainConfig, eventHandlersConfig };
  }, [props.config]);

  const mainContent = (
    <IdleRender>
      {generatePropertyControl(mainConfig, props.generatorProps)}
    </IdleRender>
  );

  // TODO (pageload): Remove this flag check once the feature is stable, this is the simplest
  // way to hide the entirety of events section for now.
  const enablePageLoadEvents = useFeatureFlag(
    Flag.ENABLE_CUSTOM_PAGE_LOAD_ACTIONS,
  );
  if (
    eventHandlersConfig.length === 0 ||
    (!enablePageLoadEvents && props.generatorProps.type === "PAGE_WIDGET")
  ) {
    return mainContent;
  }

  return (
    <PropertiesPanelSplitPane
      mainContent={mainContent}
      eventHandlerContent={generatePropertyControl(
        eventHandlersConfig,
        props.generatorProps,
        false,
      )}
    />
  );
}

function PropertyControlsGenerator<ItemKind extends ItemKinds>(
  props: PropertyControlsGeneratorProps<ItemKind>,
) {
  const useV2Props = useV2PropertyPaneEnabled(props.itemKind);

  const config = useV2Props
    ? getItemV2PropertyPaneConfig(props.type)
    : getItemPropertyPaneConfig(props.type);

  if (props.shouldSplitEventHandlers) {
    return (
      <SplitPropertyControlsGenerator config={config} generatorProps={props} />
    );
  }

  return <IdleRender>{generatePropertyControl(config, props)}</IdleRender>;
}

export default PropertyControlsGenerator;
