import { validatePageSchema } from "@superblocksteam/schemas";
import {
  Agent,
  ApplicationSignatureTreeSigned,
  BranchDto,
  CreateApplicationPagePayload,
  ENVIRONMENT_PRODUCTION,
  ENVIRONMENT_STAGING,
  IApiV3Dto,
  IPageV2Dto,
  PageDSL8,
  getDefaultPageLayout,
  verifyApplicationHashTree,
} from "@superblocksteam/shared";
import axios, { Canceler } from "axios";
import { get } from "lodash";
import { NavigateFunction } from "react-router";
import {
  all,
  call,
  cancelled,
  fork,
  getContext,
  put,
  select,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import { getEditorBasePath } from "hooks/store/useGetEditorPath";
import {
  restartEvaluation,
  startEvaluation,
} from "legacy/actions/evaluationActions";
import {
  createPageInit,
  createPageSuccess,
  deleteCurrentPage,
  deletePageInit,
  deletePageSuccess,
  duplicatePageInit,
  fetchPageSuccess,
  fetchPublishedPageSuccess,
  initCanvasLayout,
  pageLoadSuccess,
  setUrlData,
  switchCurrentPage,
  updateDataUrl,
} from "legacy/actions/pageActions";
import ApplicationApi from "legacy/api/ApplicationApi";
import {
  connectToISocketRPCServer,
  StdISocketRPCClient,
} from "legacy/api/ISocketRPC";
import LayoutApi from "legacy/api/LayoutApi";
import { CreatePageRequest } from "legacy/api/PageApi";
import { ERROR_CODES } from "legacy/constants/ApiConstants";
import { EXPECTED_PAGE_DSL_VERSION } from "legacy/constants/AppConstants";
import {
  ReduxAction,
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { EditorRoute } from "legacy/constants/routes";
import CanvasWidgetsNormalizer from "legacy/normalizers/CanvasWidgetsNormalizer";
import { sendPageRouteSuccessNotification } from "legacy/pages/Editor/Routes/Notifications";
import { UrlDataState } from "legacy/reducers/entityReducers/appReducer";
import {
  getAppMode,
  getAppProfilesInCurrentMode,
} from "legacy/selectors/applicationSelectors";
import {
  clearSelectorCache,
  getCurrentApplicationId,
  getCurrentBranch,
  getCurrentPageId,
  getPageList,
} from "legacy/selectors/editorSelectors";
import { getRoutes } from "legacy/selectors/routeSelectors";
import selectLastSuccessfulWrite from "legacy/selectors/successfulWriteSelector";
import { getIsOrgSwitched } from "legacy/selectors/usersSelectors";

import { getOpaAgents } from "legacy/utils/getOpaAgents";
import {
  getCurrentQueryParams,
  getSystemQueryParams,
} from "legacy/utils/queryParams";
import { getAllApisSaga } from "store/slices/apis";
import { selectControlFlowEnabledDynamic } from "store/slices/apisShared/selectors";
import { lock } from "store/slices/apisShared/sharedPersistApiLock";
import { getActiveAgents } from "store/slices/apisShared/utils";
import { getAllV2ApisSaga, resetApis } from "store/slices/apisV2";
import { Api } from "store/slices/apisV2/backend-types";
import {
  getCurrentCommitId,
  getEnvironment,
  selectCurrentApplicationSignatureTree,
} from "store/slices/application/selectors";
import { initPageScopedStorageVars } from "store/slices/application/stateVars/stateVarsActions";
import { getSupersetDatasourcesSaga } from "store/slices/datasources/sagas/getSupersetDatasources";
import {
  AllFlags,
  Flag,
  selectFlagById,
  selectFlags,
} from "store/slices/featureFlags";
import { duplicateApplicationError } from "store/slices/homepage/slice";
import { selectOnlyOrganization } from "store/slices/organizations";
import { orgIsOnPremise } from "store/slices/organizations/utils";
import { findAndUnMarshalProtoBlocks } from "utils/marshalProto";
import { getTargetNavigationURL } from "utils/navigation/applicationNavigation";
import { sendErrorUINotification } from "utils/notification";
import {
  AnySignedResource,
  getShouldSignAndVerify,
  updateApplicationSignature,
  verifyResources,
} from "utils/resource-signing";
import { APP_MODE, ModeMap } from "../reducers/types";
import { validateResponse } from "./ErrorSagas";
import { clearEvalCache } from "./EvaluationsSaga";
import { migrateServerDSL } from "./onLoadMigration/migrateServerDSL";
import type { AppTimer } from "store/slices/application/timers/TimerConstants";

const getCanvasWidgetsPayload = (
  pageDto: Omit<IPageV2Dto, "layouts"> & {
    layouts: Array<Omit<IPageV2Dto["layouts"][0], "dsl">>;
  },
  dsl: PageDSL8,
): Parameters<typeof initCanvasLayout>[0] => {
  const stateVarMap = dsl.stateVars.stateVarMap;
  const timerMap = dsl.timers.timerMap;
  const eventMap = dsl.events?.eventMap;
  // this mutates the dsl
  const normalizedResponse = CanvasWidgetsNormalizer.normalize(dsl);
  return {
    pageWidgetId: normalizedResponse.result,
    currentPageId: pageDto.id,
    widgets: normalizedResponse.entities.canvasWidgets,
    stateVarMap,
    timerMap: timerMap as Record<string, AppTimer>,
    eventMap,
    currentLayoutId: pageDto.layouts[0].id,
    currentApplicationId: pageDto.applicationId,
  };
};

export function* putFetchPageSagaResult(
  page: any,
  isPublish: boolean,
): Generator<any, any, any> {
  if (page?.layouts?.[0]?.dsl?.version !== EXPECTED_PAGE_DSL_VERSION) {
    // Force refresh if the page is not the latest page
    yield put({
      type: ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS_REQUEST,
      payload: {
        code: ERROR_CODES.REFRESH_REQUIRED,
      },
    });
    return;
  }
  try {
    // Make sure that the page is non-empty, which can happen if looking at an undeployed page
    if (validatePageSchema(page)) {
      // Clear any existing caches
      yield fork(clearEvalCache);
      clearSelectorCache();
      // Set url params
      yield fork(setDataUrl);
      // Get Canvas payload
      // This is used by the iframe to verify the version, which catches any issues with incompatibility
      // between wrapper & iframe
      yield put({
        type: ReduxActionTypes.SET_PAGE_DSL_VERSION,
        payload: {
          version: page.layouts[0].dsl.version,
        },
      });

      const flags: ReturnType<typeof selectFlags> = yield select(selectFlags);
      const dsl: PageDSL8 = yield call(migrateServerDSL, page.layouts[0].dsl);

      const canvasWidgetsPayload = getCanvasWidgetsPayload(page, dsl);
      yield put(initCanvasLayout(canvasWidgetsPayload));
      // set current page
      if (!flags[Flag.ENABLE_MULTIPAGE]) {
        // Not for side effects, but for setting currentPageId in reducers
        yield put(switchCurrentPage(page.id));
      }

      if (isPublish) {
        yield put(
          fetchPublishedPageSuccess({
            dsl,
            pageId: page.id,
            pageWidgetId: canvasWidgetsPayload.pageWidgetId,
          }),
        );
      } else {
        // dispatch fetch page success
        yield put(fetchPageSuccess());
        yield put({
          type: ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE,
          payload: page.updated,
        });
        const pageDsls = [
          {
            pageId: page.id,
            dsl,
          },
        ];
        yield put({
          type: ReduxActionTypes.FETCH_PAGE_DSLS_SUCCESS,
          payload: pageDsls,
        });
      }
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: isPublish
        ? ReduxActionErrorTypes.FETCH_PUBLISHED_PAGE_ERROR
        : ReduxActionErrorTypes.FETCH_PAGE_ERROR,
      payload: {
        error,
      },
    });
    // crash application with error page if can't fetch application
    const code = get(error, "code", ERROR_CODES.SERVER_ERROR);
    yield put({
      type: ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS_REQUEST,
      payload: {
        code,
      },
    });
  }
}

function* fetchPageSaga(
  action: ReduxAction<{ id: string }>,
): Generator<any, any, any> {
  const flags = yield select(selectFlags);
  if (!flags[Flag.ENABLE_MULTIPAGE]) {
    return;
  }

  const currentPageId = yield select(getCurrentPageId);
  const currentCommitId = yield select(getCurrentCommitId);
  const pageId = action.payload.id || currentPageId;
  const CancelToken = axios.CancelToken;
  let cancel: Canceler | undefined;
  try {
    const appMode: ReturnType<typeof getAppMode> = yield select(getAppMode);

    let page: IPageV2Dto;
    const v2Apis: IApiV3Dto[] = [];
    const v1Apis: IApiV3Dto[] = [];
    const branch: ReturnType<typeof getCurrentBranch> = yield select(
      getCurrentBranch,
    );
    const applicationId: ReturnType<typeof getCurrentApplicationId> =
      yield select(getCurrentApplicationId);
    let integrations = [];
    try {
      if (!applicationId) {
        throw new Error("Missing applicationId");
      }

      yield put({ type: ReduxActionTypes.FETCH_PAGE_INIT });
      const pageResponse: Awaited<ReturnType<typeof LayoutApi.getPage>> =
        yield call(
          LayoutApi.getPage,
          applicationId,
          pageId,
          ModeMap[appMode ?? APP_MODE.EDIT],
          branch?.name,
          currentCommitId,
          {
            cancelToken: new CancelToken(function executor(c) {
              cancel = c;
            }),
          },
        );
      page = pageResponse.data.page;
      integrations = pageResponse.data.integrations ?? [];
      const apis = pageResponse.data.apis;
      if (apis) {
        const controlFlowEnabled: boolean = yield select(
          selectControlFlowEnabledDynamic,
        );

        apis.forEach((api) => {
          findAndUnMarshalProtoBlocks(api.apiPb);
          if (!controlFlowEnabled) v1Apis.push(api);
          if (controlFlowEnabled && api.apiPb) v2Apis.push(api);
        });
      }
    } catch (e) {
      console.error(e);
      yield put({
        type: ReduxActionErrorTypes.FETCH_APPLICATION_ERROR,
        payload: {
          error: new Error("Failed to fetch page for application"),
        },
      });
      return;
    }

    // If using OPA, verify all of the application resources
    const organization: ReturnType<typeof selectOnlyOrganization> =
      yield select(selectOnlyOrganization);
    // The page must be verified BEFORE any mutations are made to it
    if (orgIsOnPremise(organization)) {
      const agents: Agent[] = yield call(getOpaAgents);
      const shouldVerify: boolean = yield call(getShouldSignAndVerify, agents);
      const isOrgSwitched: boolean = yield select(getIsOrgSwitched);
      if (shouldVerify && !isOrgSwitched) {
        if (agents.length === 0) {
          yield put({
            type: ReduxActionErrorTypes.FETCH_APPLICATION_ERROR,
            payload: {
              error: new Error("No OPA agents found"),
            },
          });
          yield put({
            type: ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS_REQUEST,
            payload: {
              code: ERROR_CODES.NO_AGENTS,
            },
          });
          return;
        }
        const hashTree: ReturnType<
          typeof selectCurrentApplicationSignatureTree
        > = yield select(selectCurrentApplicationSignatureTree);
        try {
          const resourcesToVerify: AnySignedResource[] = v2Apis.map((api) => ({
            api: api.apiPb as Api,
          }));
          if (!hashTree) {
            // There is no hash tree for the application because the application has not been signed before. This might still be ok,
            // if verification is disabled in the agent. So we send this as an empty signature to the send and let the agent decide
            // if this is acceptable.
            resourcesToVerify.push({ literal: { data: "" } });
          } else {
            const hashVerificationResult: Awaited<
              ReturnType<typeof verifyApplicationHashTree>
            > = yield call(verifyApplicationHashTree, {
              hashTree,
              pages: { [pageId]: page?.layouts?.[0]?.dsl },
            });
            if (!hashVerificationResult) {
              // The verification of the hash of the page failed. Despite that, we still don't want to show the verification error page
              // to the user yet, because the agent might have verification disabled. So we will send to the agent's verify endpoint an
              // invalid signature and if the agent accepts that, this means that verification is disabled.
              // Note that had verification of the hash of the page succeeded, we wouldn't have to do anything, because
              // the signature of the app has already been accepted by the agent at this point, as this happens during
              // application fetch.
              resourcesToVerify.push({
                literal: {
                  // We are only checking if invalid signatures are ok, so `data` here doesn't matter
                  data: "",
                  signature: {
                    // This is an invalid signature, it will only be accepted by the agent if signature verification is disabled in the agent.
                    // We could have also left the signature undefined, but it might be useful for debugging to use this invalid signature here.
                    algorithm: "ALGORITHM_UNSPECIFIED",
                    data: "hash-verification-failed",
                    publicKey: "",
                    keyId: "",
                  },
                },
              });
            }
          }
          yield call(verifyResources, {
            resources: resourcesToVerify,
            agents,
            organization,
            branchName: branch?.name,
          });
        } catch (e: any) {
          yield put({
            type: ReduxActionErrorTypes.FETCH_PAGE_ERROR,
            payload: {
              error: e,
            },
          });
          yield put({
            type: ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS_REQUEST,
            payload: {
              code: ERROR_CODES.VERIFY_RESOURCES_FAILED,
            },
          });
          return;
        }
      }
    }

    // Run all these effects immediately to improve flicking on page switch
    yield all([
      put(restartEvaluation()),
      put({ type: ReduxActionTypes.RESET_WIDGETS }),
      put(resetApis.create({})),
      call(getAllApisSaga.setStore, v1Apis),
      call(getAllV2ApisSaga.setStore, v2Apis),
      // in deployed mode, we get integrations from pages call because we do not need all integrations info.
      // in edit mode, we get integrations from application call because we need all inegrations.
      // integrations array will be non-empty in only one of the response, so we can set the redux state if integrations is non-empty.
      integrations.length > 0 &&
        call(getSupersetDatasourcesSaga.setStore, integrations),
      put(startEvaluation({ evaluationType: "ui" })),
      call(putFetchPageSagaResult, page, appMode === APP_MODE.PUBLISHED),
      put(initPageScopedStorageVars(applicationId, pageId)),
    ]);
    yield put(pageLoadSuccess());
    yield put(fetchPageSuccess());
  } finally {
    if (yield cancelled()) {
      cancel && cancel();
      yield put({ type: ReduxActionErrorTypes.FETCH_PAGE_ERROR });
    }
  }
}

function* createPageSaga(
  createPageAction: ReduxAction<CreatePageRequest & { switchToPage: boolean }>,
): Generator<any, any, any> {
  const unlock = yield call(lock);
  const request = createPageAction.payload;
  const applicationId = yield select(getCurrentApplicationId);
  const branch: BranchDto | undefined = yield select(getCurrentBranch);
  const lastSuccessfulWrite: Date = yield select(selectLastSuccessfulWrite);
  const organization: ReturnType<typeof selectOnlyOrganization> = yield select(
    selectOnlyOrganization,
  );
  const isOnPremise = orgIsOnPremise(organization);

  const featureFlags: AllFlags = yield select(selectFlags);
  let initialPageDSL: PageDSL8 = yield call(
    getDefaultPageLayout,
    8,
    featureFlags,
  );
  initialPageDSL = yield migrateServerDSL(initialPageDSL);

  initialPageDSL.widgetName = request.name;

  let agents: Agent[] = [];
  let isSigningRequired = false;
  if (isOnPremise) {
    const environment: ReturnType<typeof getEnvironment> = yield select(
      getEnvironment,
    );
    const profiles: ReturnType<typeof getAppProfilesInCurrentMode> =
      yield select(getAppProfilesInCurrentMode);
    const profile = profiles?.selected;
    const enableProfiles: boolean = yield select(
      selectFlagById,
      Flag.ENABLE_PROFILES,
    );
    try {
      agents = yield call(getActiveAgents, {
        organization,
        environment,
        enableProfiles,
        profile: profile,
      });
      isSigningRequired = yield call(getShouldSignAndVerify, agents);

      if (isSigningRequired && agents.length === 0) {
        yield put({
          type: ReduxActionErrorTypes.CREATE_PAGE_ERROR,
          payload: {
            error: new Error("No OPA agents found"),
          },
        });
        yield put({
          type: ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS_REQUEST,
          payload: {
            code: ERROR_CODES.NO_AGENTS,
          },
        });
        return;
      }
    } catch (e) {
      sendErrorUINotification({
        message: "Failed to connect to OPA agents",
      });
      return;
    }
  }

  let rpcClient: undefined | StdISocketRPCClient;
  try {
    if (isSigningRequired) {
      rpcClient = yield call(connectToISocketRPCServer, agents, organization);
    }
    const requestBody: CreateApplicationPagePayload = {
      dsl: initialPageDSL,
      routePath: request.routePath,
      testParams: request.routeTestParams,
      lastSuccessfulWrite: lastSuccessfulWrite.valueOf(),
    };
    const response: Awaited<ReturnType<typeof LayoutApi.createPage>> = rpcClient
      ? yield call(rpcClient.call.v2.application.page.create, {
          ...requestBody,
          applicationId,
          branchName: branch?.name,
          signingRequired: isSigningRequired,
        })
      : yield call(
          LayoutApi.createPage,
          applicationId,
          branch?.name,
          requestBody,
        );

    const isValidResponse = yield validateResponse(response);
    if (isValidResponse) {
      if (isOnPremise) {
        yield put({
          type: ReduxActionTypes.UPDATE_APPLICATION_HASH_TREE,
          payload: { tree: response.data.signature?.root },
        });
      }

      yield put({
        type: ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE,
        payload: response.data?.updated,
      });

      yield put(
        createPageSuccess({
          pageId: response.data.id,
          pageName: response.data.name,
          configuration: response.data.configuration,
        }),
      );
      yield put({
        type: ReduxActionTypes.FETCH_PAGE_DSL_SUCCESS,
        payload: {
          pageId: response.data.id,
          dsl: initialPageDSL,
        },
      });

      if (request.switchToPage) {
        yield put({
          type: ReduxActionTypes.EDITOR_VIEW_ROUTE,
          payload: {
            path: request.routePath,
            pageId: response.data.id,
            testParams: request.routeTestParams,
          },
        });
      }
      sendPageRouteSuccessNotification({
        pageName: request.name,
        isDynamic: request.routePath.includes(":"),
        showEdit: !request.switchToPage,
        viewRouteParams: {
          path: request.routePath,
          pageId: response.data.id,
          testParams: request.routeTestParams,
        },
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.CREATE_PAGE_ERROR,
      payload: {
        error,
      },
    });
  } finally {
    rpcClient?.close();
    unlock();
  }
}

function* deleteCurrentPageSaga() {
  const currentApplicationId: ReturnType<typeof getCurrentApplicationId> =
    yield select(getCurrentApplicationId);
  const currentPageId: ReturnType<typeof getCurrentPageId> = yield select(
    getCurrentPageId,
  );
  const currentBranch: ReturnType<typeof getCurrentBranch> = yield select(
    getCurrentBranch,
  );
  yield put(
    deletePageInit({
      applicationId: currentApplicationId!,
      pageId: currentPageId!,
      branch: currentBranch?.name,
    }),
  );
}

function* deletePageSaga(action: ReturnType<typeof deletePageInit>) {
  try {
    const request = action.payload;
    const applicationId: ReturnType<typeof getCurrentApplicationId> =
      yield select(getCurrentApplicationId);

    const unlock: Awaited<ReturnType<typeof lock>> = yield call(
      lock,
      request.pageId,
    );
    const lastSuccessfulWrite: number = yield select(selectLastSuccessfulWrite);

    try {
      const signature: ApplicationSignatureTreeSigned | undefined = yield call(
        updateApplicationSignature,
        [
          {
            type: "delete-page",
            pageId: action.payload.pageId,
          },
        ],
      );

      const response: Awaited<ReturnType<typeof ApplicationApi.deletePage>> =
        yield call(
          ApplicationApi.deletePage,
          request,
          signature,
          lastSuccessfulWrite,
        );
      const isValidResponse: ReturnType<typeof validateResponse> = yield call(
        validateResponse,
        response,
      );
      if (isValidResponse) {
        yield put(deletePageSuccess());
        yield put({
          type: ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE,
          payload: response.data.updated,
        });
      }
    } finally {
      unlock();
    }

    // Debounce on route update
    const routes: ReturnType<typeof getRoutes> = yield select(getRoutes);
    const removedRouteIds = Object.keys(routes).filter((routeId) => {
      const route = routes[routeId];
      if ("pageId" in route && route.pageId === action.payload.pageId) {
        return true;
      }
      return false;
    });
    yield put({
      type: ReduxActionTypes.DELETE_ROUTES,
      payload: removedRouteIds,
    });
    const currentPages: ReturnType<typeof getPageList> = yield select(
      getPageList,
    );
    const pages = currentPages.filter((page) => page.pageId !== request.pageId);
    yield put({
      type: ReduxActionTypes.FETCH_PAGE_LIST_SUCCESS,
      payload: { pages, applicationId },
    });

    const currentPageId: ReturnType<typeof getCurrentPageId> = yield select(
      getCurrentPageId,
    );
    const navigate: NavigateFunction = yield getContext("navigate");
    if (currentPageId === action.payload.pageId)
      navigate(
        getEditorBasePath(EditorRoute.EditApplication, {
          applicationId,
          currentRoute: "/",
        }),
      );
  } catch (error) {
    if (error instanceof Error) {
      yield put({
        type: ReduxActionErrorTypes.DELETE_PAGE_ERROR,
        payload: {
          error: { message: error.message, show: true },
          show: true,
        },
      });
      sendErrorUINotification({
        message: `Failed to delete page: ${error.message}`,
      });
    }
  }
}

function* duplicatePageSaga(
  createPageAction: ReduxAction<{
    pageId: string;
    newPageName: string;
    newPagePath: string;
  }>,
): Generator<any, any, any> {
  let rpcClient: undefined | StdISocketRPCClient;
  const request = createPageAction.payload;
  const applicationId = yield select(getCurrentApplicationId);
  const branch: BranchDto | undefined = yield select(getCurrentBranch);
  const lastSuccessfulWrite: Date = yield select(selectLastSuccessfulWrite);
  const organization: ReturnType<typeof selectOnlyOrganization> = yield select(
    selectOnlyOrganization,
  );
  const isOnPremise = orgIsOnPremise(organization);

  let agents: Agent[] = [];
  let isSigningRequired = false;
  if (isOnPremise) {
    const environment: ReturnType<typeof getEnvironment> = yield select(
      getEnvironment,
    );
    const profiles: ReturnType<typeof getAppProfilesInCurrentMode> =
      yield select(getAppProfilesInCurrentMode);
    const profile = profiles?.selected;
    const enableProfiles: boolean = yield select(
      selectFlagById,
      Flag.ENABLE_PROFILES,
    );
    try {
      agents = yield call(getActiveAgents, {
        organization,
        environment,
        enableProfiles,
        profile,
      });
      isSigningRequired = yield call(getShouldSignAndVerify, agents);

      if (isSigningRequired && agents.length === 0) {
        yield put(duplicateApplicationError());
        yield put({
          type: ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS_REQUEST,
          payload: {
            code: ERROR_CODES.NO_AGENTS,
          },
        });
        return;
      }
    } catch (e) {
      sendErrorUINotification({
        message: "Failed to connect to OPA agents",
      });
      return;
    }
  }

  try {
    rpcClient = yield call(connectToISocketRPCServer, agents, organization);
    if (!rpcClient) throw new Error("Failed to connect to ISocketRPC");

    const response: Awaited<
      ReturnType<typeof rpcClient.call.v2.application.page.clone>
    > = yield call(rpcClient.call.v2.application.page.clone, {
      applicationId,
      pageId: request.pageId,
      pageName: request.newPageName,
      routePath: request.newPagePath,
      branch: branch?.name,
      lastSuccessfulWrite: lastSuccessfulWrite.valueOf(),
      signingRequired: isSigningRequired,
    });

    if (isOnPremise) {
      yield put({
        type: ReduxActionTypes.UPDATE_APPLICATION_HASH_TREE,
        payload: { tree: response.data.signature?.root },
      });
    }

    yield put(
      createPageSuccess({
        pageId: response.data.page.id,
        pageName: response.data.page.name,
        configuration: response.data.configuration,
      }),
    );

    yield put({
      type: ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE,
      payload: response.data.updated,
    });

    yield put({
      type: ReduxActionTypes.EDITOR_VIEW_ROUTE,
      payload: {
        path: request.newPagePath,
      },
    });
  } catch (e) {
    sendErrorUINotification({
      message: `Failed to duplicate page. ${(e as any).message}`,
    });
    return;
  } finally {
    rpcClient?.close();
  }
}

function* setDataUrl(): Generator<any, any, any> {
  const urlData: UrlDataState = {
    fullPath: window.location.href,
    host: window.location.host,
    hostname: window.location.hostname,
    queryParams: getCurrentQueryParams(true),
    protocol: window.location.protocol,
    pathname: window.location.pathname,
    port: window.location.port,
    hash: window.location.hash,
  };
  const enableProfilesWithEnv: boolean = yield select(
    selectFlagById,
    Flag.ENABLE_PROFILES_WITH_ENV,
  );

  const mode = yield select(getAppMode);

  // TODO: remove this once we retire the feature flag
  if (enableProfilesWithEnv && !urlData.queryParams.environment) {
    // once profile is enabled, Global.URL.queryParams.environment will be undefined if not specified in url
    // for orgs that still use Global.URL.queryParams.environment in app, fill it based on current app mode
    urlData.queryParams.environment =
      mode === APP_MODE.PUBLISHED
        ? ENVIRONMENT_PRODUCTION
        : ENVIRONMENT_STAGING;
  }
  yield put(setUrlData(urlData));
}

function* performInternalNavigation(
  action: ReduxAction<{ url: string; newWindow?: boolean }>,
): Generator<any, any, any> {
  if (!action.payload.url) {
    yield put({
      type: ReduxActionErrorTypes.INTERNAL_NAVIGATION_ERROR,
      payload: {
        error: "No URL provided",
      },
    });
    return;
  }
  try {
    const navigate = yield getContext("navigate");
    const applicationId = yield select(getCurrentApplicationId);
    const appMode: APP_MODE | undefined = yield select(getAppMode);

    const navigationUrl = new URL(action.payload.url);
    const queryParams = {
      ...Object.fromEntries(navigationUrl.searchParams.entries()),
      ...getSystemQueryParams(),
    };

    const url = getTargetNavigationURL({
      targetPath: navigationUrl.pathname,
      applicationId: applicationId ?? "",
      appMode,
      params: queryParams,
    });

    if (action.payload.newWindow) {
      window.open(url.pathname + url.search, "_blank");
    } else {
      navigate(url.pathname + url.search);
      yield put(updateDataUrl());
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.INTERNAL_NAVIGATION_ERROR,
      payload: {
        error,
      },
    });
  }
}

export default function* pageSagas() {
  yield all([
    takeLeading(createPageInit.type, createPageSaga),
    takeLatest(deleteCurrentPage.type, deleteCurrentPageSaga),
    takeLatest(deletePageInit.type, deletePageSaga),
    takeLatest(switchCurrentPage.type, fetchPageSaga),
    takeLatest(duplicatePageInit.type, duplicatePageSaga),
    takeLatest(ReduxActionTypes.UPDATE_DATA_URL, setDataUrl),
    takeLatest(
      ReduxActionTypes.PERFORM_INTERNAL_NAVIGATION,
      performInternalNavigation,
    ),
  ]);
}
