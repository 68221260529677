import { all, call, select } from "redux-saga/effects";
import { ROOT } from "store/utils/types";
import { PLUGIN_INTEGRATIONS_MAP } from "utils/integrations";
import { createSaga } from "../../../utils/saga";
import { computeBlockBindings } from "../control-flow/block-bindings";
import { selectV2UserAccessibleScope } from "../control-flow/control-flow-scope-selectors";
import {
  selectControlFlowPluginInfo,
  getCachedControlFlowDSL,
} from "../control-flow/control-flow-selectors";
import { BlockType, VariablesControlBlock } from "../control-flow/types";
import { selectV2ApiById, selectV2ApiMeta } from "../selectors";
import slice, { ApiDtoWithPb } from "../slice";
import { getV2ApiId } from "../utils/getApiIdAndName";

type PayloadElement = {
  id: string;
  blockBindingsByBlock: Record<string, string[]>;
  variableBindingsByBlock: Record<string, string[]>;
  referencedVariableBlocksByBlock: Record<string, string[]>;
};

export const extractDataFromScope = (data: Record<string, any>) => {
  const newData: Record<string, any> = {};
  // Iterate over each key in the original data
  for (const key in data) {
    // Check if the data has a "!doc" property and a "value" property inside it
    if (data[key]["!doc"] && data[key]["!doc"]?.value != null) {
      newData[key] = data[key]["!doc"].value;
    }
  }

  return newData;
};

function isVariableBinding(
  binding: string,
  allVariableNames: Array<{
    varName: string;
    varBlock: string;
  }>,
) {
  const bindingFirstPiece = binding.split(".")[0];
  return allVariableNames.find(({ varName, varBlock }) => {
    return varName === bindingFirstPiece;
  });
}

function* getV2ApiBlockDepsInternal({
  apiIdsToAnalyze,
  forceEvaluation,
}: {
  apiIdsToAnalyze?: string[];
  forceEvaluation?: boolean;
}) {
  const apiMeta: ReturnType<typeof selectV2ApiMeta> = yield select(
    selectV2ApiMeta,
  );

  const apiIds = (apiIdsToAnalyze ?? Object.keys(apiMeta)).filter((id) => {
    if (id === ROOT) return false;
    const meta = apiMeta[id];
    const hasSomeTestDataShown = Object.values(
      meta.showTestDataForBlock ?? {},
    ).some((value) => value);
    return forceEvaluation || hasSomeTestDataShown;
  });

  if (!apiIds.length) {
    return [];
  }

  const apis: Array<ReturnType<typeof selectV2ApiById>> = yield all(
    apiIds.map((id) => select(selectV2ApiById, id)),
  );
  if (!apis.length) {
    return [] as Array<PayloadElement>;
  }

  const plugins = PLUGIN_INTEGRATIONS_MAP;
  const pluginInfo: ReturnType<typeof selectControlFlowPluginInfo> =
    yield select(selectControlFlowPluginInfo);

  const apiDependencies: Array<PayloadElement> = yield all(
    (apis.filter((api) => api?.apiPb?.blocks) as ApiDtoWithPb[]).map(function* (
      api,
    ) {
      const controlFlow = getCachedControlFlowDSL(api.apiPb, pluginInfo);
      const blockBindingsByBlock: Record<string, string[]> = {};
      const variableBindingsByBlock: Record<string, string[]> = {};
      const referencedVariableBlocksByBlock: Record<string, string[]> = {};
      const blockNames = Object.keys(controlFlow.blocks);
      const allVariableNames = Object.values(controlFlow.blocks).reduce(
        (accum, block) => {
          if (block.type === BlockType.VARIABLES) {
            return [
              ...accum,
              ...(block as VariablesControlBlock).config.variables.map(
                (variable) => ({ varName: variable.key, varBlock: block.name }),
              ),
            ];
          }
          return accum;
        },
        [] as Array<{ varName: string; varBlock: string }>,
      );
      for (let i = 0; i < blockNames.length; i++) {
        const blockName = blockNames[i];
        const accessibleScope: ReturnType<typeof selectV2UserAccessibleScope> =
          yield select(
            selectV2UserAccessibleScope,
            api.apiPb.metadata.id,
            blockName,
          );
        if (accessibleScope && accessibleScope.v2ComputedScope) {
          const { bindings }: Awaited<ReturnType<typeof computeBlockBindings>> =
            yield call(computeBlockBindings, controlFlow.blocks[blockName], {
              identifiers: new Set(
                Object.keys(accessibleScope.v2ComputedScope),
              ),
              dataTree: extractDataFromScope(accessibleScope.v2ComputedScope),
              plugins,
            });
          bindings.forEach((binding) => {
            const block = isVariableBinding(binding, allVariableNames);
            if (block) {
              if (binding.includes(".value")) {
                variableBindingsByBlock[blockName] = [
                  ...(variableBindingsByBlock[blockName] ?? []),
                  binding,
                ];
              }
              referencedVariableBlocksByBlock[blockName] = [
                ...(referencedVariableBlocksByBlock[blockName] ?? []),
                block.varBlock,
              ];
            } else {
              blockBindingsByBlock[blockName] = [
                ...(blockBindingsByBlock[blockName] ?? []),
                binding,
              ];
            }
          });
        }
      }

      return {
        id: getV2ApiId(api),
        variableBindingsByBlock,
        blockBindingsByBlock,
        referencedVariableBlocksByBlock,
      } as PayloadElement;
    }),
  );

  return apiDependencies as Array<PayloadElement>;
}

export const getV2ApiBlockDepsSaga = createSaga(
  getV2ApiBlockDepsInternal,
  "getV2ApiBlockDeps",
  {
    sliceName: slice.name,
    autoGenerateUniqueKey: true,
  },
);

slice.saga(getV2ApiBlockDepsSaga, {
  start(state, { payload, callId }) {
    (payload.apiIdsToAnalyze ?? Object.keys(state.meta)).forEach((id) => {
      if (id !== ROOT) {
        state.meta[id].extractingBlockBindings = true;
      }
    });
  },
  success(state, { payload, meta }) {
    if (!payload || !payload.length) return;
    payload.forEach(
      ({
        id,
        blockBindingsByBlock,
        variableBindingsByBlock,
        referencedVariableBlocksByBlock,
      }) => {
        if (state.meta[id]) {
          state.meta[id].extractingBlockBindings = false;
          state.meta[id] = state.meta[id] ?? {};
          state.meta[id].blockBindingsByBlock = blockBindingsByBlock ?? {};
          state.meta[id].variableBindingsByBlock =
            variableBindingsByBlock ?? {};
          state.meta[id].referencedVariableBlocksByBlock =
            referencedVariableBlocksByBlock ?? {};
        }
      },
    );
  },
  error(state, { meta }) {
    (meta.args.apiIdsToAnalyze ?? Object.keys(state.meta)).forEach((id) => {
      state.meta[id].variableBindingsByBlock = {};
      state.meta[id].blockBindingsByBlock = {};
    });
  },
});
