import {
  SupersetIntegrationDto,
  IntegrationKind,
  PLUGIN_ID_TO_PROTO_CLASS,
} from "@superblocksteam/shared";
import { call } from "redux-saga/effects";
import { EntitiesStateWithMeta, ROOT } from "store/utils/types";
import { createSaga } from "../../../utils/saga";
import { getSupersetDatasources } from "../client";
import slice, { DatasourceDtoViews, DatasourceMeta } from "../slice";

interface GetDatasourcesPayload {
  organizationId: string;
  kind?: IntegrationKind;
}

function* getSupersetDatasourcesInternal({
  organizationId,
  kind,
}: GetDatasourcesPayload) {
  const fullDtos: SupersetIntegrationDto[] = yield call(
    getSupersetDatasources,
    organizationId,
    kind,
  );
  const integrations = [];
  for (const integration of fullDtos) {
    if (PLUGIN_ID_TO_PROTO_CLASS[integration.pluginId]) {
      const protoConfigs = integration.configurations.map((config) => {
        return {
          ...config,
          configuration: PLUGIN_ID_TO_PROTO_CLASS[
            integration.pluginId
          ].fromJson(config.configuration, { ignoreUnknownFields: true }),
        };
      });
      integrations.push({ ...integration, configurations: protoConfigs });
    } else {
      integrations.push(integration);
    }
  }
  return integrations;
}

export const getSupersetDatasourcesSaga = createSaga(
  getSupersetDatasourcesInternal,
  "getSupersetDatasourcesSaga",
  {
    sliceName: "datasources",
  },
);

export const getAllDatasourcesSaga = createSaga(
  function* getAllDatasources({ organizationId }) {
    const pluginIntegrations = yield call(getSupersetDatasourcesInternal, {
      organizationId,
      kind: IntegrationKind.PLUGIN,
    });
    const secretIntegrations = yield call(getSupersetDatasourcesInternal, {
      organizationId,
      kind: IntegrationKind.SECRET,
    });
    return [...pluginIntegrations, ...secretIntegrations];
  },
  "getAllDatasourcesSaga",
  {
    sliceName: "datasources",
  },
);

slice.saga(getAllDatasourcesSaga, {
  start(state, { payload }) {
    state.loading[ROOT] = true;
    state.meta[ROOT] = { organizationId: payload.organizationId };
    delete state.errors[ROOT];
  },
  success(state, { payload, meta }) {
    modifyState(state, payload, meta.args.kind);
    delete state.loading[ROOT];
  },
  error(state, { payload }) {
    state.errors[ROOT] = { error: payload };
    delete state.loading[ROOT];
  },
  store(state, { payload }) {
    modifyState(state, payload);
  },
});

slice.saga(getSupersetDatasourcesSaga, {
  start(state, { payload }) {
    state.loading[ROOT] = true;
    state.meta[ROOT] = { organizationId: payload.organizationId };
    delete state.errors[ROOT];
  },
  success(state, { payload, meta }) {
    modifyState(state, payload, meta.args.kind);
    delete state.loading[ROOT];
  },
  error(state, { payload }) {
    state.errors[ROOT] = { error: payload };
    delete state.loading[ROOT];
  },
  store(state, { payload }) {
    modifyState(state, payload);
  },
});

const modifyState = (
  state: EntitiesStateWithMeta<DatasourceDtoViews, DatasourceMeta>,
  payload: SupersetIntegrationDto[],
  kind?: IntegrationKind,
): void => {
  const notSeen = new Set(
    Object.keys(state.entities).filter((entKey) =>
      kind ? state.entities[entKey].kind === kind : true,
    ),
  );
  state.entities = payload.reduce((result, datasource) => {
    notSeen.delete(datasource.id);
    result[datasource.id] = Object.assign(result[datasource.id] ?? {}, {
      integration: datasource,
      kind: state.entities?.[datasource.id]?.kind ?? datasource.kind,
    });
    return result;
  }, state.entities as Record<string, DatasourceDtoViews>);
  notSeen.forEach((id) => (state.entities[id].integration = undefined));
};
