import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { InputDataType } from "@superblocksteam/shared";
import { Button, Input, InputNumber, Typography } from "antd";
import _ from "lodash";
import React, {
  useContext,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import styled from "styled-components";
import { ReactComponent as CheckedIcon } from "assets/icons/common/checkmark.svg";
import { ReactComponent as CopyIcon } from "assets/icons/common/copy.svg";
import envs from "env";
import { useCopyToClipboard } from "hooks/ui";
import { colors } from "../../../../styles/colors";
import { DynamicFormItemProps, StyledReactMarkdown } from "../DynamicFormItem";
import { FormContext } from "../FormContext";
import { FormLabel } from "../FormLabel";
import { FormSubheading } from "../FormSubheading";
import { FormTooltip } from "../FormTooltip";
import { ruleParser } from "../utils";
import type { ChangeEvent } from "react";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.45);
  &:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  svg.stroke-outline path {
    stroke: rgba(0, 0, 0, 0.45);
  }
  &:hover svg.stroke-outline path {
    stroke: rgba(0, 0, 0, 0.85);
  }
  :not(:last-child) {
    margin-right: 3px;
  }
  svg.check-icon path {
    stroke: ${(props) => props.theme.colors.ACCENT_GREEN_600} !important;
  }
  cursor: pointer !important;
`;

const IconButton = styled(Button)`
  padding: 0px;
  border: none;
  outline: none;
  height: 20px;
`;

const StyledLabel = styled.label<{ singleLine?: boolean }>`
  display: ${({ singleLine }) => (singleLine ? "flex" : "block")};
  & > span {
    margin-right: 12px;
    padding-top: ${({ singleLine }) => (singleLine ? "6px" : "0px")};
    min-width: ${({ singleLine }) => (singleLine ? "130px" : "0px")};
  }
  .input-wrapper {
    flex: 1;
  }
  .ant-input {
    font-size: ${(props) => props.theme.text.sizes.default};
  }
`;

interface InputProps {
  dataType?: InputDataType;
  singleLine?: boolean;
  minNumber?: number;
  hidden?: boolean /* hidden input items, that should be still validated */;
  enableCopy?: boolean;
  initialValueFromEnv?: string;
  onPasswordVisibilityChange?: (fieldName: string, visible: boolean) => void;
}

type Props = InputProps & DynamicFormItemProps;

const DynamicFormInputText = React.memo(
  ({
    path,
    label,
    placeholder,
    initialValue,
    dataType,
    rules,
    singleLine,
    minNumber,
    subHeading,
    enableCopy,
    initialValueFromEnv,
    onPasswordVisibilityChange,
    ...otherProps
  }: Props) => {
    const context = useContext(FormContext);
    const [validationMessage, setValidationMessage] = useState("");

    const [value, setValue] = useState<string | number>();

    useEffect(() => {
      if (rules) {
        ruleParser(path, rules, context.registerValidation, (value) =>
          setValidationMessage(value),
        );
      }
      return () => {
        context.unregisterValidation(path);
      };
    }, [path, rules, context.registerValidation, context]);

    const changeHandler = useCallback(
      (e: ChangeEvent<HTMLInputElement> | string | number | null) => {
        switch (dataType) {
          case InputDataType.NUMBER: {
            const val = e;
            context.onChange(path, val, { debounced: true });
            setValue(val as number);
            break;
          }
          case InputDataType.PASSWORD:
          default: {
            const val =
              typeof e === "string" || typeof e === "number"
                ? e.toString()
                : e?.target?.value ?? "";
            context.onChange(path, val, { debounced: true });
            setValue(val as string);
            break;
          }
        }
      },
      [context, dataType, path],
    );

    const blurHandler = useCallback(() => {
      if (context.getValue(path) !== value) {
        context.onChange(path, value);
      }
    }, [context, path, value]);

    useEffect(() => {
      const existingValue = context.getValue(path);
      if (typeof initialValue !== "undefined" && !existingValue) {
        const evaluatedValue = evaluateTemplateIfNeeded(initialValue, dataType);
        context.onChange(path, evaluatedValue);
        setValue(evaluatedValue);
      } else {
        setValue(evaluateTemplateIfNeeded(existingValue, dataType));
      }

      if (initialValueFromEnv) {
        try {
          setValue(envs.get(initialValueFromEnv));
        } catch (e) {
          // env variable doesn't exist in this environment, don't override initialValue
        }
      }
    }, [path, initialValueFromEnv, initialValue, context, dataType]);

    const [copiedApiKey, setCopiedApiKey] = useCopyToClipboard(1500);

    const onCopyKey = useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();
        setCopiedApiKey(value as string);
      },
      [value, setCopiedApiKey],
    );

    const stopPropagation = useCallback((e: React.MouseEvent) => {
      e.stopPropagation();
    }, []);

    const suffix = useMemo(
      () =>
        enableCopy ? (
          !copiedApiKey ? (
            <IconWrapper onClick={onCopyKey}>
              <CopyIcon />
            </IconWrapper>
          ) : (
            <IconWrapper onClick={stopPropagation}>
              <CheckedIcon className="check-icon" />
            </IconWrapper>
          )
        ) : undefined,
      [copiedApiKey, enableCopy, onCopyKey, stopPropagation],
    );

    const renderVisibilityIcon = useCallback(
      (visible: boolean) => {
        const onClick = () => {
          if (onPasswordVisibilityChange) {
            onPasswordVisibilityChange(path, !visible);
          }
        };
        return (
          <IconButton onClickCapture={onClick} type="ghost">
            {visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          </IconButton>
        );
      },
      [path, onPasswordVisibilityChange],
    );

    const input = useMemo(() => {
      switch (dataType) {
        case InputDataType.PASSWORD:
          return (
            <Input.Password
              id={path}
              onChange={changeHandler}
              onBlur={blurHandler}
              value={value}
              placeholder={placeholder}
              suffix={suffix}
              iconRender={renderVisibilityIcon}
              action={"click"}
              {...otherProps}
            />
          );
        case InputDataType.NUMBER:
          return (
            <InputNumber
              id={path}
              onChange={changeHandler}
              onBlur={blurHandler}
              value={value}
              placeholder={placeholder}
              style={{ width: "100%" }}
              min={minNumber}
              {...otherProps}
            />
          );
        default:
          return (
            <Input
              id={path}
              onChange={changeHandler}
              onBlur={blurHandler}
              value={value}
              placeholder={placeholder}
              suffix={suffix}
              {...otherProps}
            />
          );
      }
    }, [
      dataType,
      path,
      changeHandler,
      blurHandler,
      value,
      placeholder,
      suffix,
      otherProps,
      minNumber,
      renderVisibilityIcon,
    ]);

    if (otherProps.hidden) {
      return <></>;
    } else {
      return (
        <StyledLabel
          htmlFor={path}
          singleLine={singleLine}
          hidden={otherProps.hidden}
        >
          <span>
            <FormLabel hidden={otherProps.hidden}>{label}</FormLabel>
            <FormTooltip tooltip={otherProps.tooltip} />
          </span>
          <div className="input-wrapper">
            {input}
            <Typography.Text type="danger">{validationMessage}</Typography.Text>
          </div>
          {subHeading && <FormSubheading text={subHeading} />}
          <div style={{ color: colors.GREY_400 }}>
            <StyledReactMarkdown linkTarget="_blank">
              {otherProps.subtitle ?? ""}
            </StyledReactMarkdown>
          </div>
        </StyledLabel>
      );
    }
  },
);

function evaluateTemplateIfNeeded(
  template: unknown,
  dataType: InputDataType | undefined,
): string | number | undefined {
  if (dataType) {
    switch (dataType) {
      case InputDataType.PASSWORD:
        return template as string;
      case InputDataType.NUMBER:
        return template as number;
    }
  }
  const compiledTemplate = _.template(template as string);
  const evaluatedValue = compiledTemplate({
    origin: window.location.origin,
  });
  return evaluatedValue;
}

DynamicFormInputText.displayName = "DynamicFormInputText";
export default DynamicFormInputText;
