import { KeyHandler } from "hotkeys-js";
import { Options, useHotkeys as useHotkeysOriginal } from "react-hotkeys-hook";
import { useSelector } from "react-redux";
import { getEditorReadOnly } from "legacy/selectors/editorSelectors";
import { sendErrorUINotification } from "utils/notification";

export function useEditorHotkeys<T extends Element>(
  keys: string,
  callback: KeyHandler,
  options?: any[] | Options,
  deps?: any[],
): React.MutableRefObject<T | null> | undefined {
  const editorReadOnly = useSelector(getEditorReadOnly);
  let overrideCallback = callback;
  if (editorReadOnly) {
    let errorMessage = "";
    if (
      keys.includes("ctrl+c") ||
      keys.includes("command+c") ||
      keys.includes("cmd+c")
    ) {
      errorMessage = "Copy is disabled on protected branch";
    }
    if (
      keys.includes("ctrl+v") ||
      keys.includes("command+v") ||
      keys.includes("cmd+v")
    ) {
      errorMessage = "Paste is disabled on protected branch";
    }
    if (keys.includes("delete") || keys.includes("backspace")) {
      errorMessage = "Delete is disabled on protected branch";
    }
    if (errorMessage) {
      overrideCallback = () =>
        sendErrorUINotification({
          message: errorMessage,
        });
    }
  }
  if (options instanceof Array) {
    deps = options;
    options = undefined;
  }

  return useHotkeysOriginal(keys, overrideCallback, options, [
    ...(deps ?? []),
    editorReadOnly,
  ]);
}
