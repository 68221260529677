import { TASKS } from "@superblocksteam/shared";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useSaga } from "hooks/store";
import { getTaskSchema, tasks } from "pages/components/Checklist/tasks";
import { selectUserChecklist, updateTaskSaga } from "store/slices/user";
import { isOnEmbedRoute } from "utils/embed/messages";
import { UIEvent } from "utils/event";
import logger from "utils/logger";

export function useMarkTaskComplete(taskId: TASKS) {
  const [updateTask] = useSaga(updateTaskSaga);
  const checklist = useSelector(selectUserChecklist);
  const totalSteps = tasks.filter((task) => !task.hidden).length;
  const stepsComplete = Math.min(
    totalSteps,
    (checklist ?? [])
      .filter((t) => t.completed)
      .filter((t) => !getTaskSchema(t.id)?.hidden ?? true).length,
  );

  const markTaskComplete = useCallback(async () => {
    if (isOnEmbedRoute()) return;
    if (!checklist.find((task) => task.id === taskId)?.completed) {
      await updateTask({ id: taskId, completed: new Date() });
      logger.event(UIEvent.COMPLETE_CHECKLIST, {
        taskId,
        stepsComplete: stepsComplete + 1,
        totalSteps,
      });
    }
  }, [checklist, stepsComplete, taskId, totalSteps, updateTask]);

  return [markTaskComplete];
}

export function useMarkTaskRead(taskId?: TASKS) {
  const [updateTask] = useSaga(updateTaskSaga);
  const checklist = useSelector(selectUserChecklist);

  const markTaskRead = useCallback(() => {
    if (taskId && !checklist.find((task) => task.id === taskId)?.viewed) {
      updateTask({ id: taskId, viewed: new Date() });
    }
  }, [checklist, taskId, updateTask]);

  return [markTaskRead];
}
