import { Classes } from "@blueprintjs/core";
import { Tooltip } from "antd";
import { noop } from "lodash";
import React, { useMemo, forwardRef } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import EditableText, { EditInteractionKind } from "components/ui/EditableText";
import { removeSpecialChars } from "legacy/utils/helpers";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import type { AppState } from "store/types";

const searchHighlightSpanClassName = "token";
const searchTokenizationDelimiter = "!!";

const Wrapper = styled.div<{ isEditing?: boolean; codeTypeface?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 4px;
  line-height: 13px;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 8px;

  ${({ codeTypeface }) =>
    codeTypeface &&
    css`
      font-family: var(--font-monospace);
      font-size: 11px;
      font-weight: 500;
    `}

  ${({ isEditing, theme }) =>
    isEditing &&
    css`
      border: 1px solid ${theme.colors.ACCENT_BLUE_500};
      padding: 0px;
      border-radius: 4px;
    `}

  & span.token {
    background: ${colors.ACCENT_BLUE_500_24};
    padding: 3px 0;
  }

  &&& div.${Classes.EDITABLE_TEXT} {
    border: none;
  }

  &&& div.${Classes.EDITABLE_TEXT_INPUT} {
  }
  .entity-name-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 16px;
  }
`;

const InputClassName = styleAsClass`
  & {
    padding-left: 6px;
    padding-right: 6px;
  }
`;

const replace = (
  str: string,
  delimiter: string,
  className = "token",
  keyIndex = 1,
): JSX.Element[] => {
  const occurrenceIndex = str.indexOf(delimiter);
  if (occurrenceIndex === -1)
    return [<span key={`notokenize-${keyIndex}`}>{str}</span>];
  const sliced = str.slice(occurrenceIndex + delimiter.length);
  const nextOccurrenceIndex = sliced.indexOf(delimiter);
  const rest = str.slice(
    occurrenceIndex + delimiter.length + nextOccurrenceIndex + delimiter.length,
  );
  const token = str.slice(
    occurrenceIndex + delimiter.length,
    occurrenceIndex + delimiter.length + nextOccurrenceIndex,
  );
  const final = [
    <span key={`tokenize-${keyIndex}`}>{str.slice(0, occurrenceIndex)}</span>,
    <span key={`tokenize-${keyIndex}-token`} className={className}>
      {token}
    </span>,
  ].concat(replace(rest, delimiter, className, keyIndex + 1));
  return final;
};

interface EntityNameProps {
  name: string;
  onChange?: (name: string) => void;
  handleNameChange: (newName: string) => void;
  isInvalidName?: (value: string) => string | boolean;
  enterEditMode: () => void;
  exitEditMode: () => void;
  entityId: string;
  searchKeyword?: string;
  nameTransformFn?: (input: string, limit?: number) => string;
  disableEdit?: boolean;
  isEditing?: boolean;
  codeTypeface?: boolean;
  showTooltip?: boolean;
  innerTooltip?: React.ReactNode;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
}

const EntityName = forwardRef<HTMLDivElement, EntityNameProps>(
  (
    {
      entityId,
      name,
      nameTransformFn,
      searchKeyword,
      handleNameChange,
      disableEdit = false,
      isInvalidName,
      isEditing,
      enterEditMode,
      exitEditMode,
      codeTypeface,
      showTooltip,
      innerTooltip,
      prefix,
      suffix,
    },
    ref,
  ) => {
    const isUpdating = useSelector(
      (state: AppState) => state.legacy.ui.explorer.updatingEntity === entityId,
    );

    const searchHighlightedName = useMemo(() => {
      if (searchKeyword) {
        let regex: string | RegExp = searchKeyword;
        try {
          regex = new RegExp(searchKeyword, "gi");
        } catch (err) {
          // If there's an error, fallback to the string.
        }
        const delimited = name.replace(regex, function (str) {
          return (
            searchTokenizationDelimiter + str + searchTokenizationDelimiter
          );
        });

        const final = replace(
          delimited,
          searchTokenizationDelimiter,
          searchHighlightSpanClassName,
        );
        return final;
      }
      return name;
    }, [name, searchKeyword]);

    if (!isEditing && !isUpdating) {
      const contents = (
        <Wrapper
          ref={ref}
          onDoubleClick={disableEdit ? noop : enterEditMode}
          codeTypeface={codeTypeface}
        >
          {prefix && <div className="entity-name-prefix"> {prefix} </div>}
          <div className="entity-name-text">
            <Tooltip title={innerTooltip}>{searchHighlightedName}</Tooltip>
          </div>
          {suffix && <div className="entity-name-suffix"> {suffix} </div>}
        </Wrapper>
      );
      return showTooltip ? (
        <Tooltip title={showTooltip ? searchHighlightedName : ""}>
          {contents}
        </Tooltip>
      ) : (
        contents
      );
    }
    return (
      <Wrapper ref={ref} isEditing={isEditing} codeTypeface={codeTypeface}>
        <EditableText
          type="text"
          forceDefault={!isUpdating}
          isUpdating={isUpdating}
          defaultValue={name}
          placeholder="Name"
          onTextChanged={handleNameChange}
          isInvalid={isInvalidName}
          valueTransform={nameTransformFn || removeSpecialChars}
          isEditingDefault={!isUpdating}
          editInteractionKind={EditInteractionKind.DOUBLE}
          onBlur={exitEditMode}
          disabled={disableEdit}
          inputClassName={InputClassName}
        />
      </Wrapper>
    );
  },
);

EntityName.displayName = "EntityName";

export default EntityName;
