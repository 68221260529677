import { set } from "dot-object";
import { useMemo } from "react";
import { Dispatch } from "redux";
import {
  deleteEmbedProp,
  updateEmbedProp,
} from "legacy/actions/embeddingActions";

import { EmbedProperty } from "legacy/constants/EmbeddingConstants";
import { getEmbedPropById } from "legacy/selectors/sagaSelectors";
import { mergeUpdatesWithBindingsOrTriggers } from "legacy/utils/DynamicBindingUtils";
import { useAppSelector } from "store/helpers";
import { fastClone } from "utils/clone";
import { NOOP } from "utils/function";
import { EMBED_PROP_PROPERTIES } from "../../Explorer/Embedding/EmbeddingProperties";
import { ItemKinds, ItemTypeNonWidget } from "../ItemKindConstants";
import type { ItemKindAccessor } from "../ItemKinds";

export const EmbedPropAccessor: ItemKindAccessor<ItemKinds.EMBED_PROP> = {
  useItemName: (itemProperties) =>
    useMemo(() => {
      return {
        name: itemProperties.name,
        editable: true,
        requiresValidation: true,
      };
    }, [itemProperties.name]),
  itemType: (itemProperties) => ItemTypeNonWidget.EMBED_PROP,
  useItemProperties: (itemId: string) => {
    return useAppSelector((state) => getEmbedPropById(state, itemId));
  },
  updateItemProperties: (
    dispatch: Dispatch,
    properties: EmbedProperty,
    updates: Record<string, unknown>,
  ) => {
    const changes: EmbedProperty = fastClone(properties);

    const updatesWithBindings = mergeUpdatesWithBindingsOrTriggers(
      properties,
      EMBED_PROP_PROPERTIES,
      updates,
    );
    for (const [key, value] of Object.entries(updatesWithBindings)) {
      set(key, value, changes);
    }
    dispatch(updateEmbedProp({ [properties.id]: changes }));
  },
  deleteItemProperties: NOOP,
  deleteItem: (dispatch, embedPropId) => {
    dispatch(deleteEmbedProp(embedPropId));
  },
};
