import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

export const NewTagClass = styleAsClass`
  color: ${colors.WHITE};
  padding: 2px 8px;
  border-radius: 14px;
  background: ${colors.ACCENT_BLUE_NEW_DARKER};
  font-size: 12px;
  height: 18px;
  display: flex;
  align-items: center;
`;
