import { AccessMode } from "@superblocksteam/shared";
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import TokenProvider from "auth/token";
import { getAccessMode } from "legacy/selectors/usersSelectors";
import { GENERATE_TOKEN } from "pages/routes";
import { HttpMethod, callServer } from "store/utils/client";

export const isExpired = (jwt: string) => {
  try {
    const decoded = JSON.parse(atob(jwt.split(".")[1]));
    return decoded.exp < Date.now() / 1000;
  } catch (e) {
    return false;
  }
};

function useJwt(): [string | undefined, () => void, boolean] {
  const [loading, setLoading] = useState(true);
  const storedToken = TokenProvider.getToken();
  const accessMode = useSelector(getAccessMode);

  const fetchJwt = useCallback(async () => {
    if (accessMode === AccessMode.EXTERNAL_USER) {
      // already have the JWT
      return;
    } else if (accessMode === AccessMode.VISITOR) {
      // don't need a JWT
      return;
    }
    try {
      const response = await callServer<{ access_token: string }>(
        {
          method: HttpMethod.Post,
          url: GENERATE_TOKEN,
        },
        {
          notifyOnError: false,
          shouldCrashApp: false,
        },
      );
      if (response.access_token) {
        TokenProvider.setToken(response.access_token);
      }
    } catch (error) {
      console.error("Could not fetch JWT");
    } finally {
      setLoading(false);
    }
  }, [accessMode]);

  useEffect(() => {
    if (
      accessMode === AccessMode.EXTERNAL_USER ||
      accessMode === AccessMode.VISITOR
    ) {
      // in this case the jwt is passed to the embed or the user is a visitor, we should never fetch it
      setLoading(false);
      return;
    } else if (storedToken && !isExpired(storedToken)) {
      setLoading(false);
    } else {
      setLoading(true);
      fetchJwt();
    }
  }, [fetchJwt, storedToken, accessMode]);

  return [storedToken, fetchJwt, loading];
}

export default useJwt;
