import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCallbackAsRef, useFeatureFlag } from "hooks/ui";
import { useEditorHotkeys } from "hooks/ui/useEditorHotkeys";
import { groupSelectedWidgets } from "legacy/actions/widgetActions";
import { getSelectedWidgets } from "legacy/selectors/sagaSelectors";
import { Flag } from "store/slices/featureFlags";

export const useGroupWidgets = (params: { disabled: boolean }) => {
  const dispatch = useDispatch();
  const isDragging = params.disabled;
  const enableGrouping = useFeatureFlag(Flag.LAYOUTS_ENABLE_GROUPING);
  const selectedWidgets = useSelector(getSelectedWidgets);
  const handleGroupRequest = useCallback(
    (ev: KeyboardEvent) => {
      if (!enableGrouping) return;
      if (selectedWidgets.length === 0) return;
      if (!ev.repeat) {
        // we don't want to continuously group if the key is held down
        dispatch(groupSelectedWidgets());
      }
      ev.preventDefault(); // allow the browser to handle how it wants if nothing is selected
    },
    [dispatch, selectedWidgets, enableGrouping],
  );
  const handleGroupRequestRef = useCallbackAsRef(handleGroupRequest);

  useEditorHotkeys("ctrl+g, command+g", handleGroupRequestRef, {
    filterPreventDefault: false,
    enabled: !isDragging,
  });
};
