import { ApplicationScope, PageRoute, RouteDef } from "@superblocksteam/shared";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { getDataTree } from "legacy/selectors/dataTreeSelectors";
import { getPageList } from "legacy/selectors/editorSelectors";
import { getCanvasWidgets } from "legacy/selectors/entitiesSelector";
import { getRoutesList } from "legacy/selectors/routeSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { useAppSelector } from "store/helpers";
import {
  transformComponentForAi,
  transformItemForAi,
} from "store/slices/ai/component-transforms";
import { getCurrentApplication } from "store/slices/application/selectors";
import { selectFlags } from "store/slices/featureFlags";

const getEntityNameFromType = (entityType: string) => {
  switch (entityType) {
    case "ACTION":
      return "apis";
    case "WIDGET":
      return "components";
    case "STATE_VAR":
      return "stateVariables";
    case "CUSTOM_EVENT":
      return "events";
    default:
      return entityType.toLowerCase() + "s";
  }
};

export const useAiTransformContext = () => {
  const featureFlags = useSelector(selectFlags);
  const theme = useSelector(selectGeneratedTheme);
  return useMemo(() => {
    return { featureFlags, theme };
  }, [featureFlags, theme]);
};

const filterContext = (
  context: Record<string, any>,
  referencedValuesSet: Set<string>,
  idKey: string = "name",
) => {
  const filteredContext = { ...context };
  for (const key in filteredContext) {
    // filter out the values
    const values = filteredContext[key].filter((v: any) =>
      referencedValuesSet.has(v[idKey]),
    );
    filteredContext[key] = values;
  }
  return filteredContext;
};

export const useAiContext = () => {
  const pages = useSelector(getPageList);
  const routes = useSelector(getRoutesList);
  const appSettings = useAppSelector(getCurrentApplication)?.settings;
  const theme = appSettings?.theme;

  const pageContext = useMemo(() => {
    const isPageRoute = (route: RouteDef): route is PageRoute =>
      "pageId" in route;
    return pages.map((page) => ({
      name: page.pageName,
      routes: routes
        .filter((route) => isPageRoute(route) && route.pageId === page.pageId)
        .map((route) => route.path),
    }));
  }, [pages, routes]);

  const dataTree = useAppSelector(getDataTree);
  const canvasWidgets = useAppSelector(getCanvasWidgets);
  const transformContext = useAiTransformContext();

  const profiles = useMemo(() => {
    return (dataTree.GLOBAL.Global?.profiles?.available ?? []).map(
      (profile) => ({
        name: profile.displayName,
        value: profile,
      }),
    );
  }, [dataTree]);

  const groups = useMemo(() => {
    return dataTree.GLOBAL.Global?.groups ?? [];
  }, [dataTree]);

  const fullContext = useMemo(() => {
    const c: Record<
      string,
      {
        name?: string;
        scope?: string;
        type?: string;
        resettableProperties?: string[];
        settableProperties?: string[];
        value?: Record<string, any>;
      }[]
    > = {};
    for (const scope in dataTree) {
      if (scope === "GLOBAL") {
        // Skip global scope for now
        continue;
      }
      const items = (dataTree as Record<string, any>)[scope];
      for (const i of Object.values(items)) {
        const item = i as any;
        const entityType = getEntityNameFromType(item.ENTITY_TYPE) as string;
        const name = (item.name ?? item.widgetName) as string;
        const widgetType = item.type as string;

        const excludedWidgetTypes = [
          "CANVAS_WIDGET",
          "EMBEDDING",
          "PAGE_WIDGET",
          "SECTION_WIDGET",
        ];
        if (excludedWidgetTypes.includes(item.type as string)) {
          continue;
        }
        if (item.ENTITY_TYPE === "EMBEDDING") {
          continue;
        }

        if (!c[entityType]) {
          c[entityType] = [];
        }
        if (item.ENTITY_TYPE === "WIDGET") {
          const widgetItem = {
            ...item,
            ...canvasWidgets[item.widgetId],
          };
          const transformed = transformComponentForAi(
            widgetItem,
            transformContext,
          );
          c[entityType].push({
            name,
            type: widgetType.toLowerCase().replace("_widget", ""),
            value: transformed,
          });
        } else {
          c[entityType].push(transformItemForAi(item, transformContext));
        }
      }
    }

    c.pages = pageContext;
    return c;
  }, [dataTree, canvasWidgets, pageContext, transformContext]);

  const autocompleteContext = useMemo(() => {
    return {
      ...fullContext,
      profiles,
      groups,
      user: [
        {
          name: "User",
        },
      ],
    };
  }, [fullContext, profiles, groups]);

  const createPromptContext = useCallback(
    (referencedValuesSet: Set<string>) => {
      const filteredGroups = filterContext(
        {
          groups: dataTree[ApplicationScope.GLOBAL].Global?.groups,
        },
        referencedValuesSet,
      );
      const user: any = {
        groups: filteredGroups.groups,
      };
      if (referencedValuesSet.has("user") || referencedValuesSet.has("User")) {
        user.name = dataTree[ApplicationScope.GLOBAL].Global?.user?.name;
        user.email = dataTree[ApplicationScope.GLOBAL].Global?.user?.email;
        user.id = dataTree[ApplicationScope.GLOBAL].Global?.user?.id;
      }
      return {
        ...filterContext(fullContext, referencedValuesSet),
        theme: {
          immutableState: theme,
        },
        Global: {
          immutableState: {
            user,
            profiles: {
              default:
                dataTree[ApplicationScope.GLOBAL].Global?.profiles?.default,
              ...filterContext(
                {
                  available:
                    dataTree[ApplicationScope.GLOBAL].Global?.profiles
                      ?.available,
                },
                referencedValuesSet,
                "displayName",
              ),
            },
            groups: filteredGroups.groups,
          },
        },
      };
    },
    [dataTree, fullContext, theme],
  );

  return {
    autocompleteContext,
    createPromptContext,
  };
};
