import { RouteDef } from "@superblocksteam/shared";
import { get, set } from "lodash";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { GeneratedTheme } from "legacy/themes";
import { unsetPropertyPath } from "legacy/utils/DynamicBindingUtils";
import { ColumnProperties } from "legacy/widgets/TableWidget/TableComponent/Constants";
import {
  getDefaultColumnProperties,
  getTableStyles,
} from "legacy/widgets/TableWidget/TableComponent/TableUtilities";
import { updateDerivedColumnsHook } from "legacy/widgets/TableWidget/TablePropertyPaneConfig";
import { fastClone } from "utils/clone";
import { getDottedPathTo } from "utils/dottedPaths";
import { getEventHandlers } from "./eventHandlers";
import {
  AddActionEvent,
  AddColumnAction,
  RemoveColumnAction,
  RemoveEventAction,
  SetPrimayColumnsAction,
} from "./types";

const PROPERTIES_TO_CHANGE = ["columnOrder"];

const initializeProperties = (changes: Record<string, any>, widget: any) => {
  PROPERTIES_TO_CHANGE.forEach((property) => {
    set(
      changes,
      property,
      fastClone(changes[property] ?? widget[property] ?? {}),
    );
  });
};

export const addColumns = (
  action: AddColumnAction,
  widget: any,
  changes: Record<string, any>,
  numColumns: number,
) => {
  const { value } = action;
  // update derived and primary columns
  initializeProperties(changes, widget);
  for (const newColumn of value) {
    const columnExists =
      widget.primaryColumns?.[newColumn.columnName] ??
      changes.primaryColumns?.[newColumn.columnName];
    if (
      !newColumn.columnName ||
      typeof newColumn.columnName !== "string" ||
      columnExists
    ) {
      continue;
    }
    const columnProps: ColumnProperties = getDefaultColumnProperties(
      newColumn.columnName,
      numColumns,
      widget.widgetName,
      true,
    );
    const tableStyles = getTableStyles(widget);
    const column = {
      ...columnProps,
      ...tableStyles,
    };
    // primaryColumns.date_of_birth
    const path = `primaryColumns${getDottedPathTo(column.id)}`;
    changes[path] = column;
    const additionalUpdates = updateDerivedColumnsHook({
      props: widget,
      propertyPath: path,
      propertyValue: column,
    });
    if (additionalUpdates) {
      additionalUpdates.forEach(
        (update: { propertyPath: string; propertyValue: any }) => {
          changes[update.propertyPath] = update.propertyValue;
        },
      );
    }
  }
};

export const removeColumns = (
  action: RemoveColumnAction,
  widget: any,
  changes: Record<string, any>,
) => {
  const { value } = action;
  initializeProperties(changes, widget);
  for (const removedColumn of value) {
    const columnExists =
      widget.primaryColumns?.[removedColumn.columnName] ??
      changes.primaryColumns?.[removedColumn.columnName];
    if (
      !removedColumn.columnName ||
      typeof removedColumn.columnName !== "string" ||
      !columnExists
    ) {
      continue;
    }
    const isDerived = widget.derivedColumns?.[removedColumn.columnName] != null;

    if (isDerived) {
      const columnOrderIndex = widget.columnOrder.findIndex(
        (column: string) => column === removedColumn.columnName,
      );
      changes[`derivedColumns${getDottedPathTo(removedColumn.columnName)}`] =
        null;
      changes[`primaryColumns${getDottedPathTo(removedColumn.columnName)}`] =
        null;
      unsetPropertyPath(changes, `columnOrder[${columnOrderIndex}]`);
    } else {
      changes[
        `${action.property}${getDottedPathTo(
          removedColumn.columnName,
        )}.isVisible`
      ] = false;
    }
  }
};

export const updateColumns = ({
  action,
  widget,
  changes,
  dataTree,
  routes,
  theme,
}: {
  action: SetPrimayColumnsAction;
  widget: any;
  changes: Record<string, any>;
  dataTree: DataTree;
  routes: RouteDef[];
  theme: GeneratedTheme;
}) => {
  initializeProperties(changes, widget);
  const updates: { path: string; value: any }[] = [];
  const topLevelUpdates = Array.isArray(action.value)
    ? action.value
    : [action.value];

  const addUpdate = (path: string, value: any, isDerived: boolean) => {
    updates.push({
      path: `${action.property}${path}`,
      value,
    });
    if (isDerived) {
      updates.push({
        path: `derivedColumns${path}`,
        value,
      });
    }
  };

  for (const value of topLevelUpdates) {
    for (const column of value.columns) {
      const columnExists =
        widget.primaryColumns?.[column] ??
        get(changes, `primaryColumns${getDottedPathTo(column)}`);
      if (!column || typeof column !== "string" || !columnExists) {
        continue;
      }
      const isDerived =
        (widget.derivedColumns?.[column] ??
          get(changes, `derivedColumns${getDottedPathTo(column)}`)) != null;
      // flatten
      for (const update of value.value) {
        const prevValue =
          get(
            changes,
            `${action.property}${getDottedPathTo(column)}.${update.property}`,
          ) ??
          get(
            widget,
            `${action.property}${getDottedPathTo(column)}.${update.property}`,
          );

        if (update.action === "set") {
          addUpdate(
            `${getDottedPathTo(column)}.${update.property}`,
            update.value,
            isDerived,
          );
        }
        if (update.action === "add") {
          const newValue = getEventHandlers({
            prevValue,
            value: update.value as AddActionEvent | AddActionEvent[],
            dataTree,
            routes,
          });
          addUpdate(
            `${getDottedPathTo(column)}.${update.property}`,
            newValue,
            isDerived,
          );
        }
        if (update.action === "remove") {
          const newValue = Array.isArray(prevValue)
            ? prevValue.filter(
                (item) =>
                  item.id !== (update.value as RemoveEventAction["value"]).id,
              )
            : prevValue;
          addUpdate(
            `${getDottedPathTo(column)}.${update.property}`,
            newValue,
            isDerived,
          );
        }
      }
    }
  }
  updates.forEach((update) => {
    // set(changes, update.path, update.value);
    changes[update.path] = update.value;
  });
};
