import { User } from "@auth0/auth0-react";
import {
  ApplicationUserStatus,
  isUserShareEntry,
  PermissionedEntities,
  RbacRole,
  ShareEntryDto,
  UserShareEntryDto,
} from "@superblocksteam/shared";
import { Avatar, Button, List, Tooltip } from "antd";
import { Typography } from "antd";
import React from "react";
import styled from "styled-components";
import DropdownButtonMinimal from "components/ui/DropdownButtonMinimal";
import { DefaultAvatarColor } from "styles/colorGeneration";
import { useFeatureFlag } from "../../hooks/ui";
import { Flag } from "../../store/slices/featureFlags";
import ColoredAvatar from "./ColoredAvatar";

const OWNER = "Owner";
const CREATOR = "Creator";

const { Text } = Typography;

const StyledEditorList = styled.div`
  margin-top: 8px;
  width: 100%;
  .ant-list-item {
    padding: 6px 0px 6px 0px;
  }
  .ant-list-item-action > li {
    padding-right: 0px;
  }
  .ant-list-item-action-split {
    width: 0px;
  }
`;

const ListItem = styled(List.Item)`
  border-bottom: 0px !important;
` as typeof List.Item;

const FlexWrapper = styled.div`
  display: flex;
`;

const StyledStatus = styled.div`
  font-size: 10px;
  border: 1px solid;
  border-radius: 4px;
  padding: 0 6px;
  color: #666666;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 12px;
`;

const StyledOwnerText = styled(Text)`
  font-size: 12px;
  margin-right: 33px;
  font-weight: 500;
  color: #454d5f;
  margin-top: 12px;
`;

const StyledListItemMeta = styled(List.Item.Meta)<{
  status?: ApplicationUserStatus;
}>`
  ${(props) =>
    props.description
      ? ``
      : `.ant-list-item-meta-content {
    margin-top: auto;
    margin-bottom: auto;
  }`}
  .ant-list-item-meta-avatar {
    margin-top: auto;
    margin-bottom: auto;
  }
  .ant-list-item-meta-title {
    margin-bottom: 0px;
  }
  .ant-list-item-meta-description {
    font-size: 12px;
  }
  .ant-avatar {
    margin-top: auto;
    margin-bottom: auto;
  }
  opacity: ${(props) =>
    props.status === ApplicationUserStatus.EDITOR_PENDING_JOIN ? "0.4" : "1"};
`;

type PermissionEntry = ShareEntryDto;

interface FakePermissionEntry {
  name: string;
  icon: React.ReactNode;
}

interface Props {
  currentUser: User | undefined;
  permissionEntries: PermissionEntry[];
  resourceType?: PermissionedEntities;
  roles?: { label: string; value: string }[];
  updateRole?: (shareEntry: ShareEntryDto, role: string) => void;
  onRemoveEntry?: (shareEntry: ShareEntryDto) => void;
  hideAction?: boolean;
}

interface PermissionListItemProps extends Props {
  permissionEntry: PermissionEntry;
}

const StyledAvatarWrapper = styled.div`
  span.ant-avatar {
    color: ${DefaultAvatarColor.FRONT};
    background: ${DefaultAvatarColor.BACKGROUND};
  }
`;

export const FakeItem = ({ entry }: { entry: FakePermissionEntry }) => {
  return (
    <div style={{ position: "relative" }}>
      <ListItem
        key={entry.name}
        data-test={`share-entry-${entry.name}`}
        style={{ padding: 0 }}
      >
        <StyledListItemMeta
          avatar={
            <StyledAvatarWrapper>
              <Avatar>{entry.icon}</Avatar>
            </StyledAvatarWrapper>
          }
          title={<FlexWrapper>{entry.name}</FlexWrapper>}
        />
      </ListItem>
    </div>
  );
};

const PermissionListItem = (props: PermissionListItemProps) => {
  const { permissionEntry, resourceType } = props;

  const orgOwnedIntegrationsEnabled = useFeatureFlag(
    Flag.ORG_OWNED_INTEGRATIONS_ENABLED,
  );

  const displayOwnerOrCreator =
    (resourceType === PermissionedEntities.INTEGRATION ||
      resourceType === PermissionedEntities.DATASOURCE) &&
    orgOwnedIntegrationsEnabled
      ? CREATOR
      : OWNER;

  return (
    <div style={{ position: "relative" }}>
      <ListItem
        key={permissionEntry.name}
        data-test={`share-entry-${permissionEntry.name}`}
        actions={
          props.hideAction
            ? undefined
            : permissionEntry.role !== RbacRole.OWNER
            ? [
                props.roles && props.roles.length > 0 ? (
                  <DropdownButtonMinimal
                    dataTest={`share-entry-${permissionEntry.name}-role-select`}
                    value={permissionEntry.role}
                    onValueChange={(role: string) => {
                      if (role === "remove") {
                        props.onRemoveEntry?.(permissionEntry);
                        return;
                      }
                      props.updateRole?.(permissionEntry, role);
                    }}
                    options={[
                      ...props.roles.map(({ label, value }) => ({
                        key: value,
                        value: value,
                        text: label,
                        groupId: "action",
                        dataTest: `share-entry-${permissionEntry.name}-role-option-${value}`,
                      })),
                      {
                        key: "remove-access",
                        value: "remove",
                        text: "Remove",
                        groupId: "remove",
                        dataTest: `share-entry-${permissionEntry.name}-role-option-remove`,
                      },
                    ]}
                  />
                ) : (
                  <Button
                    onClick={() => props.onRemoveEntry?.(permissionEntry)}
                    key="remove-access"
                  >
                    Remove
                  </Button>
                ),
              ]
            : [
                <StyledOwnerText key="owner">
                  {displayOwnerOrCreator ?? OWNER}
                </StyledOwnerText>,
              ]
        }
      >
        <StyledListItemMeta
          avatar={
            <ColoredAvatar name={permissionEntry.name || ""}>
              {permissionEntry.name.toUpperCase().charAt(0)}
            </ColoredAvatar>
          }
          title={
            <FlexWrapper>
              {`${
                permissionEntry.type === "group"
                  ? `${permissionEntry.name} (${permissionEntry.size})`
                  : permissionEntry.name
              } ${
                props.currentUser?.email ===
                (permissionEntry as UserShareEntryDto).email
                  ? "(You)"
                  : ""
              }`}
              {(permissionEntry as UserShareEntryDto).status ===
                ApplicationUserStatus.EDITOR_PENDING_JOIN && (
                <FlexWrapper>
                  <Tooltip
                    title={`User has not joined your organization.`}
                    overlayStyle={{ minWidth: 260 }}
                  >
                    <StyledStatus>Pending</StyledStatus>
                  </Tooltip>
                </FlexWrapper>
              )}
            </FlexWrapper>
          }
          description={
            isUserShareEntry(permissionEntry)
              ? permissionEntry.email
              : undefined
          }
          status={
            isUserShareEntry(permissionEntry)
              ? permissionEntry.status
              : undefined
          }
        />
      </ListItem>
    </div>
  );
};

const PermissionEntriesList = (props: Props) => {
  return (
    <StyledEditorList>
      <List
        itemLayout="horizontal"
        dataSource={props.permissionEntries}
        renderItem={(permissionEntry) => (
          <PermissionListItem permissionEntry={permissionEntry} {...props} />
        )}
      />
    </StyledEditorList>
  );
};

export default PermissionEntriesList;
