import {
  FormComponentType,
  VERSION_INITIAL,
  WorkflowExecutionParamsKey,
  ExecutionParam,
} from "@superblocksteam/shared";
import { FormInstance, InputRef, Radio } from "antd";
import { produce } from "immer";
import { isString, set } from "lodash";
import { isEmpty } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { FullWidthSpace } from "components/ui/Space";
import { ApiDto } from "store/slices/apis";
import { type ApiDtoWithPb } from "store/slices/apisV2/slice";
import DynamicForm from "../../../../../components/app/DynamicForm";
import { ParameterBuilder } from "../../../../components/ParameterBuilder";
import { findKeyInExecutionParams } from "../utils";
import { tableDataToParams, VariableDataField } from "./common";

type VariableSelectorProps = {
  apiId: ApiDto["id"];
  apiV1?: ApiDto;
  apiV2?: ApiDtoWithPb;
  params: ExecutionParam[];
  updateApi: (api: ApiDto) => Promise<void>;
  shortPreview?: boolean;
  disabled?: boolean;
};

const WorkflowBodySelector = ({
  apiId,
  apiV1,
  apiV2,
  params,
  updateApi,
  shortPreview,
  disabled,
}: VariableSelectorProps) => {
  const [isEditModeJSON, setEditModeJSON] = useState(false);

  const formRef = React.createRef<FormInstance>();

  const initialTableData = useMemo(() => {
    const bodyParam = findKeyInExecutionParams(
      params,
      WorkflowExecutionParamsKey.BODY,
    );
    if (!bodyParam) {
      return [] as VariableDataField[];
    }
    return (
      Object.entries((bodyParam.value as any) ?? {}).map(
        ([key, value]) =>
          ({
            id: uuidv4(),
            name: key,
            value: value,
            ref: React.createRef<InputRef>(),
          } as VariableDataField),
      ) ?? ([] as VariableDataField[])
    );
  }, [params]);

  const setParams = useCallback(
    async (tableData: VariableDataField[]) => {
      const newParams = tableDataToParams(
        tableData,
        WorkflowExecutionParamsKey.BODY,
      );

      let updated: ApiDto | undefined;
      if (apiV1) {
        updated = produce(apiV1, (toUpdate) => {
          if (toUpdate?.actions) toUpdate.actions.workflowParams = [newParams];
        });
      } else if (apiV2) {
        updated = produce(apiV2, (toUpdate) => {
          set(toUpdate.apiPb, "trigger.workflow.parameters.body", {
            ...newParams.value,
          });
        });
      }
      if (updated) await updateApi(updated);
    },
    [updateApi, apiV1, apiV2],
  );

  const handleJsonSave = useCallback(
    (body: any) => {
      if (isString(body)) {
        body = { value: body };
      }
      const newVariableTableData =
        Object.entries(body).map(
          ([key, value]) =>
            ({
              id: uuidv4(),
              name: key,
              value: value,
              ref: React.createRef<InputRef>(),
            } as VariableDataField),
        ) ?? [];
      setParams(newVariableTableData);
    },
    [setParams],
  );

  const jsonBodyEditor = (
    <FullWidthSpace direction="vertical">
      <DynamicForm
        key={apiId}
        disabled={disabled}
        formProps={{
          layout: "vertical",
          onValuesChange: (changedValues, allValues) => {
            try {
              const body = JSON.parse(allValues.body);
              handleJsonSave(body);
            } catch (err) {
              // no-op
            }
          },
          initialValues: {
            body: initialTableData.reduce(
              (acc, current) => ({
                ...acc,
                [current.name]: current.value,
              }),
              {},
            ),
          },
        }}
        ref={formRef}
        formTemplate={{
          sections: [
            {
              name: "main",
              items: [
                {
                  name: "body",
                  label: "",
                  startVersion: VERSION_INITIAL,
                  componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
                  rules: [
                    {
                      validator: (first: any, value: string) => {
                        try {
                          JSON.parse(value);
                          return Promise.resolve();
                        } catch (error) {
                          return Promise.reject(new Error("Invalid JSON"));
                        }
                      },
                    },
                  ],
                },
              ],
            },
          ],
        }}
      />
    </FullWidthSpace>
  );

  const bodyBuilder = (
    <ParameterBuilder
      disabled={disabled}
      isV2={apiV2 != null}
      keyColumn={{
        toolTip: `
                Use variables in Workflow steps. I.e. to reference a
                userId variable, use body.userId in Python and JS
                steps and {{body.userId}} in all other steps.`,
        placeHolder: shortPreview
          ? "Variable name"
          : "Variable name (E.g. email, location)",
      }}
      valueColumn={{
        toolTip: `
                Test values are used when you run a step or
                test your in-development workflow. Values can either be primitives or JSON`,
        placeHolder: shortPreview
          ? `test@user.com`
          : `JSON (E.g. {"name": "Superblocks", "lat": 0, "lon": 0})`,
      }}
      initialTableData={initialTableData}
      onUpdate={setParams}
      addVariableButtonDataTestString={"add-variable-body"}
      apiId={apiId}
    />
  );

  return (
    <>
      {!isEmpty(initialTableData) && (
        <Radio.Group defaultValue="a" size="large" disabled={disabled}>
          <Radio
            value="a"
            onClick={() => {
              setEditModeJSON(false);
            }}
          >
            Define Manually
          </Radio>
          <Radio
            value="b"
            onClick={() => {
              setEditModeJSON(true);
            }}
          >
            Paste Existing Body
          </Radio>
        </Radio.Group>
      )}
      {isEditModeJSON ? jsonBodyEditor : bodyBuilder}
    </>
  );
};

export default WorkflowBodySelector;
