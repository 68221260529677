import { useCallback } from "react";
import { useSelector } from "react-redux";
import { clearPropertyPane, selectWidgets } from "legacy/actions/widgetActions";
import { useAppDispatch } from "store/helpers";
import type { AppState } from "store/types";

const useClearSelectedWidgets = () => {
  const dispatch = useAppDispatch();

  const isResizing = useSelector(
    (state: AppState) => state.legacy.ui.widgetDragResize.isResizing,
  );
  const isDragging = useSelector(
    (state: AppState) =>
      state.legacy.ui.widgetDragResize.isDragging ||
      state.legacy.ui.widgetDragResize.isResizing,
  );

  const clearSelectedWidgets = useCallback(() => {
    if (isDragging || isResizing) {
      return;
    }
    dispatch(clearPropertyPane());
    dispatch(selectWidgets([]));
  }, [dispatch, isDragging, isResizing]);

  return clearSelectedWidgets;
};

export default useClearSelectedWidgets;
