import { Plugin } from "@superblocksteam/shared";
import { Button, Tooltip } from "antd";
import { noop } from "lodash";
import React, { useCallback, useMemo, useReducer, useRef } from "react";
import styled from "styled-components";
import { ReactComponent as LightbulbIcon } from "assets/icons/common/lightbulb.svg";
import { SyntaxType } from "code-formatting/constants";
import { useFeatureFlag } from "hooks/ui";
import { Flag } from "store/slices/featureFlags";
import { colors } from "styles/colors";
import { AiAssistantPositioner } from "./AiAssistantPositioner";
import { AiAssistantOptionType, FlowType, ScopeType } from "./constants";

const AI_ENABLED_PLUGINS = ["restapi", "graphql"];
interface AiAssistantState {
  isOpen: boolean;
  position: { left: number; top: number };
  availableOptions: AiAssistantOptionType[];
  maxHeight: number;
}

const ASSISTANT_WIDTH = 450;

const isAiAvailableForplugin = (plugin: Plugin) => {
  return AI_ENABLED_PLUGINS.includes(plugin.id);
};

const StyledButton = styled(Button)`
  padding: 7px;
  margin-left: 8px;
  border: 1px solid ${(props) => props.theme.colors.GREY_100};
`;

const AiAssistantForPluginWrapper = ({
  plugin,
  updateFormValues,
  initialConfig,
}: {
  plugin: Plugin;
  updateFormValues: (allValues: Record<string, any>) => void;
  initialConfig: Record<string, unknown>;
}) => {
  const isAiAssistantEnabled = useFeatureFlag(Flag.ENABLE_AI_ASSISTANT);
  if (!isAiAvailableForplugin(plugin) || !isAiAssistantEnabled) {
    return null;
  }
  return (
    <AiAssistantForPlugin
      initialConfig={initialConfig}
      plugin={plugin}
      updateFormValues={updateFormValues}
    />
  );
};

const AiAssistantForPlugin = ({
  plugin,
  updateFormValues,
  initialConfig,
}: {
  plugin: Plugin;
  updateFormValues: (allValues: Record<string, any>) => void;
  initialConfig: Record<string, unknown>;
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const aiOptions = useMemo(() => {
    switch (plugin.id) {
      case "restapi":
        return [
          {
            flowType: FlowType.GENERATE,
            syntax: SyntaxType.REST_API,
          },
        ];
      case "graphql":
        return [
          {
            flowType: FlowType.GENERATE,
            syntax: SyntaxType.GRAPHQL,
          },
        ];

      default:
        return [];
    }
  }, [plugin.id]);

  const [aiAssistantState, updateAiAssistantState] = useReducer(
    (prev: AiAssistantState, next: Partial<AiAssistantState>) => {
      return { ...prev, ...next };
    },
    {
      isOpen: false,
      position: { left: 0, top: 0 },
      availableOptions: [] as AiAssistantOptionType[],
      maxHeight: ASSISTANT_WIDTH,
    },
  );

  const openAiAssistant = useCallback(() => {
    const position = buttonRef.current?.getBoundingClientRect() ?? {
      left: 0,
      top: 0,
      height: 0,
    };
    const top = position.top + position.height + 4;
    // max height is distance from top to bottom of the screen
    const maxHeight = window.innerHeight - top - 16;
    const distanceToLeft = window.innerWidth - position.left;
    const left =
      distanceToLeft > ASSISTANT_WIDTH
        ? position.left
        : position.left - (ASSISTANT_WIDTH - distanceToLeft) - 20;
    updateAiAssistantState({
      isOpen: true,
      maxHeight,
      position: {
        left,
        top,
      },
    });
  }, []);

  const closeAiAssistant = useCallback(() => {
    updateAiAssistantState({
      isOpen: false,
    });
  }, []);

  const insertFormValues = useCallback(
    (optionType: AiAssistantOptionType, code?: null | string) => {
      if (!code) {
        return;
      }
      try {
        const parsedValues = JSON.parse(code);
        updateFormValues(parsedValues);
        closeAiAssistant();
      } catch (e) {
        return;
      }
    },
    [updateFormValues, closeAiAssistant],
  );

  const contextProps = useMemo(() => {
    return {
      pluginId: plugin.id,
      initialConfig,
    };
  }, [plugin.id, initialConfig]);

  return (
    <div>
      <Tooltip title="Ask AI">
        <StyledButton ref={buttonRef} onClick={openAiAssistant}>
          <LightbulbIcon color={colors.GREY_500} />
        </StyledButton>
      </Tooltip>

      {aiAssistantState.isOpen && (
        <AiAssistantPositioner
          availableOptions={aiOptions}
          x={aiAssistantState.position.left}
          y={aiAssistantState.position.top}
          width={ASSISTANT_WIDTH}
          isPinned={false}
          maxHeight={aiAssistantState.maxHeight}
          canResizeAssisant={true}
          onClose={closeAiAssistant}
          onConfirm={insertFormValues}
          onOptionSelected={noop}
          contextProps={contextProps}
          scope={ScopeType.PLUGIN}
        />
      )}
    </div>
  );
};

export default AiAssistantForPluginWrapper;
