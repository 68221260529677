import { TASKS, UserAnalyticsDto } from "@superblocksteam/shared";
import { call, select } from "redux-saga/effects";
import { createSaga } from "../../../utils/saga";
import { updateAnalytics } from "../client";
import { selectUserChecklist } from "../selectors";
import slice from "../slice";

type UpdateAnalyticsPayload = { id: TASKS; completed?: Date; viewed?: Date };
type TaskResult = Awaited<ReturnType<typeof updateAnalytics>>;

function* updateTaskInternal(payload: UpdateAnalyticsPayload) {
  const checklist: ReturnType<typeof selectUserChecklist> = yield select(
    selectUserChecklist,
  );

  const analytics: Partial<UserAnalyticsDto> = {
    checklist: {
      tasks: [
        ...checklist.filter((t) => t.id !== payload.id),
        {
          ...checklist.find((t) => t.id === payload.id),
          ...payload,
          displayName: TASKS[payload.id],
        },
      ],
    },
  };

  const result: TaskResult = yield call(updateAnalytics, analytics);
  return result;
}

export const updateTaskSaga = createSaga(updateTaskInternal, "updateTaskSaga", {
  sliceName: "user",
});

slice.saga(updateTaskSaga, {
  start(state) {
    state.loading.checklistTasks = true;
    state.loading.recommendedDatasources = true;
  },
  success(state, { payload }) {
    state.entity.checklistTasks = payload.checklist?.tasks ?? [];
    state.loading.checklistTasks = false;
    state.entity.recommendedDatasources = payload.recommendedDatasources || [];
    state.loading.recommendedDatasources = false;
  },
  error(state, { payload }) {
    state.error.checklistTasks = payload;
    state.loading.checklistTasks = false;
    state.loading.recommendedDatasources = false;
  },
});
