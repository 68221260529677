import {
  ActionTypeEnum,
  ResourceTypeEnum,
  RoleDto,
  RoleTypeEnum,
  snakeCaseToDisplay,
} from "@superblocksteam/shared";
import { Dropdown, Tooltip } from "antd";
import { RangeTuple } from "fuse.js";
import { get } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CellProps, Row } from "react-table";
import { ReactComponent as CheckboxIcon } from "assets/icons/common/checkbox.svg";
import { ReactComponent as DuplicateIcon } from "assets/icons/common/copy.svg";
import { ReactComponent as MoreIcon } from "assets/icons/common/dotdotdot.svg";
import { ReactComponent as EditIcon } from "assets/icons/common/edit.svg";
import { ReactComponent as StopIcon } from "assets/icons/common/stop.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/common/trash.svg";
import { Checkbox } from "components/ui/Checkbox";
import { RecColumn } from "components/ui/RecommendedTable";
import { HighlightedResult } from "components/ui/SearchSection";
import { MANAGE_ROLES } from "constants/rbac";
import { useFeatureFlag } from "hooks/ui";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { theme } from "legacy/constants/DefaultTheme";
import { getCurrentOrgId } from "legacy/selectors/organizationSelectors";
import { Flag } from "store/slices/featureFlags";
import { useCreateRoleMutation } from "store/slices/reduxApi/rbac";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { sendErrorUINotification } from "utils/notification";
import omit from "utils/omit";
import { DeleteRoleModal } from "./DeleteRoleModal";
import { UpdateRoleModal } from "./UpdateRoleModal";
import { sortRoleOrder, USER_FRIENDLY_RESOURCE_NAMES } from "./constants";

enum CheckboxState {
  Checked,
  Unchecked,
  Indeterminate,
}

const EditIndicator = styleAsClass`
  position: absolute;
  top: 2px;
  left: -16px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: ${colors.ACCENT_BLUE_500} transparent;
  border-radius: 2px;
`;

const CheckboxCellWrapper = styleAsClass`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const BottomBarClass = styleAsClass`
  height: 64px;
  width: calc(100% - ${String(theme.pageNavWidth)}px);
  padding: 16px;
  background: ${colors.WHITE};
  border-top: 1px solid ${colors.GREY_100};
  box-shadow:0px -1px 0px 0px #22272F14, 0px -3px 6px 0px #22272F0A;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  font-size: 12px;
  line-height: 16px;
`;

const RoleColumnHeaderClass = styleAsClass`
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  max-width: calc(100% + 16px);
  span {
    border-bottom: 1px dashed ${colors.GREY_200};
  }
  > .role-dropdown {
    opacity: 0;
    cursor: pointer;
    transition: opacity 0.3s;
  }
  &:hover > .role-dropdown {
    opacity: 1;
  }
`;
export const getRoleTypeName = (roleType: RoleTypeEnum) => {
  return USER_FRIENDLY_RESOURCE_NAMES[roleType] ?? snakeCaseToDisplay(roleType);
};

export type NestedRoleTableItem = {
  name: string;
  description: string;
  actionType: string;
  resourceType: string;
  highlights?: Array<[number, number]>;
} & Record<string, CheckboxState | boolean>;

export type RoleTableItem = Record<string, CheckboxState | boolean> & {
  name: string;
  resourceType: string;
  actionType: string;
  highlights?: Array<[number, number]>;
  children: Array<NestedRoleTableItem>;
};

const PERMISSION_COL_WIDTH = 250;
const PERMISSION_COLUMN: RecColumn<RoleTableItem> = {
  Header: "Permissions",
  accessor: "name",
  hidden: false,
  width: PERMISSION_COL_WIDTH,
  minWidth: PERMISSION_COL_WIDTH,
  disableSortBy: true,
  Cell: ({
    value,
    row,
  }: {
    value: string | boolean | CheckboxState;
    row: Row<RoleTableItem>;
  }) => {
    let style: React.CSSProperties = {};
    if (row.canExpand) {
      style = {
        fontSize: "12px",
        fontWeight: 500,
        color: colors.GREY_700,
      };
    } else {
      style = {
        fontFamily: "Roboto Mono",
        fontSize: "11px",
        fontWeight: 400,
        color: colors.GREY_700,
      };
    }
    return (
      <Tooltip title={row.original.description}>
        <span style={style}>
          <HighlightedResult
            value={String(value)}
            highlights={row.original.highlights}
          />
        </span>
      </Tooltip>
    );
  },
};

const BuiltinRoleMenu = ({ role }: { role: RoleDto }) => {
  const [createRole] = useCreateRoleMutation();
  const [canManageRoles] = useAuthorizationCheck([MANAGE_ROLES]);
  const organizationId = useSelector(getCurrentOrgId);
  const customRolesEnabled = useFeatureFlag(Flag.ENABLE_RBAC_CUSTOM_ROLES);

  const handleDuplicateRole = useCallback(async () => {
    try {
      await createRole({
        role: {
          ...omit(role, "id"),
          name: `${role.name} (copy)`,
          organizationId,
        },
        organizationId,
      }).unwrap();
    } catch (e: any) {
      sendErrorUINotification({
        message: e?.message ?? e?.error ?? "Failed to duplicate role",
      });
    }
  }, [createRole, organizationId, role]);

  const menuItems = useMemo(() => {
    return [
      {
        key: "duplicate",
        label: "Duplicate role",
        icon: <DuplicateIcon />,
        onClick: handleDuplicateRole,
      },
    ];
  }, [handleDuplicateRole]);

  return canManageRoles && customRolesEnabled ? (
    <Dropdown
      menu={{ items: menuItems }}
      trigger={["click"]}
      className="role-dropdown"
    >
      <MoreIcon
        style={{
          color: colors.GREY_500,
          cursor: "pointer",
          minWidth: 14,
        }}
      />
    </Dropdown>
  ) : null;
};

const CustomRoleMenu = ({
  role,
  onClearAll,
  onAllowAll,
  setTooltipOpen,
}: {
  role: RoleDto;
  onAllowAll: (props: { roleId: string; roleType: RoleTypeEnum }) => void;
  onClearAll: (props: { roleId: string; roleType: RoleTypeEnum }) => void;
  setTooltipOpen: (isOpen: boolean) => void;
}) => {
  const [canManageRoles] = useAuthorizationCheck([MANAGE_ROLES]);
  const [createRole] = useCreateRoleMutation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const organizationId = useSelector(getCurrentOrgId);

  const handleDuplicateRole = useCallback(async () => {
    try {
      await createRole({
        role: {
          ...omit(role, "id"),
          name: `${role.name} (copy)`,
          organizationId,
        },
        organizationId,
      }).unwrap();
    } catch (e: any) {
      sendErrorUINotification({
        message: e?.message ?? e?.error ?? "Failed to duplicate role",
      });
    }
  }, [createRole, organizationId, role]);

  const menuItems = useMemo(() => {
    return [
      {
        key: "clear_permissions",
        label: "Clear permissions",
        icon: <StopIcon />,
        onClick: () => onClearAll({ roleId: role.id, roleType: role.type }),
      },
      {
        key: "allow_all",
        label: "Allow all",
        icon: <CheckboxIcon />,
        onClick: () => onAllowAll({ roleId: role.id, roleType: role.type }),
      },
      {
        key: "duplicate",
        label: "Duplicate role",
        icon: <DuplicateIcon />,
        onClick: handleDuplicateRole,
      },
      {
        key: "edit-details",
        label: "Edit details",
        icon: <EditIcon color={colors.GREY_500} />,
        onClick: () => {
          setIsUpdateModalOpen(true);
          setTooltipOpen(false);
        },
      },
      {
        key: "delete",
        label: "Delete role",
        icon: <DeleteIcon />,
        onClick: () => {
          setIsDeleteModalOpen(true);
          setTooltipOpen(false);
        },
      },
    ];
  }, [
    handleDuplicateRole,
    onAllowAll,
    onClearAll,
    role.id,
    role.type,
    setTooltipOpen,
  ]);

  return canManageRoles ? (
    <>
      {isUpdateModalOpen && (
        <UpdateRoleModal
          role={role}
          onClose={() => {
            setIsUpdateModalOpen(false);
          }}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteRoleModal
          role={role}
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
        />
      )}
      <Dropdown
        menu={{ items: menuItems }}
        trigger={["click"]}
        className="role-dropdown"
      >
        <MoreIcon
          style={{
            color: colors.GREY_500,
            cursor: "pointer",
            minWidth: 14,
          }}
        />
      </Dropdown>
    </>
  ) : null;
};

const combineResourceAndAction = (resourceType: string, actionType: string) => {
  return `${resourceType}:${actionType}`;
};

const splitResourceAndAction = (name: string) => {
  const [resourceType, actionType] = name.split(":");
  return { resourceType, actionType } as {
    resourceType: ResourceTypeEnum;
    actionType: ActionTypeEnum;
  };
};

const CheckboxCell = ({
  value,
  row,
  isCustom,
  onEdit,
  role,
}: {
  value: CheckboxState;
  row: Row<RoleTableItem>;
  isCustom: boolean;
  role: RoleDto;
  onEdit: (props: {
    updates: Partial<Record<ActionTypeEnum, boolean>>;
    roleId: string;
    roleType: RoleTypeEnum;
  }) => void;
}) => {
  const onChange = useCallback(() => {
    const newValue = value === CheckboxState.Checked ? false : true;
    const { resourceType, actionType, children } = row.original;
    let updates: Record<string, boolean> = {};
    // Nested changes (i.e. toggling a top-level checkbox)
    if (children) {
      updates = children.reduce(
        (acc, { actionType: childActionType, name: childResourceName }) => {
          const { resourceType: childResourceType } =
            splitResourceAndAction(childResourceName);
          acc[combineResourceAndAction(childResourceType, childActionType)] =
            newValue;
          return acc;
        },
        {} as Record<string, boolean>,
      );
    } else {
      updates = {
        [combineResourceAndAction(resourceType, actionType)]: newValue,
      };
    }
    onEdit({
      updates,
      roleId: role.id,
      roleType: role.type,
    });
  }, [value, onEdit, row.original, role.id, role.type]);

  const isEdited = row.original[`${role.id}_isEdited`];
  const [canManageRoles] = useAuthorizationCheck([MANAGE_ROLES]);
  const isOrgManage =
    row.original.actionType === ActionTypeEnum.MANAGE &&
    row.original.resourceType === ResourceTypeEnum.ORGANIZATION;

  const isDisabled = !isCustom || !canManageRoles || isOrgManage;
  return (
    <div className={CheckboxCellWrapper}>
      {isEdited && <div className={EditIndicator} />}
      <Checkbox
        checked={value === CheckboxState.Checked}
        partialChecked={value === CheckboxState.Indeterminate}
        disabled={isDisabled}
        onClick={onChange}
        disableWithTwoTone={!isCustom}
      />
    </div>
  );
};

const RoleColumnHeader = ({
  role,
  onAllowAll,
  onClearAll,
}: {
  role: RoleDto;
  onAllowAll: (props: { roleId: string; roleType: RoleTypeEnum }) => void;
  onClearAll: (props: { roleId: string; roleType: RoleTypeEnum }) => void;
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  return (
    <div className={RoleColumnHeaderClass}>
      <Tooltip
        title={role.description}
        onOpenChange={setTooltipOpen}
        open={tooltipOpen}
      >
        <span
          style={{
            flex: "1",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {role.name}
        </span>
      </Tooltip>
      {role.organizationId ? (
        <CustomRoleMenu
          role={role}
          onClearAll={onClearAll}
          onAllowAll={onAllowAll}
          setTooltipOpen={setTooltipOpen}
        />
      ) : (
        <BuiltinRoleMenu role={role} />
      )}
    </div>
  );
};

export function getRoleTableColumns({
  data,
  roleType,
  useFixedColumnWidth = false,
  onCheckboxToggled,
  onAllowAll,
  onClearAll,
}: {
  data: undefined | Array<RoleDto>;
  roleType: RoleTypeEnum;
  useFixedColumnWidth?: boolean;
  onCheckboxToggled: (props: {
    updates: Partial<Record<ActionTypeEnum, boolean>>;
    roleId: string;
    roleType: RoleTypeEnum;
  }) => void;
  onAllowAll: (props: { roleId: string; roleType: RoleTypeEnum }) => void;
  onClearAll: (props: { roleId: string; roleType: RoleTypeEnum }) => void;
}): RecColumn<any>[] {
  if (!data) return [];

  let roleColumns = data.map((role) => {
    return {
      Header: (
        <RoleColumnHeader
          role={role}
          onAllowAll={onAllowAll}
          onClearAll={onClearAll}
        />
      ),
      accessor: role.id,
      hidden: false,
      Cell: (props: CellProps<RoleTableItem>) => {
        const isCustom = role.organizationId != null;
        return (
          <CheckboxCell
            {...props}
            isCustom={isCustom}
            onEdit={onCheckboxToggled}
            role={role}
          />
        );
      },
      disableSortBy: true,
      name: role.name,
      width: useFixedColumnWidth
        ? 120
        : `calc((100% - ${PERMISSION_COL_WIDTH}px) / ${data.length})`,
    };
  });

  roleColumns = roleColumns.sort((a, b) => {
    return (
      (sortRoleOrder[a.name] ?? sortRoleOrder.Max) -
      (sortRoleOrder[b.name] ?? sortRoleOrder.Max)
    );
  });

  return [
    {
      Header:
        roleType === RoleTypeEnum.ORGANIZATION ? (
          <span style={{ marginLeft: 12 }}>Permissions</span>
        ) : (
          "Permissions"
        ),
      ...PERMISSION_COLUMN,
    },
    ...roleColumns,
  ];
}

type PermissionObject = { description: string } & Record<string, CheckboxState>;
type RoleObject = {
  actions: Record<string, PermissionObject>;
  name: string;
  resourceType: string;
};

// Permission Edits maps roleId to resourceType:actionType to a boolean
export type PermissionEdits = Record<string, Record<string, boolean>>;

export function formatNestedRoleTableData(
  data: RoleDto[] | undefined,
  columns: RecColumn<RoleTableItem>[],
  edits?: PermissionEdits,
): RoleTableItem[] {
  if (!data) return [];
  const resourceAndActionToPermissionId: Record<string, string> = {};
  const rowDataMap = new Map<string, RoleObject>();
  const updateEntry = ({
    resourceType,
    actionType,
    description,
    roleId,
    checkboxState = CheckboxState.Checked,
    isEdit = false,
  }: {
    resourceType: ResourceTypeEnum;
    actionType: ActionTypeEnum;
    description?: string;
    roleId: string;
    checkboxState?: CheckboxState;
    isEdit?: boolean;
  }) => {
    const resourceTypeShort = resourceType.split(".")[0];
    const permissionName = `${resourceType}:${actionType}`;

    const entry = rowDataMap.get(resourceTypeShort) || {
      resourceType,
      name:
        USER_FRIENDLY_RESOURCE_NAMES[resourceTypeShort] ??
        snakeCaseToDisplay(resourceTypeShort),
      actions: {},
    };

    entry.actions[permissionName] = {
      ...entry.actions[permissionName],
      description,
      [roleId]: checkboxState,
      [`${roleId}_isEdited`]: isEdit,
    } as PermissionObject;

    rowDataMap.set(resourceTypeShort, entry);
  };

  // Group the data based on the resource type and then by action type
  for (const role of data) {
    for (const permission of role.permissions ?? []) {
      const { resourceType, actionType, description, id } = permission;
      resourceAndActionToPermissionId[`${resourceType}:${actionType}`] = id;
      updateEntry({
        resourceType,
        actionType,
        description,
        roleId: role.id,
      });
    }
    // go through all of the edits for this role and update / add the permissions
    const editsForRole = edits?.[role.id];
    Object.entries(editsForRole ?? {}).forEach(([key, value]) => {
      const { resourceType, actionType } = splitResourceAndAction(key);
      if (value == null) {
        return;
      }
      updateEntry({
        resourceType,
        actionType,
        roleId: role.id,
        checkboxState: value ? CheckboxState.Checked : CheckboxState.Unchecked,
        isEdit: true,
      });
    });
  }

  // Create array from the map
  return Array.from(rowDataMap.values())
    .map((entry) => {
      const arrayEntry = {
        name: entry.name,
        resourceType: entry.resourceType,
        children: Object.entries(entry.actions)
          .map(([permissionName, values]) => {
            const [resourceType, actionType] = permissionName.split(":");
            return {
              name: permissionName,
              actionType,
              resourceType,
              ...values,
            } as NestedRoleTableItem;
          })
          .sort((a, b) => a.name.localeCompare(b.name)),
      } as RoleTableItem;

      // Set checkbox states for columns
      for (const column of columns) {
        if (typeof column.accessor !== "string" || column.accessor === "name")
          continue;

        const accessor = column.accessor as string;
        const allChecked = arrayEntry.children.every(
          (child) => child[accessor] === CheckboxState.Checked,
        );
        const someChecked = arrayEntry.children.some(
          (child) => child[accessor] === CheckboxState.Checked,
        );
        const someEdited = arrayEntry.children.some(
          (child) => child[`${accessor}_isEdited`],
        );

        arrayEntry[accessor] = allChecked
          ? CheckboxState.Checked
          : someChecked
          ? CheckboxState.Indeterminate
          : CheckboxState.Unchecked;
        arrayEntry[`${accessor}_isEdited`] = someEdited;
      }

      return arrayEntry;
    })
    .sort((a, b) => a.name.localeCompare(b.name));
}

export function formatRoleTableData(
  data: RoleDto[] | undefined,
  edits?: PermissionEdits,
): Array<NestedRoleTableItem> {
  if (!data) return [];
  const permissionsMap: Record<string, NestedRoleTableItem> = {};
  const updateEntry = ({
    resourceType,
    actionType,
    roleId,
    isEdit = false,
    description,
    checkboxState = CheckboxState.Checked,
  }: {
    resourceType: ResourceTypeEnum;
    actionType: ActionTypeEnum;
    roleId: string;
    description?: string;
    checkboxState?: CheckboxState;
    isEdit?: boolean;
  }) => {
    const name = combineResourceAndAction(resourceType, actionType);
    const entry = get(permissionsMap, name) ?? {
      actionType,
      resourceType,
      name,
      description,
    };
    entry[roleId] = checkboxState;
    entry[`${roleId}_isEdited`] = isEdit;
    permissionsMap[name] = entry;
  };

  for (const role of data) {
    if (!role.permissions) continue;
    for (const permission of role.permissions) {
      const { actionType, resourceType, description } = permission;
      updateEntry({
        resourceType,
        actionType,
        roleId: role.id,
        description,
      });
    }
    // go through all of the edits for this role and update / add the permissions
    const editsForRole = edits?.[role.id];
    Object.entries(editsForRole ?? {}).forEach(([key, value]) => {
      const { resourceType, actionType } = splitResourceAndAction(key);
      if (value == null) {
        return;
      }
      updateEntry({
        resourceType,
        actionType,
        roleId: role.id,
        checkboxState: value ? CheckboxState.Checked : CheckboxState.Unchecked,
        isEdit: true,
      });
    });
  }
  return Object.values(permissionsMap).sort((a, b) =>
    a.name.localeCompare(b.name),
  );
}

export const filterAndTransformHighights = (
  searchTerm: string,
  indices?: readonly RangeTuple[],
) => {
  if (!indices) return indices;
  return indices
    .map(([start, end]) => {
      if (end - start === searchTerm.length) {
        return [start, end];
      } else if (end - start === searchTerm.length - 1) {
        // for some reason, full word matches are not including the last letter, so
        // we want to filter out non-full matches + add a character to the end of full-word matches
        return [start, end + 1];
      }
      return null;
    })
    .filter(Boolean) as Array<[number, number]>;
};

export const getRoleDataMap = (
  data: RoleDto[] | undefined,
): {
  dataMap: PermissionEdits;
  resourceAndActionToPermissionId: Record<string, string>;
} => {
  if (!data)
    return {
      dataMap: {},
      resourceAndActionToPermissionId: {},
    };
  const roleDataMap: PermissionEdits = {};
  const resourceAndActionToPermissionId: Record<string, string> = {};
  for (const role of data) {
    const { id: roleId, permissions } = role;
    permissions?.forEach((permission) => {
      const { resourceType, actionType, id: permissionId } = permission;
      const key = combineResourceAndAction(resourceType, actionType);
      resourceAndActionToPermissionId[key] = permissionId;
      roleDataMap[roleId] = {
        ...roleDataMap[roleId],
        [key]: true,
      };
    });
  }
  return { dataMap: roleDataMap, resourceAndActionToPermissionId };
};

export const getRolesToSave = ({
  originalRoles,
  edits,
  resourceAndActionToPermissionId,
}: {
  originalRoles: RoleDto[];
  edits: PermissionEdits;
  resourceAndActionToPermissionId: Record<string, string>;
}) => {
  const rolesToSave: Partial<RoleDto>[] = [];
  for (const role of originalRoles) {
    if (role.organizationId == null) continue; // not a custom role
    const roleEdits = edits?.[role.id];
    if (!roleEdits) continue;
    // filter out permissions that have been deleted
    const filteredPermissions = role.permissions?.filter((permission) => {
      const { resourceType, actionType } = permission;
      const key = combineResourceAndAction(resourceType, actionType);
      // if roleEdits[key] is undefined, it means the permission has not changed
      return roleEdits[key] !== false;
    });

    // add permissions that have been added
    Object.entries(roleEdits).forEach(([key, value]) => {
      if (value === true) {
        const { resourceType, actionType } = splitResourceAndAction(key);
        const permissionId = resourceAndActionToPermissionId[key];
        if (permissionId) {
          filteredPermissions?.push({
            id: permissionId,
            resourceType,
            actionType,
            description: "",
          });
        }
      }
    });
    rolesToSave.push({
      ...role,
      permissions: filteredPermissions,
    });
  }
  return rolesToSave;
};
