import React, { Component, useEffect } from "react";
import styled from "styled-components";
import Popper from "components/ui/Popper";
import { scrollbarLight } from "legacy/constants/DefaultTheme";
import { Layers } from "legacy/constants/Layers";
import { PopoverPanelConfig } from "legacy/constants/PropertyControlConstants";
import {
  getPropertyPanePopoverWidth,
  PropertyPopoverMaxHeight,
} from "legacy/constants/WidgetConstants";
import { toSanitizedDomId } from "legacy/utils/helpers";
import { styleAsClass } from "styles/styleAsClass";
import { iframeMessageHandler } from "utils/iframe";
import { SbPanelProps } from "./types";

const StyledPopover = styled.div`
  max-height: ${PropertyPopoverMaxHeight};
  overflow: auto;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(34, 39, 47, 0.06),
    0px 12px 32px -8px rgba(34, 39, 47, 0.16),
    0px 0px 1px rgba(34, 39, 47, 0.16);

  ${scrollbarLight}
`;

const PopoverWithWidth = (props: { id: string; children: React.ReactNode }) => {
  const popoverWidth = getPropertyPanePopoverWidth();

  return (
    <StyledPopover style={{ width: `${popoverWidth}px` }} id={props.id}>
      {props.children}
    </StyledPopover>
  );
};

// this is needed because the child is potentially taller than the real content
const WrapperClassName = styleAsClass`
  pointer-events: none;
`;

const AdjustableHeightContainer = styled.div`
  display: flex;
  flex-direction: column;
  pointer-events: none;
  > * {
    pointer-events: auto;
  }
`;

// TODO: pass these in as props to make his more generic
export const skipSelectors = [
  ".bp5-portal", // Blueprint portal
  ".ant-dropdown", // Ant Design dropdown
  "[data-test=popper]", // Evaluated Value Preview
  ".CodeMirror-hints", // CodeMirror autocomplete
  "[data-superblocks=shortcut-menu]", // Shortcut menu
  ".new-api-submenu", // New API submenu,
  ".sidebar-modal", // For the route editor
  ".SB-popconfirm", // Popconfirm
];

export type PopperPanel = {
  childEl?: React.ComponentType<React.PropsWithChildren<any>>;
  childProps?: SbPanelProps & { panelIndex: number };
  childSelector?: string;
};

type PanelsProps = {
  children?: React.ReactNode;
  panels: PopperPanel[];
  direction?: "left" | "right";
  closePanels: (panelIndex?: number) => void;
};

export default class PanelsOverlay extends Component<PanelsProps> {
  portalEl: React.RefObject<HTMLDivElement>;

  constructor(props: PanelsProps) {
    super(props);
    this.portalEl = React.createRef();

    if (this.props.panels.length > 0) {
      this.addOutsideListener();
    }
  }

  componentDidUpdate = (oldProps: Readonly<PanelsProps>) => {
    if (this.props.panels.length > 0 && oldProps.panels.length === 0) {
      this.addOutsideListener();
    }

    if (this.props.panels.length === 0) {
      this.removeOutsideListener();
    }
  };

  componentWillUnmount() {
    this.removeOutsideListener();
  }

  private addOutsideListener() {
    // Needed to capture CodeMirror elements before they disappear
    document.addEventListener("mousedown", this.panelOutsideClickDetector, {
      capture: true,
    });
    document.addEventListener("touchstart", this.panelOutsideClickDetector, {
      capture: true,
    });
    iframeMessageHandler.addEventListener("click", this.clickInIframeDetector);
  }

  private removeOutsideListener() {
    document.removeEventListener("mousedown", this.panelOutsideClickDetector, {
      capture: true, // Must match
    });
    document.removeEventListener("touchstart", this.panelOutsideClickDetector, {
      capture: true,
    });
    iframeMessageHandler.removeEventListener(
      "click",
      this.clickInIframeDetector,
    );
  }

  private clickInIframeDetector = () => {
    this.props.closePanels();
  };

  private panelOutsideClickDetector = (event: Event) => {
    const clickedPopper = (event.target as HTMLElement)?.closest(
      "[data-test=popper]",
    );
    const clickedIndex = clickedPopper?.getAttribute("data-popper-index");
    if (clickedPopper && clickedIndex) {
      // if you click on the panel stack in a lower element, close all panels after the clicked index
      this.props.closePanels(parseInt(clickedIndex) + 1);
      return;
    }
    const portalContainsEvent =
      this.portalEl.current &&
      !this.portalEl.current.contains(event.target as Node);

    // Each of these .contains( calls is a DOM query, so they are written
    // in a way that we can short-circuit the logic if any conditions are met
    if (
      portalContainsEvent &&
      !skipSelectors.some((selector) => {
        const els = document.querySelectorAll(selector);
        return Array.from(els).some((e) => e.contains(event.target as Node));
      })
    ) {
      this.props.closePanels();
    }
  };

  getPanelContainer = () => {
    return this.portalEl.current;
  };

  render() {
    const { panels, children } = this.props;

    return (
      <>
        {panels.map((panel, index) => {
          return (
            <PopoverPanelComponent
              key={index}
              panel={panel}
              panelConfig={panel.childProps?.panelConfig}
              portalEl={this.portalEl}
              direction={this.props.direction}
              index={index}
              getPanelContainer={this.getPanelContainer}
            />
          );
        })}
        {children}
      </>
    );
  }
}

type Justification = "center" | "flex-start" | "flex-end";
const computeJustification = (
  targetNode: undefined | Element,
): Justification => {
  const targetRect = targetNode?.getBoundingClientRect();
  if (!targetRect) return "center";

  const targetMidpoint = targetRect.top + targetRect.height / 2;
  const relativeY = targetMidpoint / window.innerHeight;

  if (relativeY < 0.25) {
    return "flex-start";
  } else if (relativeY > 0.75) {
    return "flex-end";
  } else {
    return "center";
  }
};

export const PopoverPanelComponent = (props: {
  panel: PopperPanel;
  panelConfig?: PopoverPanelConfig;
  portalEl: React.RefObject<HTMLDivElement>;
  direction?: "left" | "right";
  index: number;
  getPanelContainer?: () => HTMLDivElement | null | undefined;
}) => {
  const { panel, panelConfig, portalEl, direction, index, getPanelContainer } =
    props;

  let targetNode: undefined | Element = undefined;
  try {
    targetNode =
      (panel.childSelector && document.querySelector(panel.childSelector)) ||
      undefined;
  } catch {
    console.error("Error finding target node for panel", panel.childSelector);
  }
  const isAdjustableHeight = panelConfig?.adaptiveHeight != null;
  const [justification, setJustification] = React.useState<Justification>(
    () => {
      if (!isAdjustableHeight) {
        return "center";
      }
      return computeJustification(targetNode);
    },
  );

  useEffect(() => {
    if (!isAdjustableHeight) return;
    setJustification(computeJustification(targetNode));
  }, [targetNode, isAdjustableHeight]);

  const popoverNode = (
    <PopoverWithWidth
      id={toSanitizedDomId(panel.childProps?.panelParentPropertyPath ?? "")}
    >
      {panel.childEl && (
        <panel.childEl
          {...panel.childProps}
          getPanelContainer={getPanelContainer}
        />
      )}
    </PopoverWithWidth>
  );

  return (
    <Popper
      key={panel.childSelector}
      targetNode={targetNode}
      ref={portalEl}
      zIndex={Layers.propertiesPopupPanels}
      isOpen={true}
      placement={direction ?? "right"}
      wrapperClassName={WrapperClassName}
      modifiers={[
        {
          name: "preventOverflow",
          options: {
            padding: { top: 50, bottom: 10 },
            // Allow the target to be scrolled offscreen
            tether: false,
          },
        },
        {
          name: "offset",
          options: { offset: [0, 20] },
        },
      ]}
      extraSuperblocksAttr={String(index)}
    >
      <AdjustableHeightContainer
        style={
          isAdjustableHeight
            ? {
                height: panelConfig?.adaptiveHeight?.maxHeight,
                justifyContent: justification,
              }
            : undefined
        }
      >
        {popoverNode}
      </AdjustableHeightContainer>
    </Popper>
  );
};
