import shallowEqual from "shallowequal";
import {
  GenericBlock,
  ControlFlowFrontendDSL,
  BlockType,
  ConditionControl,
  LoopControl,
  ParallelControl,
  TryCatchControl,
  StreamControl,
} from "store/slices/apisV2/control-flow/types";

/*
 *  Block locators are a generic way to pass information about where a block is located
 *  within a control flow tree.
 */

interface BaseControlBlockLocator {
  parentId: string;
}
interface LoopLocator extends BaseControlBlockLocator {
  controlType: BlockType.LOOP;
  idx: number;
}

interface ConditionLocator extends BaseControlBlockLocator {
  controlType: BlockType.CONDITION;
  conditionType: "if" | "else" | "elseIf";
  conditionIndex: number; // for if and else this will be 0
  idx: number;
}

interface ParallelLocator extends BaseControlBlockLocator {
  controlType: BlockType.PARALLEL;
  pathName: string | null; // if null, then is dynamic
  idx: number;
}

export interface TryCatchLocator extends BaseControlBlockLocator {
  controlType: BlockType.TRY_CATCH;
  branchName: "try" | "catch" | "finally";
  idx: number;
}

export interface StreamLocator extends BaseControlBlockLocator {
  controlType: BlockType.STREAM;
  sectionName: "trigger" | "process";
  idx: number;
}

export type ControlBlockLocator =
  | LoopLocator
  | ConditionLocator
  | ParallelLocator
  | TryCatchLocator
  | StreamLocator;

export type BlockPositionLocator =
  | {
      parentId: null;
      idx: number;
    }
  | ControlBlockLocator;

/*
 *  Example
 *  Suppose Loop1 has 2 blocks inside it, Step1 and Step2:
 *  const blockList = getBlockListFromLocator(state, { parentId: "Loop1", idx: 0 });
 *  blockList would be ["Step1", "Step2"]
 *  The idx in the locator does not actually matter
 */
export const getBlockListFromLocator = (
  state: ControlFlowFrontendDSL,
  locator: BlockPositionLocator,
): Array<string> => {
  if (locator == null) {
    return [];
  }
  if (locator.parentId == null) {
    return state.rootBlocks;
  }
  const parent = state.blocks[locator.parentId];

  const locatorControlType = locator.controlType;
  switch (locatorControlType) {
    case BlockType.CONDITION: {
      if (parent.type !== BlockType.CONDITION) {
        throw new Error("Locator type does not match block type");
      }
      const { conditionType, conditionIndex } = locator;
      const cfg = parent.config as ConditionControl;
      const blockList =
        conditionType === "elseIf"
          ? cfg.elseIf?.[conditionIndex]?.blocks
          : conditionType === "if"
          ? cfg.if.blocks
          : cfg.else;
      if (blockList == null) {
        throw new Error("No blocks found at locator");
      }
      return blockList;
    }
    case BlockType.PARALLEL: {
      if (parent.type !== BlockType.PARALLEL) {
        throw new Error("Locator type does not match block type");
      }
      const cfg = parent.config as ParallelControl;
      const { pathName } = locator;
      if (pathName == null) {
        const blockList = cfg.dynamic?.blocks;
        if (blockList == null) {
          throw new Error("No blocks found at locator");
        }
        return blockList;
      } else {
        const blockList = cfg.static?.paths?.[pathName];
        if (blockList == null) {
          throw new Error("No blocks found at locator");
        }
        return blockList;
      }
    }
    case BlockType.LOOP: {
      if (parent.type !== BlockType.LOOP) {
        throw new Error("Locator type does not match block type");
      }
      const cfg = parent.config as LoopControl;
      return cfg.blocks;
    }
    case BlockType.TRY_CATCH: {
      if (parent.type !== BlockType.TRY_CATCH) {
        throw new Error("Locator type does not match block type");
      }
      const cfg = parent.config as TryCatchControl;
      const { branchName } = locator;
      const blockList = cfg[branchName];
      if (blockList == null) {
        throw new Error("No blocks found at locator");
      }
      return blockList;
    }
    case BlockType.STREAM: {
      if (parent.type !== BlockType.STREAM) {
        throw new Error("Locator type does not match block type");
      }
      const cfg = parent.config as StreamControl;
      const { sectionName } = locator;
      const blockList = cfg[sectionName];
      if (blockList == null) {
        throw new Error("No blocks found at locator");
      }
      return blockList;
    }
    default: {
      const exhaustiveCheck: never = locatorControlType;
      throw new Error(`Unhandled type: ${exhaustiveCheck}`);
    }
  }
};

/*
 *  Get a list of all locators that identify child arrays for a particular block
 *  note that this does not get every child, but only the containing arrs
 *  Example
 *  Suppose Conditional1 has an if branch and an else branch.
 *  const locators = getBlockChildListLocators(state, Conditional1Block);
 *  locators would be [
 *    { parentId: "Conditional1", controlType: "CONDITION", conditionType: "if", conditionIndex: 0, idx: -1 },
 *    { parentId: "Conditional1", controlType: "CONDITION", conditionType: "else", conditionIndex: 0, idx: -1 }}
 *  ]
 *  Note that even if there are a dozen blocks in each branch, the list of locators still only has 2 items.
 */
export const getBlockChildListLocators = (
  block: GenericBlock,
): Array<BlockPositionLocator> => {
  if (!block) {
    return [];
  }
  const { type } = block;
  // only control blocks have children
  const ret: Array<ControlBlockLocator> = [];
  switch (type) {
    case BlockType.CONDITION: {
      const cfg = block.config as ConditionControl;
      const baseLocator = {
        parentId: block.name,
        controlType: BlockType.CONDITION,
        conditionIndex: 0,
        idx: -1,
      } as const;
      ret.push({
        ...baseLocator,
        conditionType: "if",
      });
      if (cfg.else) {
        ret.push({
          ...baseLocator,
          conditionType: "else",
        });
      }
      if (cfg.elseIf) {
        cfg.elseIf.forEach((_, conditionIndex) => {
          ret.push({
            ...baseLocator,
            conditionType: "elseIf",
            conditionIndex,
          });
        });
      }
      return ret;
    }
    case BlockType.PARALLEL: {
      const cfg = block.config as ParallelControl;
      const baseLocator = {
        parentId: block.name,
        controlType: BlockType.PARALLEL,
        idx: -1,
      } as const;
      if (cfg.static != null) {
        Object.keys(cfg.static.paths ?? {}).forEach((pathName) => {
          ret.push({
            ...baseLocator,
            pathName,
          });
        });
      } else if (cfg.dynamic != null) {
        ret.push({
          ...baseLocator,
          pathName: null,
        });
      }
      return ret;
    }
    case BlockType.LOOP: {
      ret.push({
        parentId: block.name,
        controlType: BlockType.LOOP,
        idx: -1,
      });
      return ret;
    }
    case BlockType.TRY_CATCH: {
      const cfg = block.config as TryCatchControl;
      const baseLocator = {
        parentId: block.name,
        controlType: BlockType.TRY_CATCH,
        idx: -1,
      } as const;
      ret.push({
        ...baseLocator,
        branchName: "try",
      });
      ret.push({
        ...baseLocator,
        branchName: "catch",
      });
      if (cfg.finally) {
        ret.push({
          ...baseLocator,
          branchName: "finally",
        });
      }
      return ret;
    }
    case BlockType.STREAM: {
      const cfg = block.config as StreamControl;
      const baseLocator = {
        parentId: block.name,
        controlType: BlockType.STREAM,
        idx: -1,
      } as const;
      if (cfg.trigger) {
        ret.push({
          ...baseLocator,
          sectionName: "trigger",
        });
      }
      if (cfg.process) {
        ret.push({
          ...baseLocator,
          sectionName: "process",
        });
      }
      return ret;
    }
    case BlockType.BREAK:
    /* falls through */
    case BlockType.THROW:
    /* falls through */
    case BlockType.RETURN:
    /* falls through */
    case BlockType.WAIT:
    /* falls through */
    case BlockType.STEP:
    /* falls through */
    case BlockType.SEND:
    /* falls through */
    case BlockType.VARIABLES: {
      return [];
    }
    default: {
      const exhaustiveCheck: never = type;
      throw new Error(`Unhandled type: ${exhaustiveCheck}`);
    }
  }
};

/*
 *  If you don't know a block's location, you can use this function to generate the appropriate locator
 *  This isn't super performant since it needs to potentially iterate through all the children of the block's parent
 */
export const findBlockLocation = (
  state: ControlFlowFrontendDSL,
  blockName: string,
): null | BlockPositionLocator => {
  const { blocks, rootBlocks } = state;
  const block = blocks[blockName];
  if (!block) {
    return null;
  }
  const parentId = block.parentId;
  if (parentId == null) {
    return {
      parentId: null,
      idx: rootBlocks.indexOf(blockName),
    };
  }
  const parent = blocks[parentId];
  if (!parent) {
    throw new Error(`Could not find block ${parentId}`);
  }

  if (parent.type === BlockType.STEP) {
    throw new Error("Invalid block - parent type is STEP");
  }
  const locators = getBlockChildListLocators(parent);
  for (const locator of locators) {
    if (locator == null || locator.parentId == null) {
      continue;
    }
    const blockList = getBlockListFromLocator(state, locator);
    const idx = blockList.indexOf(blockName);
    if (idx !== -1) {
      return {
        ...locator,
        idx,
      };
    }
  }
  return null;
};

/*
 *  This function retrieves the parent blocks of a given block using a locator.
 *  Example:
 *  Suppose block "Child1" is nested inside "Loop1" which is nested inside "Parent1":
 *  const parentBlocks = getParentsFromLocator(state, { parentId: "Child1", idx: 0 });
 *  parentBlocks would be ["Loop1", "Parent1"]
 */
export const getParentsFromLocator = (
  state: ControlFlowFrontendDSL,
  locator: BlockPositionLocator,
): Array<string> => {
  if (locator.parentId == null) {
    return [];
  }

  const parents = [];

  let parent = state.blocks[locator.parentId];
  parents.push(parent.name);
  while (parent && parent.parentId) {
    parents.push(parent.parentId);
    parent = state.blocks[parent.parentId];
  }
  return parents;
};

/*
 *  This function retrieves the sibling blocks that are located before the given block.
 *  Example:
 *  Suppose block "Step3" is the third block inside "Loop1":
 *  const priorSiblings = getPriorSiblingsFromLocator(state, { parentId: "Loop1", idx: 2 });
 *  priorSiblings would be ["Step1", "Step2"]
 */
export const getPriorSiblingsFromLocator = (
  state: ControlFlowFrontendDSL,
  locator: BlockPositionLocator,
): Array<string> => {
  const siblingBlocks = getBlockListFromLocator(state, locator);
  const upstreamSiblingBlocks = siblingBlocks.slice(0, locator.idx);
  return upstreamSiblingBlocks;
};

export const getPriorSiblingsFromBlock = (
  state: ControlFlowFrontendDSL,
  blockName: string,
): Array<string> => {
  const locator = findBlockLocation(state, blockName);
  if (!locator) {
    throw new Error(`Could not find block ${blockName}`);
  }
  return getPriorSiblingsFromLocator(state, locator);
};

export const blockLocationsAreSiblings = (
  locator: BlockPositionLocator,
  otherLocator: BlockPositionLocator,
) => {
  // all fields except idx need to be the same
  const { idx, ...locatorWithoutIdx } = locator;
  const { idx: otherIdx, ...otherLocatorWithoutIdx } = otherLocator;

  return shallowEqual(locatorWithoutIdx, otherLocatorWithoutIdx);
};

export const getNumChildrenFromBlock = (block: GenericBlock) => {
  const locators = getBlockChildListLocators(block);
  if (locators.length === 0) {
    return 0;
  }
  // for each locator, the the blocks at that locator and sum the lengths
  return locators.reduce((acc, locator) => {
    const blocks = getBlockListFromLocator(
      {
        rootBlocks: [], // noop
        blocks: {
          [block.name]: block,
        },
      },
      locator,
    );
    return acc + blocks.length;
  }, 0);
};
