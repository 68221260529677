import { Dimension } from "@superblocksteam/shared";
import { call } from "redux-saga/effects";
import { WidgetAddChild } from "legacy/actions/pageActions";
import { generateReactKey } from "legacy/utils/generators";
import { WidgetProps } from "legacy/widgets";
import type {
  DimensionGetter,
  NumGetter,
  WidgetBlueprint,
} from "legacy/mockResponses/WidgetConfigResponse";

function getPosValue(
  start: NumGetter | undefined,
  end: NumGetter | undefined,
  length: NumGetter | DimensionGetter | undefined,
  parent: WidgetProps,
): Dimension<"gridUnit"> {
  const cStart = typeof start === "function" ? start(parent) : start;
  const cEnd = typeof end === "function" ? end(parent) : end;
  const cLength = typeof length === "function" ? length(parent) : length;
  if (cStart !== undefined) {
    return cStart;
  }
  if (cLength !== undefined && cLength.mode !== "gridUnit") {
    return Dimension.gridUnit(0);
  }
  if (cEnd !== undefined && cLength !== undefined) {
    return Dimension.minus(cEnd, cLength as Dimension<"gridUnit">).asFirst();
  }
  return Dimension.gridUnit(0);
}

function getSizeValue(
  size: NumGetter | DimensionGetter | undefined,
  parent: WidgetProps,
) {
  return typeof size === "function"
    ? size(parent)
    : size ?? Dimension.gridUnit(0);
}

function buildView(
  view: WidgetBlueprint["view"],
  widgetId: string,
  parent: WidgetProps,
): WidgetAddChild[] {
  const children: WidgetAddChild[] = [];
  if (view) {
    for (const template of view) {
      //TODO(abhinav): Can we keep rows and size mandatory?
      try {
        const pos = template.position;
        const size = template.size;
        children.push({
          widgetId,
          type: template.type,
          position: {
            left: getPosValue(pos.left, pos.right, size?.width, parent),
            top: getPosValue(pos.top, pos.bottom, size?.height, parent),
          },
          size: {
            width: getSizeValue(size?.width, parent),
            height: getSizeValue(size?.height, parent),
          },
          newWidgetId: generateReactKey(),
          props: template.props,
        });
      } catch (e) {
        console.error(e);
      }
    }
  }

  return children;
}

export function* buildWidgetBlueprint(
  blueprint: WidgetBlueprint,
  widgetId: string,
  parent: WidgetProps,
): Generator<any, WidgetAddChild[], any> {
  const widgetProps = yield call(buildView, blueprint.view, widgetId, parent);
  return widgetProps;
}
