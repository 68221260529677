import {
  CreateRepositoryRequest,
  GetProjectNodeResponse,
  HttpMethod,
  VcsProvider,
} from "@superblocksteam/shared";
import { Modal as AntdModal, Tabs as AntdTabs, Typography } from "antd";
import axios from "axios";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CheckCircle } from "assets/icons/common/check-circle-filled.svg";
import CountBadge from "components/ui/CountBadge";
import { Spinner } from "components/ui/Spinner";
import { SessionStorageKey } from "legacy/utils/sessionStorage";
import { useSaga } from "../../hooks/store";
import { getRepositoriesSaga } from "../../store/slices/repositories";
import { callServer } from "../../store/utils/client";
import ChooseRepository from "./ChooseRepository";
import { GitOnboardingContextProvider } from "./GitOnboardingContext";
import ReviewAndConnect from "./ReviewAndConnect";
import SelectConfigFile from "./SelectConfigFile";
import {
  InvalidTokenKeyWords,
  Repository,
  isInValidTokenMessage,
} from "./utils";

const { TabPane } = AntdTabs;

const StyledModal = styled(AntdModal)`
  .ant-modal-content {
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 1px 0px rgba(34, 39, 47, 0.16),
      0px 12px 32px -8px rgba(34, 39, 47, 0.16),
      0px 1px 3px 0px rgba(34, 39, 47, 0.06);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 600px;
    min-height: 300px;
  }

  .ant-modal-header {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const ModalTitle = styled.div`
  color: var(--dark-900, #1f2633);
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
`;

const StyledTabs = styled(AntdTabs)`
  width: 100%;
  .ant-tabs-nav {
    margin: 0;
    width: 100%;
    pointer-events: none;
    height: 36px;
  }
  .ant-tabs-nav-list {
    width: 100%;
    display: flex;
  }
  .ant-tabs-tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: gray;
    pointer-events: none;
    font-size: 12px;

    .ant-tabs-tab-active {
      color: black;
      pointer-events: all;
    }
  }

  .ant-tabs-content-holder {
    padding: 0px 24px 24px 24px;
  }
`;

const StepTitleWrapper = styled.div<{
  isFinished: boolean;
  isCurrent: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 4px;
  .step-title-checked {
    width: 16px;
    height: 16px;
    color: ${(props) => props.theme.colors.ACCENT_BLUE_500};
  }
  .step-title-number {
    background-color: ${({ isCurrent, isFinished, theme }) =>
      isCurrent || isFinished
        ? theme.colors.ACCENT_BLUE_500
        : theme.colors.GREY_300};
  }
  .step-title-name {
    color: ${(props) =>
      props.isFinished
        ? props.theme.colors.ACCENT_BLUE_500
        : props.theme.colors.GREY_700};
  }
`;

// Note(taha) This is a workaround to prevent Github from returning a 304. Otherwise,
// we end up with a stale list of installations and repositories. This is likely due to
//  some bad caching on Github's end.
// "cache-control" would have been a better header to use, but Github doesn't seem to
// allow it, and so we get disallowed by CORS.
const GITHUB_NO_CACHE_HEADERS = {
  "if-none-match": "",
};

const StepTitle = ({
  currentStep,
  title,
  step,
}: {
  title: string;
  currentStep: number;
  step: number;
}) => {
  const isFinished = step < currentStep;
  return (
    <StepTitleWrapper isFinished={isFinished} isCurrent={step === currentStep}>
      {isFinished ? (
        <CheckCircle className="step-title-checked" />
      ) : (
        <CountBadge className="step-title-number" count={step} />
      )}
      <div className="step-title-name">{title}</div>
    </StepTitleWrapper>
  );
};
const GitOnboardingModal = ({
  visible,
  onClose,
  githubAccessToken,
  provider,
  connectToProvider,
  isTokenModalVisible,
}: {
  visible: boolean;
  onClose: () => void;
  githubAccessToken: string;
  provider: VcsProvider;
  connectToProvider: (provider: VcsProvider) => void;
  isTokenModalVisible: boolean;
}) => {
  const [loadingInstallations, setLoadingInstallations] = useState(false);
  const [error, setError] = useState("");
  const [reposByInstallation, setReposByInstallation] = useState<
    Record<string, any>
  >(new Map());
  const [iconsByInstallation, setIconsByInstallation] = useState<
    Record<string, string>
  >({});
  const [orgToDisplayName, setOrgToDisplayName] = useState<
    Record<string, string>
  >({});
  const [activeTab, setActiveTab] = useState("1");

  const onCloseWrapped = useCallback(() => {
    onClose();
    // Reset the active tab to the first tab when the modal is closed
    setActiveTab("1");
    // Reset the flag used to determine whether github app installation has been auto triggered
    // when the modal is closed
    sessionStorage.removeItem(SessionStorageKey.GITHUB_INSTALL_AUTO_TRIGGERED);
  }, [onClose, setActiveTab]);

  // TODO(taha pimeng) Switch this to a server endpoint call that returns a list of repositories.
  const fetchInstallationsGitlab = useCallback(async () => {
    try {
      setError("");
      setLoadingInstallations(true);
      const res = await callServer<GetProjectNodeResponse>(
        {
          method: HttpMethod.GET,
          url: "/v1/git-sync/gitlab/projects",
        },
        {
          notifyOnError: false,
          onError: (err) => {
            if (
              err.code === 401 ||
              (err.code === 400 && isInValidTokenMessage(err?.message ?? ""))
            ) {
              setError(InvalidTokenKeyWords);
            } else {
              setError(`${err?.message || err?.code}`);
            }
          },
        },
      );
      if (res) {
        const orgToRepos: Record<string, Repository[]> = {};
        const orgToIcon: Record<string, string> = {};
        const orgToDisplayName: Record<string, string> = {};
        res.projects.forEach((project) => {
          const firstSlashIndex = project.fullPath?.indexOf("/");
          // root group name
          const groupName = project.fullPath?.substring(0, firstSlashIndex);
          // projectName that could include subgroup
          const projectName = project.fullPath?.substring(firstSlashIndex + 1);
          if (!groupName) return;
          if (!(groupName in orgToRepos)) {
            orgToRepos[groupName] = [];
            // TODO:git, use avatarUrl of the account if project is not created under group
            orgToIcon[groupName] = project.group?.avatarUrl ?? null;
            // Until we can get display name for root group, with path name
            orgToDisplayName[groupName] = groupName;
          }
          orgToRepos[groupName].push({
            id: project.id,
            name: projectName,
            displayName: projectName,
            owner: {
              name: groupName,
              displayName: project?.group?.name ?? groupName,
            },
            defaultBranch: project.repository?.rootRef,
            provider: VcsProvider.GITLAB,
          });
        });
        await (async () => new Promise((res) => setTimeout(res, 3000)))();
        setReposByInstallation(orgToRepos);
        setIconsByInstallation(orgToIcon);
        setOrgToDisplayName(orgToDisplayName);
      }
    } catch (err: any) {
      console.error(`Error fetching GitLab repositories: ${err}`);
      setError(`${err?.message || err?.code}`);
    } finally {
      setLoadingInstallations(false);
    }
  }, []);

  const fetchInstallations = useCallback(async (githubAccessToken: string) => {
    console.log(`fetchInstallations: fetching installations`);
    if (isEmpty(githubAccessToken)) {
      console.log("fetchInstallations: no github access token");
      return;
    }
    setLoadingInstallations(true);
    try {
      setError("");
      const installationsResponse = await axios.get(
        "https://api.github.com/user/installations",
        {
          headers: {
            Accept: "application/vnd.github+json",
            Authorization: `Bearer ${githubAccessToken}`,
            ...GITHUB_NO_CACHE_HEADERS,
          },
        },
      );

      const installs = installationsResponse.data.installations;

      const newReposByInstallation: Record<string, Repository[]> = {};
      const newIconsByInstallation: Record<string, string> = {};
      const newOrgToDisplayName: Record<string, string> = {};

      for (const install of installs) {
        let keepFetching = true;
        let page = 1;
        newIconsByInstallation[install.account.login] =
          install.account.avatar_url;
        newOrgToDisplayName[install.account.login] = install.account.login;

        while (keepFetching) {
          const reposResponse = await axios.get(
            `https://api.github.com/user/installations/${install.id}/repositories?visibility=all&per_page=100&page=${page}`,
            {
              headers: {
                Accept: "application/vnd.github.machine-man-preview+json",
                Authorization: `Bearer ${githubAccessToken}`,
                ...GITHUB_NO_CACHE_HEADERS,
              },
            },
          );

          if (reposResponse.data.repositories.length > 0) {
            if (!(install.account.login in newReposByInstallation)) {
              newReposByInstallation[install.account.login] = [];
            }
            newReposByInstallation[install.account.login] =
              newReposByInstallation[install.account.login]?.concat(
                reposResponse.data.repositories.map(
                  (repo: any) =>
                    ({
                      id: repo.id,
                      installationId: install.id,
                      owner: {
                        name: repo.owner.login,
                        displayName: repo.owner.login,
                      },
                      defaultBranch: repo.default_branch,
                      //TODO:git, we need both name and displayname
                      name: repo.name,
                      displayName: repo.name,
                      remoteUrl: repo.html_url,
                      provider: VcsProvider.GITHUB,
                    } as Repository),
                ),
              );
            page++;
          } else {
            keepFetching = false;
          }
        }
      }

      setReposByInstallation(newReposByInstallation);
      setIconsByInstallation(newIconsByInstallation);
      setOrgToDisplayName(newOrgToDisplayName);
    } catch (err: any) {
      console.error(`fetchInstallations: error fetching installations: ${err}`);
      setError(`${err?.message || err?.code}`);
    } finally {
      setLoadingInstallations(false);
    }
  }, []);

  const refetchOrgList = useCallback(() => {
    if (provider === VcsProvider.GITHUB) {
      fetchInstallations(githubAccessToken);
    } else if (provider === VcsProvider.GITLAB) {
      // fetch gitlab groups and project
      fetchInstallationsGitlab();
    }
  }, [
    fetchInstallations,
    fetchInstallationsGitlab,
    githubAccessToken,
    provider,
  ]);

  useEffect(() => {
    if (visible) {
      refetchOrgList();
    }
  }, [refetchOrgList, visible]);

  useEffect(() => {
    //if token input modal from visible to invisible and this modal is visible refetch
    if (!isTokenModalVisible && visible) {
      refetchOrgList();
    }
    // this only happens when user save new token while input is opened from OnboardingModal
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTokenModalVisible]);

  const [selectedRepo, setSelectedRepo] = useState<Repository | null>(null);
  const [selectedBranch, setSelectedBranch] = useState("");
  const handleSetup = useCallback(
    (repo: Repository) => {
      setSelectedRepo(repo);
      setSelectedBranch(repo.defaultBranch);
      setActiveTab("2");
    },
    [setSelectedBranch, setSelectedRepo, setActiveTab],
  );

  const [directory, setDirectory] = useState<string>(".");

  const handleBackToFirst = useCallback(() => {
    setActiveTab("1");
  }, []);

  const handleNextToThird = useCallback(() => {
    setActiveTab("3");
  }, []);
  const handleBackToSecond = useCallback(() => {
    setActiveTab("2");
  }, []);

  const [getRepositories] = useSaga(getRepositoriesSaga);
  const loadRepositories = useCallback(async () => {
    await getRepositories({});
  }, [getRepositories]);

  const [appFlobsInConfig, setAppFlobsInConfig] = useState<any[]>([]);

  const [protectDefaultBranch, setProtectDefaultBranch] = useState(false);

  const handleConnect = useCallback(async () => {
    try {
      if (!selectedRepo) {
        console.error(`No repo has been selected`);
        return;
      }
      await callServer({
        method: HttpMethod.POST,
        url: "/v1/repositories",
        //TODO:git, we might need to send displayName for repo and owner as well
        body: {
          name: selectedRepo.name,
          owner: selectedRepo.owner?.name,
          installationId: String(selectedRepo.installationId),
          configFileDirectory: directory,
          defaultBranch: selectedBranch,
          provider,
          protectDefaultBranch,
        } as CreateRepositoryRequest,
      });
      onCloseWrapped();
      await loadRepositories();
    } catch (err) {
      console.error(`Error connecting repo: ${err}`);
    }
  }, [
    selectedRepo,
    directory,
    selectedBranch,
    protectDefaultBranch,
    provider,
    onCloseWrapped,
    loadRepositories,
  ]);

  const currentStep = Number(activeTab);

  return (
    <GitOnboardingContextProvider
      value={{
        appFlobsInConfig,
        setAppFlobsInConfig,
        protectDefaultBranch,
        setProtectDefaultBranch,
      }}
    >
      <StyledModal
        title={<ModalTitle>Connect a repository</ModalTitle>}
        open={visible}
        footer={null}
        centered
        closable
        onCancel={onCloseWrapped}
        destroyOnClose={true}
        bodyStyle={{
          backgroundColor: "white",
          padding: 0,
          borderRadius: "0px 0px 4px 4px",
        }}
      >
        {error && !loadingInstallations && visible ? (
          <div style={{ paddingTop: 12, paddingLeft: 24 }}>
            {`There was an error fetching your repositories (${error})`}{" "}
            {isInValidTokenMessage(error) && (
              <div>
                <Typography.Link onClick={() => connectToProvider(provider)}>
                  Update access token
                </Typography.Link>{" "}
                to connect its repositories.
              </div>
            )}
          </div>
        ) : (
          <StyledTabs defaultActiveKey={"1"} activeKey={activeTab}>
            <TabPane
              tab={
                <StepTitle
                  step={1}
                  title="Choose repository"
                  currentStep={currentStep}
                />
              }
              key="1"
            >
              {loadingInstallations && (
                <div style={{ paddingTop: "20px" }}>
                  <Spinner>
                    <div>Loading your repositories...</div>
                  </Spinner>
                </div>
              )}
              {!loadingInstallations && (
                <ChooseRepository
                  reposByInstallation={reposByInstallation}
                  handleSetup={handleSetup}
                  refreshOrgsList={refetchOrgList}
                  iconsByInstallation={iconsByInstallation}
                  provider={provider}
                  connectToProvider={connectToProvider}
                  orgToDisplayName={orgToDisplayName}
                />
              )}
            </TabPane>
            <TabPane
              tab={
                <StepTitle
                  step={2}
                  title="Configure repository"
                  currentStep={currentStep}
                />
              }
              key="2"
            >
              <SelectConfigFile
                repo={selectedRepo}
                handleBack={handleBackToFirst}
                handleNext={handleNextToThird}
                selectedBranch={selectedBranch}
                directory={directory}
                setDirectory={setDirectory}
                provider={provider}
              />
            </TabPane>
            <TabPane
              tab={
                <StepTitle
                  step={3}
                  title="Review & connect"
                  currentStep={currentStep}
                />
              }
              key="3"
            >
              <ReviewAndConnect
                repo={selectedRepo}
                handleBack={handleBackToSecond}
                handleNext={handleConnect}
                selectedBranch={selectedBranch}
                directory={directory}
                setDirectory={setDirectory}
              />
            </TabPane>
          </StyledTabs>
        )}
      </StyledModal>
    </GitOnboardingContextProvider>
  );
};

export default GitOnboardingModal;
