import { useDispatch } from "react-redux";
import { useEditorHotkeys } from "hooks/ui/useEditorHotkeys";
import { usePasteWidget } from "hooks/ui/usePasteWidget";
import { copyWidget, cutWidget } from "legacy/actions/widgetActions";
import { isApiEditorFocused } from "pages/Editors/ApiEditor/utils";

export function useCopyPasteWidgets(disabled: boolean) {
  const dispatch = useDispatch();
  useEditorHotkeys(
    "ctrl+x, command+x",
    () => {
      if (isApiEditorFocused()) return;
      dispatch(cutWidget());
    },
    {
      filterPreventDefault: false,
    },
  );

  useEditorHotkeys(
    "ctrl+c, command+c",
    () => {
      if (isApiEditorFocused()) return;
      dispatch(copyWidget(true));
    },
    {
      filterPreventDefault: false,
      // NOTE(aayush): We want to disable widget copy/paste when nudge is disabled.
      // For instance, in a TableWidget when a cell is focussed, we want to disable copy/paste
      // of the entire table widget.
      enabled: !disabled,
    },
  );

  // Previously copy+paste was global, but is now dispatched only inside the iframe
  const handlePasteWidget = usePasteWidget();
  useEditorHotkeys(
    "ctrl+v, command+v",
    () => {
      if (isApiEditorFocused()) return;
      handlePasteWidget({});
    },
    // NOTE(aayush): We want to disable widget copy/paste when nudge is disabled.
    // For instance, in a TableWidget when a cell is focussed, we want to disable copy/paste
    // of the entire table widget.
    { filterPreventDefault: false, enabled: !disabled },
  );
}
