import { ApplicationScope } from "@superblocksteam/shared";
import { call, select } from "redux-saga/effects";
import { APP_MODE } from "legacy/reducers/types";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import logger from "utils/logger";
import { sendWarningUINotification } from "utils/notification";
import {
  EVAL_WORKER_ACTIONS,
  EvalActionBindingsRequest,
  EvalActionBindingsResponse,
  EvalErrorTypes,
  EvalTriggerRequest,
  EvalTriggerResponse,
} from "../utils/DynamicBindingUtils";
import { evalErrorHandler } from "./EvaluationsSagaHelpers";
import { setEvaluateActionBindings } from "./EvaluationsShared";
import { worker } from "./evaluationLoader";
import {
  waitForCurrentAndQueuedEvaluations,
  waitForEvaluationToComplete,
} from "./waitForEvaluation";

export function* evaluateActionBindings(
  bindings: string[],
  scope: ApplicationScope,
  executionParams: Record<string, any> | string = {},
  additionalNamedArguments?: Record<string, unknown>,
  spanId?: string,
  includeErrors?: boolean,
) {
  const request: EvalActionBindingsRequest = {
    bindings,
    scope,
    executionParams,
    additionalNamedArguments,
  };

  yield call(waitForCurrentAndQueuedEvaluations);
  const workerResponse: EvalActionBindingsResponse = yield call(
    worker.request,
    EVAL_WORKER_ACTIONS.EVAL_ACTION_BINDINGS_REC_EVAL,
    request,
  );

  const { errors, values, logs } = workerResponse;
  logs.forEach((evalLog) => logger.debug(evalLog, undefined, false));
  evalErrorHandler(errors, spanId);
  return includeErrors ? { values, errors } : values;
}

setEvaluateActionBindings(evaluateActionBindings);

export function* evaluateDynamicTrigger({
  propertyPath,
  dynamicTrigger,
  currentScope,
  additionalNamedArguments,
  spanId,
}: {
  propertyPath: string;
  dynamicTrigger: string;
  currentScope: ApplicationScope;
  additionalNamedArguments?: Record<string, unknown>;
  spanId?: string;
}) {
  // Force an evaluation of the tree before we actually evaluate the triggers.
  // This might be needed in situations where a user action changes the tree and causes some triggers to be ran.
  // For example, when opening a slideout, there is a change to the tree (e.g. the isVisible property of the
  // slideout changes), but also the slideout's `onOpen` trigger(s) have to be evaluated. In that scenario, it
  // is more natural to first evaluate the new tree to e.g. pick up the new value of isVisible and *then* evaluate
  // the triggers.
  yield call(waitForEvaluationToComplete);

  const request: EvalTriggerRequest = {
    scope: currentScope,
    dynamicTrigger,
    additionalNamedArguments,
  };

  const workerResponse: EvalTriggerResponse = yield call(
    worker.request,
    EVAL_WORKER_ACTIONS.EVAL_TRIGGER_REC_EVAL,
    request,
  );
  const { errors, triggers, logs } = workerResponse;

  logs.forEach((evalLog) => {
    logger.debug(evalLog, undefined, false);
  });

  // Mutate errors by adding the propertyPath
  errors.forEach((error) => {
    error.context = error.context || {};
    error.context.propertyPath = propertyPath;
  });

  const appMode: APP_MODE | undefined = yield select(getAppMode);
  if (appMode === APP_MODE.EDIT) {
    errors.forEach((error) => {
      if (error.type === EvalErrorTypes.EVAL_ERROR) {
        sendWarningUINotification({
          description: error.message,
          message:
            "Error running " + error?.context?.propertyPath ??
            "in an Event Handler",
          duration: 15,
        });
      }
    });
  }

  evalErrorHandler(errors, spanId);
  return triggers;
}
