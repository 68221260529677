import { EventArg, WidgetTypes } from "@superblocksteam/shared";
import { get, omit, set } from "lodash";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  DataTreeAction,
  DataTreeStateVar,
} from "legacy/entities/DataTree/dataTreeFactory";
import {
  forEachWidgetProperty,
  getWidgetThemeValues,
  getWidgetClassForType,
  setExpandedNestedProperty,
} from "legacy/pages/Editor/PropertyPane/widgetPropertyPaneConfigUtils";
import { GeneratedTheme } from "legacy/themes";
import { WidgetProps } from "legacy/widgets/BaseWidget/types";
import { getSettableProperties } from "legacy/widgets/eventHandlerPanel";
import { fastClone } from "utils/clone";
import { ENTITY_TYPE } from "utils/dataTree/constants";
import { AllFlags } from "../featureFlags";

const TABLE_KEYS_TO_TRIM = [
  "tableData",
  "allEdits",
  "filteredTableData",
  "tableDataWithInserts",
];

const OMITTED_PROPS = [
  "widgetLastChange",
  "updateWidgetMetaProperty",
  "invalidProps",
  "widgetId",
  "validationMessages",
  "isLoading",
  "internalMaxWidth",
  "dynamicBindingPathList",
  "dynamicTriggerPathList",
  "dragDisabled",
  "dropDisabled",
  "bindingPaths",
  "disablePropertyPane",
  "evaluatedValues",
  "resizeDisabled",
  "isDeletable",
  "isDefaultClickDisabled",
  "docsUrl",
  "appMode",
  "parentId",
  // layouts
  "minWidth",
  "minHeight",
  "maxWidth",
  "maxHeight",
  "height",
  "width",
  "gridColumns",
  "gridRows",
  "left",
  "top",
  // table widget
  "derivedColumns",
  "cachedColumnSettings",
];

const DIMENSIONLESS_PROPS = [
  "padding",
  "margin",
  "borderRadius",

  "border.bottom.width",
  "border.left.width",
  "border.right.width",
  "border.top.width",

  // search props for table
  "searchProps.border.bottom.width",
  "searchProps.border.left.width",
  "searchProps.border.right.width",
  "searchProps.border.top.width",
  "searchProps.borderRadius",
] as const;

const IMMUTABLE_STATE_PROPERTIES_BY_WIDGET_TYPE: Partial<
  Record<WidgetTypes, string[]>
> = {
  [WidgetTypes.BUTTON_WIDGET]: ["isLoading"],
  [WidgetTypes.INPUT_WIDGET]: [
    "isValid",
    "value",
    "isoCurrencyCode",
    "isTouched",
  ],
  [WidgetTypes.TABLE_WIDGET]: [
    "tableDataWithInserts",
    "filteredTableData",
    "editedRows",
    "allEdits",
    "selectedRow",
    "selectedRows",
  ],
  [WidgetTypes.DATE_PICKER_WIDGET]: [
    "value",
    "isValid",
    "outputDateLocal",
    "outputDateUtc",
  ],
  [WidgetTypes.DROP_DOWN_WIDGET]: [
    "isValid",
    "selectedOption",
    "selectedOptionArr",
    "value",
    "selectedIndex",
    "selectedIndexArr",
    "searchText",
  ],
  [WidgetTypes.GRID_WIDGET]: ["selectedCell", "cells"],
  [WidgetTypes.CHECKBOX_WIDGET]: ["value", "isValid"],
  [WidgetTypes.SWITCH_WIDGET]: ["value", "isValid"],
  [WidgetTypes.RADIO_GROUP_WIDGET]: ["value", "isValid", "selectedOption"],
  [WidgetTypes.FILE_PICKER_WIDGET]: ["isValid"],
  [WidgetTypes.MAP_WIDGET]: ["markers"],
  [WidgetTypes.FORM_WIDGET]: ["isValid", "data"],
  [WidgetTypes.TABS_WIDGET]: ["selectedTab"],
  [WidgetTypes.MODAL_WIDGET]: ["isOpen", "isVisible"],
  [WidgetTypes.SLIDEOUT_WIDGET]: ["isOpen", "isVisible"],
  [WidgetTypes.CHAT_WIDGET]: ["lastMessage"],
  [WidgetTypes.RICH_TEXT_EDITOR_WIDGET]: ["isValid"],
  [WidgetTypes.CODE_WIDGET]: ["isValid", "parsedValue"],
  [WidgetTypes.VIDEO_WIDGET]: ["playState"],
};
// Add other widget types as needed

const trimTables = (component: Partial<WidgetProps>): Partial<WidgetProps> => {
  if ((component as any).tableData != null) {
    const updatedComponent = fastClone(component);
    TABLE_KEYS_TO_TRIM.forEach((key) => {
      const val = updatedComponent[key as keyof WidgetProps];
      if (val && Array.isArray(val) && val.length > 0) {
        updatedComponent[key as keyof WidgetProps] = [
          createShape(val[0]),
        ] as any;
      } else {
        updatedComponent[key as keyof WidgetProps] = [] as any;
      }
    });

    // Delete isDerived
    const COL_KEYS_TO_DELETE = ["isDerived"];
    for (const colName in (updatedComponent as any).primaryColumns) {
      const column = (updatedComponent as any).primaryColumns[colName];
      if (column) {
        for (const key of COL_KEYS_TO_DELETE) {
          delete column[key];
        }
      }
    }

    return updatedComponent;
  }
  return component;
};

const removeKeys = (component: Partial<WidgetProps>) => {
  return omit(component, OMITTED_PROPS);
};

const removeModeFromDimensions = (component: Partial<WidgetProps>) => {
  DIMENSIONLESS_PROPS.forEach((prop) => {
    const value = get(component, prop);
    if (typeof value === "object" && value) {
      if (value.mode) {
        set(component, `${prop}.mode`, undefined);
      } else {
        Object.keys(value).forEach((subKey) => {
          if (
            value &&
            typeof value[subKey as keyof typeof value] === "object"
          ) {
            set(component, `${prop}.${subKey}.mode`, undefined);
          }
        });
      }
    }
  });
  return component;
};

const sortProperties = (
  widget: Partial<WidgetProps>,
  _context: { theme: GeneratedTheme; featureFlags: Partial<AllFlags> },
) => {
  if (!widget.type) {
    return widget;
  }
  const widgetProperties = {
    type: widget.type.replace("_WIDGET", "").toLowerCase(),
    staticProperties: {} as Record<string, any>,
    dynamicProperties: {} as Record<string, any>,
    mutableState: {} as Record<string, any>,
    immutableState: {} as Record<string, any>,
    eventListeners: {} as Record<string, any>,
  };

  forEachWidgetProperty({
    widgetClass: getWidgetClassForType(widget.type as any) as any,
    widget,
    callbackFn: (property, parentPath) => {
      if (typeof property.propertyName !== "string") return;
      const fullPath = parentPath
        ? `${parentPath}.${property.propertyName}`
        : property.propertyName;
      const isEventHandler = property.isTriggerProperty;
      if (isEventHandler) {
        widgetProperties.eventListeners[fullPath] =
          get(widget, property.propertyName) ?? [];
      } else if (property.controlType === "TEXT_STYLE") {
        // We have special handling for the textStyle property

        // Special case because text_style controls 2 properties, textColor and variant (and custom props if custom)
        if (property.enableTextColorConfig) {
          widgetProperties.dynamicProperties[`${fullPath}.textColor.default`] =
            get(widget, `${fullPath}.textColor.default`);
        }

        const variant = get(widget, `${fullPath}.variant`);

        if (variant === "SB_CUSTOM_TEXT_STYLE") {
          // the textStyle is custom, so include all the custom props but not the variant
          // as that's more an internal SB value and not helpful for AI
          widgetProperties.staticProperties[fullPath] = omit(
            get(widget, `${fullPath}`),
            "variant",
          );
          // delete text color as we've hoisted it to the top level
          delete widgetProperties.staticProperties[fullPath].textColor;
        } else {
          widgetProperties.staticProperties[`${fullPath}.variant`] = get(
            widget,
            `${fullPath}.variant`,
          );
        }
      } else {
        const isDynamic =
          property.isJSConvertible || property.controlType === "INPUT_TEXT";
        if (isDynamic) {
          widgetProperties.dynamicProperties[fullPath] = get(
            widget,
            property.propertyName,
          );
        } else {
          widgetProperties.staticProperties[fullPath] = get(
            widget,
            property.propertyName,
          );
        }
      }
    },
  });

  // roll-up static-properties up to top level
  const rolledUpStaticProperties = {};
  Object.entries(widgetProperties.staticProperties).forEach(([key, value]) => {
    setExpandedNestedProperty(rolledUpStaticProperties, key, value);
  });
  widgetProperties.staticProperties = rolledUpStaticProperties;
  widgetProperties.staticProperties["widgetName"] = widget.widgetName;

  const immutableStateProperties =
    IMMUTABLE_STATE_PROPERTIES_BY_WIDGET_TYPE[widget.type as WidgetTypes];
  if (immutableStateProperties) {
    widgetProperties.immutableState = immutableStateProperties.reduce(
      (acc, key) => {
        acc[key] = get(widget, key);
        return acc;
      },
      {} as Record<string, any>,
    );
  }
  const mutableStateProperties =
    getSettableProperties(widget.type)?.map((p) => {
      return {
        name: p.value,
        validationType: p.validationType,
      };
    }) ?? [];
  widgetProperties.mutableState = mutableStateProperties.reduce((acc, prop) => {
    acc[prop.name] = get(widget, prop.name);

    // if the value is undefined, and the type is string, set it to ""
    // so that the property doesn't get removed when we json encode and make the request
    if (
      acc[prop.name] === undefined &&
      prop.validationType === VALIDATION_TYPES.TEXT
    ) {
      acc[prop.name] = "";
    }

    return acc;
  }, {} as Record<string, any>);

  return widgetProperties;
};

export const addThemeValues = (
  widget: Partial<WidgetProps>,
  context: { theme: GeneratedTheme; featureFlags: Partial<AllFlags> },
) => {
  if (!widget.type) {
    return widget;
  }

  const widgetClass = getWidgetClassForType(widget.type as any) as any;

  const widgetPropertyThemeValues = getWidgetThemeValues({
    widgetClass,
    widget,
    flags: context.featureFlags,
    theme: context.theme,
  });
  for (const [key, value] of Object.entries(widgetPropertyThemeValues)) {
    if (get(widget, key) === undefined) {
      set(widget, key, value);
    }
  }
  return widget;
};

const moveProperties = (
  widget: Record<string, any>,
  _context: { theme: GeneratedTheme; featureFlags: Partial<AllFlags> },
) => {
  switch (widget.type) {
    case WidgetTypes.BUTTON_WIDGET:
      // move textColor to textProps.textStyle.textColor
      if (
        widget?.dynamicProperties?.textColor &&
        !widget?.dynamicProperties?.["textProps.textStyle.textColor.default"]
      ) {
        widget.dynamicProperties["textProps.textStyle.textColor.default"] =
          widget.dynamicProperties.textColor;
        delete widget.dynamicProperties.textColor;
      }
      break;
  }

  return widget;
};

export const transformComponentForAi = (
  component: Partial<WidgetProps>,
  context: { theme: GeneratedTheme; featureFlags: Partial<AllFlags> },
) => {
  const processors = [
    removeKeys,
    trimTables,
    addThemeValues,
    removeModeFromDimensions,
    sortProperties,
    moveProperties,
  ];

  const processedWidget = processors.reduce(
    (acc, processor) => processor(acc, context),
    component,
  );

  return processedWidget;
};

const transformStateVariableForAi = (stateVariable: DataTreeStateVar) => {
  return {
    name: stateVariable.name,
    scope: stateVariable.scope,
    value: {
      mutableState: {
        value: stateVariable.value,
      },
    },
  };
};

const createShape = (data: any): any => {
  if (data == null) return null;
  switch (typeof data) {
    case "string": {
      return "string value";
    }
    case "number": {
      return 0;
    }
    case "boolean": {
      return false;
    }
    case "object": {
      if (Array.isArray(data)) {
        if (data.length === 0) {
          return [];
        }
        return [createShape(data[0])];
      }
      return Object.keys(data).reduce((acc, key) => {
        acc[key] = createShape(data[key]);
        return acc;
      }, {} as Record<string, any>);
    }
    default: {
      return {};
    }
  }
};

const transformApiForAi = (api: DataTreeAction) => {
  return {
    name: api.name,
    value: {
      immutableState: {
        isLoading: api.isLoading,
        error: "",
        response: createShape(api.response),
      },
    },
  };
};

export const transformItemForAi = (
  item: any,
  context: { theme: GeneratedTheme; featureFlags: Partial<AllFlags> },
) => {
  switch (item.ENTITY_TYPE) {
    case ENTITY_TYPE.STATE_VAR:
      return transformStateVariableForAi(item);
    case ENTITY_TYPE.WIDGET:
      return transformComponentForAi(item, context);
    case ENTITY_TYPE.ACTION:
      return transformApiForAi(item);
    case ENTITY_TYPE.CUSTOM_EVENT:
      return {
        name: item.name,
        scope: item.scope,
        value: {
          dynamicProperties: {
            eventPayload: item.arguments.map((arg: EventArg) => ({
              [arg.name]: "",
            })),
          },
        },
      };
    case ENTITY_TYPE.TIMER: {
      return {
        name: item.name,
        scope: item.scope,
        value: {
          immutableState: {
            isActive: item.isActive,
          },
        },
      };
    }
    default:
      return {
        name: item.name,
        scope: item.scope,
        value: transformComponentForAi(item, context),
      };
  }
};
