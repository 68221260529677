import * as ScrollArea from "@radix-ui/react-scroll-area";
import React, { forwardRef, ReactNode } from "react";
import styled from "styled-components";
import { Layers } from "legacy/constants/Layers";
import { styleAsClass } from "styles/styleAsClass";

// The !important displays below are a workaround for the issue where the scroll area clips due to display: table
// See issue: https://github.com/radix-ui/primitives/issues/2964
const ScrollAreaViewportClass = styleAsClass`
  > div:first-child {
    overflow-x: inherit;
    min-height: 100%;
  }
  &[data-display="block"] > div:first-child {
    display: block !important;
  }
  &[data-display="flex"] > div:first-child {
    display: flex !important;
  }
`;

const ScrollAreaRoot = styled(ScrollArea.Root)`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;

  --z-index: ${Layers.scrollbars};
  --scrollbar-size: 10px;
  --mauve-10: #c1c1c1;
  --black-a6: rgba(0, 0, 0, 0.05);
  --black-a7: rgba(0, 0, 0, 0.1);

  .ScrollAreaViewport {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }

  .ScrollAreaScrollbar {
    display: flex;
    /* ensures no selection */
    user-select: none;
    /* disable browser handling of all panning and zooming gestures on touch devices */
    touch-action: none;
    padding: 2px;
    background: transparent;
    z-index: var(--z-index);

    &[data-orientation="vertical"] {
    }
    &[data-orientation="horizontal"] {
    }
  }
  .ScrollAreaCorner {
    background: transparent;
    z-index: var(--z-index);
  }
  .ScrollAreaScrollbar:hover,
  .ScrollAreaCorner:hover {
    background: transparent;
  }

  .ScrollAreaScrollbar[data-orientation="vertical"] {
    width: var(--scrollbar-size);
    padding-right: 3px;
  }

  .ScrollAreaScrollbar[data-orientation="horizontal"] {
    flex-direction: column;
    padding-bottom: 5px;
    height: calc(var(--scrollbar-size) + 2px);
  }

  .ScrollAreaThumb {
    flex: 1;
    background: var(--mauve-10);
    border-radius: var(--scrollbar-size);
    position: relative;
    z-index: var(--z-index);
  }
  /* increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html */
  .ScrollAreaThumb::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(0, -50%);
    width: 100%;
    height: 100%;
    min-width: 20px;
    min-height: 20px;
  }
`;

type ScrollContainerProps = {
  scroll?: boolean;
  verticalScroll?: boolean;
  horizontalScroll?: boolean;
  children?: ReactNode;
  style?: React.CSSProperties;
  className?: string;
  role?: string;
  innerStyle?: React.CSSProperties;
  scrollAreaId?: string;
  scrollHideDelay?: number;
  display?: "block" | "flex" | "table";
};

export const ScrollContainer = forwardRef<HTMLDivElement, ScrollContainerProps>(
  (
    {
      scroll = true,
      verticalScroll = true,
      horizontalScroll = false,
      children,
      style,
      className,
      role,
      innerStyle,
      scrollAreaId,
      scrollHideDelay = 0,
      display = "block",
    },
    ref,
  ) => {
    if (scroll === false) {
      return <>{children}</>;
    }
    return (
      <ScrollAreaRoot
        style={style}
        className={className}
        role={role}
        scrollHideDelay={scrollHideDelay}
      >
        <ScrollArea.Viewport
          ref={ref}
          className={`ScrollAreaViewport ${ScrollAreaViewportClass}`}
          data-display={display}
          style={innerStyle}
          id={scrollAreaId}
          data-superblocks={scrollAreaId}
        >
          {children}
        </ScrollArea.Viewport>
        {verticalScroll && (
          <ScrollArea.Scrollbar
            className="ScrollAreaScrollbar"
            orientation="vertical"
          >
            <ScrollArea.Thumb className="ScrollAreaThumb" />
          </ScrollArea.Scrollbar>
        )}
        {horizontalScroll && (
          <ScrollArea.Scrollbar
            className="ScrollAreaScrollbar"
            orientation="horizontal"
          >
            <ScrollArea.Thumb className="ScrollAreaThumb" />
          </ScrollArea.Scrollbar>
        )}
        <ScrollArea.Corner className="ScrollAreaCorner" />
      </ScrollAreaRoot>
    );
  },
);

ScrollContainer.displayName = "ScrollContainer";
