import {
  PostApiCommitCreateRequestBody,
  PostApplicationCommitCreateRequestBody,
} from "@superblocksteam/schemas";
import { VersionedEntityType } from "@superblocksteam/shared";
import { call, select } from "redux-saga/effects";
import { Toaster } from "legacy/components/ads/Toast";
import { Variant } from "legacy/components/ads/common";
import { getCurrentBranch } from "legacy/selectors/editorSelectors";
import { createSaga } from "../../../utils/saga";
import { resetEditor as resetEditorCall } from "../client";
import slice from "../slice";

function* resetEditorSaga({
  entityId,
  entityType,
  commitId,
}: {
  entityId: string;
  entityType: VersionedEntityType;
  commitId: string;
}): Generator<
  any,
  PostApplicationCommitCreateRequestBody | PostApiCommitCreateRequestBody,
  any
> {
  const branch: ReturnType<typeof getCurrentBranch> = yield select(
    getCurrentBranch,
  );
  const result:
    | PostApplicationCommitCreateRequestBody
    | PostApiCommitCreateRequestBody = yield call(
    resetEditorCall,
    entityId,
    entityType,
    branch?.name,
    commitId,
  );
  return result;
}

const resetEditor = createSaga(resetEditorSaga, resetEditorSaga.name, {
  sliceName: slice.name,
});
export { resetEditor as resetEditorSaga };

slice.saga(resetEditor, {
  start(state) {
    state.loading.commits = true;
  },
  success(state) {
    state.loading.commits = false;
  },
  error(state, { payload }) {
    state.error = { autosaves: payload, commits: payload };
    Toaster.show({
      text: String(payload),
      variant: Variant.danger,
    });
    state.loading.commits = false;
  },
});
