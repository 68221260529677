import { DatasourceMetadataDto } from "@superblocksteam/shared";
import { ReactComponent as CodeIcon } from "assets/icons/common/code.svg";
import { ReactComponent as EditIcon } from "assets/icons/common/edit.svg";
import { ReactComponent as ExplainIcon } from "assets/icons/common/explain.svg";
import { ReactComponent as DropdownIcon } from "assets/icons/widgets/dropdown.svg";
import { SyntaxType } from "code-formatting/constants";
import { EditorModes } from "components/app/CodeEditor/EditorConfig";
import { SYNTAX_HIGHLIGHTING_SUPPORTED_LANGUAGES } from "legacy/components/editorComponents/HighlightedCode";
import { colors } from "styles/colors";
import {
  AiAssistantRequest,
  AiAssistantOptionType,
  FlowType,
} from "./constants";

const getLanguage = () => {
  const language = navigator.language;
  if (!language) {
    return null;
  }
  return `LANGUAGE_${language.toUpperCase().replace("-", "_")}`;
};

type ContextProps = {
  integrationId?: string;
  configurationId?: string;
  datasourceMeta?: DatasourceMetadataDto;
  metadataEnabled?: boolean;
};

type GenerateProps = ContextProps & {
  flowType: FlowType.GENERATE;
  userInput: string;
  syntax: SyntaxType;
};

type ExplainProps = ContextProps & {
  flowType: FlowType.EXPLAIN;
  syntax: SyntaxType;
  selectedCode: string;
  allCode: string;
};

type MockProps = ContextProps & {
  flowType: FlowType.MOCK;
  userInput: string;
  syntax: SyntaxType;
  shape: string;
};

type EditProps = ContextProps & {
  flowType: FlowType.EDIT;
  userInput: string;
  syntax: SyntaxType;
  selectedCode: string;
  allCode: string;
};

export const generateRequestBodyForTask = (
  props: GenerateProps | ExplainProps | MockProps | EditProps,
): AiAssistantRequest => {
  const flowType = props.flowType;
  const shouldSendContext =
    props.metadataEnabled &&
    (props.integrationId || props.configurationId || props.datasourceMeta);
  switch (flowType) {
    case FlowType.GENERATE:
      return {
        task: {
          create: {
            prompt: props.userInput,
            syntax: props.syntax,
            ...(shouldSendContext
              ? {
                  context: {
                    integrationId: props.integrationId,
                    configurationId: props.configurationId,
                    metadata: props.datasourceMeta,
                  },
                }
              : {}),
          },
        },
      };
    case FlowType.EXPLAIN: {
      const hasSelectedCode =
        typeof props.selectedCode === "string" &&
        props.selectedCode.trim().length > 0;
      return {
        task: {
          explain: {
            contents: hasSelectedCode ? props.allCode : "",
            snippet: hasSelectedCode ? props.selectedCode : props.allCode,
            syntax: props.syntax,
            language: getLanguage() ?? "LANGUAGE_EN",
            ...(shouldSendContext
              ? {
                  context: {
                    integrationId: props.integrationId,
                    configurationId: props.configurationId,
                    metadata: props.datasourceMeta,
                  },
                }
              : {}),
          },
        },
      };
    }
    case FlowType.MOCK: {
      return {
        task: {
          mock: {
            prompt: props.userInput,
            syntax: props.syntax,
            shape: props.shape,
            ...(shouldSendContext
              ? {
                  context: {
                    integrationId: props.integrationId,
                    configurationId: props.configurationId,
                    metadata: props.datasourceMeta,
                  },
                }
              : {}),
          },
        },
      };
    }
    case FlowType.EDIT: {
      const hasSelectedCode =
        typeof props.selectedCode === "string" &&
        props.selectedCode.trim().length > 0;
      return {
        task: {
          edit: {
            prompt: props.userInput,
            syntax: props.syntax,
            contents: hasSelectedCode ? props.allCode : "",
            snippet: hasSelectedCode ? props.selectedCode : props.allCode,
            ...(shouldSendContext
              ? {
                  context: {
                    integrationId: props.integrationId,
                    configurationId: props.configurationId,
                    metadata: props.datasourceMeta,
                  },
                }
              : {}),
          },
        },
      };
    }
    default: {
      const exhaustiveCheck: never = flowType;
      throw new Error(`Unhandled action case: ${exhaustiveCheck}`);
    }
  }
};

export const getTitleForOption = (aiOption: AiAssistantOptionType): string => {
  if (aiOption.flowType === FlowType.MOCK) {
    return "Generate Mock Data";
  }
  const verb =
    aiOption.flowType === FlowType.GENERATE
      ? "Generate"
      : aiOption.flowType === FlowType.EDIT
      ? "Edit"
      : "Explain";
  let syntax;
  switch (aiOption.syntax) {
    case SyntaxType.JAVASCRIPT:
      syntax = "JavaScript";
      break;
    case SyntaxType.BINDING:
      syntax = "Binding";
      break;
    case SyntaxType.PYTHON:
      syntax = "Python";
      break;
    case SyntaxType.HTML:
      syntax = "HTML";
      break;
    case SyntaxType.POSTGRESQL:
    case SyntaxType.MSSQL:
    case SyntaxType.MYSQL:
    case SyntaxType.MARIADB:
    case SyntaxType.SNOWFLAKE:
    case SyntaxType.ORACLEDB:
      syntax = "SQL";
      break;
    case SyntaxType.REST_API:
      syntax = "REST API";
      break;
    case SyntaxType.GRAPHQL:
      syntax = "GraphQL";
      break;
    case SyntaxType.MONGODB:
      syntax = "MongoDB";
      break;
    case SyntaxType.DYNAMODB:
      syntax = "DynamoDB";
      break;
    default:
      syntax = "Code";
  }
  return `${verb} ${syntax}`;
};

export const getIconForOption = (
  aiOption: AiAssistantOptionType,
  iconLocation?: string,
):
  | {
      type: "svg";
      component: React.ComponentType<React.PropsWithChildren<unknown>>;
      color?: string;
    }
  | {
      type: "img";
      src: string;
    } => {
  if (aiOption.flowType === FlowType.EXPLAIN) {
    return {
      type: "svg",
      component: ExplainIcon,
    };
  }
  if (aiOption.flowType === FlowType.MOCK) {
    return {
      type: "svg",
      component: DropdownIcon,
    };
  }
  if (aiOption.flowType === FlowType.EDIT) {
    return {
      type: "svg",
      component: EditIcon,
      color: colors.ACCENT_ORANGE_600,
    };
  }
  switch (aiOption.syntax) {
    case SyntaxType.JAVASCRIPT:
    case SyntaxType.BINDING:
      return {
        type: "img",
        src: "https://superblocks.s3-us-west-2.amazonaws.com/img/integrations/javascript.png",
      };
    case SyntaxType.PYTHON:
      return {
        type: "img",
        src: "https://superblocks.s3-us-west-2.amazonaws.com/img/integrations/python.png",
      };
    case SyntaxType.HTML:
      return {
        type: "svg",
        component: CodeIcon,
      };
    default:
      return {
        type: "img",
        src:
          iconLocation ??
          "https://superblocks.s3-us-west-2.amazonaws.com/img/integrations/postgres.png",
      };
  }
};

export const makeCommentForSyntax = (
  syntax: SyntaxType,
  comment: string,
): string => {
  switch (syntax) {
    case SyntaxType.JAVASCRIPT:
      return `/* ${comment} */\n`;
    case SyntaxType.PYTHON:
      return `${comment
        .split("\n")
        .map((line) => `# ${line}`)
        .join("\n")}\n`;
    case SyntaxType.HTML:
      return `<!-- ${comment} -->\n`;
    case SyntaxType.POSTGRESQL:
    case SyntaxType.MSSQL:
    case SyntaxType.MYSQL:
    case SyntaxType.MARIADB:
    case SyntaxType.SNOWFLAKE:
    case SyntaxType.COCKROACHDB:
    case SyntaxType.REDSHIFT:
    case SyntaxType.ORACLEDB:
      return `${comment
        .split("\n")
        .map((line) => `-- ${line}`)
        .join("\n")}\n`;
    default:
      return comment;
  }
};

export const getEditorModeForSyntax = (syntax: SyntaxType): EditorModes => {
  switch (syntax) {
    case SyntaxType.PYTHON:
      return EditorModes.PYTHON;
    case SyntaxType.JAVASCRIPT:
      return EditorModes.JAVASCRIPT;
    case SyntaxType.BINDING:
      return EditorModes.TEXT_WITH_BINDING;
    default:
      return EditorModes.SQL_WITH_BINDING;
  }
};

export const getHighlightSyntaxForSyntax = (
  syntax: SyntaxType,
): SYNTAX_HIGHLIGHTING_SUPPORTED_LANGUAGES => {
  switch (syntax) {
    case SyntaxType.PYTHON:
      return SYNTAX_HIGHLIGHTING_SUPPORTED_LANGUAGES.PYTHON;
    case SyntaxType.MARIADB:
    case SyntaxType.MYSQL:
    case SyntaxType.POSTGRESQL:
    case SyntaxType.MSSQL:
    case SyntaxType.REDSHIFT:
    case SyntaxType.SNOWFLAKE:
    case SyntaxType.COCKROACHDB:
    case SyntaxType.ORACLEDB:
      return SYNTAX_HIGHLIGHTING_SUPPORTED_LANGUAGES.SQL;
    case SyntaxType.HTML:
      return SYNTAX_HIGHLIGHTING_SUPPORTED_LANGUAGES.HTML;
    default:
      // works for JSON + JS
      return SYNTAX_HIGHLIGHTING_SUPPORTED_LANGUAGES.JAVASCRIPT;
  }
};

export const makeParseKeyValueArrayToString =
  (divider: string) => (value: Array<{ key: string; value: string }>) => {
    return value
      .map((item) => {
        if (item.key) {
          return `${item.key}${divider}${item.value ?? ""}`;
        }
        return null;
      })
      .filter(Boolean)
      .join("\n");
  };

export const makeParseStringToKeyValueArray =
  (divider: string) => (value: string) => {
    return value.split("\n").map((item) => {
      const [key, ...rest] = item.split(divider);
      return {
        key,
        value: rest.join(divider),
      };
    });
  };

export const parseToString = (value: unknown): string | number | boolean => {
  if (value == null) {
    return "--";
  }
  if (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  ) {
    if (typeof value === "string" && value.length === 0) {
      return "--";
    }
    return value;
  }
  if (typeof value === "object") {
    if (
      (Array.isArray(value) && value.length === 0) ||
      Object.keys(value).length === 0
    ) {
      return "--";
    }
  }
  return JSON.stringify(value);
};

export const parseFromString = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};
