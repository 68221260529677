import { KeyHandler, HotkeysEvent } from "hotkeys-js";
import { MutableRefObject, useEffect } from "react";
import { Options, useHotkeys } from "react-hotkeys-hook";
import { postMessageQueue } from "legacy/pages/Editor/IframeWrapper";
import { Flag } from "store/slices/featureFlags";
import {
  isEmbeddedBySuperblocksFirstParty,
  iframeMessageHandler,
  sendMessage,
  IframeEventHandler,
  isChildRegistered,
} from "utils/iframe";
import { useFeatureFlag } from "./useFeatureFlag";

/**
 * Use this function in place of useHotkeys when you want to register a hotkey
 * that will work through the iframe boundary.
 */
export function useGlobalHotkeys(
  keys: string,
  callback: KeyHandler,
  options?: Options,
  deps?: any[],
): MutableRefObject<HTMLElement | null> {
  const isIframeEnabled = useFeatureFlag(Flag.ENABLE_IFRAME);

  useEffect(() => {
    if (isIframeEnabled && !isEmbeddedBySuperblocksFirstParty()) {
      if (isChildRegistered()) {
        sendMessage({ type: "register-keypress", payload: { keys, options } });
      } else {
        postMessageQueue.push({
          type: "register-keypress",
          payload: { keys, options },
        });
      }
    }
  }, [isIframeEnabled, keys, options]);

  useEffect(() => {
    if (isIframeEnabled) {
      // TODO: Verify keypress source
      const keypressEventListener: IframeEventHandler<"keypress"> = (e) => {
        const { keys: receivedKeys } = e.data?.payload ?? {};
        if (receivedKeys === keys) {
          callback(new KeyboardEvent("keydown"), {} as HotkeysEvent);
        }
      };
      iframeMessageHandler.addEventListener("keypress", keypressEventListener);
      return () => {
        iframeMessageHandler.removeEventListener(
          "keypress",
          keypressEventListener,
        );
      };
    }
  }, [callback, keys, isIframeEnabled]);
  return useHotkeys(keys, callback, options, deps);
}
