import { PostApiCreateRequestBody } from "@superblocksteam/schemas";
import { getNextEntityName } from "@superblocksteam/shared";
import { call, put, select } from "redux-saga/effects";
import { updatePartialApiInfo } from "legacy/actions/apiActions";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { getDataTree } from "legacy/selectors/dataTreeSelectors";
import {
  getCurrentBranch,
  getCurrentPageId,
} from "legacy/selectors/editorSelectors";
import {
  getApiInfoById,
  getAllEntityNames,
} from "legacy/selectors/sagaSelectors";
import { createFakeDataTree } from "store/helpers/refactoring";
import { createDuplicatePartialApiInfo } from "store/slices/apisShared/createDuplicatePartialApiInfo";
import { createSaga } from "../../../utils/saga";
import { createV3Api } from "../client";
import { selectAllApis } from "../selectors";
import slice from "../slice";
import { ApiDto, ApiTriggerType } from "../types";
import { createDuplicateApiPayload } from "../utils/createDuplicateApiPayload";

function* duplicateApiInternal(params: {
  apiId: string;
}): Generator<unknown, ApiDto, any> {
  let result: ApiDto | undefined = undefined;

  const origApiId = params.apiId;
  const apis = yield select(selectAllApis);

  const originalApi: undefined | ApiDto = apis[origApiId];
  if (originalApi == null) {
    throw new Error(`API with ID "${origApiId}" does not exist.`);
  }
  const allNames: string[] = yield select(getAllEntityNames);

  const newName = getNextEntityName("API", allNames);
  const pageId = yield select(getCurrentPageId);
  const payload: PostApiCreateRequestBody = createDuplicateApiPayload(
    originalApi,
    {
      newName,
    },
  );
  payload.pageId = pageId;

  const generator = (triggerType: ApiTriggerType) => {
    switch (triggerType) {
      case ApiTriggerType.UI:
        return createV3Api;
      case ApiTriggerType.WORKFLOW:
      case ApiTriggerType.SCHEDULE:
        // This code path is unreachable for now
        throw new Error("Non UI API duplication not yet supported.");
      default:
        throw new Error("Invalid Trigger Type");
    }
  };

  const branch: ReturnType<typeof getCurrentBranch> = yield select(
    getCurrentBranch,
  );
  result = yield call(generator(payload.triggerType), branch?.name, payload);
  yield put({
    type: ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE,
    payload: result?.updated,
  });

  if (result) {
    const dataTree = yield select(getDataTree);
    const fakeDataTree = createFakeDataTree(dataTree);

    const origApiInfo = yield select(getApiInfoById, origApiId);
    const newPartialInfo = yield call(() =>
      createDuplicatePartialApiInfo(origApiInfo, {
        newId: result?.id ?? "",
        originalApiName: originalApi?.actions?.name ?? "",
        newApiName: result?.actions?.name ?? "",
        fakeDataTree,
      }),
    );

    yield put(
      updatePartialApiInfo({
        id: result.id,
        data: newPartialInfo,
      }),
    );
  }

  return result as ApiDto;
}

export const duplicateApiSaga = createSaga(
  duplicateApiInternal,
  "duplicateApiSaga",
  {
    sliceName: "apis",
  },
);

slice.saga(duplicateApiSaga, {
  start(state, { payload }) {
    state.loading[payload.apiId] = true;
  },
  success(state, { payload, meta }) {
    state.entities[payload.id] = payload;
    delete state.loading[meta.args.apiId];
  },
  error(state, { payload, meta }) {
    state.errors[meta.args.apiId] = { error: payload };
    delete state.loading[meta.args.apiId];
  },
});
