import {
  OrganizationUserDto,
  OrganizationUserStatus,
} from "@superblocksteam/shared";
import moment from "moment";

export const formatDate = (date: Date) => moment(date).format("MMMM D, YYYY");

export type StatusType = "active" | "deactivated" | "invited";

export type UserToRender = {
  name: string;
  email: string;
  lastLogin: Date | undefined;
  status: StatusType;
  roleId: string;
  id: string;
  roleAssignmentId: string;
  roleName: string;
};

const convertStatus = (status: OrganizationUserStatus): StatusType => {
  switch (status) {
    case OrganizationUserStatus.ACTIVE:
      return "active";
    case OrganizationUserStatus.INACTIVE:
      return "deactivated";
    case OrganizationUserStatus.PENDING:
      return "invited";
  }
};

export const convertToUserToRender = (
  user: OrganizationUserDto,
): UserToRender => ({
  name: user.name,
  email: user.email,
  lastLogin: user.lastJwtIssuedAt,
  status: convertStatus(user.status),
  id: user.id,
  roleId: user.assignments?.[0]?.assignmentId ?? "",
  roleAssignmentId: user.assignments?.[0]?.id ?? "",
  roleName: user.assignments?.[0]?.role?.name ?? "",
});

export function userCompareFn(
  member1: UserToRender,
  member2: UserToRender,
): number {
  const sortOrder: Record<StatusType, number> = {
    active: 0,
    invited: 1,
    deactivated: 2,
  };
  // undefined status should be last
  const member1Order =
    sortOrder[member1.status] ?? Object.keys(sortOrder).length;
  const member2Order =
    sortOrder[member2.status] ?? Object.keys(sortOrder).length;
  // first, compare by status
  const statusComparison = member1Order - member2Order;
  if (statusComparison !== 0) {
    return statusComparison;
  }
  // finally, compare by email
  return member1.email.localeCompare(member2.email);
}
