import { Agent, ApiTriggerType, Profile } from "@superblocksteam/shared";
import { call, select } from "redux-saga/effects";
import { Profiles } from "legacy/reducers/entityReducers/appReducer";
import {
  ProfilesWithModes,
  getAppProfilesInCurrentMode,
  getWorkflowProfiles,
} from "legacy/selectors/applicationSelectors";
import { getActiveAgents } from "store/slices/apisShared/utils";
import { Api } from "store/slices/apisV2/backend-types";
import { getTriggerTypeFromApi } from "store/slices/apisV2/utils/api-utils";
import { getEnvironment } from "store/slices/application/selectors";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";

export function* getOpaAgents(api?: Api) {
  const organization: ReturnType<typeof selectOnlyOrganization> = yield select(
    selectOnlyOrganization,
  );

  const environment: ReturnType<typeof getEnvironment> = yield select(
    getEnvironment,
  );
  // get profile given mode
  let profile: Profile | undefined;
  const triggerType = api ? getTriggerTypeFromApi(api) : ApiTriggerType.UI;
  if (triggerType === ApiTriggerType.UI) {
    // select app profiles if in Application mode
    const profiles: Profiles = yield select(getAppProfilesInCurrentMode);
    profile = profiles?.selected;
  } else if (api) {
    const profiles: ProfilesWithModes = yield select(
      getWorkflowProfiles,
      api.metadata.id,
    );
    profile = profiles?.editor.default;
  }

  const enableProfiles: boolean = yield select(
    selectFlagById,
    Flag.ENABLE_PROFILES,
  );

  const agentsResponse: Agent[] | { systemError: string } = yield call(
    getActiveAgents,
    {
      organization,
      environment,
      enableProfiles,
      profile,
      sendNotification: false,
    },
  );

  return (agentsResponse as { systemError: string })?.systemError
    ? []
    : agentsResponse;
}
