import React, { useState, useEffect } from "react";
import "utils/polyfills/requestIdleCallback";

type Props = {
  children: React.ReactNode;
};

function IdleRender({ children }: Props): JSX.Element {
  const childrenArray = React.Children.toArray(children);
  const [nextIndex, setNextIndex] = useState<number>(2);

  useEffect(() => {
    if (nextIndex < childrenArray.length) {
      const idleCallbackId = requestIdleCallback(
        () => setNextIndex(nextIndex + 1),
        {
          timeout: 50,
        },
      );
      return () => cancelIdleCallback(idleCallbackId);
    }
  }, [childrenArray.length, nextIndex]);

  const childrenToRender =
    nextIndex < childrenArray.length
      ? childrenArray.slice(0, nextIndex)
      : childrenArray;
  return <>{childrenToRender}</>;
}

export default IdleRender;
