import * as Sentry from "@sentry/react";
import { ApplicationScope, UIErrorType } from "@superblocksteam/shared";
import { select } from "redux-saga/effects";
import { Toaster } from "legacy/components/ads/Toast";
import { Variant } from "legacy/components/ads/common";
import { ScopedDataTreePath } from "legacy/entities/DataTree/dataTreeFactory";
import { getEvaluationInverseDependencyMap } from "legacy/selectors/dataTreeSelectors";
import { getPageCachedData } from "legacy/selectors/sagaSelectors";
import { DependencyMap } from "legacy/utils/DynamicBindingTypes";
import { EvalError, EvalErrorTypes } from "legacy/utils/DynamicBindingUtils";
import { selectEntityDependencyMap } from "store/slices/apis";
import {
  selectAllV2Apis,
  selectV2ApiExtractedBindings,
  getV2ApiToComponentDepsSaga,
  selectPageLoadApis,
} from "store/slices/apisV2";
import apisV2Slice from "store/slices/apisV2/slice";
import {
  getV2ApiId,
  getV2ApiName,
} from "store/slices/apisV2/utils/getApiIdAndName";
import { callSagas } from "store/utils/saga";
import UITracing from "tracing/UITracing";
import { fastClone } from "utils/clone";
import toposort from "utils/dag";
import { getAllParentPaths, getEntityName } from "utils/dottedPaths";
import logger from "utils/logger";
import {
  sendErrorUINotification,
  sendWarningUINotification,
} from "utils/notification";

function* forwardMap(
  loadApiBindings: { id: string; name: string; bindings: Set<string> }[],
) {
  const inverseDepMap: Record<string, string[]> =
    yield inverseDepWithAPIResponse(
      loadApiBindings.map((m) => ({
        ...m,
        name: `${ApplicationScope.PAGE}.${m.name}`,
      })),
    );
  const forwardDepMap: Record<string, Set<string>> = {};
  for (const [dependedOnName, names] of Object.entries(inverseDepMap)) {
    for (const name of names) {
      if (forwardDepMap[name]) {
        forwardDepMap[name].add(dependedOnName);
      } else {
        forwardDepMap[name] = new Set([dependedOnName]);
      }
    }
  }
  return forwardDepMap;
}
export function* apiProps() {
  const pageLoadApis: ReturnType<typeof selectPageLoadApis> = yield select(
    selectPageLoadApis,
  );
  const allApis: ReturnType<typeof selectAllV2Apis> = yield select(
    selectAllV2Apis,
  );

  const loadApiProps = new Set<ScopedDataTreePath>([]);
  pageLoadApis
    .flatMap((api) => [
      `${getV2ApiName(api) ?? ""}.response`,
      `${getV2ApiName(api) ?? ""}.error`,
    ])
    .forEach((prop) => loadApiProps.add(`${ApplicationScope.PAGE}.${prop}`));

  const allApiProps = new Set<ScopedDataTreePath>([]);
  Object.values(allApis)
    .flatMap(
      (api) =>
        [
          `${ApplicationScope.PAGE}.${getV2ApiName(api) ?? ""}.response`,
          `${ApplicationScope.PAGE}.${getV2ApiName(api) ?? ""}.error`,
        ] as const,
    )
    .forEach((prop) => allApiProps.add(prop));

  return { allApiProps, loadApiProps };
}

export function* extractBindingsForPageLoadAPIsV2(
  // use page load APIs from cached data if available
  useApisFromCachedData: boolean,
) {
  const apiState: ReturnType<typeof apisV2Slice.selector> = yield select(
    apisV2Slice.selector,
  );
  const apisByName = Object.fromEntries(
    Object.values(apiState.entities).map((api) => [getV2ApiName(api), api]),
  );
  if (useApisFromCachedData) {
    const cachedData: ReturnType<typeof getPageCachedData> = yield select(
      getPageCachedData,
    );
    if (cachedData?.pageLoadActions) {
      yield callSagas([
        getV2ApiToComponentDepsSaga.apply({
          apiIdsToAnalyze: cachedData.pageLoadActions.apiNames
            .map((apiName) => getV2ApiId(apisByName[apiName]))
            .filter(Boolean),
        }),
      ]);
      return;
    }
  }
  const entityDepMap: DependencyMap = yield select(selectEntityDependencyMap);
  const dependentApiNames = new Set<string>();
  Object.values(entityDepMap).forEach((dependencies) => {
    dependencies.forEach((dependency) => {
      const api = apisByName[dependency];
      if (
        api &&
        api.apiPb?.trigger.application?.options?.executeOnPageLoad !== false
      ) {
        dependentApiNames.add(dependency);
      }
    });
  });

  // Add APIs that are explicitly called
  Object.values(apiState.entities).forEach((api) => {
    if (api?.apiPb?.trigger.application?.options?.executeOnPageLoad === true) {
      dependentApiNames.add(getV2ApiName(api) ?? "");
    }
  });

  let apiBatch = [...dependentApiNames];
  while (apiBatch.length > 0) {
    yield callSagas([
      getV2ApiToComponentDepsSaga.apply({
        apiIdsToAnalyze: apiBatch
          .map((apiName) => getV2ApiId(apisByName[apiName]))
          .filter(Boolean),
      }),
    ]);
    // obtain the updated apiState
    const apiState: ReturnType<typeof apisV2Slice.selector> = yield select(
      apisV2Slice.selector,
    );
    const nextApiBatch = new Set<string>();
    apiBatch.forEach((apiName) => {
      const bindings =
        apiState.meta[getV2ApiId(apisByName[apiName])].extractedBindings;
      bindings?.forEach((binding) => {
        const dependentEntityName = getEntityName(binding.dataTreePath);
        const api = apisByName[dependentEntityName];
        if (
          api &&
          !dependentApiNames.has(dependentEntityName) &&
          api.apiPb?.trigger.application?.options?.executeOnPageLoad !== false
        ) {
          dependentApiNames.add(dependentEntityName);
          nextApiBatch.add(dependentEntityName);
        }
      });
    });
    apiBatch = [...nextApiBatch];
  }
}

export function* getAllApiBindings(): Generator<
  any,
  Array<{
    id: string;
    name: string;
    bindings: Set<ScopedDataTreePath>;
  }>,
  any
> {
  const pageLoadApis: ReturnType<typeof selectPageLoadApis> = yield select(
    selectPageLoadApis,
  );
  const allV2Bindings: ReturnType<typeof selectV2ApiExtractedBindings> =
    yield select(selectV2ApiExtractedBindings);

  const loadApiBindings: {
    id: string;
    name: string;
    bindings: Set<ScopedDataTreePath>;
  }[] = [];

  pageLoadApis.forEach((api) => {
    // `id` might a temporary action id, so it might not be present in `allBindings`
    const bindObj = allV2Bindings[getV2ApiId(api)];

    if (bindObj) {
      loadApiBindings.push({
        id: bindObj.id,
        name: bindObj.name,
        bindings: new Set(bindObj.bindings?.map((b) => b.dataTreePath) ?? []),
      });
    }
  });
  return loadApiBindings;
}

function* inverseDepWithAPIResponse(
  loadApiBindings: { id: string; name: string; bindings: Set<string> }[],
) {
  const inverseMap: Record<string, string[]> = yield select(
    getEvaluationInverseDependencyMap,
  );
  const inverseDepMap: Record<string, string[]> = fastClone(inverseMap);

  loadApiBindings.forEach(({ name, bindings }) => {
    const apiResponsePath = `${name}.response`;

    for (const path of bindings) {
      // Dependency path -> api name
      const prevValue = inverseDepMap[path];
      if (prevValue) {
        if (!prevValue.includes(apiResponsePath)) {
          inverseDepMap[path].push(apiResponsePath);
        }
      } else {
        inverseDepMap[path] = [apiResponsePath];
      }
    }
  });
  return inverseDepMap;
}

export function* apiToApiDep(
  loadApiBindings: { id: string; name: string; bindings: Set<string> }[],
  allApiProps: Set<ScopedDataTreePath>,
  loadApiProps: Set<ScopedDataTreePath>,
) {
  const loadApiNames = loadApiBindings.map(({ name }) => name);
  const forwardDepMap = yield* forwardMap(loadApiBindings);

  /**
   * It takes an API name, and modifies (via @param mutableDeps) a set of all the other APIs that it depends on.
   * @param apiSourceName - the name of the API that we're currently extracting dependencies
   * @param dependencyName - the name of the entity that we're currently looking at
   * @param mutableDeps - a set of dependencies that will add to as we traverse the dependency graph.
   * @param mutablePaths - a set of paths for the extracted APIs
   * @param visited - an array of all the entities that we have traversed so far
   * @param path - the path that we're currently traversing
   */
  const extractDependenciesOnOtherApis = (
    apiSourceName: string,
    dependencyName: string,
    mutableDeps: Set<string>,
    mutablePaths: Map<string, string>,
    visited: Set<string> = new Set(),
    path: string[] = [],
  ) => {
    let actualDependencyName = dependencyName;
    if (!forwardDepMap[dependencyName]) {
      for (const ancestor of getAllParentPaths(dependencyName)) {
        if (forwardDepMap[ancestor]) {
          actualDependencyName = ancestor;
          break;
        }
      }
    }

    visited.add(dependencyName);

    for (const nextDepName of forwardDepMap[actualDependencyName] || []) {
      if (visited.has(nextDepName)) {
        continue;
      }

      // determine if nextDepName is used by a load API
      // note that it is possible that an ancestor of nextDepName is used by a load API, so we need to check all ancestors as well
      let isALoadApiDep = false;
      const [, ...parentPaths] = getAllParentPaths(nextDepName); // Skip the first as it's just the scope
      for (const ancestor of parentPaths as ScopedDataTreePath[]) {
        if (loadApiProps.has(ancestor)) {
          isALoadApiDep = true;
          break;
        }
      }

      if (isALoadApiDep) {
        mutableDeps.add(nextDepName);
        mutablePaths.set(nextDepName, path.concat(nextDepName).join(" ➜ "));
        continue;
      } else if (allApiProps.has(nextDepName as ScopedDataTreePath)) {
        // We have found an API that doesn't run on load, which we treat as if it has no dependencies
        continue;
      }

      path.push(nextDepName);
      extractDependenciesOnOtherApis(
        apiSourceName,
        nextDepName,
        mutableDeps,
        mutablePaths,
        visited,
        [...path],
      );
      path.pop();
    }
  };

  const apiToApiDependencies: Record<string, Set<string>> = {};
  const apiToApiPaths: Record<string, Map<string, string>> = {};

  loadApiNames
    .sort((name1, name2) => name1.localeCompare(name2))
    .forEach((apiName) => {
      const seenAPIs = new Set<ScopedDataTreePath>();
      const paths = new Map<ScopedDataTreePath, ScopedDataTreePath>();
      extractDependenciesOnOtherApis(
        `${ApplicationScope.PAGE}.${apiName}.response`,
        `${ApplicationScope.PAGE}.${apiName}.response`,
        seenAPIs,
        paths,
      );

      // Each API dependency might depend on the same other APIs, as long as there are no cycles
      apiToApiDependencies[apiName] = Array.from(seenAPIs)
        .map(getEntityName)
        .filter((d) => d !== apiName) // Remove self
        .reduce((acc, cur) => acc.add(cur), new Set<string>());

      // A list of how each API is connected to the other API
      apiToApiPaths[apiName] = [...paths.entries()]
        .map(([k, v]) => [getEntityName(k), v])
        .reduce((acc, [k, v]) => acc.set(k, v), new Map<string, string>());
    });

  // Use a topo sort to find any cycles, the graph is likely to be small
  const edges = Object.entries(apiToApiDependencies).flatMap(([name, deps]) => {
    return [...deps.values()].map((dep) => [name, dep]);
  }) as [string, string][];
  const { errors } = toposort(edges);

  errors.forEach(({ nodes, message }) => {
    // Remove items that are in the cycle
    nodes.forEach((node) => {
      delete apiToApiDependencies[node];
    });
    Object.values(apiToApiDependencies).forEach((deps) => {
      nodes.forEach((node) => deps.delete(node));
    });

    logger.warn("pageLoad Circular Dependency: " + message);

    // Build a path list for the api (assumes cycle is in order)
    const path = nodes.map((n, i, ns) =>
      apiToApiPaths[n].get(ns[(i + 1) % ns.length]),
    );
    path.unshift(path[path.length - 1]);

    const notification = getCircularErrorMessage(nodes);
    sendWarningUINotification({
      message: notification,
      description: path.join(" ➜ "),
      duration: 30,
    });
  });

  return apiToApiDependencies;
}

function getCircularErrorMessage(apis: string[]) {
  let nodesString;
  let pronoun;
  switch (apis.length) {
    case 1:
      nodesString = apis[0];
      pronoun = "it";
      break;
    case 2:
      nodesString = apis.join(" and ");
      pronoun = "both";
      break;
    default:
      nodesString = apis.join(", ");
      pronoun = "all";
      break;
  }

  const notification = `Cycle detected on load: ${nodesString} will not run. If you don’t need ${pronoun} to run on load consider turning ${
    apis.length === 1 ? "it" : "one"
  } off in the API Configuration.`;
  return notification;
}

export const evalErrorHandler = (errors: EvalError[], spanId?: string) => {
  if (!errors) return;
  errors.forEach((error) => {
    switch (error.type) {
      case EvalErrorTypes.DEPENDENCY_ERROR:
        sendErrorUINotification({
          message: error.message,
          duration: 20,
        });
        break;
      case EvalErrorTypes.VALIDATION_ERROR:
        logger.error(
          `Validation Error stopping the evaluation: ${error.message}`,
          {
            superblocks_ui_error_type: UIErrorType.VALIDATION_ERROR,
          },
        );
        sendErrorUINotification({
          message: error.message,
        });
        break;
      case EvalErrorTypes.EVAL_TREE_ERROR:
        Toaster.show({
          text: "Unexpected error occurred while evaluating the app",
          variant: Variant.danger,
        });
        break;
      case EvalErrorTypes.BAD_UNEVAL_TREE_ERROR:
        Sentry.captureException(error);
        break;
    }
    UITracing.addEvent(
      spanId,
      error.message ?? "An unexpected error occurred while evaluating the app.",
      UIErrorType.VALIDATION_ERROR,
    );
    logger.debug(error);
  });
};
