import {
  ApplicationScope,
  isValidStepDef,
  StepDef,
  WidgetTypes,
} from "@superblocksteam/shared";
import { diff } from "deep-diff";
import { get, isEqual, uniqBy } from "lodash";
import { EventDefinitionScoped } from "store/slices/application/events/eventConstants";
import { fastClone } from "utils/clone";
import {
  AddActionEvent,
  AddEventAction,
  ComponentEditAction,
  RemoveEventAction,
} from "./types";

const pathContainsEventHandlerKey = (path: string[]): string[] | boolean => {
  for (let i = 0; i < path.length; i++) {
    if (path[i].match(/^on[A-Z][a-zA-Z]+/)) {
      // return path up to i
      return path.slice(0, i + 1);
    }
  }
  return false;
};

const transformEventHandler = (
  eventHandler: StepDef,
  eventsByScopeAndId: Record<
    ApplicationScope,
    Record<string, EventDefinitionScoped>
  >,
): AddActionEvent | undefined => {
  // in the UI, the event handlers are slightly different than what the AI deals with
  // the UI events can also have dangling properties from when the event type is changed
  // so this creates a strict typing for the events
  if (!isValidStepDef(eventHandler)) {
    return undefined;
  }
  switch (eventHandler.type) {
    case "setComponentProperty":
      return {
        type: eventHandler.type,
        component: eventHandler.widget.name,
        propertyName: eventHandler.propertyName,
        propertyValue: eventHandler.propertyValue,
      };
    case "resetComponent": {
      return {
        type: eventHandler.type,
        component: eventHandler.widget.name,
        propertyName: eventHandler.propertyName,
      };
    }
    case "navigateToRoute": {
      return {
        type: eventHandler.type,
        routePathDescriptor: eventHandler.routePathDescriptor,
      };
    }
    case "setStateVar": {
      return {
        type: eventHandler.type,
        state: {
          name: eventHandler.state.name,
          scope: eventHandler.state.scope ?? "PAGE",
        },
        value: eventHandler.value,
      };
    }
    case "resetStateVar": {
      return {
        type: eventHandler.type,
        state: {
          name: eventHandler.state.name,
          scope: eventHandler.state.scope ?? "PAGE",
        },
      };
    }
    case "controlTimer": {
      return {
        type: eventHandler.type,
        state: {
          name: eventHandler.state?.name ?? "",
          scope: eventHandler.state?.scope ?? "PAGE",
        },
        command: eventHandler.command,
      };
    }
    case "triggerEvent": {
      // TODO: we need to get the argument names for the event payload
      const event =
        eventsByScopeAndId[eventHandler.event.scope ?? ApplicationScope.PAGE]?.[
          eventHandler.event.id
        ];
      if (event) {
        const eventPayload = Object.entries(eventHandler.eventPayload).map(
          ([key, value]) => ({
            name: event.arguments.find((a) => a.id === key)?.name ?? key,
            value,
          }),
        );
        return {
          type: eventHandler.type,
          event: {
            name: eventHandler.event.name,
            scope: eventHandler.event.scope ?? "PAGE",
          },
          eventPayload,
        };
      }
      return undefined;
    }
    case "executeApi":
    case "cancelApi": {
      return {
        type: eventHandler.type,
        apiNames: eventHandler.apiNames,
      };
    }
    case "runJs": {
      return {
        type: eventHandler.type,
        code: eventHandler.code ?? "",
      };
    }
    case "setProfile": {
      return {
        type: eventHandler.type,
        profileAction: eventHandler.profileAction,
        profileId: eventHandler.profileId,
      };
    }
    case "showAlert": {
      return {
        type: eventHandler.type,
        message: eventHandler.message,
        style: eventHandler.style,
        alertDuration: eventHandler.alertDuration,
        alertPosition: eventHandler.alertPosition,
      };
    }
    case "controlModal":
    case "controlSlideout": {
      return {
        type: eventHandler.type,
        name: eventHandler.name,
        direction: eventHandler.direction ?? "open",
      };
    }
    case "navigateTo": {
      return {
        type: eventHandler.type,
        url: eventHandler.url,
        newWindow: eventHandler.newWindow,
        replaceHistory: eventHandler.replaceHistory ?? false,
      };
    }
    default: {
      return undefined;
    }
  }
};

export const processChangesIntoActions = ({
  preAiValues,
  initialValues,
  updatedValues,
  actions,
  eventsByScopeAndId,
}: {
  preAiValues: Record<string, unknown>;
  initialValues: Record<string, unknown>;
  updatedValues: Record<string, unknown>;
  actions: ComponentEditAction[];
  eventsByScopeAndId: Record<
    ApplicationScope,
    Record<string, EventDefinitionScoped>
  >;
}): ComponentEditAction[] => {
  // if they are the same, return the actions
  if (isEqual(initialValues, updatedValues)) {
    return actions;
  }
  // reconstruct actions based on the updated values
  // deeply diff the initial and updated values
  const valuesDiff = diff(initialValues, updatedValues);
  let newActions: ComponentEditAction[] = fastClone(actions);
  let eventHandlerKeyChanges: string[][] = [];

  if (valuesDiff) {
    for (const d of valuesDiff) {
      const { kind, path } = d;
      // check if path contains an event handler key
      const eventHandlerPath = pathContainsEventHandlerKey(path ?? []);
      if (eventHandlerPath) {
        eventHandlerKeyChanges.push(eventHandlerPath as string[]);
        newActions = newActions.filter(
          (a) => a.property !== (eventHandlerPath as string[]).join("."),
        );
        continue;
      }

      switch (kind) {
        case "E":
        case "N": {
          if (path) {
            const actionsRef = newActions;
            const currentPath = path.join(".");
            const action =
              actionsRef &&
              Array.isArray(actionsRef) &&
              actionsRef.find((a) => a.property === currentPath);

            if (action) {
              // check if the value has been reverted back to the original value
              if (isEqual(d.rhs, get(preAiValues, path))) {
                // remove this action entirely
                const index = actionsRef.findIndex(
                  (a) => a.property === currentPath,
                );
                actionsRef.splice(index, 1); // update in place so that newActions is updated
              } else {
                // just update the value
                action.value = d.rhs;
              }
            } else {
              // this is a new property, add an action for it
              actionsRef.push({
                action: "set",
                property: currentPath,
                value: d.rhs,
              });
            }
          }
        }
      }
    }
  }

  // make sure event handle key changes are unique
  eventHandlerKeyChanges = uniqBy(eventHandlerKeyChanges, (path) =>
    path.join("."),
  );

  // recreate actions for all of the event handler diffs
  for (const eventHandlerPath of eventHandlerKeyChanges) {
    const preAiValue = fastClone(get(preAiValues, eventHandlerPath));
    const oldValue = fastClone(get(initialValues, eventHandlerPath));
    const newValue = fastClone(get(updatedValues, eventHandlerPath));
    // find actions that changed
    const editedActionIds = [];
    const deletedActionIds = [];
    const createdActionIds = [];
    for (const event of oldValue) {
      const newEvent = newValue.find((e: any) => e.id === event.id);
      if (!newEvent) {
        deletedActionIds.push(event.id);
      } else {
        editedActionIds.push(event.id);
      }
    }
    for (const event of newValue) {
      if (!oldValue.find((e: any) => e.id === event.id)) {
        createdActionIds.push(event.id);
      }
    }
    const eventHandlerActions: ComponentEditAction[] = [];

    deletedActionIds.forEach((id) => {
      if (preAiValue.find((e: any) => e.id === id)) {
        eventHandlerActions.push({
          action: "remove",
          property: eventHandlerPath.join("."),
          value: {
            id,
          },
        } as RemoveEventAction);
      } else {
        // do nothing, this action was added by AI and then removed by the user, so there should be no action
      }
    });

    editedActionIds.forEach((id) => {
      if (preAiValue.find((e: any) => e.id === id)) {
        // this is an update to an action that existed before AI which is a delete and then a create
        eventHandlerActions.push({
          action: "remove",
          property: eventHandlerPath.join("."),
          value: {
            id,
          },
        } as RemoveEventAction);
      }
      // add action for the event
      const event = transformEventHandler(
        newValue.find((e: any) => e.id === id),
        eventsByScopeAndId,
      );
      if (event) {
        eventHandlerActions.push({
          action: "add",
          property: eventHandlerPath.join("."),
          value: event,
        } as AddEventAction);
      }
    });

    createdActionIds.forEach((id) => {
      const event = transformEventHandler(
        newValue.find((e: any) => e.id === id),
        eventsByScopeAndId,
      );
      if (event) {
        eventHandlerActions.push({
          action: "add",
          property: eventHandlerPath.join("."),
          value: event,
        } as AddEventAction);
      }
    });

    newActions = [...newActions, ...eventHandlerActions];
  }

  // special case for button text color
  if (preAiValues.type === WidgetTypes.BUTTON_WIDGET) {
    const textColorAction = newActions.find((a) => a.property === "textColor");
    const prevTextColorAction = actions.find(
      (a) => a.property === "textProps.textStyle.textColor.default",
    );
    if (textColorAction) {
      if (prevTextColorAction) {
        // delete the old one
        newActions = newActions.filter(
          (a) => a.property !== "textProps.textStyle.textColor.default",
        );
      }

      textColorAction.property = "textProps.textStyle.textColor.default";
    }
  }

  return newActions;
};
