import { Typography } from "antd";
import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const DotSeparator = styled.span`
  font-size: 11px;
  color: ${(props) => props.theme.colors.GREY_400};
  margin: 0 4px;

  &:before {
    content: "•";
  }
`;

export const ExecutionItemCount = styled(Typography.Text)`
  &.ant-typography {
    color: ${(props) => props.theme.colors.GREY_300};
  }
  white-space: nowrap;
  font-weight: 400;
`;
