import { ApplicationScope } from "@superblocksteam/shared";
import { get } from "lodash";
import { useCallback, useMemo } from "react";
import { Dispatch } from "redux";
import {
  deleteWidgetProperty,
  updateWidgetProperties,
} from "legacy/actions/controlActions";
import { showItemPropertyPane } from "legacy/actions/propertyPaneActions";
import { selectWidgets } from "legacy/actions/widgetActions";
import { getWidgetPropertiesById } from "legacy/selectors/propertyPaneSelectors";
import { useAppSelector } from "store/helpers";
import { getFirstPathSegments } from "utils/dottedPaths";
import { NOOP } from "utils/function";
import logger from "utils/logger";
import { getNestedItemDisplayName } from "../../Explorer/helpers";
import { ItemKinds, ItemTypeNonWidget } from "../ItemKindConstants";
import {
  buildNestedItemId,
  extractPartsFromNestedItemId,
} from "../NestedItemsUtils";
import type { ItemKindAccessor } from "../ItemKinds";
import type { WidgetProps } from "legacy/widgets";
import type { NestedItemProps } from "legacy/widgets/NestedItem";
import type { AppState } from "store/types";

export const NestedItemAccessor: ItemKindAccessor<ItemKinds.NESTED_ITEM> = {
  useItemName: (itemProperties) => {
    const widgetProps = useAppSelector((state) =>
      getWidgetPropertiesById(state, itemProperties.widgetId),
    );

    let name = "";
    if (!itemProperties.path) {
      logger.warn(`Path ${itemProperties.path} not found`);
    } else if (!widgetProps) {
      logger.warn(`Widget ${itemProperties.widgetId} not found`);
    } else {
      const nestedItem = get(widgetProps, itemProperties.path);
      name = getNestedItemDisplayName(widgetProps, nestedItem);
    }

    return useMemo(() => {
      return {
        name,
        editable: false,
        requiresValidation: false,
      };
    }, [name]);
  },
  itemType: (itemProperties) => ItemTypeNonWidget.NESTED_ITEM,
  // TODO: review types here: WidgetProps & NestedItemProps ???
  // Widgets props are necessary to render the props from nested items
  useItemProperties: (itemId: string): WidgetProps & NestedItemProps => {
    const { widgetId, path } = extractPartsFromNestedItemId(itemId);
    const widgetProps = useAppSelector(
      useCallback(
        (state: AppState) => getWidgetPropertiesById(state, widgetId),
        [widgetId],
      ),
    );

    if (!path) {
      throw new Error("Missing path");
    }

    const nestedProps = get(widgetProps, path);
    return {
      ...nestedProps,
      path,
      widgetId,
    };
  },
  updateItemProperties: (
    dispatch: Dispatch,
    properties: NestedItemProps,
    updates: Record<string, unknown>,
  ) => {
    const nestedUpdates = Object.fromEntries(
      Object.entries(updates).map(([property, value]) => {
        const nestedProperty = [properties.path, property].join(".");
        return [nestedProperty, value];
      }),
    );

    dispatch(updateWidgetProperties(properties?.widgetId, nestedUpdates));
  },
  deleteItemProperties: NOOP,
  deleteItem: (dispatch, itemId) => {
    const { widgetId, path } = extractPartsFromNestedItemId(itemId);
    if (path) {
      const action = deleteWidgetProperty(widgetId, [path]);
      dispatch(action);

      const pathParts = getFirstPathSegments(path, -2);

      if (pathParts) {
        const parentItemId = buildNestedItemId({
          widgetId,
          path: pathParts,
        });

        dispatch(
          showItemPropertyPane({
            kind: ItemKinds.NESTED_ITEM,
            id: parentItemId,
            scope: ApplicationScope.PAGE,
          }),
        );
      } else {
        dispatch(selectWidgets([widgetId]));
      }
    }
  },
};
