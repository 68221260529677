import Icon from "@ant-design/icons";
import { Placement, Offsets } from "@popperjs/core";
import {
  Root as Collapsible,
  Trigger as CollapsibleTrigger,
  Content as CollapsibleContent,
} from "@radix-ui/react-collapsible";
import { ApplicationScope } from "@superblocksteam/shared";
import { Typography, Tooltip } from "antd";
import { isArray, isObject } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import styled, { css, ThemeContext } from "styled-components";
import { ReactComponent as CheckCircle } from "assets/icons/common/check-circle.svg";
import { ReactComponent as ChevronDownCircle } from "assets/icons/common/chevron-down-circle.svg";
import { ReactComponent as CopyIcon } from "assets/icons/common/copy.svg";
import { ReactComponent as PlayCircleIcon } from "assets/icons/common/play-circle.svg";
import { ReactComponent as ErrorIcon } from "assets/icons/common/system-danger.svg";
import { ReactComponent as DocsIcon } from "assets/icons/home/docs.svg";
import JsonView from "components/ui/JsonView";
import Popper from "components/ui/Popper";
import { useSaga } from "hooks/store";
import { useGetEditorPath } from "hooks/store/useGetEditorPath";
import { useCopyToClipboard } from "hooks/ui";
import {
  EventType,
  ExecuteActionCallStack,
} from "legacy/constants/ActionConstants";
import { WIDGET_TYPE_VALIDATION_ERROR } from "legacy/constants/messages";
import { EditorRoute, QueryEditorRouteParams } from "legacy/constants/routes";
import { getApiExecutionParams } from "pages/Editors/ApiEditor/utils";
import { useAppSelector, useAppDispatch } from "store/helpers";
import {
  executeV1ApiSaga,
  selectV1ApiById,
  selectV1ApiLoadingById,
} from "store/slices/apisShared";
import {
  executeV2ApiSaga,
  getV2ApiExecutionParams,
  selectV2ApiById,
  selectV2ApiLoadingById,
} from "store/slices/apisV2";
import { getV2ApiId } from "store/slices/apisV2/utils/getApiIdAndName";
import { getEnvironment } from "store/slices/application/selectors";
import { colors } from "styles/colors";
import { ApiError, ApiErrorType } from "utils/error/error";
import { ActionShortcut } from "./ActionShortcut";
import { EditorTheme } from "./EditorConfig";

// length of text that wraps to second line in 300px wide popover
const EXPERIMENTALLY_DERIVED_LONG_VALUE_THRESHOLD = 36;

export enum VALIDATION_STATUS {
  VALID = "valid",
  ERROR = "error",
  WARNING = "warning",
  NONE = "none",
}

const getValueViewerColor = (validationStatus?: VALIDATION_STATUS) => {
  const defaultColors = {
    border: colors.GREY_200,
    text: colors.GREY_500,
    background: colors.GREY_25,
  };
  switch (validationStatus) {
    case VALIDATION_STATUS.VALID:
      return {
        border: colors.ACCENT_GREEN,
        text: colors.ACCENT_GREEN_600,
        background: colors.SUBTLE_GREEN,
      };
    case VALIDATION_STATUS.ERROR:
      return {
        border: colors.RED_500,
        text: colors.RED_500,
        background: colors.RED_25,
      };
    case VALIDATION_STATUS.WARNING:
      return {
        border: colors.ORANGE_600,
        text: colors.ORANGE_600,
        background: colors.ORANGE_25,
      };
    case VALIDATION_STATUS.NONE:
      return defaultColors;
    default:
      return defaultColors;
  }
};

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

const ContentWrapper = styled.div<{ $width: number }>`
  width: ${(props) => props.$width}px;
  overflow: hidden;
  background-color: ${(props) =>
    props.theme.legacy.appColors.evalPane.background};
  box-shadow: 0px 1px 3px rgba(34, 39, 47, 0.06),
    0px 12px 32px -8px rgba(34, 39, 47, 0.16),
    0px 0px 1px rgba(34, 39, 47, 0.16);
  border-radius: 4px;
`;

const CollapsibleInnerWrapper = styled.div`
  padding: 16px;
  padding-bottom: 0px;
  max-height: 250px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const CopyButtonWrapper = styled.div<{
  validationStatus?: VALIDATION_STATUS;
  color?: string;
}>`
  position: absolute;
  bottom: 8px;
  right: 8px;
  height: 16px;
  width: 16px;
  color: ${({ color, validationStatus }) =>
    color || getValueViewerColor(validationStatus)?.border};
  opacity: 0;
  cursor: pointer;
`;

const ExampleWrapper = styled.div`
  position: relative;

  &:hover .copy-example-button {
    opacity: 1;
  }
`;

interface CurrentValueWrapperProps {
  validationStatus: VALIDATION_STATUS;
  expanded: boolean;
}

const CurrentValueWrapper = styled.div<CurrentValueWrapperProps>`
  position: relative;
  max-height: 320px;
  min-height: 36px;
  overflow: hidden;
  background: ${(props) =>
    getValueViewerColor(props.validationStatus).background};
  border: 1px solid
    ${(props) => getValueViewerColor(props.validationStatus).border};
  border-radius: 4px;
  ${({ expanded }) =>
    expanded &&
    css`
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}

  &:hover .copy-value-button {
    opacity: 1;
  }
`;

const ScrolledValueWrapper = styled.div`
  max-height: 200px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const CodeWrapper = styled.pre<{
  validationStatus: VALIDATION_STATUS;
}>`
  padding: 8px 12px;
  margin: 0px 0px;
  color: ${(props) => getValueViewerColor(props.validationStatus).text};
  font-size: 12px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  white-space: pre-wrap;
`;

const JsonViewWrapper = styled.div<{
  validationStatus: VALIDATION_STATUS;
}>`
  padding: 4px;
  .json-container .json-value {
    color: ${(props) => getValueViewerColor(props.validationStatus).text};
  }
`;

const TypeText = styled.pre`
  padding: 8px 12px;
  background-color: ${(props) => props.theme.colors.GREY_25};
  color: ${(props) => props.theme.colors.GREY_400};
  font-size: 12px;
  margin-bottom: 16px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-radius: 4px;
`;

const StyledExpandIcon = styled(Icon)<{
  validationStatus: VALIDATION_STATUS;
}>`
  position: absolute;
  top: 8px;
  right: 8px;
  transform: rotate(90deg);
  svg {
    width: 16px;
    height: 16px;
    path {
      fill: ${(props) => getValueViewerColor(props.validationStatus).border};
    }
  }
`;

const ErrorText = styled.div`
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.GREY_700};
  border-bottom: 1px solid ${(props) => props.theme.colors.GREY_100};
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

const StyledErrorLink = styled(Link)`
  text-decoration: underline;
  font-weight: bold;
  text-align: right;
  color: inherit;
  :hover {
    color: inherit;
  }
`;

const ApiErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.div`
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.GREY_800};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitleSmall = styled.div`
  margin: 8px 0;
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.GREY_800};
`;

const StyledTitleContent = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const StyledDocsIcon = styled.div<{
  validationStatus: VALIDATION_STATUS;
}>`
  position: absolute;
  top: 8px;
  right: 32px;
  svg {
    width: 16px;
    height: 16px;
  }
  path {
    fill: ${(props) => getValueViewerColor(props.validationStatus).border};
  }
`;

const ClickableWrapper = styled.div`
  cursor: pointer;
  display: flex;
`;

export interface EvaluatedValuePopupProps {
  currentScope: ApplicationScope;
  dataTreePath: string | undefined;
  theme: EditorTheme;
  isOpen: boolean;
  hasError: boolean;
  validationError?: string;
  apiErrors?: ApiError[];
  expected?: string;
  evaluatedValue?: any;
  children: JSX.Element;
  exampleData?: string;
  docLink?: string;
  shouldOpenExample: boolean;
  validationStatus: VALIDATION_STATUS;
  showValue: boolean;
  showValueOnly: boolean;
  placement?: Placement;
  offset?: Offsets | number[];
  getWrapperElement?: () => HTMLElement | null;
  openDelay?: number;
}

interface PopoverContentProps {
  width: number;
  hasError: boolean;
  validationError?: string;
  apiErrors?: ApiError[];
  expected?: string;
  evaluatedValue: any;
  theme: EditorTheme;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  exampleData?: string;
  docLink?: string;
  shouldOpenExample: boolean;
  validationStatus: VALIDATION_STATUS;
  showValue: boolean;
  showValueOnly: boolean;
  currentScope: ApplicationScope;
  dataTreePath?: string;
}

interface ApiItemProps {
  apiId: string;
  apiName: string;
  message?: string;
  level?: "warn" | "info";
}

const ApiItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px 12px;
  border-bottom: 1px solid ${(props) => props.theme.colors.GREY_100};
`;

const ApiItem = ({ apiId, apiName, message, level }: ApiItemProps) => {
  const environment = useSelector(getEnvironment);
  const apiV1 = useAppSelector((state) => selectV1ApiById(state, apiId));
  const apiV2 = useAppSelector((state) => selectV2ApiById(state, apiId));
  const isLoading = useAppSelector((state) => {
    if (apiV1) {
      return selectV1ApiLoadingById(state, apiId);
    } else if (apiV2) {
      return selectV2ApiLoadingById(state, apiId);
    }
    return false;
  });

  const [executeV1Api] = useSaga(executeV1ApiSaga);
  const [executeV2Api] = useSaga(executeV2ApiSaga);

  const handleRunClick = useCallback(async () => {
    const callStack: ExecuteActionCallStack = [
      {
        type: EventType.ON_RUN_CLICK,
        propertyPath: `${apiName}.<value popup run>`,
      },
    ];
    if (apiV2) {
      await executeV2Api({
        apiId: getV2ApiId(apiV2),
        environment,
        eventType: EventType.ON_RUN_CLICK,
        params: getV2ApiExecutionParams(apiV2.apiPb),
        notifyOnSystemError: true,
        manualRun: true,
        callStack,
        viewMode: false,
        includeOutputs: true,
      });
    }
    if (apiV1) {
      await executeV1Api({
        apiId: apiV1.id,
        environment,
        viewMode: false,
        eventType: EventType.ON_RUN_CLICK,
        params: getApiExecutionParams(apiV1),
        notifyOnSystemError: true,
        manualRun: true,
        callStack,
      });
    }
  }, [apiName, apiV1, apiV2, environment, executeV1Api, executeV2Api]);

  const dispatch = useAppDispatch();
  const handleCancel = useCallback(() => {
    if (apiV1)
      dispatch({
        type: executeV1ApiSaga.cancel.type,
        payload: {
          apiId: apiV1.id,
        },
      });
    if (apiV2) {
      dispatch({
        type: executeV2ApiSaga.cancel.type,
        payload: {
          apiId: getV2ApiId(apiV2),
        },
      });
    }
  }, [apiV1, apiV2, dispatch]);

  return (
    <ActionShortcut
      message={
        <>
          <b>{apiName}</b>
          <span>&nbsp;{message ?? "has not run"}</span>
        </>
      }
      buttonText={isLoading ? "Cancel" : "Run API"}
      buttonIcon={<PlayCircleIcon />}
      level={level ?? "info"}
      isLoading={isLoading}
      mode="light"
      onClick={isLoading ? handleCancel : handleRunClick}
    />
  );
};

const MAX_STRING_LENGTH_DISPLAY = 1000;
const CurrentValueContent = (props: {
  evaluatedValue: any;
  validationStatus: VALIDATION_STATUS;
}) => {
  let content = (
    <CodeWrapper validationStatus={props.validationStatus}>
      {"undefined"}
    </CodeWrapper>
  );
  if (props.evaluatedValue !== undefined) {
    if (isObject(props.evaluatedValue) || Array.isArray(props.evaluatedValue)) {
      content = (
        <JsonViewWrapper validationStatus={props.validationStatus}>
          <JsonView
            data={props.evaluatedValue}
            maxStringLength={20}
            maxBufferLength={20}
            maxArrayLength={100}
            maxHeight={200}
            width={300}
          />
        </JsonViewWrapper>
      );
    } else {
      content = (
        <CodeWrapper validationStatus={props.validationStatus}>
          {props.evaluatedValue === null
            ? "null"
            : props.evaluatedValue
                .toString()
                .substr(0, MAX_STRING_LENGTH_DISPLAY)}
        </CodeWrapper>
      );
    }
  }
  return content;
};

const PopoverContent = (props: PopoverContentProps) => {
  const [expanded, setExpanded] = React.useState(props.hasError);
  useEffect(() => {
    setExpanded(props.shouldOpenExample);
  }, [props.shouldOpenExample]);

  const [copiedValue, copyValue] = useCopyToClipboard(1500);
  const [copiedExample, copyExample] = useCopyToClipboard(1500);

  const params = useParams<QueryEditorRouteParams>() as QueryEditorRouteParams;
  const getEditorPath = useGetEditorPath();

  const executionErrors = props.apiErrors?.filter(
    (apiError) => apiError.type === ApiErrorType.EXECUTION_ERROR,
  );
  const notRunWarnings = props.apiErrors?.filter(
    (apiError) => apiError.type === ApiErrorType.NOT_EXECUTED,
  );
  const staleResults = props.apiErrors?.filter(
    (apiError) => apiError.type === ApiErrorType.STALE,
  );
  const hasValidationError = Boolean(props.validationError);
  const hasApiError = Boolean(executionErrors && executionErrors.length > 0);
  const hasNotRunWarning = Boolean(notRunWarnings && notRunWarnings.length > 0);
  const hasStaleResults = Boolean(staleResults && staleResults.length > 0);

  const valueForClipboard = useMemo(() => {
    let stringValue = props.evaluatedValue;
    if (typeof props.evaluatedValue?.toString === "function")
      stringValue = props.evaluatedValue.toString();
    if (isObject(props.evaluatedValue) || isArray(props.evaluatedValue))
      try {
        stringValue = JSON.stringify(props.evaluatedValue);
      } catch (e) {
        stringValue = props.evaluatedValue.toString();
      }
    return stringValue;
  }, [props.evaluatedValue]);

  const onCopyValue = useCallback(() => {
    copyValue(valueForClipboard);
  }, [valueForClipboard, copyValue]);

  const onCopyExample = useCallback(() => {
    if (props.exampleData) copyExample(props.exampleData as string);
  }, [props.exampleData, copyExample]);

  const hasEvaluatedValue = props.evaluatedValue !== undefined;
  return (
    <ContentWrapper
      $width={props.width}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      className="t--CodeEditor-evaluatedValue"
    >
      <Collapsible open={expanded} onOpenChange={setExpanded}>
        {props.showValue && (
          <CurrentValueWrapper
            data-test="evaluated-value"
            validationStatus={props.validationStatus}
            expanded={
              expanded || hasValidationError || hasApiError || hasNotRunWarning
            }
          >
            <ScrolledValueWrapper>
              <CurrentValueContent
                evaluatedValue={props.evaluatedValue}
                validationStatus={props.validationStatus}
              />
            </ScrolledValueWrapper>
            {valueForClipboard &&
              valueForClipboard?.length >
                EXPERIMENTALLY_DERIVED_LONG_VALUE_THRESHOLD && (
                <Tooltip
                  title={copiedValue ? `Copied to clipboard` : `Copy`}
                  mouseEnterDelay={0.5}
                >
                  <CopyButtonWrapper
                    className="copy-value-button"
                    validationStatus={props.validationStatus}
                    onClick={onCopyValue}
                  >
                    {copiedValue ? (
                      <CheckCircle data-test="evaluated-value-copy-successful" />
                    ) : (
                      <CopyIcon data-test="evaluated-value-copy" />
                    )}
                  </CopyButtonWrapper>
                </Tooltip>
              )}
            {props.docLink && (
              <Tooltip title={`View docs`} mouseEnterDelay={0.5}>
                <StyledDocsIcon validationStatus={props.validationStatus}>
                  <Typography.Link
                    href={props.docLink}
                    target="_blank"
                    aria-label="Docs"
                  >
                    <DocsIcon />
                  </Typography.Link>
                </StyledDocsIcon>
              </Tooltip>
            )}
            {(props.expected || props.exampleData) && (
              <CollapsibleTrigger asChild>
                <ClickableWrapper>
                  {expanded ? (
                    <StyledExpandIcon
                      validationStatus={props.validationStatus}
                      component={ChevronDownCircle}
                      style={{ transform: "rotate(0)" }}
                    />
                  ) : (
                    <Tooltip
                      title={`Required data format`}
                      mouseEnterDelay={0.5}
                    >
                      <StyledExpandIcon
                        validationStatus={props.validationStatus}
                        component={ChevronDownCircle}
                      />
                    </Tooltip>
                  )}
                </ClickableWrapper>
              </CollapsibleTrigger>
            )}
          </CurrentValueWrapper>
        )}

        {!props.showValueOnly && hasNotRunWarning && (
          <ApiItems>
            {notRunWarnings?.map(({ apiId, apiName }) => (
              <ApiItem
                key={apiId}
                apiId={apiId}
                apiName={apiName}
                level="info"
              />
            ))}
          </ApiItems>
        )}

        {!props.showValueOnly &&
          hasEvaluatedValue &&
          hasStaleResults &&
          !hasNotRunWarning && (
            <ApiItems>
              {staleResults?.map(({ apiId, apiName }) => (
                <ApiItem
                  key={apiId}
                  apiId={apiId}
                  apiName={apiName}
                  level="warn"
                  message="has been modified, this result may be incorrect, rerun to see the latest result"
                />
              ))}
            </ApiItems>
          )}

        {hasValidationError &&
          !hasApiError &&
          !hasNotRunWarning &&
          !props.showValueOnly && (
            <ErrorText data-test="error-text-invalid-error">
              <ErrorIcon color={colors.DANGER} />
              {props.validationError
                ?.split("\n")
                .map((line, index) => <span key={index}>{line}</span>) ??
                WIDGET_TYPE_VALIDATION_ERROR}
            </ErrorText>
          )}

        {hasApiError && !props.showValueOnly && (
          <ErrorText>
            <ErrorIcon color={colors.DANGER} />
            {executionErrors?.map(
              ({ apiId, stepId, apiName, stepName, error }) => {
                return (
                  <ApiErrorWrapper
                    data-test="api-error-wrapper"
                    key={`${apiName}-${stepName}`}
                  >
                    <span>{`${error}`}</span>
                    <StyledErrorLink
                      to={getEditorPath(EditorRoute.EditApiAction, {
                        applicationId: params.applicationId,
                        apiId: apiId,
                        actionId: stepId,
                      })}
                    >
                      {`${apiName}.${stepName}`}
                    </StyledErrorLink>
                  </ApiErrorWrapper>
                );
              },
            )}
          </ErrorText>
        )}

        <CollapsibleContent>
          {!props.showValueOnly && (props.expected || props.exampleData) && (
            <CollapsibleInnerWrapper>
              {props.expected && (
                <>
                  <StyledTitle>
                    <StyledTitleContent>
                      Required Data format
                    </StyledTitleContent>
                  </StyledTitle>
                  <TypeText>{props.expected}</TypeText>
                </>
              )}

              {props.exampleData && (
                <ExampleWrapper>
                  <StyledTitleSmall>Example</StyledTitleSmall>
                  <TypeText>{props.exampleData}</TypeText>
                  <Tooltip
                    title={copiedExample ? `Copied to clipboard` : `Copy`}
                    mouseEnterDelay={0.5}
                  >
                    <CopyButtonWrapper
                      className="copy-example-button"
                      color={colors.GREY_300}
                      onClick={onCopyExample}
                    >
                      {copiedExample ? (
                        <CheckCircle data-test="example-value-copy-successful" />
                      ) : (
                        <CopyIcon data-test="example-code-copy" />
                      )}
                    </CopyButtonWrapper>
                  </Tooltip>
                </ExampleWrapper>
              )}
            </CollapsibleInnerWrapper>
          )}
        </CollapsibleContent>
      </Collapsible>
    </ContentWrapper>
  );
};

const EvaluatedValuePopup = (props: EvaluatedValuePopupProps) => {
  const theme = useContext(ThemeContext);
  const [contentHovered, setContentHovered] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const offset = props.offset || [0, 20];
  const showPopover = props.isOpen || contentHovered;
  const [isVisible, setIsVisible] = useState(showPopover);
  useEffect(() => {
    if (showPopover) {
      const timeout = setTimeout(() => {
        setIsVisible(true);
      }, props.openDelay ?? 0);
      return () => clearTimeout(timeout);
    } else {
      setIsVisible(false);
    }
  }, [showPopover, props.openDelay]);

  const getWrapperElement = props.getWrapperElement;
  const { placement, contentWidth } = useMemo(() => {
    let placement: Placement = props.placement ?? "left-start";
    let contentWidth = theme.legacy.evaluatedValuePopup.width;

    const wrapperElement = getWrapperElement?.() ?? wrapperRef.current;
    // Only perform placement calculation if the popover is open
    if (isVisible && wrapperElement) {
      // Calculate the bounding rectangle of the wrapperRef
      const boundingRect = (() => {
        let result: DOMRect | undefined;
        return () => {
          result ??= wrapperElement?.getBoundingClientRect();
          return result as DOMRect;
        };
      })();

      // Check if the placement is not provided
      if (!props.placement) {
        // Check if the left position of the bounding rectangle is less than the width of the popover
        if (boundingRect().left < theme.legacy.evaluatedValuePopup.width) {
          placement = "bottom-start";
        }
      }

      // Check if the placement is "bottom-start"
      if (placement === "bottom-start") {
        contentWidth = Math.min(boundingRect().width ?? 0, 600);
      }
    }

    return { placement, contentWidth };
  }, [
    props.placement,
    isVisible,
    theme.legacy.evaluatedValuePopup.width,
    getWrapperElement,
  ]);

  const onMouseLeave = useCallback(() => {
    setContentHovered(false);
  }, []);

  const onMouseEnter = useCallback(() => {
    setContentHovered(true);
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      {isVisible && (
        <Popper
          targetNode={getWrapperElement?.() ?? wrapperRef.current ?? undefined}
          isOpen
          zIndex={15}
          placement={placement}
          modifiers={[
            {
              name: "offset",
              options: {
                offset,
              },
            },
          ]}
        >
          <PopoverContent
            width={contentWidth}
            expected={props.expected}
            exampleData={props.exampleData}
            docLink={props.docLink}
            evaluatedValue={props.evaluatedValue}
            hasError={props.hasError}
            validationError={props.validationError}
            apiErrors={props.apiErrors}
            shouldOpenExample={props.shouldOpenExample}
            theme={EditorTheme.LIGHT}
            onMouseLeave={onMouseLeave}
            onMouseEnter={onMouseEnter}
            validationStatus={props.validationStatus}
            showValue={props.showValue}
            showValueOnly={props.showValueOnly}
            currentScope={props.currentScope}
            dataTreePath={props.dataTreePath}
          />
        </Popper>
      )}
      {props.children}
    </Wrapper>
  );
};

export default EvaluatedValuePopup;
