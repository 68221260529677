import { UIErrorType } from "@superblocksteam/shared";
import { call, cancelled, put, select } from "redux-saga/effects";
import { stopEvaluation } from "legacy/actions/evaluationActions";
import { EventType } from "legacy/constants/ActionConstants";
import { waitForNextEvaluationToComplete } from "legacy/sagas/waitForEvaluation";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "legacy/utils/PerformanceTracker";
import {
  TriggerFrontendEventHandlersPayload,
  triggerFrontendEventHandlersSaga,
} from "store/sagas/triggerFrontendEventHandlers";
import formatV2ApiStepsSaga from "store/slices/apisV2/formatCodeSaga";
import { CancelledByType } from "store/slices/apisV2/slice";
import { getEnvironment } from "store/slices/application/selectors";
import { Flag } from "store/slices/featureFlags/models/Flags";
import { selectFlagById } from "store/slices/featureFlags/selectors";
import { callSagas, createSaga } from "store/utils/saga";
import { EntitiesErrorType } from "store/utils/types";
import UITracing from "tracing/UITracing";
import { sendWarningUINotification } from "utils/notification";
import {
  ApiExecutionResponseDto,
  ApiExecutionSagaPayload,
} from "../slices/apis/types";
import slice, {
  executeV2ApiSaga,
  selectV2ApiRunCancelledById,
  type ExecutionResponse,
} from "../slices/apisV2";

const MAX_CALL_DEPTH = 10;

type Props = Omit<ApiExecutionSagaPayload, "environment" | "viewMode"> & {
  includeOutputs: boolean;
  formatCode?: boolean;
};

const apiAbortMap = new Map<string, AbortController>();

function* executeV2ApiWithViewModeInternal({
  apiId,
  eventType,
  includeOutputs,
  params,
  notifyOnSystemError,
  commitId,
  spanId,
  manualRun,
  callStack,
  formatCode,
}: Props): Generator<unknown, void, any> {
  apiAbortMap.set(apiId, new AbortController());

  const isCodeFormattingFeatureFlagEnabled: boolean = yield select(
    selectFlagById,
    Flag.CODE_FORMATTING,
  );

  let result: ExecutionResponse | ApiExecutionResponseDto | undefined;
  try {
    if (callStack && callStack.length > MAX_CALL_DEPTH) {
      const msg =
        "Infinite loop detected. Breaking out of the loop, max depth: " +
        MAX_CALL_DEPTH +
        ". Triggers executed: " +
        [...callStack]
          .reverse()
          .map((item) => item.propertyPath)
          .join(" ➜ ");
      console.warn(msg);
      UITracing.addEvent(spanId, msg, UIErrorType.VALIDATION_ERROR);
      sendWarningUINotification({
        message: msg,
      });
      return;
    }

    if (isCodeFormattingFeatureFlagEnabled && formatCode) {
      yield callSagas([
        formatV2ApiStepsSaga.apply({
          apiId,
          manualRun,
        }),
      ]);
    }

    if (!apiAbortMap.get(apiId)?.signal.aborted) {
      const environment: ReturnType<typeof getEnvironment> = yield select(
        getEnvironment,
      );

      const isCancelledBeforeItRan: ReturnType<
        typeof selectV2ApiRunCancelledById
      > = yield select(selectV2ApiRunCancelledById, apiId);

      if (!isCancelledBeforeItRan) {
        PerformanceTracker.startAsyncTracking(
          PerformanceTransactionName.EXECUTE_ACTION,
          { actionId: apiId },
          apiId,
        );

        [result] = yield callSagas([
          executeV2ApiSaga.apply({
            apiId,
            includeOutputs,
            viewMode: false, // TODO: viewMode is ignored and re-calculated in executeV2ApiSaga. Deprecate it from props
            environment,
            eventType,
            params,
            notifyOnSystemError,
            manualRun,
            callStack,
            spanId,
            commitId,
          }),
        ]);

        PerformanceTracker.stopAsyncTracking(
          PerformanceTransactionName.EXECUTE_ACTION,
          undefined,
          apiId,
        );

        if (result) {
          yield call(waitForNextEvaluationToComplete);
        }
      }
    }

    // We use try & finally to handle sagas cancellation
  } finally {
    // The way `callSagas` work, those could be still running even if this saga is cancelled.
    // We trigger an explicit cancel to them, even if they haven't started.
    if (yield cancelled()) {
      if (isCodeFormattingFeatureFlagEnabled && formatCode) {
        yield put(formatV2ApiStepsSaga.cancel.create({ apiId }));
      }

      // We should be able to use `executeV2ApiSaga.cancel.create(params)`
      // but params are not typed correctly for cancellation
      yield put({
        type: executeV2ApiSaga.cancel.type,
        payload: {
          cancelledByType: CancelledByType.MANUAL,
          apiId,
        },
      });
    }

    // Run front-end event handlers, even if this saga was cancelled (to trigger `onCancel`)
    const payload: TriggerFrontendEventHandlersPayload = {
      version: "v2",
      apiId,
      result,
      callStack,
      manualRun: eventType === EventType.ON_RUN_CLICK,
    };
    yield call(triggerFrontendEventHandlersSaga, payload);
  }
}

export const executeV2ApiWithViewModeSaga = createSaga(
  executeV2ApiWithViewModeInternal,
  "executeV2ApiWithViewModeSaga",
  {
    sliceName: slice.name,
    keySelector: (payload) => payload.apiId,
    cancelledBy: [stopEvaluation.type],
  },
);

slice.saga(executeV2ApiWithViewModeSaga, {
  start(state, { payload }) {
    state.meta[payload.apiId] = state.meta[payload.apiId] ?? {};
    state.loading[payload.apiId] = true;

    state.meta[payload.apiId].cancelled = false;
    delete state.meta[payload.apiId].cancelledByType;

    // clear out the single step run state
    delete state.meta[payload.apiId].singleStepRuns;

    if (state.errors[payload.apiId]?.type === EntitiesErrorType.EXECUTE_ERROR) {
      delete state.errors[payload.apiId];
    }
  },
  cancel(state, { payload }) {
    apiAbortMap.get(payload.apiId)?.abort();
  },
});
