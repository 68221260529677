import { ApplicationScope, WidgetTypes } from "@superblocksteam/shared";
import { Button, Input, Tooltip } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useEntityNameValidator } from "hooks/store";
import { usePrevious } from "hooks/ui";
import { ControlLayout } from "legacy/components/propertyControls/BaseControl";
import {
  V2ControlPropertyLabelContainer,
  V2ControlWrapper,
} from "legacy/components/propertyControls/StyledControls";
import { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { generatePropertyControl } from "legacy/pages/Editor/PropertyPane/Generator";
import {
  ItemKinds,
  ItemTypeNonWidget,
  ItemWithPropertiesType,
} from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { getItemPropertyPaneConfig } from "legacy/pages/Editor/PropertyPane/ItemPropertyPaneConfig";
import {
  PopoverPanelComponent,
  PopperPanel,
  skipSelectors,
} from "legacy/pages/Editor/PropertyPane/PanelsOverlay";
import PropertyHelpLabel from "legacy/pages/Editor/PropertyPane/PropertyHelpLabel";
import { generateReactKey } from "legacy/utils/generators";
import { removeSpecialChars } from "legacy/utils/helpers";
import { selectAiState } from "store/slices/ai/selectors";
import { updateAiChanges } from "store/slices/ai/slice";
import { colors } from "styles/colors";
import { iframeMessageHandler } from "utils/iframe";
import { PrimaryColumnChanges } from "./PrimaryColumnChanges";

const EmptyWrapper = styled.div`
  text-align: center;
  width: 100%;
  color: ${colors.GREY_600};
`;

const StyledInput = styled(Input)`
  font-size: 12px;
  &:focus {
    box-shadow: none;
  }
`;

export const PropertyResponseSection = ({
  widgetType,
  widgetId,
  isDragging,
}: {
  widgetType: WidgetTypes;
  widgetId: string;
  isDragging: boolean;
}) => {
  const [openPanels, setOpenPanels] = useState<Array<PopperPanel>>([]);
  const {
    reasoning,
    dataTreeChanges: aiEdits,
    widgetRename,
    changedKeys,
    propertiesToChange,
    isLoading,
  } = useSelector(selectAiState);
  const targetNode = useRef<HTMLDivElement | null>(null);
  const portalEl = useRef<HTMLDivElement | null>(null);

  const flattenedProperties = useMemo(() => {
    const propSections = getItemPropertyPaneConfig(
      widgetType as ItemWithPropertiesType,
    );
    return propSections.flatMap((section) => section.children);
  }, [widgetType]);

  const propertyControlConfigs = useMemo(() => {
    const aiEditKeys = new Set([
      ...Object.keys(aiEdits ?? []),
      ...(isLoading ? propertiesToChange ?? [] : []),
    ]);
    if (aiEditKeys.size === 0) {
      return [];
    }
    const props = flattenedProperties
      .filter((prop) =>
        aiEditKeys.has((prop as any)?.propertyName.split(".")[0]),
      )
      .filter((prop) => (prop as any)?.propertyName !== "primaryColumns")
      .filter(Boolean) as any as PropertyPaneConfig[];
    return props;
  }, [aiEdits, flattenedProperties, propertiesToChange, isLoading]);

  const panel = useMemo(
    () => ({
      openPanel: ({
        component,
        elementSelector,
        props,
      }: {
        component: any;
        elementSelector: any;
        props: any;
      }) => {
        setOpenPanels((prev) => [
          ...prev,
          {
            id: props.itemId || generateReactKey(),
            childEl: component,
            childProps: { ...props, panelIndex: prev.length },
            childSelector: elementSelector,
          },
        ]);
      },
      closePanel: (index?: number) => {
        if (index && index >= openPanels.length) {
          return;
        }

        const remainingPanelsCount = index ?? 0;
        for (let i = remainingPanelsCount; i < openPanels.length; i++) {
          const panel = openPanels[i];
          if (typeof panel.childProps?.onClose === "function") {
            panel.childProps.onClose();
          }
          setOpenPanels(openPanels.slice(0, remainingPanelsCount));
        }
      },
    }),
    [openPanels],
  );

  const hasPrimaryColumnChanges = useMemo(() => {
    return !!aiEdits?.primaryColumns;
  }, [aiEdits]);

  const handlePanelOutsideClick = useCallback(
    (event: Event) => {
      const clickedPopper = (event.target as HTMLElement)?.closest(
        "[data-test=popper]",
      );
      const clickedIndex = clickedPopper?.getAttribute("data-popper-index");
      if (clickedPopper && clickedIndex) {
        // if you click on the panel stack in a lower element, close all panels after the clicked index
        panel.closePanel(parseInt(clickedIndex) + 1);
        return;
      }
      const portalContainsEvent =
        portalEl.current && !portalEl.current.contains(event.target as Node);
      // Each of these .contains( calls is a DOM query, so they are written
      // in a way that we can short-circuit the logic if any conditions are met
      if (
        portalContainsEvent &&
        !skipSelectors.some((selector) => {
          const els = document.querySelectorAll(selector);
          return Array.from(els).some((e) => e.contains(event.target as Node));
        })
      ) {
        panel.closePanel();
      }
    },
    [panel],
  );

  const clickInIframeDetector = useCallback(() => {
    panel.closePanel();
  }, [panel]);

  useEffect(() => {
    document.addEventListener("mousedown", handlePanelOutsideClick, {
      capture: true,
    });
    document.addEventListener("touchstart", handlePanelOutsideClick, {
      capture: true,
    });
    iframeMessageHandler.addEventListener("click", clickInIframeDetector);
    return () => {
      document.removeEventListener("mousedown", handlePanelOutsideClick, {
        capture: true,
      });
      document.removeEventListener("touchstart", handlePanelOutsideClick, {
        capture: true,
      });
      iframeMessageHandler.removeEventListener("click", clickInIframeDetector);
    };
  }, [handlePanelOutsideClick, clickInIframeDetector]);

  const [showReasoning, setShowReasoning] = useState(false);
  const dispatch = useDispatch();
  const [name, setName] = useState(widgetRename ?? "");
  const [nameError, setNameError] = useState<string | undefined>(undefined);
  const nameValidator = useEntityNameValidator(
    widgetRename ?? "",
    ApplicationScope.PAGE,
  );

  const prevWidgetRename = usePrevious(widgetRename);
  useEffect(() => {
    if (
      widgetRename &&
      prevWidgetRename !== widgetRename &&
      name !== widgetRename
    ) {
      setName(widgetRename);
    }
  }, [widgetRename, nameError, name, prevWidgetRename]);

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = removeSpecialChars(e.target.value);
      setName(value);
      const error = nameValidator(value);
      if (error) {
        setNameError(error);
      } else {
        setNameError(undefined);
        dispatch(updateAiChanges({ rename: value, updates: {} }));
      }
    },
    [nameValidator, dispatch],
  );

  const hasLoadingProperties =
    propertiesToChange && propertiesToChange.length > 0 && isLoading;

  const showEmptyMessage =
    !hasLoadingProperties &&
    !aiEdits &&
    !widgetRename &&
    !hasPrimaryColumnChanges;

  if (showEmptyMessage) {
    // TODO: better message
    return (
      <EmptyWrapper>
        We couldn&apos;t find any changes to make.
        <br />
        Please try again.
        {reasoning && (
          <Button
            onClick={() => setShowReasoning(!showReasoning)}
            type="link"
            size="small"
          >
            {showReasoning ? "Hide" : "Show"} reasoning
          </Button>
        )}
        {showReasoning && reasoning && (
          <div
            style={{
              textAlign: "left",
              padding: "8px",
              border: `1px solid ${colors.GREY_300}`,
              background: colors.GREY_25,
              borderRadius: "4px",
              marginTop: "8px",
            }}
          >
            {reasoning.map(({ task, steps }, index) => {
              return (
                <>
                  <div>
                    <b>Task: </b>
                    <span>&quot;{task}&quot;</span>
                  </div>
                  <ol>
                    {steps.map((step, index) => {
                      return <li key={index}>{step}</li>;
                    })}
                  </ol>
                  {index < reasoning.length - 1 && <hr />}
                </>
              );
            })}
          </div>
        )}
      </EmptyWrapper>
    );
  }

  return (
    <div ref={targetNode}>
      {widgetRename && (
        <V2ControlWrapper data-layout={ControlLayout.GRID}>
          <V2ControlPropertyLabelContainer data-layout={ControlLayout.GRID}>
            <PropertyHelpLabel
              itemKind={ItemKinds.AI_EDITS}
              label={"Component name"}
            />
          </V2ControlPropertyLabelContainer>

          <Tooltip title={nameError}>
            <StyledInput
              value={name}
              onChange={handleNameChange}
              status={nameError ? "error" : undefined}
              style={{ fontSize: 12 }}
            />
          </Tooltip>
        </V2ControlWrapper>
      )}
      {generatePropertyControl(propertyControlConfigs, {
        itemKind: ItemKinds.AI_EDITS,
        itemId: widgetId,
        type: ItemTypeNonWidget.AI_EDITS,
        panel,
        panelProps: {},
        getPanelContainer: () => undefined,
      })}
      {widgetType === WidgetTypes.TABLE_WIDGET && (
        <PrimaryColumnChanges
          widgetId={widgetId}
          aiEdits={aiEdits ?? {}}
          changedKeys={changedKeys || []}
          panel={panel}
        />
      )}
      {targetNode.current && !isDragging && (
        <>
          {openPanels.map((panel, index) => {
            return (
              <PopoverPanelComponent
                key={index}
                panel={panel}
                panelConfig={panel.childProps?.panelConfig}
                portalEl={portalEl}
                direction={"right"}
                index={index}
                getPanelContainer={() => portalEl.current}
              />
            );
          })}
        </>
      )}
    </div>
  );
};
