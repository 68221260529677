import { useCallback } from "react";
import { getWidget } from "legacy/selectors/sagaSelectors";
import { useAppSelector } from "store/helpers";
import { processChangesIntoActions } from "store/slices/ai/processChangesIntoActions";
import { selectAiState } from "store/slices/ai/selectors";
import { selectEventsByScopeAndId } from "store/slices/application/events/selectors";

export const useGetUpdatedActions = (widgetId: string) => {
  const aiState = useAppSelector(selectAiState);
  const { initialDataTreeChanges, dataTreeChanges, actions } = aiState;
  const existingWidget = useAppSelector((state) => getWidget(state, widgetId));
  const eventsByScopeAndId = useAppSelector(selectEventsByScopeAndId);

  const getUpdatedActions = useCallback(() => {
    return processChangesIntoActions({
      preAiValues: existingWidget as any as Record<string, unknown>,
      initialValues: initialDataTreeChanges ?? {},
      updatedValues: dataTreeChanges ?? {},
      actions: actions ?? [],
      eventsByScopeAndId,
    });
  }, [
    initialDataTreeChanges,
    dataTreeChanges,
    actions,
    existingWidget,
    eventsByScopeAndId,
  ]);

  return getUpdatedActions;
};
