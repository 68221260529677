interface DebouncedWithStateFunc<T extends (...args: any[]) => any> {
  /**
   * Call the original function, but applying the debounce rules.
   *
   * The return value will be ignored as the actual call will occur at a later time.
   */
  (...args: Parameters<T>): void;

  /**
   * Cancel the call if it was pending and return true, otherwise do nothing and
   * return false
   */
  cancel(): boolean;
}

/**
 * Creates a debounced function that delays invoking `func` until after `wait`
 * milliseconds have elapsed since the last time the debounced function was
 * invoked. The debounced function comes with a `cancel` method to cancel
 * delayed `func` invocations.
 *
 * In comparison to Lodash debounce the `cancel` method returns boolean to be
 * able to know whether an invocation was in progress during cancelling.
 *
 * @param func The function to debounce.
 * @param wait The number of milliseconds to delay.
 * @return Returns the new debounced function.
 */
export function debounceWithState<T extends (...args: any[]) => any>(
  func: T,
  wait: number,
): DebouncedWithStateFunc<T> {
  let timeoutId: ReturnType<typeof setTimeout> | undefined = undefined;

  const result: DebouncedWithStateFunc<T> = (...args) => {
    if (typeof timeoutId !== "undefined") {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(function () {
      func(...args);
      timeoutId = undefined;
    }, wait);
  };

  result.cancel = function () {
    if (typeof timeoutId !== "undefined") {
      clearTimeout(timeoutId);

      return true;
    }

    return false;
  };

  return result;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const NOOP = (...args: unknown[]) => {};
