import { InfoCircleOutlined } from "@ant-design/icons";
import { Interval, ScheduleConfig } from "@superblocksteam/shared";
import {
  InputNumber,
  Select,
  Space,
  TimePicker,
  Tooltip,
  Typography,
} from "antd";
import { parseExpression as parseCronExpression } from "cron-parser";
import moment from "moment";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import WeekdaySelector from "components/ui/WeekdaySelector";
import { pluralize } from "utils/string";
import { dayOfMonthSuffix } from "../../utils";

const { Text } = Typography;
const { Option } = Select;

const MinutePickerWrapper = styled.div`
  .ant-picker-footer {
    display: none;
  }
  display: flex;
  gap: 15px;
  align-items: center;
  flex-direction: row;
`;

const shouldShowFrequency = (interval: Interval): boolean => {
  return interval === "minute" || interval === "hour" || interval === "month";
};

const shouldShowTime = (interval: Interval): boolean => {
  return interval === "day" || interval === "week" || interval === "month";
};

const shouldShowMinutes = (interval: Interval): boolean => {
  return interval === "hour";
};

const shouldShowDaysOfWeek = (interval: Interval): boolean => {
  return interval === "week";
};

const shouldShowDayOfMonth = (interval: Interval): boolean => {
  return interval === "month";
};

const timePickerFormat = "hh:mm A";
const minutePickerFormat = "mm";
const schedulePreviewCount = 5;

const maxForInterval = (interval: Interval): number => {
  switch (interval) {
    case "minute":
      return 60;
    case "hour":
      return 24;
    case "month":
      return 12;
    default:
      return 1000;
  }
};

export const getNextNRuns = (
  scheduleConfig: ScheduleConfig,
  n: number,
): string[] => {
  const nextRunFormatOptions: Intl.DateTimeFormatOptions = {
    weekday: "long",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  };

  const config = hydrateSchedule(scheduleConfig);
  if (!config) {
    return [];
  }
  const cron = config.toCron();
  const interval = parseCronExpression(cron, { tz: config.timezoneLocale });
  return Array.from({ length: n }, () =>
    interval.next().toDate().toLocaleString("en-US", nextRunFormatOptions),
  );
};

function timezoneLocale() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

function hydrateSchedule(
  oldSchedule: ScheduleConfig | undefined,
): ScheduleConfig | undefined {
  if (!oldSchedule) {
    return undefined;
  }
  const scheduleConfig = ScheduleConfig.default();
  scheduleConfig.frequency = oldSchedule.frequency;
  scheduleConfig.interval = oldSchedule.interval;
  scheduleConfig.dayOfMonth = oldSchedule.dayOfMonth;
  scheduleConfig.time = new Date(oldSchedule.time);
  scheduleConfig.daysOfWeek = oldSchedule.daysOfWeek;
  // We do not allow changing timezoneLocale for now
  // but we backfill empty timezoneLocale for old schedules
  if (
    !oldSchedule.timezoneLocale ||
    oldSchedule.timezoneLocale === "" ||
    oldSchedule.timezoneLocale === "UTC"
  ) {
    scheduleConfig.timezoneLocale = timezoneLocale();
  } else {
    scheduleConfig.timezoneLocale = oldSchedule.timezoneLocale;
  }
  return scheduleConfig;
}

type ScheduleSelectorProps = {
  initConfig?: ScheduleConfig;
  onConfigChange?: (newConfig: ScheduleConfig) => Promise<void>;
  disabled?: boolean;
};

const ScheduleSelector = ({
  initConfig,
  onConfigChange,
  disabled,
}: ScheduleSelectorProps) => {
  const [scheduleConfig, setScheduleConfig] = useState(
    Object.assign({}, hydrateSchedule(initConfig) ?? ScheduleConfig.default()),
  );

  const setSchedule = useCallback(
    async (config: ScheduleConfig) => {
      setScheduleConfig(config);
      if (onConfigChange) {
        await onConfigChange(config);
      }
    },
    [onConfigChange],
  );

  const onIntervalChange = useCallback(
    (newInterval: any) => {
      const newConfig = Object.assign({}, scheduleConfig, {
        interval: newInterval,
      });
      setSchedule(newConfig);
    },
    [scheduleConfig, setSchedule],
  );

  const onFrequencyChange = useCallback(
    (newFrequency: number | string | null) => {
      if (typeof newFrequency !== "number") {
        newFrequency = 1;
      }
      const newConfig = Object.assign({}, scheduleConfig, {
        frequency: newFrequency,
      });
      setSchedule(newConfig);
    },
    [scheduleConfig, setSchedule],
  );

  const onTimeChange = useCallback(
    (newTime: any) => {
      const newConfig = Object.assign({}, scheduleConfig, {
        time: newTime.toDate(),
      });
      setSchedule(newConfig);
    },
    [scheduleConfig, setSchedule],
  );

  const onDayOfMonthChange = useCallback(
    (newDayOfMonth: any) => {
      const newConfig = Object.assign({}, scheduleConfig, {
        dayOfMonth: newDayOfMonth,
      });
      setSchedule(newConfig);
    },
    [scheduleConfig, setSchedule],
  );

  const onDaysOfWeekChange = useCallback(
    (newDaysOfWeek: any) => {
      const newConfig = Object.assign({}, scheduleConfig, {
        daysOfWeek: newDaysOfWeek,
      });
      setSchedule(newConfig);
    },
    [scheduleConfig, setSchedule],
  );

  return (
    <Space direction="vertical">
      <Space direction="vertical">
        <Space direction="horizontal" size={15}>
          <Text> Every </Text>
          {shouldShowFrequency(scheduleConfig.interval) && (
            <InputNumber
              min={1}
              max={maxForInterval(scheduleConfig.interval)}
              value={scheduleConfig.frequency}
              onChange={onFrequencyChange}
              data-test="schedule-input-number"
              disabled={disabled}
            />
          )}
          <Select
            value={scheduleConfig.interval}
            onChange={onIntervalChange}
            style={{
              width: 100,
            }}
            data-test="schedule-select-every"
            disabled={disabled}
          >
            <Option value="minute" data-test="schedule-select-every-minutes">
              {pluralize(scheduleConfig.frequency, "minute")}
            </Option>
            <Option value="hour" data-test="schedule-select-every-hours">
              {pluralize(scheduleConfig.frequency, "hour")}
            </Option>
            <Option value="day" data-test="schedule-select-every-day">
              day
            </Option>
            <Option value="week" data-test="schedule-select-every-week">
              week
            </Option>
            <Option value="month" data-test="schedule-select-every-month">
              {pluralize(scheduleConfig.frequency, "month")}
            </Option>
          </Select>
          {shouldShowTime(scheduleConfig.interval) && (
            <>
              <Text> at </Text>
              <TimePicker
                value={moment(scheduleConfig.time).tz(
                  scheduleConfig.timezoneLocale,
                )}
                format={timePickerFormat}
                showNow={false}
                allowClear={false}
                onSelect={onTimeChange}
                data-test="schedule-select-time"
                disabled={disabled}
              />
            </>
          )}
          {shouldShowMinutes(scheduleConfig.interval) && (
            <MinutePickerWrapper>
              <Text> at </Text>
              <TimePicker
                value={moment(scheduleConfig.time).tz(
                  scheduleConfig.timezoneLocale,
                )}
                format={minutePickerFormat}
                showNow={false}
                allowClear={false}
                onSelect={onTimeChange}
                data-test="schedule-select-minute"
                getPopupContainer={(trigger) =>
                  (trigger.parentNode as HTMLElement) ?? document.body
                }
                disabled={disabled}
              />
              <Text> minutes in </Text>
            </MinutePickerWrapper>
          )}
          {shouldShowDaysOfWeek(scheduleConfig.interval) && (
            <>
              <Text> on </Text>
              <WeekdaySelector
                value={scheduleConfig.daysOfWeek}
                onChange={onDaysOfWeekChange}
                disabled={disabled}
              />
            </>
          )}
          {shouldShowDayOfMonth(scheduleConfig.interval) && (
            <>
              <Text> on the </Text>
              <Select
                value={scheduleConfig.dayOfMonth}
                onChange={onDayOfMonthChange}
                disabled={disabled}
              >
                {Array.from({ length: 31 }, (_, i) => i + 1).map((dom) => (
                  <Option key={`day-of-month-${dom}`} value={dom}>
                    {dom.toString() + dayOfMonthSuffix(dom)}
                  </Option>
                ))}
              </Select>
              <Text> day </Text>
            </>
          )}
          {scheduleConfig.timezoneLocale !== timezoneLocale() && (
            <Tooltip title={`Timezone: ${scheduleConfig.timezoneLocale}`}>
              <InfoCircleOutlined />
            </Tooltip>
          )}
        </Space>
      </Space>
      <Space direction="vertical">
        <Text strong>Next {schedulePreviewCount} runs</Text>
        {getNextNRuns(scheduleConfig, schedulePreviewCount).map(
          (nextRunDesc, i) => (
            <Text key={`next-run-${i}`}>{nextRunDesc}</Text>
          ),
        )}
      </Space>
    </Space>
  );
};

export default ScheduleSelector;
