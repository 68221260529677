import {
  ApplicationScope,
  OBS_TAG_ENTITY_ID,
  OBS_TAG_ENTITY_NAME,
  OBS_TAG_ENTITY_TYPE,
} from "@superblocksteam/shared";
import { once } from "lodash";
import { runEventHandlers } from "legacy/actions/widgetActions";
import { EventType } from "legacy/constants/ActionConstants";
import { createRunEventHandlersPayload } from "legacy/utils/actions";
import { getStore } from "store/dynamic";
import {
  ActiveTimerStatus,
  AppTimer,
  TIMER_MAX_INTERVAL,
} from "store/slices/application/timers/TimerConstants";
import { updateTimerMetaProperties } from "store/slices/application/timersMeta/slice";
import { addNewPromise } from "store/utils/resolveIdSingleton";
import { ENTITY_TYPE } from "utils/dataTree/constants";
import { Interval, Timestamp } from "utils/interval";

const windowTimerIds = new Map<AppTimer["id"], Interval>();
const isStillExecutingLastInterval = new Set<AppTimer["id"]>();

export const createTimerInterval = ({
  id,
  intervalMs,
  minIntervalMs,
  steps,
  name,
  scope,
}: {
  id: AppTimer["id"];
  intervalMs: AppTimer["intervalMs"];
  minIntervalMs: number;
  steps: AppTimer["steps"];
  name: AppTimer["name"];
  scope: ApplicationScope;
}): Timestamp => {
  let interval = windowTimerIds.get(id);
  // Don't start interval if already started
  if (!interval) {
    if (isNaN(intervalMs)) intervalMs = minIntervalMs;
    interval = new Interval(
      Math.min(TIMER_MAX_INTERVAL, Math.max(intervalMs, minIntervalMs)),
      () => {
        if (!isStillExecutingLastInterval.has(id)) {
          isStillExecutingLastInterval.add(id);

          const store = getStore();

          store.dispatch(
            updateTimerMetaProperties({
              id,
              updates: {
                state: { status: ActiveTimerStatus.EXECUTING_STEPS },
              },
              scope,
            }),
          );

          const callbackId = addNewPromise(
            // sometimes callback is called multiple times, so wrap it with once
            once(() => {
              isStillExecutingLastInterval.delete(id);

              store.dispatch(
                updateTimerMetaProperties({
                  id,
                  updates: {
                    state: {
                      status: ActiveTimerStatus.IDLE,
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      nextInvocation: interval!.nextInvocation,
                    },
                  },
                  scope,
                }),
              );
            }),
          );
          store.dispatch(
            runEventHandlers(
              createRunEventHandlersPayload({
                steps,
                type: EventType.ON_TIMER_INTERVAL,
                entityName: name,
                currentScope: scope,
                callbackId,
                additionalEventAttributes: {
                  [OBS_TAG_ENTITY_TYPE]: ENTITY_TYPE.TIMER,
                  [OBS_TAG_ENTITY_ID]: id,
                  [OBS_TAG_ENTITY_NAME]: name,
                  pathToDisplay: `${name}.onTimerInterval`,
                },
              }),
            ),
          );
        }
      },
    );
    windowTimerIds.set(id, interval);
  }
  return interval.nextInvocation;
};

export const stopTimerInterval = (id: AppTimer["id"]) => {
  const interval = windowTimerIds.get(id);
  if (interval) {
    interval.stop();
    windowTimerIds.delete(id);

    // Reset finished executing flag to true to prepare for next start
    isStillExecutingLastInterval.delete(id);
  }
};

export const stopAllTimerIntervals = () => {
  for (const interval of windowTimerIds.values()) {
    interval.stop();
  }

  // Reset local variables that track execution
  windowTimerIds.clear();
  isStillExecutingLastInterval.clear();
};
