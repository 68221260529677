import styled, { css } from "styled-components";

interface WrapperProps {
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
  overflow?: string;
}

const MainWrapper = styled.div.attrs((props: WrapperProps) => ({
  paddingLeft: props.paddingLeft ?? "40px",
  paddingRight: props.paddingRight ?? "40px",
  paddingTop: props.paddingTop ?? "40px",
  paddingBottom: props.paddingBottom ?? "40px",
  overflow: props.overflow,
}))`
  padding-left: ${(props) => props.paddingLeft};
  padding-right: ${(props) => props.paddingRight};
  padding-top: ${(props) => props.paddingTop};
  padding-bottom: ${(props) => props.paddingBottom};
  min-height: 100%;
  width: 100%;
  background-color: #fff;
  ${(props) =>
    props.overflow &&
    css`
      overflow-y: ${props.overflow};
    `}
`;

export default MainWrapper;
