import { type ApplicationScope } from "@superblocksteam/shared";
import { type EditorModes } from "components/app/CodeEditor/EditorConfig";
import { type ControlType } from "legacy/components/propertyControls";
import { type UnitOption } from "legacy/components/propertyControls/InputNumberControl";
import { type ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { type GeneratedTheme } from "legacy/themes";
import { type AllFlags } from "store/slices/featureFlags";
import { type AppState } from "store/types";
import { type ValidationType } from "./WidgetValidation";
import type { DataTreeWidget } from "legacy/entities/DataTree/dataTreeFactory";
import type { WidgetProps } from "legacy/widgets";
import type { Flags } from "store/slices/featureFlags/models/Flags";

export type UpdateHookArgs = {
  props: any;
  propertyPath: string;
  propertyValue: any;
  additionalDataForPropFunc?: Record<string, any>;
  flags?: Flags;
};

export type UpdateHookReturnUpdateItem = {
  propertyPath: string;
  propertyValue: any;
};

type UpdateHook = (
  params: UpdateHookArgs,
) =>
  | Array<UpdateHookReturnUpdateItem>
  | Promise<Array<UpdateHookReturnUpdateItem>>
  | undefined;

export type Hidden = (
  props: any,
  propertyPath: string,
  featureFlags: Flags,
  additionalHiddenData?: Record<string, any>,
  theme?: GeneratedTheme,
) => boolean;

export type ThemeValueFunction<PropType = any> = (obj: {
  theme: GeneratedTheme;
  props: PropType;
  flags: Flags;
  propertyName: string;
}) => {
  value: any;
  treatAsNull?: boolean;
};

type DefaultValueFn = ({
  props,
  propertyName,
  theme,
  flags,
}: {
  props: any;
  propertyName: string;
  theme?: GeneratedTheme;
  flags?: Flags;
}) => any;

type PropertyPathAccessor = (
  props: any,
  extras: {
    originalItemId?: string;
  },
) => string;

type PropertyVisibility =
  | "DEFAULT"
  | "SHOW_NAME" // show just the property name if the value is not defined
  | "IF_VALUE"; // Only show if the control is defined

export type HeaderType = "Collapse" | "Add" | "Large" | "Drawer";

export type PropertyPaneCategoryConfig<T = any> = Omit<
  PropertyPaneSectionConfig<T>,
  "children" | "sectionName"
> & {
  categoryName: string;
  children: PropertyPaneConfig<T>[];
};

export type PropertyPaneSectionConfig<T = any> = {
  key?: string; // Optional key to be used by react, if not supplied, propertyName is used
  sectionName: string; // To be deprecated by v2 of the property pane
  sectionCategory?: PropsPanelCategory; // the new v2 way to handle "sections". Children that specify a different cateogry will be broken out.
  helpText?: string | JSX.Element; // shown in tooltip
  showHeader?: boolean;
  children: PropertyPaneControlConfig<T>[];
  hidden?: Hidden;
  getAdditionalHiddenData?: AdditionalDataSelectors;
  isDefaultOpen?: boolean;
  propertySectionPath?: string;
  headerType?: HeaderType;
  addButtonTooltip?: string; // tooltip for "Add" headers
  sectionStyle?: React.CSSProperties;
  childrenStyle?: React.CSSProperties;
  subHeader?: string;
  dataTreePrefix?: string;
};

type PanelConfig = {
  // Each panel is an object with a unique ID
  panelIdPropertyName: string;
  editableTitle: boolean;
  // For example "widgetName" or "label"
  titlePropertyName?: string;
  children: PropertyPaneConfig[];
  updateHook?: UpdateHook;
  adaptiveHeight?: {
    maxHeight: number | string;
  };
};

export type PopoverPanelConfig = PanelConfig & {
  title?: string;
  isDeletable?:
    | boolean
    | ((props: WidgetProps, propertyPath: string) => boolean);
  showEditIcon?: boolean;
  docLink?: string; // link to documentation for a panel
  getItemId?: (
    originalItemId: string,
    panelProps?: Record<string, unknown>,
  ) => string;
  getItemKind?: (
    originalItemKind: ItemKinds,
    panelProps?: Record<string, unknown>,
  ) => ItemKinds;
};

export enum PropsPanelCategory {
  Content = "Content",
  Routing = "Routing",
  Interaction = "Interaction",
  Layout = "Layout",
  Appearance = "Appearance",
  Permissions = "Permissions",
  EventHandlers = "EventHandlers",
  Uncategorized = "Uncategorized", // do not set this explicitly on a property
}

export type AdditionalDataSelectors = Record<
  string,
  (appState: AppState) => unknown
>;

export type PropertyPaneControlConfig<T = any> = {
  key?: string; // Optional key to be used by react, if not supplied, propertyName is used
  itemId?: string;
  itemScope?: ApplicationScope;
  itemKind?: ItemKinds;
  label: string;
  hideLabel?: boolean;
  propertyName: (keyof T & string) | PropertyPathAccessor;
  propertyCategory?: PropsPanelCategory; // the new v2 way to handle "sections"
  forceVertical?: boolean; // Show label & input vertically. Only observed in v2
  labelIconPrefix?: JSX.Element;
  visibility?: PropertyVisibility;
  helpText?: string | JSX.Element; // shown in tooltip
  subheader?: string | JSX.Element; // shown below the label
  isJSConvertible?: boolean;
  customJSControl?: string;
  codeEditorMode?: EditorModes;
  // Opt-in to letting the user expand editor into bottom panel
  canExpandEditor?: boolean;
  controlType: ControlType;
  validationMessage?: string;
  dataTreePath?: string;
  children?: PropertyPaneControlConfig[];
  panelConfig?: PopoverPanelConfig;
  panelProps?: Record<string, unknown>;
  updateHook?: UpdateHook;
  hidden?: Hidden;
  getAdditionalHiddenData?: AdditionalDataSelectors;
  getAdditionalDataForPropFunc?: Record<
    string,
    (appState: AppState) => unknown
  >;
  isBindProperty: boolean;
  isTriggerProperty: boolean;
  appendedDocLink?: string;
  appendedDocLinkText?: string;
  dataTreePrefix?: string;
  isRemovable?: boolean; // when visibility is set to IF_VALUE, this allows the property to be hidden
  // used on timer control
  minValue?: number;
  // Used on the Input widget to declare a validation type
  inputType?: string;
  placeholderText?: string;
  options?: any[];
  optionsFunc?: (
    props: any,
    flags?: Flags,
    itemScope?: ApplicationScope,
    originalOptions?: any,
  ) => any[];
  optionsSelector?: (
    state: AppState,
    props: any,
    flags?: Partial<AllFlags>,
    propertyName?: string,
  ) => any[];
  warningFunc?: (params: {
    props: any;
    additionalDataForPropFunc?: Record<string, any>;
    propertyName: string;
  }) => string | undefined;
  warning?: string;
  optionsFuncWithAdditionalData?: ({
    props,
    additionalDataForPropFunc,
    propertyName,
  }: {
    props: any;
    additionalDataForPropFunc?: Record<string, any>;
    propertyName: string;
  }) => any[];
  menuFooterOptions?: Array<string | JSX.Element>;
  headerControlType?: ControlType;
  headerControlShowOnHover?: boolean;

  // supply this if property value is not a string
  optionValueToKey?: (value: any) => string;
  additionalAutoComplete?: Record<string, Record<string, unknown>>;
  getDynamicAdditionalAutocomplete?: (
    props: any,
    dataTreeEntity?: DataTreeWidget,
  ) => Record<string, Record<string, unknown>>;
  validation?: ValidationType;
  // Used by Step control
  stepType?: "ZOOM_PERCENTAGE";
  defaultValue?: any;
  defaultValueFn?: DefaultValueFn;
  themeValue?: string | object | ThemeValueFunction;
  resetToThemeBtnText?: string;
  hideLabelsForUnselected?: boolean;

  // used by object indexer control
  fieldToGetKeysOf?: string;
  preferredOptions?: Array<string>;
  isRequired?: boolean;

  // Used by EVENT_TRIGGER control to hide the control and show + Add button
  showAddButton?: boolean;

  // Used by RADIO
  radioType?: "RADIO" | "BUTTON_GROUP"; // defaults to BUTTON_GROUP

  // Used by HeightControlProps
  supportFitContent?: boolean | ((flags: Flags) => boolean);

  // Used by custom components
  ccExpectedType?: string;
  ccExampleData?: string;

  /** Use when a parent element needs to modify a child's props, such as containers.
   * Can also be used to modify the parent's props.
   */
  getTargetWidgetId?: (props: WidgetProps) => string;
  originalItemId?: string;

  // used by ChildListControl
  childFilter?: (props: WidgetProps) => boolean;
  showColumnRatio?: boolean;
  useIndexedNames?: boolean;

  // used by DimensionValueControl
  dimensionOptions?: {
    label: string;
    value: "px" | "gridUnit" | "fitContent" | "fillParent";
  }[];

  dimensionUnit?: "px" | "gridUnit";
  parentSpace?: number;

  // Used by input number control
  unitOptions?: Array<UnitOption>;
  defaultUnit?: string;
  precision?: number;
  transformValueOnUnitChange?: (params: {
    oldUnit: string | undefined;
    newUnit: string;
    value: unknown;
    path: string;
    widgetProperties?: any;
  }) => number | undefined;

  // Used by TextStyleControl
  enableTextColorConfig?: boolean;
};

export type PropertyPaneConfig<T = any> =
  | PropertyPaneSectionConfig<T>
  | PropertyPaneControlConfig<T>
  | PropertyPaneCategoryConfig<T>;
