import { Alert, AlertProps } from "antd";
import React from "react";
import styled from "styled-components";

export type AlertType = "success" | "error" | "info" | "warning" | undefined;

const StyledAlert = styled(Alert)`
  font-size: 12px;
  background: ${({ theme }) => theme.colors.DANGER_SUBTLE};
  border: 0;
  pre {
    margin-bottom: 0;
  }
  .ant-alert-message {
    font-size: 14px;
  }
  .ant-alert-description {
    font-size: 12px;
  }
`;

const InnerAlert = (props: AlertProps) => {
  return <StyledAlert {...props} />;
};

export default InnerAlert;
