import * as ContextMenu from "@radix-ui/react-context-menu";
import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { IconProps } from "legacy/constants/IconConstants";
import { Layers } from "legacy/constants/Layers";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

export const ContextMenuContentClass = styleAsClass`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 0px rgba(34, 39, 47, 0.32);
  box-shadow: 0px 12px 32px -8px rgba(34, 39, 47, 0.16);
  box-shadow: 0px 1px 3px 0px rgba(34, 39, 47, 0.12);
  min-width: 184px;
  max-width: 200px;
  z-index: ${String(Layers.contextMenuContent)};

  [role="menuitem"], .ContextMenuHeader {
    border-radius: 4px;
    padding: 6px 6px 6px 10px;
    height: 28px;
    line-height: 28px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${colors.GREY_700};
    font-size: 13px;
    cursor: pointer;
    overflow: hidden;
    gap: 4px;
  }

  [role="menuitem"].selected {
    background-color: ${colors.ACCENT_BLUE_500_25};

    svg {
      color: ${colors.ACCENT_BLUE_NEW_DARKER};
    }
  }

  [role="menuitem"][data-highlighted] {
    background-color: ${colors.GREY_50};
  }

  [role="menuitem"].danger {
    color: ${colors.DANGER};
  }

  [role="menuitem"][data-disabled], [role="menuitem"][data-highlighted][data-disabled] {
    color: ${colors.GREY_500};
    background-color: transparent;
    cursor: default;
  }

  [role="menuitem"].danger[data-highlighted] {
    background-color: ${colors.DANGER_SUBTLE};
  }

  .ContextMenuHeader {
    font-weight: 500;
    color: ${colors.GREY_800};
    cursor: default;
    background-color: ${colors.GREY_25};
    border-bottom: 1px solid ${colors.GREY_100};
    border-radius: 4px 4px 0 0;
    height: 36px;
    padding: 10px 16px;
  }

  [role="separator"] {
    height: 1px;
    background-color: ${colors.GREY_100};
    margin: 8px -8px;
  }
`;

const ContextMenuOverlay = () => {
  return ReactDOM.createPortal(
    <div
      id="WidgetContextMenuOverlay"
      style={{
        position: "fixed",
        inset: 0,
        zIndex: Layers.contextMenuOverlay,
        pointerEvents: "auto",
        backgroundColor: "transparent",
      }}
    />,
    document.body,
  );
};

export const Slot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  overflow: hidden;
`;

export const ItemText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 10;
`;

export const RightSlot = styled(Slot)`
  color: ${colors.GREY_300};
`;

export const ContentWrapper = styled.div`
  padding: 8px;
`;

const preventDefault = (e: React.MouseEvent) => {
  e.preventDefault();
};

const SBContextMenu: React.FC<{
  contextMenuId: string;
  children: React.ReactNode | React.ReactNode[];
  trigger: React.ReactNode;
  headerText?: string;
  headerDocsUrl?: string;
  isOpen: boolean;
  onCloseAutoFocus?: (e: Event) => void;
  onOpenChange: (open: boolean) => void;
  HeaderIcon?: React.JSXElementConstructor<IconProps>;
}> = ({
  children,
  contextMenuId,
  trigger,
  headerText,
  onCloseAutoFocus,
  onOpenChange,
  isOpen,
  HeaderIcon,
}) => {
  return (
    <>
      {isOpen && <ContextMenuOverlay />}
      <ContextMenu.Root modal={true} onOpenChange={onOpenChange}>
        <ContextMenu.Trigger id={contextMenuId}>{trigger}</ContextMenu.Trigger>
        <ContextMenu.Portal>
          <ContextMenu.Content
            className={ContextMenuContentClass}
            onCloseAutoFocus={onCloseAutoFocus}
            onContextMenu={preventDefault}
          >
            {headerText && (
              <>
                <div className="ContextMenuHeader">
                  <Slot>
                    {HeaderIcon && <HeaderIcon width={16} height={16} />}
                    <ItemText>{headerText}</ItemText>
                  </Slot>
                </div>
              </>
            )}
            <ContentWrapper>{children}</ContentWrapper>
          </ContextMenu.Content>
        </ContextMenu.Portal>
      </ContextMenu.Root>
    </>
  );
};

export default SBContextMenu;
