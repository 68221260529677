import { WidgetTypes } from "@superblocksteam/shared";
import React, { useMemo } from "react";
import styled from "styled-components";
import { ReactComponent as Edit } from "assets/icons/common/edit.svg";
import { ReactComponent as MinusCircle } from "assets/icons/common/minus-circle-red.svg";
import { ReactComponent as PlusCircle } from "assets/icons/common/plus-circle.svg";
import { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { generatePropertyControl } from "legacy/pages/Editor/PropertyPane/Generator";
import {
  ItemKinds,
  ItemTypeNonWidget,
} from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { getItemPropertyPaneConfig } from "legacy/pages/Editor/PropertyPane/ItemPropertyPaneConfig";
import { PanelChildProps } from "legacy/pages/Editor/PropertyPane/types";
import { getWidget } from "legacy/selectors/sagaSelectors";
import { useAppSelector } from "store/helpers";
import { colors } from "styles/colors";

type PrimaryColumnChangesObj = {
  addedColumns: Record<string, PropertyPaneConfig[]>;
  removedColumns: Set<string>;
  updatedColumns: Record<string, PropertyPaneConfig[]>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  background-color: ${colors.GREY_25};
  border-radius: 4px;
`;

const ColumnCard = styled.div`
  background-color: ${colors.WHITE};
  padding: 4px;
  border-radius: 4px;
`;

const ColumnHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  gap: 4px;
`;

const PropertyControlWrapper = styled.div`
  padding-left: 20px;
  padding-right: 12px;
`;

const getColorForAction = (action: "added" | "updated" | "removed") => {
  switch (action) {
    case "added":
      return colors.ACCENT_GREEN;
    case "updated":
      return colors.ACCENT_BLUE_500;
    case "removed":
      return colors.RED_500;
    default:
      return "inherit";
  }
};

export const PrimaryColumnChanges = ({
  widgetId,
  aiEdits,
  changedKeys,
  panel,
}: {
  widgetId: string;
  aiEdits: Record<string, any>;
  changedKeys: string[];
  panel: PanelChildProps;
}) => {
  const widgetProps = useAppSelector((state) => getWidget(state, widgetId));

  const flattenedProperties = useMemo(() => {
    const propSections = getItemPropertyPaneConfig(WidgetTypes.TABLE_WIDGET);
    return propSections.flatMap((section) => section.children);
  }, []);

  // special case for flattening changes to primary columns
  const primaryColumnUpdates: PrimaryColumnChangesObj | undefined =
    useMemo(() => {
      const primaryColumnKeys = changedKeys?.filter((key) =>
        key.startsWith("primaryColumns"),
      );
      if (!primaryColumnKeys || !primaryColumnKeys.length) {
        return;
      }
      const primaryColumnChanges: PrimaryColumnChangesObj = {
        addedColumns: {},
        removedColumns: new Set(),
        updatedColumns: {},
      };
      const existingColumns = Object.keys(
        (widgetProps as any)?.primaryColumns ?? {},
      );
      const newColumns = Object.keys(aiEdits?.primaryColumns ?? {});

      let columnConfigItems = (
        flattenedProperties.find(
          (item) => (item as any).propertyName === "primaryColumns",
        ) as any
      )?.panelConfig?.children;
      columnConfigItems = columnConfigItems?.flatMap(
        (item: any) => item.children,
      ) as PropertyPaneConfig[];

      existingColumns.forEach((column) => {
        if (!newColumns.includes(column)) {
          primaryColumnChanges.removedColumns.add(column);
        }
      });
      newColumns.forEach((column) => {
        if (!existingColumns.includes(column)) {
          primaryColumnChanges.addedColumns[column] = [];
        }
      });
      primaryColumnKeys.forEach((key) => {
        const columnName = key.split(".")[1];
        // property name is everything after primayColumns.columName.
        const propertyName = key.replace(`primaryColumns.${columnName}.`, "");
        const panelConfig = columnConfigItems.find(
          (item: any) => item.propertyName === propertyName,
        );
        if (!panelConfig) return;

        if (primaryColumnChanges.addedColumns[columnName]) {
          primaryColumnChanges.addedColumns[columnName] = [
            ...primaryColumnChanges.addedColumns[columnName],
            { ...panelConfig, propertyName: key }, // set key to the full key so that we can use it to update the property
          ];
        } else {
          primaryColumnChanges.updatedColumns[columnName] = [
            ...(primaryColumnChanges.updatedColumns[columnName] ?? []),
            { ...panelConfig, propertyName: key }, // set key to the full key so that we can use it to update the property
          ];
        }
      });
      return primaryColumnChanges;
    }, [changedKeys, aiEdits, widgetProps, flattenedProperties]);

  if (!primaryColumnUpdates) {
    return null;
  }
  const { addedColumns, removedColumns, updatedColumns } = primaryColumnUpdates;
  return (
    <Container>
      {Object.entries(addedColumns).map(([columnName, configs]) => (
        <ColumnCard key={columnName}>
          <ColumnHeader>
            <PlusCircle
              height={12}
              width={12}
              color={getColorForAction("added")}
            />
            <div>
              <span style={{ fontWeight: 500 }}>{columnName}</span>
              &nbsp;added
            </div>
          </ColumnHeader>
          <PropertyControlWrapper>
            {generatePropertyControl(configs, {
              itemKind: ItemKinds.AI_EDITS,
              itemId: widgetId,
              type: ItemTypeNonWidget.AI_EDITS,
              panel,
              panelProps: {},
              getPanelContainer: () => undefined,
            })}
          </PropertyControlWrapper>
        </ColumnCard>
      ))}

      {Object.entries(updatedColumns).map(([columnName, configs]) => (
        <ColumnCard key={columnName}>
          <ColumnHeader>
            <Edit height={12} width={12} color={getColorForAction("updated")} />
            <div>
              <span style={{ fontWeight: 500 }}>{columnName}</span>
              &nbsp;updated
            </div>
          </ColumnHeader>
          <PropertyControlWrapper>
            {generatePropertyControl(configs, {
              itemKind: ItemKinds.AI_EDITS,
              itemId: widgetId,
              type: ItemTypeNonWidget.AI_EDITS,
              panel,
              panelProps: {},
              getPanelContainer: () => undefined,
            })}
          </PropertyControlWrapper>
        </ColumnCard>
      ))}

      {Array.from(removedColumns).map((columnName) => (
        <ColumnCard key={columnName}>
          <ColumnHeader>
            <MinusCircle
              height={12}
              width={12}
              color={getColorForAction("removed")}
            />
            <div>
              <span style={{ fontWeight: 500 }}>{columnName}</span>
              &nbsp;removed
            </div>
          </ColumnHeader>
        </ColumnCard>
      ))}
    </Container>
  );
};
